export const Documents = {
  24: {
    name: "Bank Detail Proof",
  },
  59: {
    name: "Pancard",
  },
  67: {
    name: "GST Certificate",
  },
};
