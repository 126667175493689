import React, {Component} from "react";
import {Button, Col, Modal, Row, Table} from "reactstrap";
import {Products} from "../../../data/products";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import DropdownCustom from "../../../components/dropDown";
import moment from "moment";
import Loader from "../../../components/CustomUi/Loader/Loader";
import { downloadPDF } from '../../../helpers/utils';

class PolicyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPolicyActionDialog: false,
      cancelPolicyDialog: false,
      orderId: null,
      policyDoc: "",
      approveCancellationDialog: false,
      isCancellationInitiated: false,
      isCancelled: false,
      isBulkCancelled: this.props.policyHolderDetailsData?.is_cancelled,
      isBulkCancellationInitiated:
        this.props.policyHolderDetailsData?.status_id === 26,
      cancelConfirmDialog: false,
      approveConfirmDialog: false,
      updatePolicyDialog: false,
      productName: "",
      policyIssueDate: "",
      policyNumber: "",
      policy: "",
      productId: null,
      productOrderId: null,
      activePolicyCount: null,
    };
    this.getPolicyData();
    this.onValueChange = this.onValueChange.bind(this);
  }

  mobileAndCycleProductsList = [
    19, 22, 31, 33, 35, 36, 38, 40, 41, 43, 44, 45, 46,
  ];
  motorInsurancePoductsList = [61, 64, 66];
  assurancePoductsList = [
    75, 76, 77, 83, 84, 85, 78, 86, 87, 89, 90, 94, 95, 96, 97, 98, 99,
  ];
  lendingProductsList = [79, 80, 81, 82];

  mobileAndCycleProducts = (product_id) => {
    let isMatch = false;
    this.mobileAndCycleProductsList.find((productId) => {
      return (isMatch = product_id === productId);
    });
    return isMatch;
  };

  getPolicyData = () => {
    this.props.onGetPolicyHolderDetails(this.props.order_id);
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.policyHolderDetailsData !== this.props.policyHolderDetailsData
    ) {
      this.getInitialData();
    }
  }

  getInitialData = () => {
    this.setState({
      policyDetails: {
        policy_number:
          this.props.policyHolderDetailsData?.products[this.state.productId]
            ?.policy_number,
        start_date:
          this.props.policyHolderDetailsData?.products[this.state.productId]
            ?.start_date,
      },
      policyDocs: {
        policy_document:
          this.props.policyHolderDetailsData?.products[this.state.productId]
            ?.policy_document,
      },
    });
  };
  createPolicyOptions = [{ value: "createPolicy", text: "Create Policy" }];
  activePolicyOptions = [
    { value: "viewPolicyDocument", text: "View Plan Document" },
    { value: "viewInvoice", text: "Download Invoice" },
    { value: "cancelPolicy", text: "Cancel Plan" },
  ];
  docPendingCancellationInitiatedOptions = [
    { value: "cancellationApproved", text: "Cancellation Approved" },
    { value: "uploadPolicyDocument", text: "Upload Policy Document" },
  ];
  issuancePendingOptions = [
    { value: "approvePolicy", text: "Approve Policy" },
    { value: "uploadPolicyDocument", text: "Upload Policy Document" },
  ];
  docPendingOptions = [
    { value: "cancelPolicy", text: "Cancel Plan" },
    { value: "uploadPolicyDocument", text: "Upload Policy Document" },
  ];
  issuancePendingDocUploadedOptions = [
    { value: "approvePolicy", text: "Approve Policy" },
    { value: "viewPolicyDocument", text: "View Plan Document" },
  ];

  cancellationInitiatedOptions = [
    { value: "viewPolicyDocument", text: "View Plan Document" },
    { value: "cancellationApproved", text: "Cancellation Approved" },
  ];

  customDropdownOptions = (data) => {
    return data.policy_creation_ts
      ? data.is_cancelled
        ? ""
        : data.status_id === 26 && data.policy_document
        ? this.cancellationInitiatedOptions
        : data.status_id === 26 && !data.policy_document
        ? this.docPendingCancellationInitiatedOptions
        : data.policy_number === null && data.policy_document === null
        ? this.issuancePendingOptions
        : data.policy_number !== null && data.policy_document === null
        ? this.docPendingOptions
        : data.policy_number === null && data.policy_document !== null
        ? this.issuancePendingDocUploadedOptions
        : this.activePolicyOptions
      : this.mobileAndCycleProducts(
          this.props.policyHolderDetailsData?.product_id
        )
      ? this.createPolicyOptions
      : "";
  };

  trimDateTime(date) {
    let splittedString = date.split(" ");
    return splittedString.slice(0, -1).join("");
  }

  lastPolicyToCancel = (data) => {
    let counts = {};

    for (let i = 0; i < data.length; i++) {
      let value = data[i];
      counts[value] = counts[value] ? counts[value] + 1 : 1;
    }
    return counts[false];
  };

  handleAction = (
    e,
    { value },
    orderId,
    doc,
    statusId,
    product,
    product_id,
    oneActivePolicyRemaining,
    viewInvoice
  ) => {
    this.setState({
      orderId: orderId,
      policyDoc: doc,
      isCancellationInitiated: statusId === 26,
      isCancelled: statusId === 27,
      productName: product,
      dropDownValue: value,
    });
    if (value === "cancelPolicy") {
      this.setState({
        cancelConfirmDialog: true,
        activePolicyCount: this.lastPolicyToCancel(oneActivePolicyRemaining),
        dropDownValue: "",
      });
    }
    if (value === "cancellationApproved") {
      this.setState({
        cancelConfirmDialog: true,
        dropDownValue: "",
      });
    }
    if (value === "approvePolicy") {
      this.setState({
        updatePolicyDialog: true,
        policy: product,
        productId: product_id,
        productOrderId: orderId,
        dropDownValue: "",
      });
    }
    if (value === "uploadPolicyDocument") {
      this.setState({
        policy: product,
        productId: product_id,
        productOrderId: orderId,
        dropDownValue: "",
      });
      this.onClickUploadPolicyDocument();
    }
    if (value === "viewPolicyDocument") {
      if (doc) {
        if (this.isMotorInsuranceProduct(product_id)) {
          this.downloadPolicy(orderId);
        } else {
          window.open(doc);
          this.setState({
            dropDownValue: "",
          });
        }
      } else {
        this.showMsg("Policy Document not available ! ", "danger");
        this.setState({
          dropDownValue: "",
        });
      }
    }
    if (value === "viewInvoice") {
      if (viewInvoice) {
        console.log(doc, "doc");
        if (this.isMotorInsuranceProduct(product_id)) {
          this.downloadPolicy(orderId);
        } else {
          window.open(viewInvoice);
          this.setState({
            dropDownValue: "",
          });
        }
      } else {
        this.showMsg("Policy Document not available ! ", "danger");
        this.setState({
          dropDownValue: "",
        });
      }
    }
    if (value === "createPolicy") {
      this.createDocument(orderId, { document_type: "certificate" }, "Policy");
    }
  };
  handleCancelAction = (e) => {
    this.setState({
      cancelPolicyDialog: true,
    });
  };

  handleApproveAction() {
    this.setState({
      approveCancellationDialog: true,
    });
  }

  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type,
    });
  };

  onClickCancelAll = (type) => {
    this.onCancel(
      this.props.order_id,
      {
        action: type === "cancel" ? "initiate" : "approve",
      },
      type
    );
  };
  onCancel = (id, params, type) => {
    let text;
    if (type === "cancel") {
      text = "Initiated";
    } else {
      text = "Approved";
    }
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.getRequest("orders/" + id + "/cancel", params)
      .then((res) => {
        this.showMsg("Policy Cancellation " + text, "success");
        this.setState({
          showLoader: false,
          cancelPolicyDialog: false,
          approveCancellationDialog: false,
        });
        if (type === "cancel") {
          this.setState({
            isBulkCancellationInitiated: true,
          });
        } else {
          this.setState({
            isBulkCancelled: true,
            isBulkCancellationInitiated: false,
          });
        }
        this.props.refresh();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };
  onClickCancelSinglePolicy = (type) => {
    this.cancelSinglePolicy(
      this.state.orderId,
      {
        action: type === "cancel" ? "initiate" : "approve",
      },
      type
    );
  };
  cancelSinglePolicy = (id, params, type) => {
    let text;
    let order_id;
    if (type === "cancel") {
      text = "Initiated";
    } else {
      text = "Approved";
    }
    if (this.state.activePolicyCount === 1) {
      order_id = this.props.order_id;
    } else {
      order_id = id;
    }
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.getRequest("orders/" + order_id + "/cancel", params)
      .then((res) => {
        this.showMsg("Policy Cancellation " + text, "success");
        this.setState({
          showLoader: false,
          openPolicyActionDialog: false,
          orderId: null,
          cancelConfirmDialog: false,
        });
        this.props.refresh();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  onValueChange = (e, key) => {
    let policyDetails = this.state.policyDetails;
    policyDetails[key] = e.target.value;
    this.setState({
      userDetails: policyDetails,
    });
  };
  onClickUpdatePolicy = () => {
    let policyDetails = this.state.policyDetails;
    this.updateDetails(this.state.productOrderId, policyDetails);
  };

  updateDetails = (id, payload, type, base64) => {
    let policyDoc = this.state.policyDocs;
    policyDoc["policy_document"] = base64;
    this.setState({
      policyDocs: policyDoc,
    });
    if (type === "upload") {
      this.setState({
        uploading: true,
        showLoader: true,
      });
    } else {
      this.setState({
        showLoader: true,
      });
    }
    AxiosReactClient.putRequest(
      "orders/" + id,
      type === "upload" ? this.state.policyDocs : payload,
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.setState({
            uploadPercent: percentCompleted,
          });
        },
      }
    )
      .then((res) => {
        if (type === "upload") {
          this.setState({
            showLoader: false,
            uploading: false,
            uploadPercent: 0,
          });
          this.showMsg("Document uploaded successfully", "success");
          this.getPolicyData();
          // this.updateDocumentUrl(
          //     this.state.imageAsset,
          // );
        } else {
          this.setState({
            showLoader: false,
            updatePolicyDialog: false,
          });
          this.getPolicyData();
        }
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
          uploading: false,
          uploadPercent: 0,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  onClickUploadPolicyDocument(id, tag) {
    this.setState(
      {
        documentId: id,
        fileExists: false,
      },
      () => {
        this.refs.fileUploader.click();
      }
    );
  }

  detectImage = (e) => {
    this.setState({
      imageAsset: this.detectPdf(e.target.files[0].name)
        ? require("../../../assets/img/icons/pdf.png")
        : URL.createObjectURL(e.target.files[0]),
    });
    e.target.files[0] &&
      this.fileToBase64(e.target.files[0]).then((result) => {
        this.updateDetails(this.state.productOrderId, "", "upload", result);
        this.refs.fileUploader.value = "";
      });
  };

  fileToBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  detectPdf(url) {
    let fileType = url.split(".").slice(-1)[0];
    return fileType === "pdf";
  }

  updateDocumentUrl(url) {
    let allData = { ...this.props.policyHolderDetailsData.products };
    let data =
      allData[this.state.productId] && allData[this.state.productId]
        ? allData[this.state.productId]
        : [];
    data.push({
      policy_document: url,
    });
    allData = { ...allData, [this.state.productId]: data };
    let newData = {
      ...this.props.policyHolderDetailsData,
      products: allData,
    };
    this.props.updatePolicyHolderData(newData);
  }

  createDocument = (id, payload, type) => {
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.postRequest(`orders/${id}/create_documents`, payload)
      .then((res) => {
        this.setState({
          showLoader: false,
        });
        this.showMsg(`${type} Document Created Successfully`, "success");
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };
  createCovernote = () => {
    this.createDocument(
      this.props.policyHolderDetailsData?.order_id,
      { document_type: "covernote" },
      "Covernote"
    );
  };

  isMotorInsuranceProduct = (product_id) => {
    return this.motorInsurancePoductsList.indexOf(Number(product_id)) > -1
      ? true
      : false;
  };

  isAssuranceProduct = (product_id) => {
    return this.assurancePoductsList.indexOf(Number(product_id)) > -1
      ? true
      : false;
  };

  isLendingProduct = (product_id) => {
    return this.lendingProductsList.indexOf(Number(product_id)) > -1
      ? true
      : false;
  };

  downloadPolicy = async (orderId) => {
    const response = await AxiosReactClient.getRequest(
      `order/${orderId}/downloadPolicy`
    );

    this.setState({ dropDownValue: "" });
    downloadPDF(response.data);
  };

  render() {
    let oneActivePolicyRemaining = [];
    console.log(this.props.policyHolderDetailsData, "data");
    return (
      <>
        <Row>
          <Col lg="6" md="6" xl="6">
            <h2>
              {this.props.policyHolderDetailsData &&
                Products[this.props.policyHolderDetailsData?.product_id].name}
            </h2>
          </Col>
          <Col lg="6" md="6" xl="6">
            <div className={"d-flex justify-content-end"}>
              {this.mobileAndCycleProducts(
                this.props.policyHolderDetailsData?.product_id
              ) && (
                <>
                  <Button
                    className="shadow-none"
                    size="sm"
                    outline
                    color={"primary"}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.createDocument(
                        this.props.policyHolderDetailsData?.order_id,
                        { document_type: "certificate" },
                        "Policy"
                      );
                    }}
                  >
                    Create Policy
                  </Button>
                  {this.props.policyHolderDetailsData?.covernote_available && (
                    <Button
                      className="shadow-none"
                      size="sm"
                      outline
                      color={"primary"}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.createCovernote();
                      }}
                    >
                      Create Covernote
                    </Button>
                  )}
                </>
              )}
              {this.props.policyHolderDetailsData?.covernote_available &&
                this.props.policyHolderDetailsData?.covernote &&
                !this.isMotorInsuranceProduct(
                  this.props.policyHolderDetailsData?.product_id
                ) && (
                  <Button
                    className="shadow-none"
                    size="sm"
                    outline
                    color={"primary"}
                    type="button"
                    onClick={(e) => {
                      window.open(this.props.policyHolderDetailsData.covernote);
                    }}
                  >
                    View Cover Note
                  </Button>
                )}
              {!this.props.policyHolderDetailsData?.is_cancelled ? (
                <>
                  {!this.state.isBulkCancellationInitiated &&
                    !this.state.isBulkCancelled && (
                      <Button
                        className="shadow-none"
                        size="sm"
                        contained={"true"}
                        color={"primary"}
                        type="button"
                        onClick={(e) => {
                          this.handleCancelAction();
                        }}
                      >
                        Cancel Plan
                      </Button>
                    )}
                  {this.state.isBulkCancellationInitiated && (
                    <Button
                      className="shadow-none"
                      size="sm"
                      contained
                      color={"primary"}
                      type="button"
                      onClick={(e) => {
                        this.handleApproveAction();
                      }}
                    >
                      Approve Cancellation
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row className={"mt-4"}>
          <Col>
            <div className="table-responsive">
              <Table
                className="align-items-center table-flush"
                hover
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th>Plan Number</th>
                    <th>Plan</th>
                    <th>Plan Premium</th>
                    {/* <th>Sum Insured</th> */}
                    <th>Issue Date</th>
                    <th>Expiry Date</th>
                    <th>Status</th>
                    <th className="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.policyHolderDetailsData &&
                    Object.values(
                      this.props.policyHolderDetailsData.products
                    ).map((data, index) => {
                      let product_id = Object.keys(
                        this.props.policyHolderDetailsData.products
                      )[index];
                      oneActivePolicyRemaining = [
                        ...oneActivePolicyRemaining,
                        data.is_cancelled,
                      ];
                      return (
                        <tr key={index + "dfsdgf"}>
                          <td className="table-user">
                            {data.policy_number ? data.policy_number : "N/A"}
                          </td>
                          <td>
                            <b>{Products[product_id]?.name}</b> <br />
                            {Products[product_id]?.provider}
                          </td>
                          <td>{data.premium}</td>
                          {/* <td>
                                            {data.sum_insured}
                                        </td> */}
                          <td>{data.start_date ? data.start_date : "N/A"}</td>
                          <td>
                            {data.end_date
                              ? data.end_date && data.end_date
                              : "N/A"}
                          </td>
                          <td
                            className={
                              data.status === "CANCELED" && "text-danger"
                            }
                          >
                            {data.status === "POLICY_CREATED"
                              ? "PLAN_CREATED"
                              : data.status}
                          </td>
                          <td className="text-right">
                            <DropdownCustom
                              open={this.state.isDropdownOpen}
                              trigger={
                                <i
                                  className="fa fa-ellipsis-v cursor-pointer"
                                  aria-hidden="true"
                                />
                              }
                              options={this.customDropdownOptions(data)}
                              position={"top right"}
                              onClickOption={(e, { value }) => {
                                console.log(value, e, "doc");
                                this.handleAction(
                                  e,
                                  { value },
                                  data.order_id,
                                  data.policy_document,
                                  data.status_id,
                                  Products[product_id]?.name,
                                  product_id,
                                  oneActivePolicyRemaining,
                                  this.props.policyHolderDetailsData
                                    ?.invoice_photo
                                );
                              }}
                              value={this.state.dropDownValue}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <input
                type="file"
                accept="image/*,.pdf"
                hidden
                id="file"
                ref="fileUploader"
                onChange={this.detectImage}
              />

              <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={this.state.cancelPolicyDialog}
                toggle={() => {
                  this.setState({
                    cancelPolicyDialog: !this.state.cancelPolicyDialog,
                  });
                }}
              >
                <div className={"modal-header"}>
                  <h4>Action</h4>
                  <i
                    className="ni ni-fat-remove fnt-20 cursor-pointer"
                    onClick={(e) => {
                      this.setState({
                        cancelPolicyDialog: false,
                      });
                    }}
                  />
                </div>
                <hr className={"text-dark m-0 p-0"} />
                <div className="modal-body text-center">
                  <h5 className="mt-0 m-auto">
                    Do you really want to cancel this policy?
                  </h5>
                </div>
                <div className="modal-footer date-modal-footer ">
                  <Button
                    color="primary"
                    className="ml-auto"
                    type="button"
                    size="sm"
                    onClick={(e) => {
                      this.onClickCancelAll("cancel");
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    color="primary"
                    data-dismiss="modal"
                    type="button"
                    size="sm"
                    onClick={() => {
                      this.setState({
                        cancelPolicyDialog: false,
                        isBulkCancelled: false,
                      });
                    }}
                  >
                    No
                  </Button>
                </div>
              </Modal>

              <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={this.state.approveCancellationDialog}
                toggle={() => {
                  this.setState({
                    approveCancellationDialog:
                      !this.state.approveCancellationDialog,
                  });
                }}
              >
                <div className={"modal-header"}>
                  <h4>Action</h4>
                  <i
                    className="ni ni-fat-remove fnt-20 cursor-pointer"
                    onClick={(e) => {
                      this.setState({
                        approveCancellationDialog: false,
                      });
                    }}
                  />
                </div>
                <hr className={"text-dark m-0 p-0"} />
                <div className="modal-body text-center">
                  <h5 className="mt-0 m-auto">
                    Cancellation Approved for this policy
                  </h5>
                </div>
                <div className="modal-footer date-modal-footer ">
                  <Button
                    color="primary"
                    className="ml-auto"
                    type="button"
                    size="sm"
                    onClick={(e) => {
                      this.onClickCancelAll("approve");
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    color="primary"
                    data-dismiss="modal"
                    type="button"
                    size="sm"
                    onClick={() => {
                      this.setState({
                        approveCancellationDialog: false,
                      });
                    }}
                  >
                    No
                  </Button>
                </div>
              </Modal>

              <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={this.state.cancelConfirmDialog}
                toggle={() => {
                  this.setState({
                    cancelConfirmDialog: !this.state.cancelConfirmDialog,
                  });
                }}
              >
                <div className={"modal-header"}>
                  <h4>Action</h4>
                  <i
                    className="ni ni-fat-remove fnt-20 cursor-pointer"
                    onClick={(e) => {
                      this.setState({
                        cancelConfirmDialog: false,
                      });
                    }}
                  />
                </div>
                <hr className={"text-dark m-0 p-0"} />
                <div className="modal-body text-center mb-3">
                  <div className="list-group">
                    <h5>
                      Do you really want to{" "}
                      {!this.state.isCancellationInitiated
                        ? "Initiate Cancellation "
                        : "Approve Cancellation "}
                      for {this.state.productName && this.state.productName}{" "}
                      policy ?
                    </h5>
                  </div>
                </div>
                <div className="modal-footer date-modal-footer ">
                  {this.state.isCancellationInitiated && (
                    <Button
                      color="primary"
                      data-dismiss="modal"
                      type="button"
                      size="sm"
                      onClick={() => {
                        this.onClickCancelSinglePolicy("approve");
                      }}
                    >
                      Yes
                    </Button>
                  )}
                  {!this.state.isCancellationInitiated && (
                    <Button
                      color="primary"
                      data-dismiss="modal"
                      type="button"
                      size="sm"
                      onClick={() => {
                        this.onClickCancelSinglePolicy("cancel");
                      }}
                    >
                      Yes
                    </Button>
                  )}
                  <Button
                    color="primary"
                    data-dismiss="modal"
                    type="button"
                    size="sm"
                    onClick={() => {
                      this.setState({
                        cancelConfirmDialog: false,
                      });
                    }}
                  >
                    No
                  </Button>
                </div>
              </Modal>

              <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={this.state.updatePolicyDialog}
                toggle={() => {
                  this.setState({
                    updatePolicyDialog: !this.state.updatePolicyDialog,
                  });
                }}
              >
                <div className={"modal-header"}>
                  <h4>{this.state.policy}</h4>
                  <i
                    className="ni ni-fat-remove fnt-20 cursor-pointer"
                    onClick={(e) => {
                      this.setState({
                        updatePolicyDialog: false,
                      });
                    }}
                  />
                </div>
                <div className="modal-body text-center">
                  <Row>
                    <Col lg="6" md="6" xl="6">
                      <input
                        type="text"
                        className={
                          "form-control bg-transparent border-1 text-dark"
                        }
                        placeholder={"Enter Policy number"}
                        onChange={(e) => this.onValueChange(e, "policy_number")}
                      />
                    </Col>
                    <Col lg="6" md="6" xl="6">
                      <input
                        type="date"
                        max={moment().subtract(0, "years").format("YYYY-MM-DD")}
                        className={
                          "form-control bg-transparent border-1 text-dark"
                        }
                        placeholder={"Enter Policy Issue Date"}
                        onChange={(e) => this.onValueChange(e, "start_date")}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer date-modal-footer ">
                  <Button
                    color="primary"
                    data-dismiss="modal"
                    type="button"
                    size="sm"
                    onClick={() => {
                      this.setState({
                        updatePolicyDialog: false,
                      });
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    className="ml-auto"
                    type="button"
                    size="sm"
                    onClick={(e) => {
                      this.onClickUpdatePolicy();
                    }}
                  >
                    Done
                  </Button>
                </div>
              </Modal>
              {this.state.showLoader && <Loader msg={"Please Wait..."} />}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
    return {
        policyCancellationLoading: state.policies.policyCancellationLoading,
        policyHolderDetailsLoading: state.policies.policyHolderDetailsLoading,
        policyHolderDetailsData: state.policies.policyHolderDetailsData,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetPolicyCancellationData: (notification) => dispatch(actions.getPolicyCancellationData(notification)),
        onGetPolicyHolderDetails: (id) => dispatch(actions.getPolicyHolderDetails(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyDetails);