import authRoute from "./views/pages/Auth/authRoute";
import dealerRoute from "./views/pages/Dealers/dealerRoute";
import partnerRoute from "./views/pages/Partners/partnerRoute";
import profileRoute from "./views/pages/Profile/profileRoute";
import serviceCentreRoute from "./views/pages/ServiceCentre/serviceCentreRoute";
// import providerRoute from "./views/pages/Providers/providerRoute";
import dashboardRoute from "./views/pages/Dashboard/dashboardRoute";
import policiesRoute from "./views/pages/Policies/policiesRoute";
import settingsRoute from "./views/pages/Settings/settingsRoute";
// import financeRoute from "./views/pages/Finance/financeRoute";
// import quotationsRoute from "./views/pages/Quotations/quotationsRoute";
// import petRoute from "./views/pages/pet/petRoute";
import forgotPasswordRoute from "./views/pages/Password/forgotPasswordRoute";
import reportRoute from "./views/pages/Report/reportRoute";

const routes = [
  dashboardRoute,
  authRoute,
  profileRoute,
  policiesRoute,
  // financeRoute,
  dealerRoute,
  serviceCentreRoute,
  reportRoute,
  // productRoute,
  partnerRoute,
  // quotationsRoute,
  // petRoute,
  settingsRoute,
  forgotPasswordRoute,
];

export default routes;
