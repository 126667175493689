import Connection from "../config/Connection";
import querystring from "querystring";
// import { NetInfo, } from "react-native";
import axios from "axios";
const googleApiKey = "AIzaSyDrRXycUYMU-vM4UMzKHnQSh1gv81nv4Xs";

class AxiosRestClient {
  static getGooglePlaceByPincode(pincode) {
    return axios.get(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        pincode +
        "&key=" +
        googleApiKey
    );
  }

  static postLoginRequest(url, payload) {
    return axios.post(Connection.getResturl() + url, payload);
  }

  static getRequest(url, params, isLocal = false) {
    const token = localStorage.getItem("token");
    let query = querystring.stringify(params);
    return axios.get(
      isLocal ? url : Connection.getResturl() + url + "?" + query,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  static postRequest(url, payload, config = {}) {
    const token = localStorage.getItem("token");

    return axios.post(Connection.getResturl() + url, payload, {
      headers: {
        Authorization: "Bearer " + token,
      },
      ...config,
    });
  }

  static postImageRequest(url, payload) {
    const token = localStorage.getItem("token");
    return axios.post(Connection.getResturl() + url, payload, {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/x-www-form-urlencoded",
      },
    });
  }
  static putRequest(url, payload) {
    const token = localStorage.getItem("token");

    return axios.put(Connection.getResturl() + url, payload, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  static deleteRequest(url, payload) {
    const token = localStorage.getItem("token");

    return axios.delete(Connection.getResturl() + url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  static patchRequest(url, payload) {
    const token = localStorage.getItem("token");

    return axios.patch(Connection.getResturl() + url, payload, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
}

export default AxiosRestClient;
