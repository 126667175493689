import { Box, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router";
import ReactDatetime from "react-datetime";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import AxiosReactClient from "../../../../utilities/AxiosRestClient";

const AddInvoices = () => {
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [error, setError] = useState("");
  const today = new Date().toISOString().split("T")[0];
  console.log(date, "....");
  console.log(date, "...");
  const history = useHistory();

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDate((prevDate) => ({
      ...prevDate,
      [name]: value,
    }));
    // Validate dates
    if (name === "startDate" && value > date.endDate) {
      setError("Start date cannot be greater than end date");
    } else if (name === "endDate" && value < date.startDate) {
      setError("End date cannot be less than start date");
    } else {
      setError("");
    }
  };
  const handleSubmit = async () => {
    const query = {
      date: "custom",
      state_id: "all",
      startDate: date.startDate,
      endDate: date.endDate,
    };
    try {
      const response = await AxiosReactClient.postRequest(
        `dealers/invoice`,
        query
      );
      // response?.data?.data?.message  key for showing msg
      console.log(response?.data?.data?.message, "...");
      if (response?.data.code === 200) {
        history.push("/admin/invoices");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Fragment>
      <Container fluid>
        <CardHeader className="border-0 px-0 bg-transparent ">
          <Row className="align-items-center py-4">
            <Col xs="6">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listClassName="breadcrumb-links breadcrumb-dark"
              >
                <BreadcrumbItem>
                  <a
                    href="#pablo"
                    className="text-primary h5 font-weight-400"
                    onClick={(e) => history.push("/admin/invoices")}
                  >
                    Invoices
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a href="#pablo" className="text-primary h5 font-weight-400">
                    Create Invoice
                  </a>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </CardHeader>
        <Card className="mb-6">
          <CardBody className="p-4">
            <Typography variant="h5">Create Invoice</Typography>
            <Box
              className="mt-4 d-flex"
              style={{ width: "60%", justifyContent: "space-between" }}
            >
              <div>
                <label htmlFor="startDate">Start Date</label> <br />
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={date.startDate}
                  max={today}
                  onChange={handleDateChange}
                  style={{ width: "20rem" }}
                />
              </div>
              <div>
                <label htmlFor="endDate">End Date</label> <br />
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={date.endDate}
                  max={today}
                  onChange={handleDateChange}
                  style={{ width: "20rem" }}
                />
              </div>
            </Box>
            {error && (
              <Box className="mt-2">
                <Typography color="error">{error}</Typography>
              </Box>
            )}
            <Box className="mt-5">
              <Button
                color="primary"
                type="button"
                onClick={handleSubmit}
                disabled={error ? true : false}
              >
                Submit
              </Button>
            </Box>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default AddInvoices;
