import React from "react";
import ReactDatetime from "react-datetime";
import StateDropdown from "../StateDropdown/StateDropdown";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Row,
  Col,
  Badge,
  Form,
  FormGroup,
  Modal,
  Button,
} from "reactstrap";
import moment from "moment";
import { defaultStatusId } from "../../helpers/utils";
class AlternativeHeader extends React.Component {
  state = {
    filter: this.props.defaultFilter ?? "today",
    stateId:
      // defaultStatusId() === "all" ? this.props.state_id : 
      defaultStatusId(),
    showRangeModal: false,
  };

  onRefreshAllDashboardComponents(filter, stateId) {
    this.setState({
      filter: filter,
      stateId: stateId,
    });
    if (filter === "custom") {
      this.props.click(filter, {
        state_id: stateId ?? "all",
        startDate: new Date(this.state.startDate._d).toDateString(),
        endDate: new Date(this.state.endDate._d).toDateString(),
      });
    } else {
      this.props.click(filter, {
        state_id: stateId ?? "all",
      });
    }
  }
  openRangeModal() {
    this.setState({
      startDate: moment(moment(new Date())).subtract(6, "days"),
      endDate: moment(moment(new Date())),
    });
    this.setState({
      showRangeModal: true,
    });
  }
  closeRangeModal() {
    this.setState({
      showRangeModal: false,
    });
  }
  sendDateRequest() {
    this.props.click("custom", {
      startDate: new Date(this.state.startDate._d).toDateString(),
      endDate: new Date(this.state.endDate._d).toDateString(),
      state_id: this.state.stateId,
    });
    this.setState({
      showRangeModal: false,
      filter: "custom",
    });
    // if (this.props.title === "All Plans" || "Cancel Plans") {
    //   var map = {
    //     date: "custom",
    //     startDate: new Date(this.state.startDate._d).toDateString(),
    //     endDate: new Date(this.state.endDate._d).toDateString(),
    //     state_id: this.state.stateId,
    //   };
    //   this.onRefreshAllDashboardComponents(map, this.state.stateId);
    // }
  }
  onReactDateFocused = (who) => {
    if (who === "startDate") {
      this.setState({
        openEndDateDialog: false,
        openStartDateDialog: true,
      });
    } else {
      this.setState({
        openEndDateDialog: true,
        openStartDateDialog: false,
      });
    }
  };
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [who]: date,
      });
    }

    if (who === "startDate") {
      this.setState({
        endDate: moment(date).add(6, "days"),
      });
      this.setState({
        openEndDateDialog: true,
        openStartDateDialog: false,
      });
    }
    if (who === "endDate") {
      this.setState({
        openEndDateDialog: false,
        openStartDateDialog: false,
      });
    }
  };
  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };
  disableFutureDt = (current) => {
    const today = moment();
    return current.isBefore(today);
  };
  isValidEndDate = (current) => {
    try {
      const startDate = moment(this.state.startDate).startOf("day");
      const endDate = moment(this.state.startDate)
        .add(1, "year")
        .subtract(1, "days");
      const currentEnddate = endDate.startOf("day");
      return (
        current.isSameOrAfter(startDate) &&
        current.isSameOrBefore(currentEnddate)
      );
    } catch (error) {
      console.log("error", error);
    }
  };
  render() {
    console.log(this.state, "this");
    return (
      <>
        <div className={`header ${this.props.class}`}>
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="4" xs="6">
                  <Breadcrumb
                    className="d-none d-md-inline-block"
                    listClassName="breadcrumb-links"
                  >
                    {this.props.icon && (
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <i className={`fas ${this.props.icon}`} />
                        </a>
                      </BreadcrumbItem>
                    )}
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        {this.props.title}
                      </a>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
              <Row className="align-items-center py-4">
                <Col lg="4">
                  <StateDropdown
                    onChange={(selectedOption) => {
                      this.onRefreshAllDashboardComponents(
                        this.state.filter,
                        selectedOption.id
                      );
                    }}
                  />
                </Col>
                <Col className="text-right" lg="8" xs="6">
                  <div className="d-none d-md-block dashboard-duration-filter">
                    {/* <Badge
                      color={this.state.filter === 'all' ? 'primary' : 'secondary'}
                      href="#pablo"
                      pill
                      onClick={e => {
                        e.preventDefault();
                        this.onRefreshAllDashboardComponents('all')
                      }}
                    >
                      All</Badge> */}
                    <Badge
                      color={
                        this.state.filter === "today" ? "primary" : "secondary"
                      }
                      href="#pablo"
                      pill
                      onClick={(e) => {
                        e.preventDefault();
                        this.onRefreshAllDashboardComponents(
                          "today",
                          this.state.stateId
                        );
                      }}
                    >
                      Today
                    </Badge>
                    <Badge
                      color={
                        this.state.filter === "yesterday"
                          ? "primary"
                          : "secondary"
                      }
                      href="#pablo"
                      pill
                      onClick={(e) => {
                        e.preventDefault();
                        this.onRefreshAllDashboardComponents(
                          "yesterday",
                          this.state.stateId
                        );
                      }}
                    >
                      Yesterday
                    </Badge>
                    <Badge
                      color={
                        this.state.filter === "thisWeek"
                          ? "primary"
                          : "secondary"
                      }
                      href="#pablo"
                      pill
                      onClick={(e) => {
                        e.preventDefault();
                        this.onRefreshAllDashboardComponents(
                          "thisWeek",
                          this.state.stateId
                        );
                      }}
                    >
                      This Week
                    </Badge>
                    <Badge
                      color={
                        this.state.filter === "thisMonth"
                          ? "primary"
                          : "secondary"
                      }
                      href="#pablo"
                      pill
                      onClick={(e) => {
                        e.preventDefault();
                        this.onRefreshAllDashboardComponents(
                          "thisMonth",
                          this.state.stateId
                        );
                      }}
                    >
                      This Month
                    </Badge>
                    <Badge
                      color={
                        this.state.filter === "lastWeek"
                          ? "primary"
                          : "secondary"
                      }
                      href="#pablo"
                      pill
                      onClick={(e) => {
                        e.preventDefault();
                        this.onRefreshAllDashboardComponents(
                          "lastWeek",
                          this.state.stateId
                        );
                      }}
                    >
                      Last Week
                    </Badge>
                    <Badge
                      color={
                        this.state.filter === "lastMonth"
                          ? "primary"
                          : "secondary"
                      }
                      href="#pablo"
                      pill
                      onClick={(e) => {
                        e.preventDefault();
                        this.onRefreshAllDashboardComponents(
                          "lastMonth",
                          this.state.stateId
                        );
                      }}
                    >
                      Last Month
                    </Badge>
                    <Badge
                      color={
                        this.state.filter === "custom" ? "primary" : "secondary"
                      }
                      href="#pablo"
                      pill
                      onClick={(e) => {
                        e.preventDefault();
                        this.openRangeModal();
                      }}
                    >
                      {this.state.filter !== "custom"
                        ? "Custom"
                        : this.state.startDate?._d.toLocaleDateString() +
                          " - " +
                          this.state.endDate?._d.toLocaleDateString()}
                    </Badge>
                    {this.props.allDisabled === "false" ? (
                      ""
                    ) : (
                      <Badge
                        color={
                          this.state.filter === "all" ? "primary" : "secondary"
                        }
                        href="#pablo"
                        pill
                        onClick={(e) => {
                          e.preventDefault();
                          this.onRefreshAllDashboardComponents(
                            "all",
                            this.state.stateId
                          );
                        }}
                      >
                        All Time
                      </Badge>
                    )}
                    <Modal
                      className="modal-dialog-centered"
                      size="sm"
                      isOpen={this.state.showRangeModal}
                      toggle={() => this.closeRangeModal()}
                    >
                      <div className="modal-body">
                        <Form role="form">
                          <Row className="input-daterange datepicker align-items-center">
                            <Col xs={12} sm={6}>
                              <label className=" form-control-label">
                                Start date
                              </label>
                              <FormGroup>
                                <ReactDatetime
                                  open={this.state.openStartDateDialog}
                                  isValidDate={this.disableFutureDt}
                                  onFocus={(e) =>
                                    this.onReactDateFocused("startDate")
                                  }
                                  inputProps={{
                                    placeholder: "Start Date",
                                    readOnly: true,
                                  }}
                                  value={this.state.startDate}
                                  timeFormat={false}
                                  onChange={(e) =>
                                    this.handleReactDatetimeChange(
                                      "startDate",
                                      e
                                    )
                                  }
                                  renderDay={(
                                    props,
                                    currentDate,
                                    selectedDate
                                  ) => {
                                    let classes = props.className;
                                    classes +=
                                      this.getClassNameReactDatetimeDays(
                                        currentDate
                                      );
                                    return (
                                      <td {...props} className={classes}>
                                        {currentDate.date()}
                                      </td>
                                    );
                                  }}
                                  dateFormat="DD/MM/YYYY"
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                              <FormGroup>
                                <label className=" form-control-label">
                                  End date
                                </label>
                                <ReactDatetime
                                  open={this.state.openEndDateDialog}
                                  isValidDate={this.isValidEndDate}
                                  onFocus={(e) =>
                                    this.onReactDateFocused("endDate")
                                  }
                                  inputProps={{
                                    placeholder: "End Date",
                                    readOnly: true,
                                  }}
                                  value={this.state.endDate}
                                  timeFormat={false}
                                  onChange={(e) =>
                                    this.handleReactDatetimeChange("endDate", e)
                                  }
                                  renderDay={(
                                    props,
                                    currentDate,
                                    selectedDate
                                  ) => {
                                    let classes = props.className;
                                    classes +=
                                      this.getClassNameReactDatetimeDays(
                                        currentDate
                                      );
                                    return (
                                      <td {...props} className={classes}>
                                        {currentDate.date()}
                                      </td>
                                    );
                                  }}
                                  dateFormat="DD/MM/YYYY"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                      <div className="modal-footer date-modal-footer ">
                        <Button
                          color="link"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => this.closeRangeModal()}
                        >
                          Close
                        </Button>
                        <Button
                          color="primary"
                          className="ml-auto"
                          type="button"
                          onClick={() => this.sendDateRequest()}
                        >
                          Apply
                        </Button>
                      </div>
                    </Modal>
                  </div>
                  <UncontrolledDropdown className="d-md-none">
                    <DropdownToggle
                      className="btn-neutral"
                      color=""
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Filters
                    </DropdownToggle>
                    <DropdownMenu right>
                      {/* <DropdownItem
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          this.onRefreshAllDashboardComponents('all&state_id='.this.state.stateId)
                        }}
                      >
                        All {(this.props.filter === 'all') ? <i className="ni ni-check-bold" ></i> : ''}
                      </DropdownItem> */}
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onRefreshAllDashboardComponents(
                            "today",
                            this.state.stateId
                          );
                        }}
                      >
                        Today{" "}
                        {this.props.filter === "today" ? (
                          <i className="ni ni-check-bold"></i>
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onRefreshAllDashboardComponents(
                            "yesterday",
                            this.state.stateId
                          );
                        }}
                      >
                        Yesterday{" "}
                        {this.props.filter === "yesterday" ? (
                          <i className="ni ni-check-bold"></i>
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onRefreshAllDashboardComponents(
                            "thisWeek",
                            this.state.stateId
                          );
                        }}
                      >
                        This Week{" "}
                        {this.props.filter === "thisWeek" ? (
                          <i className="ni ni-check-bold"></i>
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onRefreshAllDashboardComponents(
                            "thisMonth",
                            this.state.stateId
                          );
                        }}
                      >
                        This Month{" "}
                        {this.props.filter === "thisMonth" ? (
                          <i className="ni ni-check-bold"></i>
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onRefreshAllDashboardComponents(
                            "lastWeek",
                            this.state.stateId
                          );
                        }}
                      >
                        Last Week{" "}
                        {this.props.filter === "lastWeek" ? (
                          <i className="ni ni-check-bold"></i>
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onRefreshAllDashboardComponents(
                            "lastMonth",
                            this.state.stateId
                          );
                        }}
                      >
                        Last Month{" "}
                        {this.props.filter === "lastMonth" ? (
                          <i className="ni ni-check-bold"></i>
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.openRangeModal();
                        }}
                      >
                        {this.state.filter !== "custom"
                          ? "Custom"
                          : this.state.startDate._d.toLocaleDateString() +
                            " - " +
                            this.state.endDate._d.toLocaleDateString() +
                            " "}
                        {this.props.filter === "custom" ? (
                          <i className="ni ni-check-bold"></i>
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default AlternativeHeader;
