// import Dealers from "./Dealers.jsx";
import * as permissions from "../../../config/Casl/permissions";
import AllRetailersListing from "../Partners/AllRetailersListing.jsx";
import PartnerDealersListing from "../Partners/Dealers";
import FranchiseListing from "../Report/franchise/FranchiseListing.jsx";
import FranchiseRetailersListing from "../Partners/FranchiseRetailersListing.jsx";
import AddDealer from "./AddDealer.jsx";
import dealerView from "./DealerView.jsx";
import DealerPolicies from "./Policies.jsx";
import Policy from "./Policy";

const dealerRoute = {
  collapse: true,
  name: "Retailers",
  icon: "ni ni-badge text-primary",
  state: "dealersCollapse",
  permission: permissions.DEALER.menu,
  subject: permissions.DEALER.subject,
  views: [
    /* {
        path: "/dealers/all",
        name: "All Dealers",
        component: Dealers,
        layout: "/admin",
        permission: permissions.DEALER.list,
        subject: permissions.DEALER.subject
    }, */
    {
      path: "/partners/retailers",
      name: "Active Retailers",
      component: PartnerDealersListing,
      layout: "/admin",
      permission: permissions.PARTNERS.dealer_list,
      subject: permissions.PARTNERS.subject,
    },
    {
      path: "/partners/allretailers",
      name: "All Retailers",
      component: AllRetailersListing,
      layout: "/admin",
      permission: permissions.PARTNERS.retailers,
      subject: permissions.PARTNERS.subject,
    },
    {
      path: "/partners/franchise-retailers",
      name: "Franchise Retailers",
      component: FranchiseRetailersListing,
      layout: "/admin",
      // permission: permissions.PARTNERS.franchise,
      // subject: permissions.PARTNERS.subject,
    },
    {
      path: "/franchise",
      name: "Franchise",
      component: FranchiseListing,
      layout: "/admin",
      permission: permissions.PARTNERS.franchise,
      subject: permissions.PARTNERS.subject,
    },
    {
      path: "/dealers/add",
      name: "Add Retailers",
      component: AddDealer,
      layout: "/admin",
      // permission: permissions.DEALER.create,
      // subject: permissions.DEALER.subject,
    },
    {
      path: "/dealers/policies",
      name: "All Policies",
      component: DealerPolicies,
      layout: "/admin",
    },
    {
      path: "/dealers/:id",
      name: "Dealer View",
      component: dealerView,
      layout: "/admin",
    },
    {
      path: "/dealers/policies/:id",
      name: "Policy",
      component: Policy,
      layout: "/admin",
    },
    // {
    //   path: "/franchiseProfile",
    //   name: "Franchise Profile",
    //   component: Profile,
    //   layout: "/admin",
    //   permission: permissions.SERVICE_CENTER.dealer_list,
    //   subject: permissions.SERVICE_CENTER.subject,
    // },
  ],
};

export default dealerRoute;
