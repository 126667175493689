import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Ability, AbilityBuilder } from "@casl/ability";
import ability from "../config/Casl/ability";
import AxiosReactClient from "../utilities/AxiosRestClient";
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import routes from "../routes.js";
import Notification from "../components/CustomUi/Notification";
class Admin extends React.Component {
  state = {
    sidenavOpen: true,
  };

  constructor() {
    super();
    this.getUserPermissions();
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  getNames = (array) => {
    return array.map((a) => a.name);
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };

  getUserPermissions = () => {
    const userId = localStorage.getItem("userId");
    AxiosReactClient.getRequest("users/" + userId + "/permissions")
      .then((response) => {
        if (response.data.data.Permissions) {
          this.updateAbility(response.data.data.Permissions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateAbility = (permissions) => {
    let permissionArray = [];
    permissions.forEach((group) => {
      var permissionObject = {
        groupName: group.groupName,
        permissions: group.permissions.map((a) => {
          let arr = a.name.split("_");
          arr.shift();
          return arr.join("_");
        }),
      };
      permissionObject.permissions.length > 0 &&
        permissionObject.permissions.push("menu");
      permissionArray.push(permissionObject);
    });

    const { can, rules } = AbilityBuilder.extract();
    permissionArray.forEach((element) => {
      can(element.permissions, element.groupName);
    });
    ability.update(rules);
    this.props.history.push(
      this.props.history.location.pathname
        ? this.props.history.location.pathname
        : "/admin/dashboard/home"
    );
  };

  render() {
    return (
      <div>
        <Notification />

        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("../assets/img/brand/toffee-logo-colored.png")
              .default,
            imgAlt: "...",
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <div style={{ minHeight: "84vh" }}>
            <Switch>
              {this.getRoutes(routes)}
              <Redirect to="/404" />
            </Switch>
          </div>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </div>
    );
  }
}

export default Admin;
