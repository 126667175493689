export const Products = {
  63: {
    provider: "Garantie",
    name: "Screen Damage Protection",
  },
  67: {
    provider: "Garantie",
    name: "Complete Damage Protection",
  },
  92: {
    provider: "Garantie",
    name: "Extended Warranty",
  },
};

export const DealerTypes = {
  CREDIT: "COCO",
  PREPAID_WALLET: "FOCO",
  PAYG: "GT",
  CREDIT_IN_DAYS: "CREDIT_IN_DAYS",
  DAILY_CREDIT: "DAILY_CREDIT",
  WALLET: "WALLET",
};
