import Dashboard from "./Index";
import BusinessDashboard from "./BusinessDashboard";
import * as permissions from "../../../config/Casl/permissions";
import TransactionListing from "../Transaction/TransactionListing";
import AnalyticsMain from "../Report/Analytics/AnalyticsMain";

let dashboardRoute = {
  path: "/dashboard",
  collapse: true,
  name: "Dashboard",
  icon: "ni ni-shop text-primary",
  state: "dashboardCollapse",
  permission: permissions.DASHBOARD.menu,
  subject: permissions.DASHBOARD.subject,
  views: [
    {
      path: "/dashboard/home",
      name: "Home",
      component: Dashboard,
      layout: "/admin",
      permission: permissions.DASHBOARD.menu,
      subject: permissions.DASHBOARD.subject,
    },
    {
      // path: "/dashboard/wallet/:status?/:addWalletAmount?",
      path: "/dashboard/wallet",
      name: "Wallet",
      component: TransactionListing,
      layout: "/admin",
      permission: permissions.DASHBOARD.wallet_balance,
      subject: permissions.DASHBOARD.subject,
    },
    {
      path: "/analytics",
      name: "Analytics",
      component: AnalyticsMain,
      layout: "/admin",
      permission: permissions.DASHBOARD.analytics_chart,
      subject: permissions.DASHBOARD.subject,
    },
    {
      path: "/dashboard/business",
      name: "Business Dashboard",
      component: BusinessDashboard,
      layout: "/admin",
      invisible: true,
    },
  ],
};
// if (franchiseStatus === "true") {
//   dashboardRoute = {
//     path: "/dashboard",
//     collapse: true,
//     name: "Dashboard",
//     icon: "ni ni-shop text-primary",
//     state: "dashboardCollapse",
//     permission: permissions.DASHBOARD.menu,
//     subject: permissions.DASHBOARD.subject,
//     views: [
//       {
//         path: "/dashboard/home",
//         name: "Home",
//         component: Dashboard,
//         layout: "/admin",
//         permission: permissions.DASHBOARD.menu,
//         subject: permissions.DASHBOARD.subject,
//       },
//       {
//         // path: "/dashboard/wallet/:status?/:addWalletAmount?",
//         path: "/dashboard/wallet",
//         name: "Wallet",
//         component: TransactionListing,
//         layout: "/admin",
//         permission: permissions.DASHBOARD.menu,
//         subject: permissions.DASHBOARD.subject,
//       },
//       {
//         path: "/dashboard/business",
//         name: "Business Dashboard",
//         component: BusinessDashboard,
//         layout: "/admin",
//         invisible: true,
//       },
//     ],
//   };
// } else {
//   dashboardRoute = {
//     path: "/dashboard",
//     collapse: true,
//     name: "Dashboard",
//     icon: "ni ni-shop text-primary",
//     state: "dashboardCollapse",
//     permission: permissions.DASHBOARD.menu,
//     subject: permissions.DASHBOARD.subject,
//     views: [
//       {
//         path: "/dashboard/home",
//         name: "Home",
//         component: Dashboard,
//         layout: "/admin",
//         permission: permissions.DASHBOARD.menu,
//         subject: permissions.DASHBOARD.subject,
//       },
//       {
//         path: "/analytics",
//         name: "Analytics",
//         component: AnalyticsMain,
//         layout: "/admin",
//         permission: permissions.POLICY.list,
//         subject: permissions.POLICY.subject,
//       },
//       {
//         path: "/dashboard/business",
//         name: "Business Dashboard",
//         component: BusinessDashboard,
//         layout: "/admin",
//         invisible: true,
//       },
//     ],
//   };
// }

export default dashboardRoute;
