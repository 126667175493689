export const ROLES = {
    subject: 'Roles',
    menu: 'menu',
    store: 'store',
    show: 'show',
    list: 'list',
    delete: 'delete',
    update: 'update'
}
export const USERS = {
    subject: 'Users',
    menu: 'menu',
    store: 'store',
    show: 'show',
    list: 'list',
    delete: 'delete',
    update: 'update',
    permissions_show: 'permissions_show',
    config: 'config'
}
export const AUTH = {
    subject: 'Auth',
    menu: 'menu',
    login: 'login',
    logout: 'logout',
    refresh: 'refresh',
    data: 'data'
}
export const POLICY = {
    subject: 'Policy',
    menu: 'menu',
    count: 'count',
    list: 'list',
    linegraph: 'linegraph',
    policy_issuePolicy: 'issuePolicy',
    policy_buyFlowSteps: 'buyFlowSteps',
    policy_relationships: 'relationships',
    policy_getPremium: 'getPremium',
    policy_dealer_creditAvailable: 'dealer_creditAvailable',
    cancelled_list: 'cancelled_list'
}
export const CLAIM = {
    subject: 'Claim',
    menu: 'menu',
    count: 'count',
    bargraph: 'bargraph',
    statusgraph: 'statusgraph'
}
export const LEAD = {
    subject: 'Lead',
    menu: 'menu',
    count: 'count'
}
export const DEALER = {
    subject: 'Dealers',
    menu: 'menu',
    list: 'list',
    update: 'update',
    show: 'show',
    cards: 'cards',
    cards_show: 'cards_show',
    kam_update: 'kam_update',
    kam_list: 'kam_list',
    product_store: 'product_store',
    product_update: 'product_update',
    brands_list: 'brands_list',
    brands_update: 'brands_update',
    history_list: 'history_list',
    additional_number_list: 'additional-number_list',
    additional_number_store: 'additional-number_store',
    additional_number_delete: 'additional-number_delete',
    create: 'create',
    create_bulk: 'create_bulk',
    invoiceModelUpdate: 'invoice-model_update'
}
export const PRODUCT = {
    subject: 'Product',
    menu: 'menu',
    list: 'list'
}
export const PROVIDERS = {
    subject: 'Providers',
    menu: 'menu',
    product_float: 'product_float',
    list: 'list',
    float_add: 'float_add',
}
export const REVENUE = {
    subject: 'Revenue',
    menu: 'menu',
    show: 'show'
}
export const PERMISSIONS = {
    subject: 'Permissions',
    menu: 'menu',
    list: 'list'
}
export const DASHBOARD = {
    subject: 'Dashboard',
    menu: 'menu',
    policy_count: 'policy_count',
    claim_count: 'claim_count',
    lead_count: 'lead_count',
    dealer_count: 'dealer_count',
    policy_linegraph: 'policy_linegraph',
    claim_bargraph: 'claim_bargraph',
    product_list: 'product_list',
    dealer_list: 'dealer_list',
    claim_statusgraph: 'claim_statusgraph',
    revenue_show: 'revenue_show',
    product_revenue: 'product_revenue',
    product_sales: 'product_sales',
    wallet_balance: 'wallet_balance',
    analytics_chart: 'analytics_chart',
}

export const SERVICE_CENTER = {
    subject: 'Sevice',
    menu: 'menu',
    policy_list: 'policy_list',
    finance_list: 'finance_list',
    dealer_list: 'dealer_list',
    claim_list: 'claim_list',
    dealer_cards: 'dealer_cards',
    policy_cards: 'policy_cards',
    finance_cards: 'finance_cards',
    claim_cards: 'claim_cards',
    claim_show: 'claim_show',
    list: 'list',
    oem_store: 'oem_store',
    oem_delete: 'oem_delete',
    centres_getlist: 'centres_getlist'
}

export const GARANTIE = {
    subject: 'Garantie',
    menu: 'menu',
    garantie_margin: 'garantie_margin',
    customer_name: 'customer_name',
    customer_details: 'customer_details',
    state_margin: 'state_margin',
    vivo_india: 'vivo_india',
    retailer_commission: 'retailer_commission',
    total_premium: 'total_premium'
}

export const Claims = {
    subject: 'Claims',
    menu: 'menu',
    claim_list: 'claim_list',
}

export const PARTNERS = {
    subject: 'Partners',
    menu: 'menu',
    policy_list: 'policy_list',
    finance_list: 'finance_list',
    dealer_list: 'dealer_list',
    claim_list: 'claim_list',
    dealer_cards: 'dealer_cards',
    policy_cards: 'policy_cards',
    finance_cards: 'finance_cards',
    claim_cards: 'claim_cards',
    claim_show: 'claim_show',
    list: 'list',
    oem_store: 'oem_store',
    oem_delete: 'oem_delete',
    retailers: 'retailers',
    franchise: 'franchise'
}
export const FINANCE = {
    subject: 'Finance',
    menu: 'menu',
    payout_products: 'payout_products',
    payout_list: 'payout_list',
    payout_initiate: 'payout_initiate',
    payout_bulkInitiate: 'payout_bulkInitiate',
    payout_status: 'payout_status',
    payout_cards: 'payout_cards',
    payout_history: 'payout_history',
    offlinepayment_show: 'offlinepayment_show',
    billing_report: 'billing_report',
    waterfall_report: 'waterfall_report',
    franchise_addmoney: 'franchise_addmoney'
}
export const OTHERPOLICIES = {
    documents_list: 'list'
}
export const COMPARISONS = {
    subject: 'Comparisons',
    menu: 'menu',
    list: 'list',
    store: 'store'
}
export const OTHER_POLICIES = {
    subject: 'Documents',
    menu: 'menu',
    list: 'list'
}
export const DOG_INSURANCE = {
    subject: 'Dog',
    menu: 'menu',
    list: 'insurance_list',
    store: 'insurance_store'
}
export const VEHICLES = {
    subject: 'Vehicles',
    menu: 'menu',
    master_list: 'master_list',
    master_store: 'master_store',
    master_update: 'master_update',
    master_show: 'master_show',
    master_delete: 'master_delete',
    list: 'list'
}
export const UNIFIED_POLICIES = {
    subject: 'Unified',
    menu: 'menu',
    list: 'policies_list',
    master: 'policies_master'
}