import React, { Component, Fragment } from "react";
import Shimmer from "../../../components/CustomUi/Shimmer/Shimmer";
import Spinner from "../../../components/Spinner/Spinner";
import ReactDatetime from "react-datetime";
import {
  Button,
  Card,
  CardTitle,
  Badge,
  CardBody,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Pagination,
  PaginationItem,
  CardFooter,
  PaginationLink,
  Row,
  Table,
  Col,
  CardHeader,
} from "reactstrap";
import Can from "../../../config/Casl/Can";
import * as permissions from "../../../config/Casl/permissions";
import CustomDropdown from "../../../components/Filters/CustomDropdown";
import { connect } from "react-redux";
import * as actions from "../../../Store/actions";
import { buildPoliciesList, downloadExcel } from "../../../helpers/utils";
import moment from "moment";
class PartnerPolicies extends Component {
  state = {
    hideFilters: false,
    searchText: "",
    productFilterId: "",
    partnerFilterId: "",
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    cardFilter: "custom",
    openMonthDialog: false,
    selectedCardsFilterDate: new Date(),
  };
  previousMonthDate =
    new Date().getMonth() === 0
      ? new Date(new Date().getFullYear() - 1, 11)
      : new Date(new Date().getFullYear(), new Date().getMonth() - 1);
  pager = {
    pagerStart: 0,
    pagerEnd: 0,
    pageArray: [],
    limit: 25,
    productId: "",
  };
  componentDidMount = () => {
    this.props.onGetPartnerPolicies(this.buildDefaultParams());
    this.props.onGetProductFilterList();
    this.props.onGetPartnerFilterList();
    this.props.onGetPartnerPolicyCards(this.buildDefaultParams());
    document.addEventListener("mousedown", this.handleClickOutside);
  };
  updatePagination = (total) => {
    this.pager.pageArray = [];
    this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
    for (let index = 0; index < this.pager.pagerEnd; index++) {
      this.pager.pageArray.push(index + 1);
    }
  };
  switchPage = (page) => {
    if (this.pager.pagerStart + 1 !== page) {
      this.pager.pagerStart = page - 1;
      this.getPolicies();
    }
  };
  handleReactMonthFilterChange(e) {
    this.pager.pagerStart = 0;
    this.setState({
      cardFilter: "custom",
      selectedCardsFilterDate: e._d,
    });
    this.props.onGetPartnerPolicyCards({
      month: e._d.getMonth() + 1,
      year: e._d.getFullYear(),
    });
    this.props.onGetPartnerPolicies({
      month: e._d.getMonth() + 1,
      year: e._d.getFullYear(),
    });
  }
  validDateMonthFilter = (current) => {
    return current.isSameOrBefore(new Date());
  };
  productFilterChanged = (product) => {
    this.setState(
      {
        productFilterId: product,
      },
      () => {
        this.getPolicies();
      }
    );
  };
  partnerFilterChanged = (partner) => {
    this.setState(
      {
        partnerFilterId: partner,
      },
      () => {
        this.getPolicies();
      }
    );
  };
  getPolicies = () => {
    this.props.onGetPartnerPolicies({
      product_id: this.state.productFilterId,
      partner_id: this.state.partnerFilterId,
      policy_search: this.state.searchText,
      start: this.pager.pagerStart,
      month: this.state.selectedCardsFilterDate.getMonth() + 1,
      year: this.state.selectedCardsFilterDate.getFullYear(),
    });
  };
  isHidden = (page) => {
    var start = this.pager.pagerStart + 1;
    if (page > start) {
      if (page - start > 2 && start === 1) {
        return true;
      } else if (start !== 1 && page - start > 1) {
        return true;
      }
    } else {
      if (start - page > 2 && start === this.pager.pagerEnd) {
        return true;
      } else if (start !== this.pager.pagerEnd && start - page > 1) {
        return true;
      }
    }
    return false;
  };
  onResetAllCards = (filter) => {
    this.setState({
      cardFilter: filter,
    });
    this.props.onGetPartnerPolicyCards(this.buildDefaultParams());
    this.props.onGetPartnerPolicies(this.buildDefaultParams());
  };
  getTwoFixedPoint = (num) => {
    var x = num * 1;
    return x.toFixed(2);
  };
  getMonthYear = (date) => {
    return (
      this.state.months[new Date(date).getMonth()] +
      " " +
      new Date(date).getFullYear()
    );
  };
  searchPolicy = () => {
    if (!this.state.searchText || this.state.searchText.length < 3) {
      this.showMsg("Please enter 3 or more characters.", "danger");
      return;
    }
    this.getPolicies();
  };
  onChangeSearchFeild = (text) => {
    this.setState({
      searchText: text,
    });
  };
  clearSearchText = () => {
    this.setState(
      {
        searchText: "",
      },
      () => {
        this.getPolicies();
      }
    );
  };
  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type,
    });
  };
  refreshFilters = () => {
    this.pager.pagerStart = 0;
    this.pager.productId = "";
    this.setState(
      {
        partnerFilterId: "",
        productFilterId: "",
        searchText: "",
      },
      () => {
        this.props.onGetPartnerPolicies({
          month: this.state.selectedCardsFilterDate.getMonth() + 1,
          year: this.state.selectedCardsFilterDate.getFullYear(),
        });
      }
    );
  };
  handleClickOutside = (e) => {
    let classArray = [];
    if (e.path) {
      e.path.forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    } else {
      e.composedPath().forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    }

    if (!classArray.includes("reactDateTimeCustomClass")) {
      this.setState({
        openMonthDialog: false,
      });
    }
  };
  buildDefaultParams = () => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    return { month, year };
  };
  exportList = () =>
    downloadExcel(
      buildPoliciesList(this.props.policiesList),
      "PoliciesList.xlsx"
    );

  render() {
    this.props.policiesListCount !== null &&
      this.updatePagination(this.props.policiesListCount);
    return (
      <Fragment>
        <Container fluid>
          <CardHeader className="border-0 bg-transparent px-0">
            <Row>
              <Col lg="8" xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-0"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary h5 font-weight-400"
                      onClick={(e) => e.preventDefault()}
                    >
                      Partners
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary h5 font-weight-400"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/admin/partners/policies");
                      }}
                    >
                      Policies
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="text-right" lg="4" xs="6">
                <div className="d-inline">
                  {1 === 2 && (
                    <Badge
                      color={
                        this.state.cardFilter === "all"
                          ? "primary"
                          : "secondary"
                      }
                      href="#pablo"
                      pill
                      onClick={(e) => {
                        e.preventDefault();
                        this.onResetAllCards("all");
                      }}
                    >
                      All
                    </Badge>
                  )}
                  <Badge
                    color={
                      this.state.cardFilter === "custom"
                        ? "primary"
                        : "secondary"
                    }
                    href="#pablo"
                    pill
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        openMonthDialog: true,
                      });
                    }}
                  >
                    {this.state.cardFilter === "custom"
                      ? this.getMonthYear(this.state.selectedCardsFilterDate)
                      : "Select Month"}
                  </Badge>
                  <ReactDatetime
                    className="d-inline cursor-pointer reactDateTimeCustomClass position-absolute rdtPicker-right-0"
                    open={this.state.openMonthDialog}
                    onFocus={(e) => {
                      this.setState({
                        openMonthDialog: true,
                      });
                    }}
                    onBlur={(e) => {
                      this.setState({
                        openMonthDialog: false,
                      });
                    }}
                    disableCloseOnClickOutside={false}
                    inputProps={{
                      placeholder: "Month",
                    }}
                    value={this.state.selectedCardsFilterDate}
                    dateFormat="MMM-YYYY"
                    timeFormat={false}
                    onChange={(e) => {
                      this.handleReactMonthFilterChange(e);
                      this.setState({
                        openMonthDialog: false,
                      });
                    }}
                    isValidDate={this.validDateMonthFilter}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <Can
            I={permissions.PARTNERS.policy_cards}
            a={permissions.PARTNERS.subject}
          >
            <Row>
              <Col md="6" xl="3">
                <Card className="bg-gradient-primary card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Sold Policies
                        </CardTitle>
                        <span className="h2 font-weight-bold text-white mb-0">
                          {!this.props.policyCardsLoading &&
                          this.props.policyCards ? (
                            this.props.policyCards.policiesSold
                          ) : (
                            <Shimmer />
                          )}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap text-light">
                        {this.state.cardFilter === "all"
                          ? "Till " + this.getMonthYear(this.previousMonthDate)
                          : this.getMonthYear(
                              this.state.selectedCardsFilterDate
                            )}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="bg-gradient-primary card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Total Premium
                        </CardTitle>
                        <span className="h2 font-weight-bold text-white mb-0">
                          {!this.props.policyCardsLoading &&
                          this.props.policyCards ? (
                            "₹ " +
                            this.getTwoFixedPoint(
                              this.props.policyCards.revenue
                            )
                          ) : (
                            <Shimmer />
                          )}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap text-light">
                        {this.state.cardFilter === "all"
                          ? "Till " + this.getMonthYear(this.previousMonthDate)
                          : this.getMonthYear(
                              this.state.selectedCardsFilterDate
                            )}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="bg-gradient-primary card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Total Commission
                        </CardTitle>
                        <span className="h2 font-weight-bold text-white mb-0">
                          {!this.props.policyCardsLoading &&
                          this.props.policyCards ? (
                            "₹ " +
                            this.getTwoFixedPoint(
                              this.props.policyCards.commission
                            )
                          ) : (
                            <Shimmer />
                          )}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap text-light">
                        {this.state.cardFilter === "all"
                          ? "Till " + this.getMonthYear(this.previousMonthDate)
                          : this.getMonthYear(
                              this.state.selectedCardsFilterDate
                            )}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="bg-gradient-primary card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Cancelled Policies
                        </CardTitle>
                        <span className="h2 font-weight-bold text-white mb-0">
                          {!this.props.policyCardsLoading &&
                          this.props.policyCards ? (
                            this.props.policyCards.cancelledPolicies
                          ) : (
                            <Shimmer />
                          )}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap text-light">
                        {this.state.cardFilter === "all"
                          ? "Till " + this.getMonthYear(this.previousMonthDate)
                          : this.getMonthYear(
                              this.state.selectedCardsFilterDate
                            )}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Can>
          <Row className="px-3">
            <Col xs={12} sm={6} className="pl-0">
              <h5 className="text-primary h5 font-weight-400">Policies</h5>
            </Col>
            <Col className="text-right pr-0">
              <Button
                className="btn-icon btn-2"
                size="sm"
                outline
                color="secondary"
                type="button"
                onClick={(e) => {
                  this.setState({
                    hideFilters: !this.state.hideFilters,
                  });
                }}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-filter"></i>
                </span>
              </Button>
            </Col>
          </Row>
          <div>
            <Row className="bg-white m-0 py-2" hidden={this.state.hideFilters}>
              <Col className="p-1 px-3 text-muted">
                <small>
                  <input
                    type="text"
                    placeholder="Policy No"
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.onChangeSearchFeild(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      e.key === "Enter" && this.searchPolicy();
                    }}
                    className="ml-2 bg-white border-0 text-muted pr-2"
                  />
                  <i
                    className="fas fa-search cursor-pointer mt-2"
                    aria-hidden="true"
                    onClick={(e) => {
                      e.preventDefault();
                      this.searchPolicy();
                    }}
                  ></i>
                </small>
                {this.state.searchText !== "" && (
                  <Badge color="primary" pill className="ml-2">
                    {this.state.searchText}
                    <span
                      className="p-1 ml-2 cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState(
                          {
                            searchText: "",
                          },
                          () => {
                            this.clearSearchText();
                          }
                        );
                      }}
                    >
                      <i className="fa fa-times-circle" aria-hidden="true"></i>
                    </span>
                  </Badge>
                )}
              </Col>
              <Col className="p-1 text-right px-3">
                <CustomDropdown
                  name="Product"
                  selectedId={this.state.productFilterId}
                  handleSelection={(product) => {
                    this.productFilterChanged(product);
                  }}
                  options={this.props.productFilterList}
                />
                <CustomDropdown
                  name="Partner"
                  selectedId={this.state.partnerFilterId}
                  handleSelection={(partner) => {
                    this.partnerFilterChanged(partner);
                  }}
                  options={this.props.partnerFilterList}
                />
                <Button
                  color="secondary "
                  outline
                  size="sm"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.refreshFilters();
                  }}
                >
                  Clear
                </Button>

                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.exportList();
                  }}
                >
                  Export
                </Button>
              </Col>
            </Row>
          </div>
          <Card className="mt-2">
            <CardBody className="px-0 py-0" style={{ minHeight: "708px" }}>
              <Table
                className="align-items-center table-flush"
                hover
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>Policy Number</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Premium</th>
                    <th>Product</th>
                    <th>Customer Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="all_tabl_heigth">
                {/* style={{ height: " 466px" }} */}
                  {this.props.policiesListLoading && (
                    <tr>
                      <td colSpan="7">
                        <Spinner />
                      </td>
                    </tr>
                  )}
                  {!this.props.policiesListLoading &&
                    this.props.policiesList &&
                    this.props.policiesList.length < 1 && (
                      <tr key={"keyxasx1_duwe823"}>
                        <td colSpan="7" className="text-center">
                          <span className="text-muted">
                            <b>No Policies found</b>
                          </span>
                        </td>
                      </tr>
                    )}
                  {!this.props.policiesListLoading &&
                    this.props.policiesList &&
                    this.props.policiesList.map((policy, index) => {
                      return (
                        <tr key={"partner_policies_" + index}>
                          <td className="table-user">
                            <span
                              className="text-primary font-weight-bold cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                  "/admin/policies/" + policy.id
                                );
                              }}
                            >
                              {policy.id}
                            </span>
                          </td>
                          <td>
                            <span>
                              {policy.policy_number
                                ? policy.policy_number
                                : "NA"}
                            </span>
                          </td>
                          
                          <td>
                            <span>
                              {moment(policy.start_date).format("DD-MM-YYYY")}
                            </span>
                          </td>
                          <td>
                            <span>
                              {moment(policy.end_date).format("DD-MM-YYYY")}
                            </span>
                          </td>
                          <td>
                            <span>
                              {policy.amount
                                ? "₹" + this.getTwoFixedPoint(policy.amount)
                                : "NA"}
                            </span>
                          </td>
                          <td>
                            {policy.product.name}
                          </td>
                          <td>
                            {policy.insured
                              ? policy.insured.name
                              : policy.proposer
                              ? policy.proposer.name
                              : "NA"}
                          </td>
                          <td className="table-user">
                            {policy.status.body}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </CardBody>
            {!this.props.policiesListLoading &&
              this.props.policiesList &&
              this.props.policiesList.length > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={this.pager.pagerStart > 0 ? "" : "disabled"}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.switchPage(this.pager.pagerStart);
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {this.pager.pageArray.map((page) => {
                        return (
                          <PaginationItem
                            key={"pager_" + page}
                            hidden={this.isHidden(page)}
                            className={
                              this.pager.pagerStart + 1 === page ? "active" : ""
                            }
                          >
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                this.switchPage(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}

                      <PaginationItem
                        className={
                          this.pager.pagerEnd > this.pager.pagerStart + 1
                            ? ""
                            : "disabled"
                        }
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.switchPage(this.pager.pagerStart + 2);
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              )}
          </Card>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    policiesListLoading: state.partnerPolicies.policiesListLoading,
    policiesList: state.partnerPolicies.policiesList,
    policiesListCount: state.partnerPolicies.policiesListCount,
    policyCards: state.partnerPolicies.policyCardsData,
    policyCardsLoading: state.partnerPolicies.policyCardsLoading,
    productFilterList: state.common.productFilterData,
    partnerFilterList: state.common.partnerFilterData,
    error: state.partnerPolicies.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetProductFilterList: () =>
      dispatch(
        actions.getProductFilterListData({
          partner_slug: "vivo-mobile-partner",
          group_retail_suffix: "no",
        })
      ),
    onGetPartnerFilterList: () => dispatch(actions.getPartnerFilterListData()),
    onGetPartnerPolicyCards: (params) =>
      dispatch(actions.getPartnerPolicyCardsData(params)),
    onGetPartnerPolicies: (params) =>
      dispatch(actions.getPartnerPoliciesListData(params)),
    onShowNotification: (notification) =>
      dispatch(actions.setNotificationData(notification)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PartnerPolicies);
