import { useState, useEffect } from "react";
import AxiosReactClient from "../utilities/AxiosRestClient";

const useApi = (
  endpoint,
  params = {},
  isLocal = false,
  defaultValue = null
) => {
  const [result, setResult] = useState(defaultValue);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await AxiosReactClient.getRequest(
      endpoint,
      params,
      isLocal
    );

    setResult(response.data.data);
  };

  return result;
};

export default useApi;
