import { ReducerUtils } from "../constants/reducers";

export default function reducer(state, action) {
  switch (action.type) {
    case ReducerUtils.agent.details:
      return {
        ...state,
        agentDetails: action.payload,
      };

    default:
      return state;
  }
}
