import moment from "moment";
import { Utils } from "../constants/utils";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { DealerTypes } from "../data/products";

export const compareValues = (key, order = "asc") => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === "desc" ? comparison * -1 : comparison;
  };
};

// Convert date format to string
export const convertDateToString = (date, format = "YYYY-MM-DD") => {
  return moment(date).format(format);
};

// Download document/file
export const downloadFile = (url) => {
  let link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = extractFileName(url);
  link.dispatchEvent(new MouseEvent("click"));
};

// Validate input field length
export const validataInputLength = (event) => {
  let elName = event.target.name;
  let elValue = event.target.value;

  if (elValue.length > Utils.limit.digits[elName]) {
    event.target.value = elValue.substring(0, Utils.limit.digits[elName]);
  }
};

export const convertToArray = (list) => {
  let array = [];

  list.map((item) => {
    array.push(item.url);
  });

  return array;
};

// Convert url to base64 encoded data
const convertUrlToBase64EncodedData = async (url) => {
  // Fetch the url and parse the response stream as a blob
  const blobData = await fetch(url).then((response) => response.blob());

  // create a new file from the blob object
  return new File([blobData], "filename.jpg");
};

// Extract filename from url
export const extractFileName = (filePath) => {
  return filePath ? filePath.replace(/^.*[\\\/]/, "") : null;
};

// Download multiple files as zip file,
export const downloadZipFile = async (
  urls,
  zipFileName = "policy-documents.zip"
) => {
  const zip = new JSZip();
  let files = [];

  urls.map((url) => {
    files.push({
      dataUrl: convertUrlToBase64EncodedData(url),
      filename: extractFileName(url),
    });
  });

  files.map((item) => {
    zip.file(item.filename, item.dataUrl, { base64: true });
  });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, zipFileName);
  });
};

export const defaultStatusId = () => {
  let value = "";
  if (localStorage.getItem("type") === "gsp") {
    value = "all";
  } else if (localStorage.getItem("type") === "oem") {
    if (localStorage.getItem("category") === "hod") {
      value = "all";
    } else if (localStorage.getItem("category") === "gj") {
      value = "all";
    } else if (localStorage.getItem("category") === "hp") {
      value = "14";
    } else if (localStorage.getItem("category") === "up") {
      value = "33";
    } else if (localStorage.getItem("category") === "rj") {
      value = "29";
    } else if (localStorage.getItem("category") === "mp") {
      value = "20";
    } 
    // else {
    //   value = "all";
    // }
  } else if (localStorage.getItem("type") === "franchise"){
    if (localStorage.getItem("category") === "gj"){
      value = "12";
    } else if (localStorage.getItem("category") === "hp") {
      value = "14";
    } else if (localStorage.getItem("category") === "up") {
      value = "33";
    } else if (localStorage.getItem("category") === "rj") {
      value = "29";
    } else if (localStorage.getItem("category") === "mp") {
      value = "20";
    }
  }
  //  else {
  //   value = "all";
  // }
  return value;
};

export const downloadZipFileExample = async () => {
  const sampleUrl =
    "https://i.picsum.photos/id/358/200/300.jpg?hmac=-67HJ1NCrWtZ_ZrX9NaG90kmRdhaN-tkC3A1EoGev3A";

  // Fetch the image and parse the response stream as a blob
  const imageBlob = await fetch(sampleUrl).then((response) => response.blob());

  // create a new file from the blob object
  const imgData = new File([imageBlob], "filename.jpg");

  // Copy-pasted from JSZip documentation
  var zip = new JSZip();

  var img = zip.folder("images");
  img.file("smile1.gif", imgData, { base64: true });
  img.file("smile2.gif", imgData, { base64: true });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, "other_polices.zip");
  });
};

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const parseMessage = (message) => {
  if (typeof message === "string") {
    return message;
  } else if (Array.isArray(message) && message.length) {
    let errors = [];

    const output = message.map((item) => [...errors, item.msg]);

    return output.join(", ");
  } else {
    let errors = [];

    for (const [key, value] of Object.entries(message)) {
      errors.push(value);
    }

    return errors;
  }
};

export const notify = (message) => {
  toast.success(message);
};

export const notifyError = (message) => {
  toast.error(message);
};

export const redirectWithBlank = (url) => {
  var a = document.createElement("a");
  a.target = "_blank";
  a.href = url;
  a.click();
};

export const redirectWithImage = (url) => {
  let data = `${url}`;
  let w = window.open("about:blank");
  let image = new Image();
  image.src = data;
  setTimeout(function () {
    w.document.write(image.outerHTML);
  }, 0);
};

export const convertDateToYYYYMMDD = (dateString) => {
  if (dateString === "Invalid date") {
    return dateString;
  }

  let convertedDate = convertDateToString(dateString);

  if (convertedDate !== dateString) {
    const dateArray = dateString.split("-");

    convertedDate = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
  }

  return convertedDate;
};

export const getUtf8Bytes = (str) => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  var utf8Bytes = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function (match, p1) {
      return String.fromCharCode("0x" + p1);
    }
  );

  return btoa(utf8Bytes);
};

export const downloadPDF = (data) => {
  var byteCharacters = atob(getUtf8Bytes(data));
  var byteNumbers = new Array(byteCharacters.length);

  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  var byteArray = new Uint8Array(byteNumbers);
  var file = new Blob([byteArray], { type: "application/pdf;base64" });
  var fileURL = URL.createObjectURL(file);

  window.open(fileURL);
};

export const validateEmail = (inputText) => {
  var mailformat =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (mailformat.test(inputText)) {
    return true;
  } else {
    return false;
  }
};

export const getExcelData = (event, callback) => {
  try {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
      });
      /* Update state */
      const filterData = [];
      const index = {};
      data.forEach((e) => {
        if (
          e &&
          e.length > 0 &&
          e[0] &&
          index[`${e[0]}`?.toLowerCase()] === undefined
        ) {
          index[`${e[0]}`.toLowerCase()] = 0;
          filterData.push([e[0]]);
        }
      });
      filterData.shift();
      callback(filterData);
    };
    if (event && event.target && event.target.files[0]) {
      if (rABS) {
        reader.readAsBinaryString(event.target.files[0]);
      } else {
        reader.readAsArrayBuffer(event.target.files[0]);
      }
    }
  } catch (error) {
    return [];
  }
};

export const buildClaimsListToExport = (data) => {
  const shallowCopy = JSON.parse(JSON.stringify(data));
  const list = [];

  shallowCopy.forEach((item) => {
    list.push({
      "Claim Reference": item?.id,
      "Job Sheet Number": item?.job_sheet_number,
      "Customer Name": item?.name,
      "Product Name": item?.product,
      "Customer Mob. No.": item?.mobile,
      "Claimed Amount": `₹${item?.claimed_amount}`,
      "Copayment Amount": `₹${item?.copayment_amount}`,
      "Approved Amount": item?.approved_amount
        ? `₹${item?.approved_amount}`
        : "-",
      "Order ID": item?.order_id ? `₹${item?.order_id}` : "-",
      "Repair Amount": item?.repair_amount ? `₹${item?.repair_amount}` : "-",
      "Service Centre Name": item?.service_centre_name
        ? `${item?.service_centre_name}`
        : "-",
      "Device Unique Identifier (IMEI/Serial#)": item?.imei_number
        ? `${item?.imei_number}`
        : "-",
      "Claim Date": item?.created_at
        ? moment(item?.created_at).format("DD/MM/YYYY")
        : "",
      "Claim Status": item?.status ? item.status : "", //renderClaimStatus(item?.active)
    });
  });

  return list;
};

export const buildPartnerServiceCentreList = (data) => {
  const shallowCopy = JSON.parse(JSON.stringify(data));
  const list = [];
  shallowCopy.forEach((item) => {
    list.push({
      id: item?.id ?? "-",
      Name: item?.name,
      Phone: item?.mobile,
      Email: item?.email,
      City: item?.city,
      State: item?.state,
      Address: item?.address.address_line_1,
      "Created Date": item?.created_at,
    });
  });

  return list;
};

export const buildPartnerDealersList = (data) => {
  const shallowCopy = JSON.parse(JSON.stringify(data));
  const list = [];
  shallowCopy.forEach((item) => {
    list.push({
      // "Retailer V Work Code": item?.code ?? "-",
      "Child Code": item?.store_code ?? "-",
      "Retailer Name": item?.dealership_name,
      "Contact Person Name": item?.name,
      "Contact Person Mob. No.": item?.mobile,
      "Retailer Email ID": item?.email,
      "Application User Name": item?.code,
      "Store Category": item.invoicing_modal
        ? DealerTypes[item.invoicing_modal]
        : "-",
      "Count Policy Sold": item?.total_sales_count
        ? item.total_sales_count
        : "-",
      "Active Status": item?.is_active_for_sell ? "Active" : "Inactive",
      "Activation Date": item.activation_date ? item.activation_date : "-",
      "Total Premium":
        item?.sale_premium_count > 0
          ? `₹${Math.ceil(item?.sale_premium_count)}`
          : "-",
      "Commission Earned":
        item?.commission > 0 ? `₹${Math.ceil(item?.commission)}` : "-",
      Address: item?.complete_address,
      Address: item?.address
        ? `${item?.address?.address_line_1}, ${item?.address?.city}, ${item?.address?.state}, ${item?.address?.pincode}`
        : "",
    });
  });

  return list;
};

export const buildPoliciesList = (data) => {
  const shallowCopy = JSON.parse(JSON.stringify(data));
  const list = [];
  shallowCopy.forEach((item) => {
    list.push({
      "Customer Name": item.name
        ? item.name
        : item.insured
        ? item.insured.name
        : item.proposer
        ? item.proposer.name
        : "-",
      "Customer Email": item?.email ?? "-",
      "Customer Phone Number": item?.mobile ?? "-",
      "Plan Name": item?.product_name ?? "-",
      "Plan Sales Code": item.product_code ?? "-",
      "Plan Number": item.policy_number ?? "-",
      "Plan Status": item.status_id == 21 ? "Activated" : "Inactivated",
      // "Plan Price": item?.premium
      //   ? item?.premium
      //   : item?.amount
      //   ? `₹${item?.amount}`
      //   : "-",
      "Mode of Payment": item?.payment_mode ?? "-",
      "Transaction Id": item?.transaction_number ?? "-",
      "Total Premium": item?.premium ?? "-",
      "Retailer Name": item?.provider_name ?? "-",
      "Retail Location": item?.retail_location ?? "-",
      // "Invoice Number": item?.invoice_number ?? "-",
      // "vivo VBA ID": "-",
      "Official Model": item?.phone_model ?? "-",
      "MOP of Device": item?.invoice_amount ?? "-",
      "Device Unique Identifier (IMEI/Serial#)": item?.imei_number ?? "-",
      // "Price Range": "-",
      "Parent Code": item?.code ?? "-",
      "State Code": item?.state_code ?? "-",
      "Store Category": DealerTypes[item?.invoicing_modal] ?? "-",
      // "Plan Activation Code": "-",
      "Plan Purchase Date": item?.contract_start_date
        ? moment(item?.contract_start_date).format("DD-MM-YYYY")
        : "",
      "Plan End Date": item?.end_date
        ? moment(item?.end_date).format("DD-MM-YYYY")
        : "",
      "Plan Start Date": item?.start_date
        ? moment(item?.start_date).format("DD-MM-YYYY")
        : "",
      "Promoter ID": item?.promoter_id ?? "-",
      "User Name": item?.code ?? "-",
      "Retailer Margin with GST": item?.commission,
      "OEM Margin with GST": item?.oem_commission,
      "vivo india margin with GST": item?.oem_commission_part,
      "vivo state margin with GST": item?.state_commission_part,
      "Cost to Retailer": `${item?.premium - item?.commission}`,
    });
  });

  return list;
};

export const downloadExcel = (data, filename = "List.xlsx") => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, filename);
};

export const convertToCommaSeperated = (number) =>
  Number(number).toLocaleString("en");

export const buildDocumentTypeList = () => {
  return [
    {
      document_type_id: 24,
      name: "Bank Detail Proof",
      slug: "DOCUMENT_BANK_DETAILS_PROOF",
      filename: "bank",
    },
    {
      document_type_id: 59,
      name: "Pancard",
      slug: "DOCUMENT_PAN",
      filename: "pancard",
    },
    {
      document_type_id: 67,
      name: "GST Certificate",
      slug: "DOCUMENT_GST_CERTIFICATE",
      filename: "gst",
    },
  ];
};

export const toBase64 = async (file) => {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return result_base64;
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const checkDocumentExistense = (documents) => {
  const object = {};

  documents.map((item) => {
    object[item.document_type_id] = true;
  });

  return object;
};

export const syncDocumentTypeList = (list, document) => {
  let filteredList = [];

  list.map((item) => {
    // if (!document[item.document_type_id]) {
    filteredList.push(item);
    //}
  });

  return filteredList;
};

export const buildSystemErrorMessage = () => {
  return {
    data: {
      error_msg:
        "It looks like something went wrong. Please try again in a while.",
    },
  };
};

export const getQueryStringParameterValue = (keyName) =>
  new URLSearchParams(window.location.search).get(keyName);

export const getSelectedDurationFilter = (filter) => {
  return filter;
};

export const exportFun = (data) => {
  const shallowCopy = JSON.parse(JSON.stringify(data));
  const list = [];
  shallowCopy.forEach((item) => {
    list.push({
      // "Retailers Name": item.dealer_name,
      "Child Code": item.child_code,
      "Trade Name": item.trade_name,
      "Retailer Address": item.dealer_address,
      GSTIN: item.GSTIN,
      State: item.state,
      "Billing Month": item.billing_period,
      "Product Type": item.product_type,
      "Product Price Range": item.price_range,
      "Cost To Retailer": item.cost_to_dealer,
      // Location: item.location,
      // Region: item.region,
      // "Plan Number": item.plan_number,
      // "Plan Purchase Date": item.plan_purchase_date,
      // "Customer Name": item.customer_name,
      // "Device Purchase date": item.device_purchase_date,
      // "Period of Coverage": item.period_of_coverage,
      // "IMEI Number": item.IMEI_number,
      // "Mobile Model Name": item.mobile_model_name,
    });
  });

  return list;
};

export const exportWaterFallFun = (data) => {
  const shallowCopy = JSON.parse(JSON.stringify(data));
  const list = [];
  shallowCopy.forEach((item) => {
    list.push({
      "Retailer Name": item.dealer_name,
      "Customer Name": item.customer_name,
      "Order Status": item.order_status,
      "Purchase Date": item.purchase_date,
      "Phone Model Name": item.phone_model_name,
      "Product Type": item.product_id,
      "Min Price": item.min_price,
      "Max Price": item.max_price,
      "Landing Cost Without GST": item.landing_cost_without_gst,
      "Landing Cost With GST": item.landing_cost_with_gst,
      "Vivo India Margin With GST": item.vivo_india_margin_with_gst,
      "Vivo India Margin Percentage": item.vivo_india_margin_percentage,
      "State Landing Cost Without GST": item.state_landing_cost_without_gst,
      "State Landing Cost With GST": item.state_landing_cost_with_gst,
      "Vivo MOP With GST": item.vivo_mop_with_gst,
      "Vivo MOP Without GST": item.vivo_mop_without_gst,
      "Margin Available": item.margin_available,
      "Margin Percentage": item.margin_percentage,
      "Retailer Margin Pre GST": item.retailer_margin_pre_gst,
      "Retailer Margin Percentage": item.retailer_margin_percentage,
      "Retailer Margin With GST": item.retailer_margin_with_gst,
      "State Margin Pre GST": item.state_margin_pre_gst,
      "State Margin Percentage": item.state_margin_percentage,
      "State Margin With GST": item.state_margin_with_gst,
      "Additional Cost At Service": item.additional_cost_at_service,
    });
  });

  return list;
};

export const exportTransactionFun = (data) => {
  const shallowCopy = JSON.parse(JSON.stringify(data));
  const list = [];
  shallowCopy.forEach((item) => {
    list.push({
      "Franchise Id": item.franchise_id,
      "Created At": item.created_at,
      "Payment Status": item.payment_successful ? "Success" : "Fail",
      "Amount (in ₹)": item.amount,
      "Transaction Id": item.razorpay_order_id,
    });
  });

  return list;
};

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
export const buildRazorpayResponseErrorMessage = () => {
  return {
    data: {
      // error_msg: PAYMENT_FAILED_MESSAGE,
      error_msg: "Payment Failed",
    },
  };
};

// export const parseMessage = (message) => {
//   if (typeof message === "string") {
//     return message;
//   } else if (Array.isArray(message) && message.length) {
//     let errors = [];
//     const output = message.map((item) => [...errors, item.msg]);
//     return output.join(", ");
//   } else {
//     let errors = [];
//     for (const [key, value] of Object.entries(message)) {
//       errors.push(value);
//     }
//     return errors;
//   }
// };

export const createPaginationArr = (num) => {
  let arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(i + 1);
  }
  return arr;
};
function dataExtraction(data) {
  if (!data) {
    console.log("No data provided.");
    return;
  }
  let keys = Object.keys(data);
  let values = Object.values(data);
  let plansSoldArray = [];
  let premiumCollected = [];
  let sdpValue = [];
  for (let i = 0; i < values.length; i++) {
    if (values[i] && typeof values[i] === "object") {
      plansSoldArray.push(values[i].plan_sold);
      premiumCollected.push(values[i].premium_collected);
      sdpValue.push(values[i].SDP);
    }
  }
  return { keys, plansSoldArray, premiumCollected, sdpValue };
}
function dataExtractionModelWise(data) {
  if (!data) {
    console.log("No data provided.");
    return;
  }
  let keys = Object.keys(data);
  let values = Object.values(data);
  let sdpValues = [];
  let cdpValues = [];
  let ewValues = [];
  for (let i = 0; i < values.length; i++) {
    if (values[i] && typeof values[i] === "object") {
      // if (values[i]?.SDP) {
      sdpValues.push(values[i].SDP);
      // }
      // if (values[i]?.CDP) {
      cdpValues.push(values[i].CDP);
      // }
      // if (values[i]?.EW) {
      ewValues.push(values[i].EW);
      // }
    }
  }

  return { keys, sdpValues, cdpValues, ewValues };
}
function dataExtractionActiveRetailerVsPlanSold(data) {
  if (!data) {
    console.log("No data provided.");
    return;
  }
  let keys = Object.keys(data);
  let values = Object.values(data);
  let activeRetailers = [];
  let totalSale = [];
  for (let i = 0; i < values.length; i++) {
    if (values[i] && typeof values[i] === "object") {
      activeRetailers.push(values[i].active_retailers);
      totalSale.push(values[i].total_plans_sold);
    }
  }

  return { keys, activeRetailers, totalSale };
}
const dataExtractionForContribution = (data) => {
  const contributionKey = Object.keys(data);
  let value = Object.values(data);
  let percent_contribution = [];
  let premium_collected = [];
  for (let i = 0; i < value.length; i++) {
    percent_contribution.push(value[i].percent_contribution);
    premium_collected.push(value[i].premium_collected);
  }
  return { contributionKey, percent_contribution, premium_collected };
};
export const planSaleData = (data) => {
  let radialChartKeys = data?.all_plan_sold
    ? Object.keys(data?.all_plan_sold)
    : [];
  let radialChartValues = data?.all_plan_sold
    ? Object.values(data?.all_plan_sold)
    : [];

  let { keys, plansSoldArray, premiumCollected } = dataExtraction(
    data?.plan_premium ?? []
  );

  let {
    keys: modelWiseKeys,
    sdpValues,
    cdpValues,
    ewValues,
  } = dataExtractionModelWise(data?.best_sold_model_report ?? []);
  let {
    keys: retailerWiseKeys,
    sdpValues: sdpCount,
    cdpValues: cdpCount,
    ewValues: ewCount,
  } = dataExtractionModelWise(data?.best_retailers_plan_sold ?? []);
  let transactionKeys = data?.payment_method_data
    ? Object.keys(data?.payment_method_data)
    : [];
  let transactionValues = data?.payment_method_data
    ? Object.values(data?.payment_method_data)
    : [];
  let {
    keys: priceRangeKeys,
    sdpValues: sdpRangeCount,
    cdpValues: cdpRangeCount,
    ewValues: ewRangeCount,
  } = dataExtractionModelWise(data?.price_range_wise_best_sold ?? []);
  let { contributionKey, percent_contribution, premium_collected } =
    dataExtractionForContribution(
      data?.price_range_wise_premium_contribution ?? []
    );
  let {
    keys: activeRetailersKeys,
    activeRetailers,
    totalSale,
  } = dataExtractionActiveRetailerVsPlanSold(data?.active_retailers ?? []);
  const chartData = {
    series: [
      {
        name: "Plans Sold",
        type: "column",
        data: plansSoldArray,
      },
      {
        name: "Total Premium (in ₹)",
        type: "line",
        data: premiumCollected,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: "Plans Sale & Total Premium",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: keys,
      xaxis: {
        type: keys,
      },
      yaxis: [
        {
          title: {
            text: "Plan Sold",
          },
        },
        {
          opposite: true,
          title: {
            text: "Total Premium (in ₹)",
          },
        },
      ],
    },
  };
  const radialBarChartData = {
    series: radialChartValues,
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
          barLabels: {
            enabled: true,
            useSeriesColors: true,
            margin: 8,
            fontSize: "16px",
            formatter: function (seriesName, opts) {
              return (
                seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
              );
            },
          },
        },
      },
      colors: ["#1ab7ea", "#0084ff", "#39539E"],
      labels: radialChartKeys,
      title: {
        text: "Plan Sale With Plans Type",
        align: "left",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };
  const chartRowChartData = {
    series: [
      {
        name: "SDP",
        data: sdpValues,
      },
      {
        name: "CDP",
        data: cdpValues,
      },
      {
        name: "EW",
        data: ewValues,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "Top Model Wise Plan Sales",
      },
      xaxis: {
        categories: modelWiseKeys,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };
  const topRetailersData = {
    series: [
      {
        name: "SDP",
        data: sdpCount,
      },
      {
        name: "CDP",
        data: cdpCount,
      },
      {
        name: "EW",
        data: ewCount,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "Top Retailer Wise Plan Sold",
      },
      xaxis: {
        categories: retailerWiseKeys,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };
  const topPriceRangWisePlanSold = {
    series: [
      {
        name: "SDP",
        data: sdpRangeCount,
      },
      {
        name: "CDP",
        data: cdpRangeCount,
      },
      {
        name: "EW",
        data: ewRangeCount,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "Price Range Wise Plan Sold",
      },
      xaxis: {
        categories: priceRangeKeys,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };
  const premiumContribution = {
    series: [
      {
        name: "Premium Collected",
        type: "column",
        data: premium_collected,
      },
      {
        name: "Contribution (in %)",
        type: "line",
        data: percent_contribution,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: "Price Range Wise Premium Contribution",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: contributionKey,
      xaxis: {
        type: contributionKey,
      },
      yaxis: [
        {
          title: {
            text: "Premium Collected",
          },
        },
        {
          opposite: true,
          title: {
            text: "Contribution (in %)",
          },
        },
      ],
    },
  };

  const activeRetailerVsPlanSold = {
    series: [
      {
        name: "Active Retailers",
        data: activeRetailers,
      },
      // {
      //   name: "Total Plan Sold",
      //   data: totalSale,
      // },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "Active Retailers",
      },
      xaxis: {
        categories: activeRetailersKeys,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };

  // const activeRetailerVsPlanSold = {
  //   series: [
  //     {
  //       name: "Active Retailers",
  //       type: "column",
  //       data: activeRetailers,
  //     },
  //     {
  //       name: "Total Plan Sold",
  //       type: "line",
  //       data: totalSale,
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: "line",
  //     },
  //     stroke: {
  //       width: [0, 4],
  //     },
  //     title: {
  //       text: "Active Retailer vs Plan Sold",
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       enabledOnSeries: [1],
  //     },
  //     labels: activeRetailersKeys,
  //     // xaxis: {
  //     //   type: activeRetailersKeys,
  //     // },
  //     yaxis: [
  //       {
  //         title: {
  //           text: "Active Retailers",
  //         },
  //       },
  //       {
  //         opposite: true,
  //         title: {
  //           text: "Total Plan Sold",
  //         },
  //       },
  //     ],
  //   },
  // };
  return {
    chartData,
    radialBarChartData,
    chartRowChartData,
    topRetailersData,
    topPriceRangWisePlanSold,
    premiumContribution,
    activeRetailerVsPlanSold,
  };
};

export const priceStandardForm = (num) => {
  let standard = "₹ " + Number(num).toLocaleString("en");
  return standard;
};