import React, { Component, Fragment } from 'react';
import Can from '../../../../config/Casl/Can'
import * as permissions from '../../../../config/Casl/permissions';
import { connect } from "react-redux";
import * as actions from '../../../../Store/actions';
import Spinner from "../../../../components/Spinner/Spinner";

import {
    Button,
    Card,
    Table,
    Row,
    Container,
    Breadcrumb,
    BreadcrumbItem,
    Col,
    UncontrolledTooltip,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Badge,
} from "reactstrap";

class Users extends Component {
    state = {
        rolesList: [{}, {}, {}, {}, {},],
        view: "user-table",
        searchText: '',
    }
    constructor(props) {
        super(props)
        this.getuserData();
    }
    pager = {
        pagerStart: 0,
        pagerEnd: 0,
        pageArray: [],
        limit: 25,
    }
    getuserData = () => {
        this.props.onGetUsersData({ start: this.pager.pagerStart, user_search: this.state.searchText });
    }
    updatePagination = (total) => {
        this.pager.pageArray = [];
        this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
        for (let index = 0; index < this.pager.pagerEnd; index++) {
            this.pager.pageArray.push(index + 1)
        }
    }
    switchPage = (page) => {
        if (((this.pager.pagerStart + 1) !== page)) {
            this.pager.pagerStart = page - 1
            this.getuserData();
        }
    }
    isHidden = (page) => {
        var start = this.pager.pagerStart + 1;
        if (page > start) {
            if ((page - start) > 2 && (start === 1)) {
                return true;
            } else if ((start !== 1) && ((page - start) > 1)) {
                return true;
            }
        } else {
            if ((start - page) > 2 && (start === this.pager.pagerEnd)) {
                return true;
            } else if ((start !== this.pager.pagerEnd) && ((start - page) > 1)) {
                return true;
            }
        }
        return false
    }
    resetFilters = () => {
        this.pager.pagerStart = 0
        this.pager.sortBy = 0
        this.setState({
            searchText: '',
        }, () => {
            this.getuserData()
        })
    }
    onSearch = (value) => {
        if (!this.state.searchText || this.state.searchText.length < 2) {
            this.showMsg(" Please, enter atlest 2 characters", "danger")
            return
        }

        this.getuserData()
        this.setState({
            view: "table"
        })
    }
    render() {
        (this.props.usersCount !== null) && this.updatePagination(this.props.usersCount)
        return (
            <Fragment>
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="4" xs="6">
                                {/* <h6 className="h2 d-inline-block mb-0">Alternative</h6>{" "} */}
                                <Breadcrumb
                                    className="d-none d-md-inline-block"
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-user" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Users
                      </a>
                                    </BreadcrumbItem>
                                    {/* <BreadcrumbItem aria-current="page" className="active">
                      Alternative
                    </BreadcrumbItem> */}
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="8" xs="6">
                                <Can I={permissions.USERS.store} a={permissions.ROLES.subject}>

                                    <Button
                                        className="btn-round btn-icon "
                                        color="success"
                                        href="#pablo"
                                        id="tooltip28070728"
                                        onClick={e => {
                                            e.preventDefault()
                                            this.props.history.push('/admin/users/create')
                                        }}
                                        size="sm"
                                    >
                                        <span className="btn-inner--icon mr-1">
                                            <i className="fas fa-user-plus" />
                                        </span>
                                        <span className="btn-inner--text">Add</span>
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="tooltip28070728">
                                        Add new user</UncontrolledTooltip>
                                </Can>
                            </Col>
                        </Row>
                    </div>
                    <div >
                        <Row className="bg-white m-0 py-2">
                            <Col className="p-1 px-3 text-muted text-left">
                                <small>
                                    <form xs="6" className="form-inline md-form mr-3 mb-4 d-inline" action="javascript:void(-1)">
                                        <input className="ml-2 bg-white border-0 text-muted pr-2 searchWidth"
                                            value={this.state.searchText}
                                            onKeyDown={e => {
                                                (e.key === 'Enter') && this.onSearch(e.target.value)
                                            }}
                                            onChange={e => {
                                                if (e.target.value == " ") {
                                                    this.setState({
                                                        view: "user-table"
                                                    })
                                                }
                                                this.setState({
                                                    searchText: e.target.value
                                                })
                                            }}
                                            type="text" placeholder="Name / Email" aria-label="Search" />
                                        <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
                                            onClick={
                                                e => {
                                                    e.preventDefault()
                                                    this.onSearch(this.state.searchText)
                                                }}
                                        ></i>
                                    </form>
                                </small>
                                {(this.state.searchText !== "") && (this.state.view === "table") && <Badge color="primary" pill>
                                    {this.state.searchText}
                                    <span className="p-1 ml-2 cursor-pointer" onClick={
                                        e => {
                                            e.preventDefault()
                                            this.resetFilters()
                                            this.setState({
                                                view: "user-table",
                                                searchText: ""
                                            })
                                        }} >
                                        <i className="fa fa-times-circle" aria-hidden="true"></i>
                                    </span>
                                </Badge>}
                            </Col>

                        </Row>
                    </div>
                    <Card className="" >
                        <Table className="align-items-center table-flush" hover responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>Avatar</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Created At</th>
                                    <th>Permissions</th>
                                    <th className="text-right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.usersListLoading && <tr >
                                        <td colSpan="12">
                                            <Spinner></Spinner>
                                        </td>
                                    </tr>
                                }
                                {this.props.usersList && this.props.usersList.map((user, index) => {
                                    return <tr key={Math.random(100)}>
                                        <td className="table-user">
                                            {/* <img
                                                alt=""
                                                className="avatar rounded-circle mr-3"
                                                src={user.avatar}
                                            /> */}
                                            <img
                                                alt=""
                                                src={"../../../userIcon.svg"}
                                            />
                                        </td>
                                        <td>
                                            {user.name}
                                        </td>
                                        <td>
                                            {user.mobile ?? '-'}
                                        </td>
                                        <td title={user.email}>
                                           {user.email.length > 25 ? user.email.substr(0, 22) + "..." : user.email}
                                        </td>
                                        <td>
                                            {/* {user.created_at.replace(" ","-")} */}
                                            {user.created_at && new Date(user.created_at.replace(" ", "T")).toDateString()}
                                        </td>
                                        <td>
                                            {user.permission_count ? user.permission_count : 0}
                                        </td>
                                        <td className="text-right">
                                            <Can I={permissions.USERS.show} a={permissions.USERS.subject}>

                                                <Button
                                                    color="secondary" outline type="button"

                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.props.history.push('/admin/users/' + user.id)
                                                    }}
                                                    size="sm"
                                                >
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="fas fa-eye" />
                                                    </span>
                                                    <span className="btn-inner--text">View</span>
                                                </Button>
                                            </Can>
                                            <Can I={permissions.USERS.update} a={permissions.USERS.subject}>

                                                <Button
                                                    className="btn-round btn-icon"
                                                    outline
                                                    color="primary"
                                                    id="tooltip-edit"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.props.history.push('/admin/users/' + user.id + "/edit")
                                                    }}
                                                    size="sm"
                                                >
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="fas fa-edit" />
                                                    </span>
                                                    <span className="btn-inner--text">Edit</span>
                                                </Button>
                                                <UncontrolledTooltip delay={0} target="tooltip-edit">
                                                    Edit Role</UncontrolledTooltip>
                                            </Can>
                                        </td>

                                    </tr>
                                })}


                            </tbody>
                        </Table>
                        <CardFooter className="py-4">
                            <nav aria-label="...">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={this.pager.pagerStart > 0 ? '' : 'disabled'}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.switchPage(this.pager.pagerStart);
                                            }}
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {this.pager.pageArray.map(page => {
                                        return < PaginationItem key={'pager_' + page} hidden={this.isHidden(page)} className={((this.pager.pagerStart + 1) === page) ? "active" : ''}>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.switchPage(page);
                                                }}
                                            >
                                                {page}
                                            </PaginationLink>
                                        </PaginationItem>
                                    })}
                                    <PaginationItem className={this.pager.pagerEnd > this.pager.pagerStart + 1 ? '' : 'disabled'}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.switchPage(this.pager.pagerStart + 2);
                                            }}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </nav>
                        </CardFooter>
                    </Card>
                </Container>
            </Fragment>
        );
    }
}



const mapStateToProps = state => {
    return {
        usersListLoading: state.roles.usersListLoading,

        usersList: state.roles.usersList,
        usersCount: state.roles.usersCount,


        error: state.roles.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetUsersData: (data) => dispatch(actions.getUsersForListing(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);