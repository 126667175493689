import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "reactstrap";
import AxiosReactClient from "../../utilities/AxiosRestClient";
import NotificationShow from "./NotificationShow";

const ExportButton = ({ exportPayload, url }) => {
  console.log(".....");
  const [handleToast, setHandleToast] = useState({
    isLoading: false,
    msg: "",
  });
  const removeToast = () => {
    setTimeout(() => {
      setHandleToast({
        ...handleToast,
        isLoading: false,
      });
    }, 2000);
  };
  const exportBtn = async () => {
    console.log("call");
    const payload = {
      export: 1,
      ...exportPayload,
    };
    try {
      const response = await AxiosReactClient.getRequest(`${url}`, payload);
      if (response.data.success) {
        setHandleToast({
          isLoading: true,
          msg: response.data.data.message,
        });
        removeToast();
      }
    } catch (err) {}
  };
  return (
    <>
      <Button
        color="primary"
        size="sm"
        type="button"
        onClick={exportBtn}
        style={{ cursor: "pointer", height: "2rem" }}
      >
        Export
      </Button>
      {handleToast.isLoading && (
        <Box style={{ position: "fixed", top: "4rem", right: "4rem" }}>
          <NotificationShow
            msg={handleToast.msg}
            status={"success"}
            onClose={() => {
              // setHandleToast({ ...handleToast, isLoading: false });
            }}
          />
        </Box>
      )}
    </>
  );
};

export default ExportButton;
