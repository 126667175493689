import * as permissions from "../../../config/Casl/permissions.js";
import PartnerServiceCentreListing from "../Partners/ServiceCentres.jsx";

const serviceCentreRoute = {
  collapse: true,
  name: "Service Centres",
  icon: "ni ni-badge text-primary",
  state: "centresCollapse",
  permission: permissions.SERVICE_CENTER.menu,
  subject: permissions.SERVICE_CENTER.subject,
  views: [
    {
      path: "/serviceCentres",
      name: "All Service Centres",
      component: PartnerServiceCentreListing,
      layout: "/admin",
      permission: permissions.SERVICE_CENTER.centres_getlist,
      subject: permissions.SERVICE_CENTER.subject,
    },
  ],
};

export default serviceCentreRoute;
