import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Form,
  FormGroup,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import * as actions from "../../../Store/actions";
import CustomMultiSelect from "../../../components/CustomMultiSelect";
import CustomDropdown from "../../../components/Filters/CustomDropdown";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader.jsx";
import AlertModal from "../../../components/Modal/Alert";
import Spinner from "../../../components/Spinner/Spinner";
import { DealerTypes } from "../../../data/products";
import { cycleProductList } from "../../../defaultValues/comparison";
import {
  buildPoliciesList,
  convertDateToString,
  convertToCommaSeperated,
  downloadExcel,
  getQueryStringParameterValue,
  defaultStatusId,
} from "../../../helpers/utils";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import * as permissions from "../../../config/Casl/permissions";
import Can from "../../../config/Casl/Can";

const validPatternSearch = /^[ a-zA-Z&0-9_-]*$/;

class CancelPolicies extends Component {
  dealerTypes = [
    { id: "ALL", name: "ALL" },
    { id: "CREDIT", name: "COCO" },
    { id: "PREPAID_WALLET", name: "FOCO" },
    { id: "PAYG", name: "GT" },
  ];

  constructor(props) {
    super(props);
    this.state = {
      openMonthDialog: false,
      cardFilter: "all",
      startDateFilter: "",
      endDateFilter: "",
      startDate: "",
      endDate: "",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      selectedDate:
        new Date().getMonth() === 0
          ? new Date(new Date().getFullYear() - 1, 11)
          : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
      showMultiSelectOption: false,
      showProduct: false,
      showProvider: false,
      filter: "This Month",
      customDateSelected: false,
      searchText: "",
      sweetAlertType: "",
      sweetAlertData: {},
      dealerType: "ALL",
      state_id: defaultStatusId(),
    };
    this.invokeApiCall();
  }

  invokeApiCall = async () => {
    await this.getPolicies();
    await this.props.onGetProductList();
    await this.props.onGetProviderList();
    await this.props.onGetStatusList();
    await this.props.onGetPaymentDueCardData();
    await this.props.onGetResubscriptionCardData();
  };

  previousMonthDate =
    new Date().getMonth() === 0
      ? new Date(new Date().getFullYear() - 1, 11)
      : new Date(new Date().getFullYear(), new Date().getMonth() - 1);

  cancellationInitiatedOptions = [
    { value: "resendPolicyMail", text: "Resend Plan Mail" },
    { value: "cancellationApproved", text: "Cancellation Approved" },
  ];

  activePolicyOptions = [
    { value: "resendPolicyMail", text: "Resend Plan Mail" },
    { value: "cancelPolicy", text: "Cancel Plan" },
  ];
  pager = {
    pagerStart: 0,
    pagerEnd: 0,
    pageArray: [],
    limit: 25,
    dealerId: "",
    productIds: [],
    providerIds: [],
    policyStatusId: "",
    sortBy: 0,
    filter: getQueryStringParameterValue("date") ?? "today",
    export: 0,
    statusIds: [],
    dir: "desc",
  };

  getPolicies = () => {
    this.props.onGetPolicyList(
      {},
      {
        policy_status: "cancelled",
        page: this.pager.pagerStart,
        // sortBy: this.pager.sortBy,
        dir: this.pager.dir,
        order_search: this.state.searchText,
        startDate: this.state.startDateFilter,
        endDate: this.state.endDateFilter,
        date: this.state.endDateFilter ? "custom" : this.pager.filter,
        // "product_id[]":
        //   this.pager.productIds.length > 0 ? this.pager.productIds : "",
        // "provider_id[]":
        //   this.pager.providerIds.length > 0 ? this.pager.providerIds : "",
        // "status_id[]":
        //   this.pager.statusIds.length > 0 ? this.pager.statusIds : "",
        export: this.pager.export,
        invoicing_modal:
          this.state.dealerType != "ALL" ? this.state.dealerType : "",
        state_id: this.state.state_id,
      }
    );
    this.pager.export = 0;
  };

  updatePagination = (total) => {
    this.pager.pageArray = [];
    this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
    for (let index = 0; index < this.pager.pagerEnd; index++) {
      this.pager.pageArray.push(index + 1);
    }
  };
  switchPage = (page) => {
    if (this.pager.pagerStart + 1 !== page) {
      this.pager.pagerStart = page - 1;
      this.getPolicies();
    }
  };
  isHidden = (page) => {
    var start = this.pager.pagerStart + 1;
    if (page > start) {
      if (page - start > 2 && start === 1) {
        return true;
      } else if (start !== 1 && page - start > 1) {
        return true;
      }
    } else {
      if (start - page > 2 && start === this.pager.pagerEnd) {
        return true;
      } else if (start !== this.pager.pagerEnd && start - page > 1) {
        return true;
      }
    }
    return false;
  };
  handleCancel = () => {
    this.setState({ ...this.state, sweetAlertType: "" });
  };
  handleConfirm = () => {
    if (
      this.state.sweetAlertType === "convertToPaid" ||
      this.state.sweetAlertType === "convertToEmbedded"
    ) {
      this.onConvertClick(this.state.sweetAlertData);
    }
    this.setState({ ...this.state, sweetAlertType: "", sweetAlertData: {} });
  };
  handleAction = (e, { value }, orderId, statusId) => {
    this.setState({
      orderId: orderId,
      isCancellationInitiated: statusId === 26,
      isCancelled: statusId === 27,
      dropDownValue: value,
    });
    if (value === "cancelPolicy") {
      this.setState({
        cancelConfirmDialog: true,
        dropDownValue: "",
      });
      this.cancelPolicy(orderId, { action: "initiate" }, value);
    }
    if (value === "cancellationApproved") {
      this.setState({
        cancelConfirmDialog: true,
        dropDownValue: "",
      });
      this.cancelPolicy(orderId, { action: "approve" }, value);
    }
    if (value === "resendPolicyMail") {
      this.onClickSend(orderId);
      this.setState({
        dropDownValue: "",
      });
    }
    if (value === "convertToPaid") {
      let payload = {
        action: "is_embedded",
        is_embedded: false,
        order_id: orderId,
      };
      this.setState({
        ...this.state,
        sweetAlertType: "convertToPaid",
        sweetAlertData: payload,
      });

      // this.onConvertClick(payload)
    } else if (value === "convertToEmbedded") {
      let payload = {
        action: "is_embedded",
        is_embedded: true,
        order_id: orderId,
      };
      this.setState({
        ...this.state,
        sweetAlertType: "convertToEmbedded",
        sweetAlertData: payload,
      });
      // this.onConvertClick(payload)
    }
  };

  onClickSend = (orderId) => {
    this.sendComms({
      order_id: orderId,
      template: "policy_mail",
    });
  };
  onConvertClick = (payload) => {
    AxiosReactClient.putRequest("v2/orders/" + payload.order_id, payload)
      .then((res) => {
        this.showMsg(res.data.message);
        this.getPolicies();
      })
      .catch((err) => {
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  sendComms = (payload) => {
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.postRequest("comms", payload)
      .then((res) => {
        this.setState({
          showLoader: false,
          sendMsgDialog: false,
        });
        this.showMsg("Communication Sent !", "success");
        this.getPolicies();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  cancelPolicy = (id, params, type) => {
    let text;
    let order_id;
    if (type === "cancelPolicy") {
      text = "Initiated";
    } else {
      text = "Approved";
    }
    if (this.state.activePolicyCount === 1) {
      order_id = this.props.order_id;
    } else {
      order_id = id;
    }
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.getRequest("orders/" + order_id + "/cancel", params)
      .then((res) => {
        this.showMsg("Policy Cancellation " + text, "success");
        this.setState({
          showLoader: false,
          openPolicyActionDialog: false,
          orderId: null,
          cancelConfirmDialog: false,
        });
        this.getPolicies();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  dateChangeFilter = (date, type) => {
    switch (type) {
      case "startDate":
        this.setState(
          {
            startDateFilter: date,
          },
          () => {
            this.getPolicies();
          }
        );
        break;
      case "endDate":
        this.setState(
          {
            endDateFilter: date,
          },
          () => {
            this.getPolicies();
          }
        );
        break;
      default:
        break;
    }
  };

  sortByColumn = (column_no) => {
    this.pager.sortBy = column_no;
    this.pager.dir = this.pager.dir === "desc" ? "asc" : "desc";
    this.getPolicies();
  };

  filterByArray(data, type) {
    let ids = data.map((element) => element.value);
    this.pager[type + "Ids"] = ids;
  }

  openMultiSelectOption() {
    this.setState({
      showMultiSelectOption: true,
    });
  }

  closeMultiSelectOption() {
    this.setState({
      showMultiSelectOption: false,
    });
    if (
      this.pager.productIds.length > 0 ||
      this.pager.providerIds.length > 0 ||
      this.pager.statusIds.length > 0
    ) {
      this.getPolicies();
    }
  }

  openRangeModal() {
    this.setState({
      startDate: moment(moment(new Date()))
        .subtract(6, "days")
        .format("YYYY-MM-DD"),
      endDate: moment(moment(new Date())).format("YYYY-MM-DD"),
    });
    this.setState({
      showRangeModal: true,
    });
  }

  closeRangeModal() {
    this.setState({
      showRangeModal: false,
    });
  }

  sendDateRequest() {
    // this.pager.pagerStart = 0;
    this.setState(
      {
        startDateFilter: moment(this.state.startDate._d).format("YYYY/MM/DD"),
        endDateFilter: moment(this.state.endDate._d).format("YYYY/MM/DD"),
        showRangeModal: false,
        // filter: "custom",
        // customDateSelected: true,
      },

      () => {
        this.fetchAllExportData(this.state.startDate, this.state.endDate);
      }
    );
  }

  onReactDateFocused = (who) => {
    if (who === "startDate") {
      this.setState({
        openEndDateDialog: false,
        openStartDateDialog: true,
      });
    } else {
      this.setState({
        openEndDateDialog: true,
        openStartDateDialog: false,
      });
    }
  };

  handleReactDatetimeChange = (type, date) => {
    let modifiedDate = moment(date).format("DD-MM-YYYY");

    if (
      this.state.startDate &&
      type === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else if (
      this.state.endDate &&
      type === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [type]: date,
      });
    }

    if (type === "startDate") {
      this.setState({
        endDate: moment(date).add(6, "days").format("YYYY-MM-DD"),
      });
      this.setState({
        openEndDateDialog: true,
        openStartDateDialog: false,
      });
    }
    if (type === "endDate") {
      this.setState({
        openEndDateDialog: false,
        openStartDateDialog: false,
      });
    }
  };

  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  selectionOfOptions = (item) => {
    let selectOpt = [];
    if (item.status_id === 26) {
      selectOpt = this.cancellationInitiatedOptions;
    }
    if (item.status_id !== 26 && item.status_id !== 27) {
      selectOpt = this.activePolicyOptions;
    }
    if (cycleProductList.includes(item.product_id)) {
      if (item.is_embedded) {
        selectOpt = [
          ...selectOpt,
          { value: "convertToPaid", text: "Convert To Paid" },
        ];
      } else {
        selectOpt = [
          ...selectOpt,
          { value: "convertToEmbedded", text: "Convert To Embedded" },
        ];
      }
    }
    return selectOpt;
  };

  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type,
    });
  };

  onCustomDateSelection = (filter, stateId) => {
    if (filter !== "custom") {
      this.pager.filter = filter;
      this.pager.pagerStart = 0;
      this.setState(
        {
          startDateFilter: "",
          endDateFilter: "",
          startDate: "",
          endDate: "",
          filter: filter,
          customDateSelected: false,
          state_id: stateId.state_id,
        },
        () => {
          this.getPolicies();
        }
      );
    } else {
      this.pager.filter = filter;
      this.pager.pagerStart = 0;
      this.setState(
        {
          startDateFilter: stateId.startDate,
          endDateFilter: stateId.endDate,
          startDate: stateId.startDate,
          endDate: stateId.endDate,
          filter: filter,
          customDateSelected: false,
          state_id: stateId.state_id,
        },
        () => {
          this.getPolicies();
        }
      );
    }
  };

  onSearch = () => {
    if (!this.state.searchText || this.state.searchText.length < 2) {
      this.showMsg(" Please, enter at least 2 characters", "danger");
      return;
    }
    if (!this.state.searchText.match(validPatternSearch)) {
      this.showMsg(" Please, don't enter any special characters", "danger");
      return;
    }
    this.getPolicies();
    this.setState({
      view: "table",
    });
  };

  exportData = () => {
    this.pager.export = 1;
    let page = this.pager.pagerStart;
    let sortBy = this.pager.sortBy;
    let order_search = this.state.searchText;
    let startDate = this.state.startDateFilter;
    let endDate = this.state.endDateFilter;
    let date = this.state.endDateFilter ? "custom" : this.pager.filter;
    let productId =
      this.pager.productIds.length > 0 ? this.pager.productIds : "";
    let providerId =
      this.pager.providerIds.length > 0 ? this.pager.providerIds : "";
    let statusId = this.pager.statusIds.length > 0 ? this.pager.statusIds : "";
    this.pager.export = 0;

    let url =
      // "https://qa.toffeeinsurance.com/api/orders?page=" +
      "orders?page=" +
      page +
      "&sortBy=" +
      sortBy +
      "&order_search=" +
      order_search +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&date=" +
      date +
      "&product_id%5B%5D=" +
      productId +
      "&provider_id%5B%5D=" +
      providerId +
      "&status_id%5B%5D=" +
      statusId +
      "&export=1";

    AxiosReactClient.getRequest(url)
      .then((res) => {
        this.setState({
          showLoader: false,
          sendMsgDialog: false,
        });
        this.showMsg("Report sent at your registered email !", "success");
        this.getPolicies();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };
  refreshFilters = () => {
    this.pager.productIds = "";
    this.pager.providerIds = "";
    this.pager.policyStatusId = "";
    this.pager.sortBy = 0;
    this.pager.filter = getQueryStringParameterValue("date") ?? "today"; //"thisMonth";
    this.pager.export = 0;
    this.pager.statusIds = "";
    this.pager.pagerStart = 0;
    this.setState(
      {
        startDateFilter: "",
        endDateFilter: "",
        startDate: "",
        endDate: "",
        filter: "This Month",
        customDateSelected: false,
        searchText: "",
        dealerType: "ALL",
      },
      () => {
        this.getPolicies();
      }
    );
  };

  disableFutureDt = (current) => {
    const today = moment();
    return current.isBefore(today);
  };

  isValidEndDate = (current) => {
    try {
      const startDate = moment(this.state.startDate).startOf("day");
      const endDate = moment(this.state.startDate)
        .add(1, "year")
        .subtract(1, "days");
      const currentEnddate = endDate.startOf("day");
      return (
        current.isSameOrAfter(startDate) &&
        current.isSameOrBefore(currentEnddate)
      );
    } catch (error) {}
  };

  exportList = () =>
    downloadExcel(
      buildPoliciesList(this.props.policyListData),
      "SalesReport.xlsx"
    );

  fetchAllExportData = (d1, d2) => {
    let payload = {
      startDate:
        this.state.filter === "custom"
          ? moment(this.state.startDate).format("DD-MM-YYYY")
          : "",
      endDate:
        this.state.filter === "custom"
          ? moment(this.state.endDate).format("DD-MM-YYYY")
          : "",
      export: 1,
      policy_status: "cancelled",
      state_id: this.state.state_id,
      date: this.pager.filter,
    };
    let url = `v2/orders`;
    AxiosReactClient.getRequest(url, payload)
      .then((res) => {
        this.setState({
          showLoader: false,
        });
        this.showMsg(`${res?.data?.data?.message}`, "success");
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  dealerTypeChanged = (id) => {
    this.pager.pagerStart = 0;
    this.setState(
      {
        dealerType: id,
      },
      () => {
        this.getPolicies();
      }
    );
  };

  render() {
    const { policyListData, policyListLoading } = this.props;
    this.props.policyTotalCount !== null &&
      this.updatePagination(this.props.policyTotalCount);
    let adminType = localStorage.getItem("type");
    let categoryType = localStorage.getItem("category");
    let franchise = localStorage.getItem("franchise");
    return (
      <Fragment>
        {this.state.sweetAlertType && (
          <AlertModal
            showCancel={true}
            confirmText="Yes"
            cancelText="Cancel"
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
            title="Are you sure to perform changes in policy?"
          />
        )}

        <AlternativeHeader
          filter={this.pager.filter}
          click={(filter, stateId) =>
            this.onCustomDateSelection(filter, stateId)
          }
          title={"Cancelled Plans"}
          icon={"fa-list"}
          defaultFilter={getQueryStringParameterValue("date") ?? "today"}
        />
        <Container fluid>
          {/* <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="8" xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block ml-1"
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary h5 font-weight-400"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      All Plans
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div> */}

          {/*<Row className="py-1 ">
            <Col xs={12} sm={6}>
              <h5 className="text-primary h5 font-weight-400"> Policies</h5>
            </Col>
          </Row>*/}

          <div>
            <Row className="bg-white m-0" hidden={this.state.hideFilters}>
              <Col
                className="p-0 text-muted d-flex justify-content-between"
                lg="6"
                xs="6"
              >
                <div className={"pt-2 mb--2 flex-grow-1 mr-3"}>
                  <form
                    xs="6"
                    className="form-inline md-form mr-3 mb-4 d-inline"
                  >
                    <div className="form-group border justify-content-between rounded-5">
                      <input
                        type="text"
                        className="form-control flex-grow-1 border-0"
                        placeholder="Search by ref id, plan number, or customer name"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={this.state.searchText}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                          e.key === "Enter"
                            ? this.onSearch()
                            : this.setState({
                                searchText: e.target.value,
                              });
                        }}
                        onChange={(e) => {
                          e.preventDefault();
                          if (e.target.value == " ") {
                            this.setState({
                              view: "dealer-table",
                            });
                          }
                          this.setState({
                            searchText: e.target.value,
                          });
                        }}
                      />
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text cursor-pointer border-0 mr-2"
                            id="basic-addon1"
                          >
                            <i
                              className="fas fa-search cursor-pointer mt-2"
                              aria-hidden="true"
                              onClick={(e) => {
                                e.preventDefault();
                                this.onSearch();
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {this.state.searchText !== "" &&
                  this.state.view === "table" && (
                    <Badge color="primary" pill className={"m-auto py-2 px-3"}>
                      {this.state.searchText}
                      <span
                        className="p-1 ml-2 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState(
                            {
                              view: "finance-table",
                              searchText: "",
                            },
                            () => this.getPolicies()
                          );
                        }}
                      >
                        <i className="fa fa-times-circle" aria-hidden="true" />
                      </span>
                    </Badge>
                  )}
              </Col>
              <Col className="text-right m-auto" lg="6" xs="6">
                <div className="">
                  <span className="mr-2">
                    <CustomDropdown
                      name="Retailer Type"
                      selectedId={this.state.dealerType}
                      handleSelection={(id) => {
                        this.dealerTypeChanged(id);
                      }}
                      options={this.dealerTypes}
                      defaultValue="ALL"
                    />
                  </span>

                  {/* <Badge
                    className={
                      this.state.customDateSelected ? "d-none" : "mr-3"
                    }
                    color={
                      this.state.filter === "custom" ? "secondary" : "primary"
                    }
                    href="#pablo"
                    pill
                  >
                    {this.state.filter !== "custom" ? this.state.filter : ""}
                  </Badge> */}

                  <Badge
                    className={
                      this.state.customDateSelected ? "mr-3" : "d-none"
                    }
                    color={
                      this.state.filter === "custom" ? "primary" : "secondary"
                    }
                    href="#pablo"
                    pill
                    onClick={(e) => {
                      e.preventDefault();
                      this.openRangeModal();
                    }}
                  >
                    {(this.state.startDate
                      ? moment(this.state.startDate).format("DD-MM-YYYY")
                      : "") +
                      " TO " +
                      (this.state.endDate
                        ? moment(this.state.endDate).format("DD-MM-YYYY")
                        : "")}
                  </Badge>

                  <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={this.state.showRangeModal}
                    toggle={() => this.closeRangeModal()}
                  >
                    <div className="modal-body">
                      <Form role="form">
                        <Row className="input-daterange datepicker align-items-center">
                          <Col xs={12} sm={6}>
                            <label className=" form-control-label">
                              Start date
                            </label>
                            <FormGroup>
                              <ReactDatetime
                                open={this.state.openStartDateDialog}
                                isValidDate={this.disableFutureDt}
                                onFocus={(e) =>
                                  this.onReactDateFocused("startDate")
                                }
                                inputProps={{
                                  placeholder: "Start Date",
                                  readOnly: true,
                                }}
                                value={
                                  this.state.startDate === ""
                                    ? this.state.startDate
                                    : moment(this.state.startDate).format(
                                        "DD-MM-YYYY"
                                      )
                                }
                                timeFormat={false}
                                onChange={(e) =>
                                  this.handleReactDatetimeChange("startDate", e)
                                }
                                renderDay={(
                                  props,
                                  currentDate,
                                  selectedDate
                                ) => {
                                  let classes = props.className;
                                  classes +=
                                    this.getClassNameReactDatetimeDays(
                                      currentDate
                                    );
                                  return (
                                    <td {...props} className={classes}>
                                      {currentDate.date()}
                                    </td>
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <FormGroup>
                              <label className=" form-control-label">
                                End date
                              </label>
                              <ReactDatetime
                                open={this.state.openEndDateDialog}
                                isValidDate={this.isValidEndDate}
                                onFocus={(e) =>
                                  this.onReactDateFocused("endDate")
                                }
                                inputProps={{
                                  placeholder: "End Date",
                                  readOnly: true,
                                }}
                                value={
                                  this.state.endDate === ""
                                    ? this.state.endDate
                                    : moment(this.state.endDate).format(
                                        "DD-MM-YYYY"
                                      )
                                }
                                timeFormat={false}
                                onChange={(e) =>
                                  this.handleReactDatetimeChange("endDate", e)
                                }
                                renderDay={(
                                  props,
                                  currentDate,
                                  selectedDate
                                ) => {
                                  let classes = props.className;
                                  classes +=
                                    this.getClassNameReactDatetimeDays(
                                      currentDate
                                    );
                                  return (
                                    <td {...props} className={classes}>
                                      {currentDate.date()}
                                    </td>
                                  );
                                }}
                                dateFormat="DD/MM/YYYY"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </div>

                    <div className="modal-footer date-modal-footer ">
                      <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.closeRangeModal()}
                      >
                        Close
                      </Button>
                      <Button
                        color="primary"
                        className="ml-auto"
                        type="button"
                        onClick={() => this.sendDateRequest()}
                      >
                        Apply
                      </Button>
                    </div>
                  </Modal>

                  <UncontrolledDropdown className="">
                    {/* <DropdownToggle
                      className="btn-primary"
                      color=""
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Date Filter
                    </DropdownToggle> */}
                    <DropdownMenu right>
                      {/* <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.refreshFilters();
                        }}
                      >
                        All{" "}
                        {this.props.filter === "all" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem> */}
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("today");
                        }}
                      >
                        Today{" "}
                        {this.props.filter === "today" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("yesterday");
                        }}
                      >
                        Yesterday{" "}
                        {this.props.filter === "yesterday" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("thisWeek");
                        }}
                      >
                        This Week{" "}
                        {this.props.filter === "thisWeek" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("thisMonth");
                        }}
                      >
                        This Month{" "}
                        {this.props.filter === "thisMonth" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("lastWeek");
                        }}
                      >
                        Last Week{" "}
                        {this.props.filter === "lastWeek" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("lastMonth");
                        }}
                      >
                        Last Month{" "}
                        {this.props.filter === "lastMonth" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.openRangeModal();
                          // this.setState({
                          //     customDateSelected: true
                          // })
                        }}
                      >
                        {this.state.filter !== "custom"
                          ? "Custom"
                          : this.state.startDate._d?.toLocaleDateString() +
                            " - " +
                            this.state.endDate._d?.toLocaleDateString() +
                            " "}
                        {this.props.filter === "custom" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      {/* <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("resubscription");
                        }}
                      >
                        Enach resubscription required
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("oneTimePayment");
                        }}
                      >
                        One time payment due this week
                      </DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  {/* <Button
                    color="primary "
                    outline
                    size="sm"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.refreshFilters();
                    }}
                  >
                    Clear
                  </Button> */}

                  {/* <span>
                    <Button
                      color="primary"
                      size="sm"
                      type="button"
                      className="mr-2"
                      onClick={(e) => {
                        e.preventDefault();
                        this.exportList();
                      }}
                      disabled={!policyListData?.length}
                    >
                      Export
                    </Button>
                  </span>
                  <span>
                    <Button
                      color="primary"
                      size="sm"
                      type="button"
                      className="mr-2"
                      onClick={(e) => {
                        this.openRangeModal();
                        // this.fetchAllExportData();
                      }}
                    >
                      Custom Export
                    </Button>
                  </span> */}
                  <span>
                    <Button
                      color="primary"
                      size="sm"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.fetchAllExportData();
                      }}
                      disabled={
                        this.props.policyListData?.length > 0 ? false : true
                      }
                    >
                      Export
                    </Button>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <Card
            className="m-0 mt-1"
            style={{
              height: "auto",

              overflowY: "scroll",
            }}
          >
            <CardBody className="px-0 py-0">
              <div className="table-responsive">
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Ref ID</th>
                      <Can
                        I={permissions.GARANTIE.customer_name}
                        a={permissions.GARANTIE.subject}
                      >
                        <th>Customer Name</th>
                      </Can>
                      <th
                        onClick={() => {
                          this.setState({
                            showProvider: false,
                            showProduct: true,
                            showStatus: false,
                          });
                          this.openMultiSelectOption();
                        }}
                      >
                        <div className={"cursor-pointer d-flex justify-center"}>
                          <span>Plan&nbsp;&nbsp;&nbsp;</span>
                          {this.state.showProduct && (
                            <CustomMultiSelect
                              showMultiSelectOption={
                                this.state.showMultiSelectOption
                              }
                              closeMultiSelectOption={() =>
                                this.closeMultiSelectOption()
                              }
                              options={this.props.productListData}
                              filterByArray={(ids) =>
                                this.filterByArray(ids, "product")
                              }
                              title="Plan"
                            />
                          )}
                          <i className="fas fa-filter align-self-center" />
                        </div>
                      </th>
                      <th>Plan Number</th>
                      <Can
                        I={permissions.GARANTIE.total_premium}
                        a={permissions.GARANTIE.subject}
                      >
                        <th>Premium</th>
                      </Can>
                      <Can
                        I={permissions.GARANTIE.retailer_commission}
                        a={permissions.GARANTIE.subject}
                      >
                        <th>Retailer Commission</th>
                      </Can>
                      <Can
                        I={permissions.GARANTIE.vivo_india}
                        a={permissions.GARANTIE.subject}
                      >
                        <th>
                          {(adminType === "oem" && categoryType === "hod") ||
                          adminType === "gsp"
                            ? "vivo margin"
                            : null}
                        </th>
                      </Can>
                      <Can
                        I={permissions.GARANTIE.state_margin}
                        a={permissions.GARANTIE.subject}
                      >
                        {franchise === "false" && <th>State margin</th>}
                      </Can>
                      <Can
                        I={permissions.GARANTIE.garantie_margin}
                        a={permissions.GARANTIE.subject}
                      >
                        {(adminType === "gsp" ||
                          (adminType === "oem" && categoryType === "hod")) && (
                          <th>Garantie margin</th>
                        )}
                      </Can>

                      <th> Retailer Type</th>
                      <th>Retailer Code</th>
                      <th>Child Code</th>
                      <th>promoter Id</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Plan Purchase Date</th>
                      {/* <th>Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {policyListLoading && (
                      <tr>
                        <td colSpan="12">
                          <Spinner></Spinner>
                        </td>
                      </tr>
                    )}
                    {!policyListLoading &&
                      policyListData &&
                      policyListData.length < 1 && (
                        <tr className="cursor-pointer">
                          <td colSpan="12" className="text-center">
                            <span className="text-muted">
                              <b>No plan data found</b>
                            </span>
                          </td>
                        </tr>
                      )}
                    {!policyListLoading &&
                      policyListData &&
                      policyListData.map((item, index) => {
                        return (
                          <tr key={index + "hgshdsh"}>
                            <td className="table-user">
                              <a
                                className="font-weight-bold"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                    "/admin/policies/" + item.id
                                  );
                                }}
                              >
                                {item.id}
                              </a>
                            </td>
                            <Can
                              I={permissions.GARANTIE.customer_name}
                              a={permissions.GARANTIE.subject}
                            >
                              <td title={item.name}>
                                {item.name
                                  ? item.name.length >= 20
                                    ? `${item.name
                                        .substring(0, 1)
                                        .toUpperCase()}${item.name.substring(
                                        1,
                                        17
                                      )}...`
                                    : item.name.charAt(0).toUpperCase() +
                                      item.name.slice(1)
                                  : "-"}
                              </td>
                            </Can>
                            <td>{item.product_name}</td>
                            <td>{item.policy_number}</td>
                            <Can
                              I={permissions.GARANTIE.total_premium}
                              a={permissions.GARANTIE.subject}
                            >
                              <td>{`₹${convertToCommaSeperated(
                                item.premium
                              )}`}</td>
                            </Can>
                            <Can
                              I={permissions.GARANTIE.retailer_commission}
                              a={permissions.GARANTIE.subject}
                            >
                              <td>{`₹${convertToCommaSeperated(
                                item.commission
                              )}`}</td>
                            </Can>
                            <Can
                              I={permissions.GARANTIE.vivo_india}
                              a={permissions.GARANTIE.subject}
                            >
                              <td>
                                {(adminType === "oem" &&
                                  categoryType === "hod") ||
                                adminType === "gsp"
                                  ? `₹${convertToCommaSeperated(
                                      item.oem_commission_part
                                    )}`
                                  : null}
                              </td>
                            </Can>
                            <Can
                              I={permissions.GARANTIE.state_margin}
                              a={permissions.GARANTIE.subject}
                            >
                              {franchise === "false" && (
                                <td>
                                  {`₹${convertToCommaSeperated(
                                    item.state_commission_part
                                  )}`}
                                </td>
                              )}
                            </Can>
                            <Can
                              I={permissions.GARANTIE.garantie_margin}
                              a={permissions.GARANTIE.subject}
                            >
                              {(adminType === "gsp" ||
                                (adminType === "oem" &&
                                  categoryType === "hod")) && (
                                <td>
                                  {" "}
                                  {`₹${convertToCommaSeperated(
                                    item.garantie_commission
                                  )}`}
                                </td>
                              )}
                            </Can>
                            <td>
                              {item.invoicing_modal
                                ? DealerTypes[item.invoicing_modal]
                                : item.invoicing_modal}
                            </td>
                            <td>{item?.code}</td>
                            <td>{item?.store_code}</td>
                            <td>
                              {item?.promoter_id ? item?.promoter_id : "-"}
                            </td>
                            <td>
                              {convertDateToString(
                                item.start_date,
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td>
                              {convertDateToString(item.end_date, "DD-MM-YYYY")}
                            </td>
                            <td>
                              {item?.contract_start_date
                                ? moment(item?.contract_start_date).format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            {!policyListLoading &&
              policyListData &&
              policyListData.length > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={this.pager.pagerStart > 0 ? "" : "disabled"}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.switchPage(this.pager.pagerStart);
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {this.pager.pageArray.map((page) => {
                        return (
                          <PaginationItem
                            key={"pager_" + page}
                            hidden={this.isHidden(page)}
                            className={
                              this.pager.pagerStart + 1 === page ? "active" : ""
                            }
                          >
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                this.switchPage(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                      <PaginationItem
                        className={
                          this.pager.pagerEnd > this.pager.pagerStart + 1
                            ? ""
                            : "disabled"
                        }
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.switchPage(this.pager.pagerStart + 2);
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              )}
          </Card>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    policyListLoading: state.policies.policyListLoading,
    policyListData: state.policies.policyListData,
    productListData: state.policies.policyProductListData,
    providerListData: state.policies.policyProviderListData,
    statusListData: state.policies.policyStatusListData,
    policyTotalCount: state.policies.policyTotalCount,
    policyPaymentDueCardLoading: state.policies.policyPaymentDueCardLoading,
    policyResubscriptionCardLoading:
      state.policies.policyResubscriptionCardLoading,
    policyPaymentDueCardData: state.policies.policyPaymentDueCardData,
    policyResubscriptionCardData: state.policies.policyResubscriptionCardData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onShowNotification: (notification) =>
      dispatch(actions.setNotificationData(notification)),
    onGetPolicyList: (filter, params) =>
      dispatch(actions.getPolicyList(filter, params)),
    onGetProductList: (filter, params) =>
      dispatch(actions.getPolicyProductListData(filter, params)),
    onGetProviderList: (filter, params) =>
      dispatch(actions.getPolicyProviderListData(filter, params)),
    onGetStatusList: (filter, params) =>
      dispatch(actions.getPolicyStatusListData(filter, params)),
    onGetPaymentDueCardData: () =>
      dispatch(actions.getPolicyPaymentDueCardData()),
    onGetResubscriptionCardData: () =>
      dispatch(actions.getPolicyResubscriptionCardData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CancelPolicies);
