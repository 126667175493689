import * as actionTypes from "./actionTypes/policyTypes";
import AxiosReactClient from "../../utilities/AxiosRestClient";

// START
export const getPolicyInputsStart = () => {
  return {
    type: actionTypes.GET_POLICY_INPUTS_START,
  };
};
export const getPolicyProductListStart = () => {
  return {
    type: actionTypes.GET_POLICY_PRODUCT_LIST_START,
  };
};
export const getPolicyProviderListStart = () => {
  return {
    type: actionTypes.GET_POLICY_PROVIDER_LIST_START,
  };
};
export const getPolicyStatusListStart = () => {
  return {
    type: actionTypes.GET_POLICY_STATUS_LIST_START,
  };
};
export const getPolicyDealerListStart = () => {
  return {
    type: actionTypes.GET_POLICY_DEALER_LIST_START,
  };
};
export const getPolicyCityListStart = () => {
  return {
    type: actionTypes.GET_POLICY_CITY_LIST_START,
  };
};
export const getPolicyDealerCreditStart = () => {
  return {
    type: actionTypes.GET_POLICY_DEALER_CREDIT_START,
  };
};
export const getPolicyBrandsStart = () => {
  return {
    type: actionTypes.GET_POLICY_BRANDS_START,
  };
};
export const getPolicyListStart = () => {
  return {
    type: actionTypes.GET_POLICY_LIST_START,
  };
};
export const getTransactionListStart = () => {
  return {
    type: actionTypes.GET_TRANSACTION_LIST_START,
  };
};
export const getPolicyHolderDetailsStart = () => {
  return {
    type: actionTypes.GET_POLICY_HOLDER_DETAILS_START,
  };
};
export const postQuotationStart = () => {
  return {
    type: actionTypes.POST_RETRIEVE_QUOTATION_DATA_START,
  };
};
export const getPolicyCancellationStart = () => {
  return {
    type: actionTypes.GET_POLICY_CANCELLATION_START,
  };
};
export const getPolicyPaymentDueCardStart = () => {
  return {
    type: actionTypes.GET_POLICY_PAYMENT_DUE_CARD_START,
  };
};
export const getPolicyResubscriptionCardStart = () => {
  return {
    type: actionTypes.GET_POLICY_RESUBSCRIPTION_DUE_CARD_START,
  };
};

export const getPolicyQuotationListStart = () => {
  return {
    type: actionTypes.GET_QUOTATION_LIST_START,
  };
};
export const getCyclePolicyBrandsStart = () => {
  return {
    type: actionTypes.GET_CYCLE_BRANDS_START,
  };
};

// SUCCESS
export const getPolicyInputsSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_INPUTS_SUCCESS,
    data: data,
  };
};
export const getPolicyProductListSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_PRODUCT_LIST_SUCCESS,
    data: data,
  };
};
export const getPolicyProviderListSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_PROVIDER_LIST_SUCCESS,
    data: data,
  };
};
export const getPolicyStatusListSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_STATUS_LIST_SUCCESS,
    data: data,
  };
};
export const getPolicyDealerListSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_DEALER_LIST_SUCCESS,
    data: data,
  };
};
export const getPolicyCityListSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_CITY_LIST_SUCCESS,
    data: data,
  };
};
export const getPolicyDealerCreditSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_DEALER_CREDIT_SUCCESS,
    data: data,
  };
};
export const getPolicyBrandsSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_BRANDS_SUCCESS,
    data: data,
  };
};

export const getPolicyListSuccess = (data, count) => {
  return {
    type: actionTypes.GET_POLICY_LIST_SUCCESS,
    data: data,
    count: count,
  };
};
export const getTransactionListSuccess = (data) => {
  return {
    type: actionTypes.GET_TRANSACTION_LIST_SUCCESS,
    data: data,
  };
};
export const getPolicyHolderDetailsSuccess = (policyHolderDetailsData) => {
  return {
    type: actionTypes.GET_POLICY_HOLDER_DETAILS_SUCCESS,
    policyHolderDetailsData: policyHolderDetailsData,
  };
};
export const postQuotationSuccess = (quotation) => {
  return {
    type: actionTypes.POST_RETRIEVE_QUOTATION_DATA_SUCCESS,
    quotation: quotation,
  };
};
export const getPolicyCancellationSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_CANCELLATION_SUCCESS,
    data: data,
  };
};
export const getPolicyPaymentDueCardSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_PAYMENT_DUE_CARD_SUCCESS,
    data: data,
  };
};
export const getPolicyResubscriptionCardSuccess = (data) => {
  return {
    type: actionTypes.GET_POLICY_RESUBSCRIPTION_DUE_CARD_SUCCESS,
    data: data,
  };
};

export const getPolicyQuotationListSuccess = (data, count) => {
  return {
    type: actionTypes.GET_QUOTATION_LIST_SUCCESS,
    data: data,
    count: count,
  };
};
export const getCyclePolicyBrandsSuccess = (data, count) => {
  return {
    type: actionTypes.GET_CYCLE_BRANDS_SUCCESS,
    data: data,
  };
};

// FAIL
export const getPolicyInputsFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_INPUTS_FAIL,
    error: error,
  };
};
export const getPolicyProductListFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_PRODUCT_LIST_FAIL,
    error: error,
  };
};
export const getPolicyProviderListFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_PROVIDER_LIST_FAIL,
    error: error,
  };
};
export const getPolicyStatusListFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_STATUS_LIST_FAIL,
    error: error,
  };
};
export const getPolicyDealerListFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_DEALER_LIST_FAIL,
    error: error,
  };
};
export const getPolicyCityListFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_CITY_LIST_FAIL,
    error: error,
  };
};
export const getPolicyDealerCreditFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_DEALER_CREDIT_FAIL,
    error: error,
  };
};
export const getPolicyBrandsFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_BRANDS_FAIL,
    error: error,
  };
};

export const getPolicyListFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_LIST_SUCCESS,
    error: error,
  };
};
export const getTransactionListFail = (error) => {
  return {
    type: actionTypes.GET_TRANSACTION_LIST_SUCCESS,
    error: error,
  };
};
export const getPolicyHolderDetailsFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_HOLDER_DETAILS_SUCCESS,
    error: error,
  };
};
export const postQuotationFail = (error) => {
  return {
    type: actionTypes.POST_RETRIEVE_QUOTATION_DATA_FAIL,
    error: error,
  };
};
export const getPolicyCancellationFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_CANCELLATION_FAIL,
    error: error,
  };
};
export const getPolicyPaymentDueCardFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_PAYMENT_DUE_CARD_FAIL,
    error: error,
  };
};
export const getPolicyResubscriptionCardFail = (error) => {
  return {
    type: actionTypes.GET_POLICY_RESUBSCRIPTION_DUE_CARD_FAIL,
    error: error,
  };
};

export const getPolicyQuotationListFail = (error) => {
  return {
    type: actionTypes.GET_QUOTATION_LIST_SUCCESS,
    error: error,
  };
};
export const getCyclePolicyBrandsFail = (error) => {
  return {
    type: actionTypes.GET_CYCLE_BRANDS_FAIL,
    error: error,
  };
};

// for custom brands fix
export const setCustomBrandVisibility = (data) => {
  return {
    type: actionTypes.CUSTOM_BRAND_SHOW,
    show: data,
  };
};

export const setCustomCycleBrandVisibility = (data) => {
  return {
    type: actionTypes.CUSTOM_BRAND_SHOW,
    show: data,
  };
};

// ACTION
export const getPolicyInputsData = (id, dealer_id) => {
  return (dispatch) => {
    dispatch(getPolicyInputsStart());
    AxiosReactClient.getRequest(
      "products/" + id + "/" + dealer_id + "/buyFlowSteps",
      {}
    )
      .then((response) => {
        dispatch(getPolicyInputsSuccess(response.data.data));
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyInputsFail(err.response.data.error));
        else
          dispatch(
            getPolicyInputsFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};

export const getPolicyProductListData = () => {
  return (dispatch) => {
    dispatch(getPolicyProductListStart());
    AxiosReactClient.getRequest("filter/product/list", {
      group_retail_suffix: "no",
      partner_slug: "vivo-mobile-partner",
    })
      .then((response) => {
        dispatch(getPolicyProductListSuccess(response.data.data.product));
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyProductListFail(err.response.data.error));
        else
          dispatch(
            getPolicyProductListFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};

export const getPolicyProviderListData = () => {
  return (dispatch) => {
    dispatch(getPolicyProviderListStart());
    AxiosReactClient.getRequest("filter/provider/list")
      .then((response) => {
        dispatch(getPolicyProviderListSuccess(response.data.data.provider));
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyProviderListFail(err.response.data.error));
        else
          dispatch(
            getPolicyProviderListFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};

export const getPolicyStatusListData = () => {
  return (dispatch) => {
    dispatch(getPolicyStatusListStart());
    AxiosReactClient.getRequest("filter/status/list")
      .then((response) => {
        dispatch(getPolicyStatusListSuccess(response.data.data.status));
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyStatusListFail(err.response.data.error));
        else
          dispatch(
            getPolicyStatusListFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};

export const getPolicyDealerListData = () => {
  return (dispatch) => {
    dispatch(getPolicyDealerListStart());
    AxiosReactClient.getRequest("products/getDealerUser")
      .then((response) => {
        dispatch(getPolicyDealerListSuccess(response.data.data.dealers));
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyDealerListFail(err.response.data.error));
        else
          dispatch(
            getPolicyDealerListFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};
export const getPolicyCityListData = (id) => {
  return (dispatch) => {
    dispatch(getPolicyCityListStart());
    AxiosReactClient.getRequest("cities/" + id)
      .then((response) => {
        dispatch(getPolicyCityListSuccess(response.data.data.cities));
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyCityListFail(err.response.data.error));
        else
          dispatch(
            getPolicyCityListFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};
export const getPolicyDealerCreditData = (id) => {
  return (dispatch) => {
    dispatch(getPolicyDealerCreditStart());
    AxiosReactClient.getRequest("products/" + id + "/creditAvailable")
      .then((response) => {
        dispatch(getPolicyDealerCreditSuccess(response.data.data));
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyDealerCreditFail(err.response.data.error));
        else
          dispatch(
            getPolicyDealerCreditFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};
export const getPolicyBrandsData = (id) => {
  return (dispatch) => {
    dispatch(getPolicyBrandsStart());
    AxiosReactClient.getRequest("products/getBrands/" + id)
      .then((response) => {
        dispatch(getPolicyBrandsSuccess(response.data.data.brands));
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyBrandsFail(err.response.data.error));
        else
          dispatch(
            getPolicyBrandsFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};

// for custom brands fix
export const onSetCustomBrandVisibility = (data) => {
  return (dispatch) => {
    if (data.name == "response_brand_id" && data.value == 23) {
      dispatch(setCustomBrandVisibility(true));
    } else if (data.name == "response_brand_id" && data.value !== 23) {
      dispatch(setCustomBrandVisibility(false));
    }
  };
};

export const getPolicyList = (filter, params) => {
  return (dispatch) => {
    dispatch(getPolicyListStart());

    AxiosReactClient.getRequest(`v2/orders`, params)
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        dispatch(
          getPolicyListSuccess(
            res.data.data.policies,
            res.data.data.total_count
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getPolicyCancellationData = (id, params) => {
  return (dispatch) => {
    dispatch(getPolicyCancellationStart());

    AxiosReactClient.getRequest("orders/" + id + "/cancel", params)
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        dispatch(getPolicyCancellationSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getPolicyHolderDetails = (orderId) => {
  return (dispatch) => {
    dispatch(getPolicyHolderDetailsStart());

    AxiosReactClient.getRequest(`orders/${orderId}`)
      .then((res) => {
        dispatch(getPolicyHolderDetailsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getTransactionList = (orderId) => {
  return (dispatch) => {
    dispatch(getTransactionListStart());

    AxiosReactClient.getRequest(`orders/${orderId}/transactions`)
      .then((res) => {
        dispatch(getTransactionListSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const postRetrieveQuotation = (productId) => {
  return (dispatch) => {
    dispatch(postQuotationStart());

    AxiosReactClient.postRequest(`web/quotations/${productId}`, {})
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        dispatch(postQuotationSuccess(res.data.data[0]));
      })
      .catch((err) => {
        console.log(err);
        // if (err.response) dispatch(postSendOtpFail(err.response.data.error));
        // else
        //   dispatch(
        //     postQuotationFail({
        //       code: 502,
        //       message: err.message,
        //     })
        //   );
      });
  };
};

export const getPolicyPaymentDueCardData = () => {
  return (dispatch) => {
    dispatch(getPolicyPaymentDueCardStart());
    AxiosReactClient.getRequest("orders/payment_due/count", {
      type: "payment_link",
    })
      .then((response) => {
        dispatch(
          getPolicyPaymentDueCardSuccess(response.data.data.due_payment_count)
        );
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyPaymentDueCardFail(err.response.data.error));
        else
          dispatch(
            getPolicyPaymentDueCardFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};

export const getPolicyResubscriptionCardData = () => {
  return (dispatch) => {
    dispatch(getPolicyResubscriptionCardStart());
    AxiosReactClient.getRequest("orders/resubscriptions/count")
      .then((response) => {
        dispatch(
          getPolicyResubscriptionCardSuccess(
            response.data.data.resubscriptions_count
          )
        );
      })
      .catch((err) => {
        if (err.response)
          dispatch(getPolicyResubscriptionCardFail(err.response.data.error));
        else
          dispatch(
            getPolicyResubscriptionCardFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};
export const getPolicyQuotationList = (filter, params) => {
  return (dispatch) => {
    dispatch(getPolicyQuotationListStart());

    AxiosReactClient.getRequest(`/orders`, params)
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        dispatch(
          getPolicyQuotationListSuccess(
            res.data.data.policies,
            res.data.data.total_count
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getCyclePolicyBrandsData = (id) => {
  return (dispatch) => {
    dispatch(getCyclePolicyBrandsStart());
    AxiosReactClient.getRequest(`products/${id}/brands`)
      .then((response) => {
        dispatch(getCyclePolicyBrandsSuccess(response.data.data.brands));
      })
      .catch((err) => {
        if (err.response)
          dispatch(getCyclePolicyBrandsFail(err.response.data.error));
        else
          dispatch(
            getCyclePolicyBrandsFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};

// for custom brands fix
export const onSetCustomCycleBrandVisibility = (value) => {
  return (dispatch) => {
    dispatch(setCustomCycleBrandVisibility(value == 23));
  };
};
