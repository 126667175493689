import moment from 'moment';
import { convertDateToString } from './utils';
import { Utils } from '../constants/utils';

const formFields = {
  date: [],
  number: []
};

export const buildCreateCompareQuotationPayload = ({ hasError, ...rest }) => {
  const { is_smoker } = rest;

  return {
    ...rest,
    is_smoker: is_smoker === "true" || is_smoker === true
  };
};

export const castToFields = ({...object}) => {
  for (var key in object) {
    if (object[key] === '') {
      object[key] = null;
    } else if (formFields.date.indexOf(key) > -1 ) {
      object[key] = convertDateToString(object[key]);
    } else if (formFields.number.indexOf(key) > -1 ) {
      object[key] = parseInt(object[key], 10);
    } 
  } 

  return object;
};

export const filterList = list => list.filter(item => item.title);

export const getOptionsList = () => {
  return [
    {
      value: 'viewCompareQuotation', 
      text: 'View Compare Quotation'
    },
  ];
};

export const isValidDate = (current, field) => {
  if (field === 'issued_date') {
    return current.isBefore( moment() );
  }
};

export const buildPolicyTermOptions = (min = 10, max = 67) => {
  let options = [];

  for (let start = min; start <= max; start++) {
    options.push({
      title: `${start} Years`, 
      value: start
    });
  }

  return options;
};

export const isCalculatePremiumButtonDisabled = ({ annual_income, dob, gender, is_smoker, occupation, mobile, name, pincode, hasError }) => {
  return (!annual_income || !dob || !gender || is_smoker === '' || !occupation || !mobile || !name || !pincode || hasError)
};

export const buildCoverageOptions = () => {
  return [
    {
      title: 2500000, value: 2500000
    },
    {
      title: 5000000, value: 5000000
    },
    {
      title: 7500000, value: 7500000
    },
    {
      title: 10000000, value: 10000000
    },
    {
      title: 15000000, value: 15000000
    },
    {
      title: 20000000, value: 20000000
    }
  ];
};

export const capitalize = word => {
	return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const getAge = dob => {
  return moment().diff(dob, 'years');
};

export const syncSelectionWithObject = (object, products) => {
  for (const property in object) {
    if (property != 0) {
      object[property].checked = products[property].premium && products[property].is_selected;
    }
  }
};

export const syncCalculatePremiumData = (object, products) => {
  for (const property in object) {
    if (property != 0) {
      if (products[property].premium === 0) {
        object[property].checked = false;
      }
    }
  }
};

export const syncSelectionWithProducts = (object, products) => {
  for (const property in object) {
    if (property != 0) {
      products[property].is_selected = object[property].checked;
    }
  }
};

export const buildCalculatePremiumPayload = ({ sum_assured, policy_term, payment_frequency }) => {
  return {
    sum_assured,
    policy_term,
    payment_frequency
  };
};

export const buildShareComparisonPayload = ({ products }, product) => {
  const object = {...products};

  for (const property in object) {
    object[property].is_selected = product[property].checked && object[property].premium > 0 ? true : false;
  }

  return {
    products: object
  };
};

export const isSharedDisabled = ({ products }, product) => {
  let isDisabled = true,
    selectedCount = 0,
    totalAvailable = 0;

  for (const property in products) {
    if (products[property].premium > 0) {
      ++totalAvailable;
      if (product[property].checked) {
        ++selectedCount;
      }
    }
  }

  if (selectedCount >= 1) {
    isDisabled = false;
  }
  // if (selectedCount >= 2) {
  //   isDisabled = false;
  // } else if (totalAvailable === 1 && selectedCount === totalAvailable) {
  //   isDisabled = false;
  // }

  return isDisabled;
};

// export const renderSelectedPlans = ({ products }) => {
//   let selectedPlans = [];

//   for (const property in products) {
//     if (products[property].premium && products[property].is_selected) {
//       selectedPlans.push(Utils.products[property].plan);
//     }
//   }

//   return selectedPlans.length ? selectedPlans.join(', ') : null;
// };

export const renderSelectedPlans = (product) => {
  let selectedPlans = [];

  for (const property in product) {
    if (property != 0 && product[property].checked) {
      selectedPlans.push(Utils.products[property].plan);
    }
  }

  return selectedPlans.length ? selectedPlans.join(', ') : null;
};

export const minimumSelectionRequired = ({ products }) => {
  let totalAvailable = 0;

  for (const property in products) {
    if (products[property].premium) {
      totalAvailable++;
    }
  }

  return totalAvailable >= 2 ? 2 : 1;
};

export const syncMissingGPPProducts = ({ products }) => {
  const gppProducts = ['59_increasing_sa', '59_return_of_premium', '59_accelerated_critical_illness'];

  gppProducts.map((item) => {
    if (!products[item]){
      products[item] = {};
      products[item].premium = 0;
      products[item].is_available = false;
      products[item].is_selected = false;
    }
  });
};