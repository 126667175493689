import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import * as actions from '../../../../Store/actions';
import Spinner from "../../../../components/Spinner/Spinner";
import Alert from "../../../../components/Modal/Alert"

import {
	Button,
	Card,
	Table,
	Row,
	Container,
	Breadcrumb,
	BreadcrumbItem,
	Col,
	UncontrolledTooltip,
	CardFooter,
	Pagination,
	Tooltip,
	PaginationItem,
	PaginationLink,
	Badge,
} from "reactstrap";
import AxiosRestClient from '../../../../utilities/AxiosRestClient';

class Users extends Component {
	state = {
		rolesList: [{}, {}, {}, {}, {},],
		view: "user-table",
		searchText: '',
		isModalProcess:false,
		openDeleteModal:false,
		delete_id:"",
		searchEntityId:"",
		searchEntityName:""
	}
	constructor(props) {
		super(props)
		this.props.clearList()
		// this.getConfigData();
	}
	pager = {
		pagerStart: 0,
		pagerEnd: 0,
		pageArray: [],
		limit: 25,
	}
	getConfigData = () => {
		const query = {};
		if(this.state.searchEntityName){
			query.entity=this.state.searchEntityName;
		}
		if(this.state.searchEntityId){
			query.entity_id=this.state.searchEntityId;
		}
		this.props.onGetConfigData({ ...query });
	}
	updatePagination = (total) => {
		this.pager.pageArray = [];
		this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
		for (let index = 0; index < this.pager.pagerEnd; index++) {
			this.pager.pageArray.push(index + 1)
		}
	}
	switchPage = (page) => {
		if (((this.pager.pagerStart + 1) !== page)) {
			this.pager.pagerStart = page - 1
			this.getConfigData();
		}
	}
	isHidden = (page) => {
		var start = this.pager.pagerStart + 1;
		if (page > start) {
			if ((page - start) > 2 && (start === 1)) {
				return true;
			} else if ((start !== 1) && ((page - start) > 1)) {
				return true;
			}
		} else {
			if ((start - page) > 2 && (start === this.pager.pagerEnd)) {
				return true;
			} else if ((start !== this.pager.pagerEnd) && ((start - page) > 1)) {
				return true;
			}
		}
		return false
	}

	onSearch = (value) => {
		this.props.clearList()
		this.getConfigData()
		this.setState({
			view: "table"
		})
	}

	onDelete = (id) =>{
		this.setState({
          isModalProcess:true
      	});
      AxiosRestClient.postRequest('delete_config',{id:id}).then(response => {
		  this.props.removeConfig(this.props.configList,id);
          this.setState({
			openDeleteModal:false,delete_id:null,isModalProcess:false,
		})
          this.showMsg("Config removed successfully","success")
      }).catch(err=>{
          this.setState({
              showLoader:false,
				isModalProcess:false,
				openDeleteModal:false,delete_id:null
          })
          if(err.response){
              this.showMsg(err.response.data.message,"danger");
          }else if(err.message){
              this.showMsg(err.message,"danger")
          }
      })
	}

	showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };

	onConfirm = () =>{
		this.setState({isModalProcess:true})
		if(this.state.delete_id){
			this.onDelete(this.state.delete_id)
		}else{
			this.setState({openDeleteModal:false,delete_id:null,isModalProcess:false,})
		}
    }
	closeConfirmationModal = () =>{
		this.setState({
			openDeleteModal:false,delete_id:null
		})
    }
	render() {
		const {isModalProcess,openDeleteModal} = this.state;
		return (
			<Fragment>
				<Alert loading={isModalProcess} onCancel={this.closeConfirmationModal} onConfirm={this.onConfirm} show={openDeleteModal} title="Are you sure you want to delete this?"/>
				<Container fluid>
					<div className="header-body">
						<Row className="align-items-center py-4">
							<Col lg="4" xs="6">
								{/* <h6 className="h2 d-inline-block mb-0">Alternative</h6>{" "} */}
								<Breadcrumb
									className="d-none d-md-inline-block"
									listClassName="breadcrumb-links"
								>
									<BreadcrumbItem>
										<a href="#pablo" onClick={e => e.preventDefault()}>
											<i className="fas fa-user" />
										</a>
									</BreadcrumbItem>
									<BreadcrumbItem>
										<a href="#pablo" onClick={e => e.preventDefault()}>
											Config
										</a>
									</BreadcrumbItem>
								</Breadcrumb>
							</Col>
							<Col className="text-right" lg="8" xs="6">

								<Button
									className="btn-round btn-icon "
									color="success"
									href="#pablo"
									id="tooltip28070728"
									onClick={e => {
										e.preventDefault()
										this.props.history.push('/admin/config/create')
									}}
									size="sm"
								>
									<span className="btn-inner--icon mr-1">
										<i className="fas fa-user-plus" />
									</span>
									<span className="btn-inner--text">Add</span>
								</Button>
								<UncontrolledTooltip delay={0} target="tooltip28070728">
									Add Config
								</UncontrolledTooltip>

							</Col>
						</Row>
					</div>
					<div >
						<Row className="bg-white m-0 py-2">
							<Col className="p-1 px-3 text-muted text-left">
								<small>
									<form xs="6" className="form-inline md-form mr-3 mb-4 d-inline">
										<input className="ml-2 bg-white border-0 text-muted pr-2 searchWidth"
											value={this.state.searchEntityName}
											onKeyDown={e => {
												(e.key === 'Enter') && this.onSearch(e.target.value)
											}}
											onChange={e => {
												this.setState({
													searchEntityName: e.target.value
												})
											}}
											type="text" placeholder="Entity Name" aria-label="Search" />
										<input className="ml-2 bg-white border-0 text-muted pr-2 searchWidth"
											value={this.state.searchEntityId}
											onKeyDown={e => {
												(e.key === 'Enter') && this.onSearch(e.target.value)
											}}
											onChange={e => {
												this.setState({
													searchEntityId: e.target.value
												})
											}}
											type="text" placeholder="Entity Id" aria-label="Search" />
										<i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
											onClick={
												e => {
													e.preventDefault()
													this.onSearch(this.state.searchText)
												}}
										></i>
									</form>
								</small>
								{(this.state.searchText !== "") && (this.state.view === "table") && <Badge color="primary" pill>
									{this.state.searchText}
									<span className="p-1 ml-2 cursor-pointer" onClick={
										e => {
											e.preventDefault()
											this.resetFilters()
											this.setState({
												view: "user-table",
												searchText: ""
											})
										}} >
										<i className="fa fa-times-circle" aria-hidden="true"></i>
									</span>
								</Badge>}
							</Col>

						</Row>
					</div>
					<Card className="" >
						<Table className="align-items-center table-flush" hover responsive>
							<thead className="thead-light">
								<tr>
									<th>Entity Id</th>
									<th>Entity</th>
									<th>Data</th>
									<th>Created At</th>
									<th className="text-right">Action</th>
								</tr>
							</thead>
							<tbody>
								{
									this.props.configListLoading && <tr >
										<td colSpan="12">
											<Spinner></Spinner>
										</td>
									</tr>
								}
								{
									!this.props.configListLoading && this.props.configList && this.props.configList instanceof Array && this.props.configList.length < 1 && <tr>
										<td colSpan="12" style={{textAlign: "center"}}>
											No Data
										</td>
									</tr>
								}
								{this.props.configList instanceof Array && this.props.configList.map((item, index) => {
									return <tr key={Math.random(100)}>
											<td className="table-user">
											{item.entity_id}
										</td>
										<td className="table-user">
											{item.entity}
										</td>
										<td style={{whiteSpace : "inherit"}}>
											{/* <b>{item.name}</b> */}
											<div>
												{JSON.stringify(item?.data, null, " ")}
											</div>
										</td>
										<td>
											{item.created_at && new Date(item.created_at.replace(" ", "T")).toDateString()}
										</td>
										<td className="text-right">

											<Button
												className="btn-round btn-icon"
												outline
												color="primary"
												id="tooltip-edit"
												onClick={e => {
													e.preventDefault()
													this.props.history.push('/admin/config/' + item.entity + "/edit/" + item.entity_id + "/" + item.id)
												}}
												size="sm"
											>
												<span className="btn-inner--icon mr-1">
													<i className="fas fa-edit" />
												</span>
												<span className="btn-inner--text">Edit</span>
											</Button>
											<UncontrolledTooltip delay={0} target="tooltip-edit">
												Edit Config
											</UncontrolledTooltip>
											<Button
												className="btn-round btn-icon"
												outline
												color="danger"
												id="tooltip-edit"
												onClick={e => {
													e.preventDefault()
													this.setState({openDeleteModal:true,delete_id: item.id})
												}}
												size="sm"
											>
												<span className="btn-inner--icon mr-1">
													<i className="fas fa-edit" />
												</span>
												<span className="btn-inner--text">Delete</span>
											</Button>
										</td>
									</tr>
								})}
							</tbody>
						</Table>
						{/* <CardFooter className="py-4">
							<nav aria-label="...">
								<Pagination
									className="pagination justify-content-end mb-0"
									listClassName="justify-content-end mb-0"
								>
									<PaginationItem className={this.pager.pagerStart > 0 ? '' : 'disabled'}>
										<PaginationLink
											href="#pablo"
											onClick={e => {
												e.preventDefault();
												this.switchPage(this.pager.pagerStart);
											}}
											tabIndex="-1"
										>
											<i className="fas fa-angle-left" />
											<span className="sr-only">Previous</span>
										</PaginationLink>
									</PaginationItem>
									{this.pager.pageArray.map(page => {
										return < PaginationItem key={'pager_' + page} hidden={this.isHidden(page)} className={((this.pager.pagerStart + 1) === page) ? "active" : ''}>
											<PaginationLink
												href="#pablo"
												onClick={e => {
													e.preventDefault();
													this.switchPage(page);
												}}
											>
												{page}
											</PaginationLink>
										</PaginationItem>
									})}
									<PaginationItem className={this.pager.pagerEnd > this.pager.pagerStart + 1 ? '' : 'disabled'}>
										<PaginationLink
											href="#pablo"
											onClick={e => {
												e.preventDefault();
												this.switchPage(this.pager.pagerStart + 2);
											}}
										>
											<i className="fas fa-angle-right" />
											<span className="sr-only">Next</span>
										</PaginationLink>
									</PaginationItem>
								</Pagination>
							</nav>
						</CardFooter> */}
					</Card>
				</Container>
			</Fragment>
		);
	}
}



const mapStateToProps = state => {
	return {
		configListLoading: state.config.configDataLoading,
		configList: state.config.configData,
		usersCount: state.config.configCount,
		error: state.config.error,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		onGetConfigData: (data) => dispatch(actions.getConfigData(data)),
		onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
		removeConfig:(data,id)=>dispatch(actions.removeConfig(data,id)),
		clearList:()=>dispatch(actions.clearListConfig())
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);