import { Box, InputLabel, OutlinedInput, Select } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import StateDropdown from "../../../components/StateDropdown/StateDropdown";
import NoDataFound from "../../../components/reusableComp/NoDataFound";
import PaginationComp from "../../../components/reusableComp/PaginationComp";
import {
  convertDateToString,
  createPaginationArr,
  defaultStatusId,
} from "../../../helpers/utils";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import ExportButton from "../../../components/reusableComp/ExportButton";
import { MenuItem } from "@mui/material";

const AllRetailersListing = () => {
  const [activeDateFilter, setActiveDateFilter] = useState("today");
  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState([]);
  const val = defaultStatusId();
  const [activeState, setActiveState] = useState(val);
  const [selectRetailerType, setSelectRetailerType] = useState("");
  console.log(activeState, "activeState");
  const [filter, setFilter] = useState({
    state: { id: "all", value: "ALL", label: "All States" },
  });
  console.log(filter, "filter");
  const [paginationState, setPaginationState] = useState({
    start: 1,
    current: 1,
    end: 0,
    limit: 25,
    count: 0,
    arr: [],
  });
  let tableHeader = [
    "Retailer Name",
    "Email",
    "Mobile number",
    "ownership type",
    "Child Code",
    "Store Name",
    "Status",
    "Wallet",
    "Created at",
    "",
  ];
  const handleChange = (e) => {
    setTimeout(() => {
      setSelectRetailerType(e.target.value);
    }, 1000);
  };
  const handleSearchKeyDown = (e) => {
    e.key === "Enter" && handleClick();
  };
  const handleClick = () => {
    // write a logic to hit the api for search field
  };
  const handleOnChange = (selected) => {
    console.log(selected, "selected");
    // setFilter({
    //   ...filter,
    //   state: selected,
    // });
    setActiveState(selected);
  };

  const fetchListingData = async () => {
    const query = {
      page: paginationState.current - 1,
      limit: paginationState.limit,
      // status: "inactive",
      state_id: activeState,
      status: selectRetailerType,
    };
    try {
      const response = await AxiosReactClient.getRequest(
        `partners/retailers`,
        query
      );
      console.log(response?.data?.data, "...");
      setListingData(response?.data?.data?.data);
      setPaginationState({
        ...paginationState,
        end: Math.ceil(
          response?.data?.data?.totalCount / paginationState.limit
        ),
        total: response?.data?.data?.totalCount,
        arr: createPaginationArr(
          Math.ceil(response?.data?.data?.totalCount / paginationState.limit)
        ),
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchListingData();
  }, [paginationState.current, activeState, selectRetailerType]);
  let exportPayload = {
    status: selectRetailerType,
    state_id: activeState,
  };

  return (
    <>
      <Fragment>
        <Container fluid>
          <CardHeader className="border-0 px-0 bg-transparent ">
            <Row className="align-items-center py-4">
              <Col xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary h5 font-weight-400"
                      onClick={(e) => e.preventDefault()}
                    >
                      All Retailers
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
            <Col
              className="pl-0 d-flex "
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <StateDropdown
                onChange={(selectedOption) => handleOnChange(selectedOption.id)}
              />
              <div style={{ marginBottom: "1rem" }}>
                <Select
                  labelId="retailersList"
                  id="retailersList"
                  value={selectRetailerType}
                  onChange={handleChange}
                  displayEmpty
                  style={{
                    marginRight: "2rem",
                    border: "1px solid #d7d7d7",
                    borderRadius: "5px",
                    width: "200px",
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Retailer Status
                  </MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
                <ExportButton url="partners/retailers" {...{ exportPayload }} />
              </div>
            </Col>

            <Box></Box>
            <Card className="mb-6">
              <CardBody className="px-0 py-0">
                <div className=" table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        {tableHeader.map((cur, id) => (
                          <th key={id}>{cur}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="all_tabl_heigth">
                      {listingData?.length === 0 && <NoDataFound />}
                      {listingData?.length > 0 &&
                        listingData.map((cur, id) => (
                          <tr key={id}>
                            <td className="table-user">
                              {cur?.dealership_name}
                            </td>
                            <td className="table-user">{cur?.email}</td>
                            <td className="table-user">{cur?.mobile}</td>
                            <td className="table-user">
                              {cur?.ownership_type}
                            </td>
                            <td className="table-user">{cur?.store_code}</td>
                            <td className="table-user">{cur?.store_name}</td>
                            <td className="table-user">{cur?.status}</td>
                            <td className="table-user">
                              {cur?.wallet_required ? "Yes" : "No"}
                            </td>
                            <td className="table-user">
                              {" "}
                              {convertDateToString(
                                cur.created_at,
                                "DD-MM-YYYY"
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              {listingData?.length > 0 && (
                <PaginationComp
                  {...{ paginationState, setPaginationState, fetchListingData }}
                />
              )}
            </Card>
          </CardHeader>
        </Container>
      </Fragment>
    </>
  );
};

export default AllRetailersListing;
