import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Modal } from "reactstrap";
import * as actions from "../../../Store/actions";
import { connect } from "react-redux";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import Loader from "../../../components/CustomUi/Loader/Loader";
import Otp from "./otp";
import { IndiaFirstLocation } from "../../../locations/indiaFirstLocations";
import moment from "moment";
import { options, petAddOns } from "../../../defaultValues/lead";
import { mapping, renderPaymentByText, renderPACoverYesNoText, renderOwnDamageAddOns, renderOwnDamageDiscount, renderThirdPartyAddOnsCoverage, NOT_AVAILABLE } from "../../../helpers/motor";
import { options as travelOptions } from "../../../helpers/travel";
import { options as assuranceOptions } from "../../../helpers/assurance";
import { options as lendingOptions } from "../../../helpers/lending";

class DetailsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editDetails: false,
            userDetails: {},
            members: {},
            updatedUserDetails: {},
            membersToDelete: '',
            premiumChangeDialog: false,
            insureeTitleChanged: false,
            insureeDobChanged: false,
            memberDeleted: false,
            memberDobChanged: false,
            stateName: this.props.policyHolderDetailsData?.state,
            cityName: this.props.policyHolderDetailsData?.city,
            monthlyPremium: '',
            yearlyPremium: '',
            isBelowMinimum: '',
            valueChanged: false,
            cycleValueChanged: false,
            dogValueChanged: false,
            editCycleDetails: false,
            editDogDetails: false,
            customBrandVisible: false,
            cycleDetails: {},
            editVehicleDetails: false,
            dogDetails: { dog: {} }
        }
        this.onValueChange = this.onValueChange.bind(this);
    }

    formData = {};
    cycleProducts = [11, 19, 22, 31, 40];
    lifeInsuranceProducts = [51, 48, 49, 52, 53, 55, 58];
    dogProducts = [60];
    motorInsurancePoducts = [61, 64, 66];
    travelInsurancePoducts = [68, 69, 70, 71];
    assurancePoducts = [75, 76, 77, 83, 84, 85, 78, 86, 87, 89, 90, 94, 95, 96, 97, 98, 99];
    lendingProducts = [79, 80, 81, 82];

    componentDidMount() {
        this.getPolicyData()
    }

    componentDidUpdate() {
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.policyHolderDetailsData !== this.props.policyHolderDetailsData) {
            this.getInitialData();
            this.checkMembers()
            this.setState({
                stateName: this.props.policyHolderDetailsData?.state,
                cityName: this.props.policyHolderDetailsData?.city
            })
        }
    }

    getPolicyData = () => {
        this.props.onGetPolicyHolderDetails(this.props.order_id)
    }

    getCycleDetails = () => {
        return {
            'brand_id': this.state.cycleDetails?.brand_id ?? this.props.policyHolderDetailsData?.response_brand_id,
            'color': this.props.policyHolderDetailsData?.response_color,
            'custom_brand': this.state.cycleDetails?.custom_brand ?? this.props.policyHolderDetailsData?.response_custom_brand,
            'frame_no': this.props.policyHolderDetailsData?.response_frame_no,
            'invoice_number': this.props.policyHolderDetailsData?.response_invoice_number,
            'model': this.props.policyHolderDetailsData?.response_model
        }
    }

    getInitialData = () => {
        if (this.isCycleProduct()) {
            this.props.policyHolderDetailsData && this.props.onGetCycleBrandList(this.props.policyHolderDetailsData.product_id);
        }

        this.props.policyHolderDetailsData && this.props.onSetCustomBrandVisibility(this.state.cycleDetails?.brand_id ?? this.props.policyHolderDetailsData?.response_brand_id);
        this.setState({
            userDetails: {
                'name': this.props.policyHolderDetailsData?.name,
                'mobile': this.props.policyHolderDetailsData?.mobile,
                'email': this.props.policyHolderDetailsData?.email,
                'title': this.props.policyHolderDetailsData?.title,
                'dob': this.getDobFormattedDate(this.props.policyHolderDetailsData?.dob),
                'nominee_relation_id': this.props.policyHolderDetailsData?.nominee_relation_id,
                'nominee_name': this.props.policyHolderDetailsData?.nominee_name,
                'nominee_mobile': this.props.policyHolderDetailsData?.nominee_mobile,
                'pincode': this.props.policyHolderDetailsData?.pincode,
                'state': this.props.policyHolderDetailsData?.state,
                'city': this.props.policyHolderDetailsData?.city,
                'address_line_1': this.props.policyHolderDetailsData?.address_line_1,
                'address_line_2': this.props.policyHolderDetailsData?.address_line_2,
                'response_nominee_dob': this.props.policyHolderDetailsData?.response_nominee_dob,
                'response_landmark': this.props.policyHolderDetailsData?.response_landmark,
                'response_pan': this.props.policyHolderDetailsData?.response_pan,
                'response_marital_status': this.props.policyHolderDetailsData?.response_marital_status,
                'response_organization_name': this.props.policyHolderDetailsData?.response_organization_name,
                'response_annual_income': this.props.policyHolderDetailsData?.response_annual_income,
                'response_source_of_fund': this.props.policyHolderDetailsData?.response_source_of_fund,
                'response_feet': this.props.policyHolderDetailsData?.response_feet,
                'response_inches': this.props.policyHolderDetailsData?.response_inches,
                'response_weight': this.props.policyHolderDetailsData?.response_weight,
                'response_father_name': this.props.policyHolderDetailsData?.response_father_name,
            },
            members: this.checkMembers(),
            cycleDetails: this.getCycleDetails(),
            customBrandVisible: this.props.policyHolderDetailsData?.response_brand_id == 23
        })
    }

    checkMembers = () => {
        let members = {};
        if (this.props.policyHolderDetailsData?.response_members?.hasOwnProperty('spouse')) {
            members['spouse'] = {
                'dob': this.props.policyHolderDetailsData?.response_members?.spouse?.dob,
                'height_feet': this.props.policyHolderDetailsData?.response_members?.spouse?.height_feet,
                'height_inch': this.props.policyHolderDetailsData?.response_members?.spouse?.height_inch,
                'name': this.props.policyHolderDetailsData?.response_members?.spouse?.name,
                'title': this.props.policyHolderDetailsData?.response_members?.spouse?.title,
                'weight': this.props.policyHolderDetailsData?.response_members?.spouse?.weight
            }
            members = { ...members }
        }
        if (this.props.policyHolderDetailsData?.response_members?.hasOwnProperty('child1')) {
            members['child1'] = {
                'dob': this.props.policyHolderDetailsData?.response_members?.child1?.dob,
                'height_feet': this.props.policyHolderDetailsData?.response_members?.child1?.height_feet,
                'height_inch': this.props.policyHolderDetailsData?.response_members?.child1?.height_inch,
                'name': this.props.policyHolderDetailsData?.response_members?.child1?.name,
                'title': this.props.policyHolderDetailsData?.response_members?.child1?.title,
                'weight': this.props.policyHolderDetailsData?.response_members?.child1?.weight
            }
            members = { ...members }
        }
        if (this.props.policyHolderDetailsData?.response_members?.hasOwnProperty('child2')) {
            members['child2'] = {
                'dob': this.props.policyHolderDetailsData?.response_members?.child2?.dob,
                'height_feet': this.props.policyHolderDetailsData?.response_members?.child2?.height_feet,
                'height_inch': this.props.policyHolderDetailsData?.response_members?.child2?.height_inch,
                'name': this.props.policyHolderDetailsData?.response_members?.child2?.name,
                'title': this.props.policyHolderDetailsData?.response_members?.child2?.title,
                'weight': this.props.policyHolderDetailsData?.response_members?.child2?.weight
            }
            members = { ...members }
        }
        return members
    }

    capitalizeWords = (str) => {
        return str?.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    onClickEditDetails = () => {
        this.setState({
            editDetails: !this.state.editDetails,
        }, () => {
            if (!this.state.editDetails) {
                let userDetails = this.state.userDetails;
                let response_members = this.state.members;
                userDetails = { ...userDetails, response_members }
                if ((this.state.insureeDobChanged || this.state.insureeTitleChanged || this.state.memberDeleted || this.state.memberDobChanged) && !this.isMotorInsuranceProduct()) {
                    this.setState({
                        premiumChangeDialog: true,
                        editDetails: true
                    })
                    this.calculatePremium()
                } else {

                    this.updateDetails(this.props.order_id, userDetails)
                }
            }
        })
    }

    onClickEditCycleDetails = () => {
        this.setState({
            editCycleDetails: !this.state.editCycleDetails,
        }, () => {
            if (!this.state.editCycleDetails) {
                this.updateCycleDetails(this.props.order_id);
            }
        })
    }

    onClickCancelCycleEdit = () => {
        if (this.state.cycleValueChanged) {
            window.location.reload();
        }

        this.setState({
            editCycleDetails: false
        })
    }

    onClickCancelEdit = () => {
        window.location.reload();
        this.setState({
            editDetails: false
        })
    }

    onClickEditDogDetails = () => {
        this.setState({
            editDogDetails: !this.state.editDogDetails,
        }, () => {
            if (!this.state.editDogDetails) {
                this.updateDogDetails(this.props.order_id);
            }
        })
    }

    onClickCancelDogEdit = () => {
        if (this.state.dogValueChanged) {
            window.location.reload();
        }

        this.setState({
            editDogDetails: false
        })
    }

    onClickCancelEdit = () => {
        if (this.state.valueChanged) {
            window.location.reload();
            this.setState({
                editDetails: false,
                error: '',
            })
        } else {
            this.setState({
                editDetails: false,
                error: '',
            })
        }
    }

    onValueChange = (e, key, isMember, member) => {
        this.setState({
            valueChanged: true
        })
        if (isMember) {
            let memberDetails = this.state.members;
            this.setState({
                memberOldDob: this.state.members[member][key]
            })
            if (key === 'dob') {
                memberDetails[member][key] = this.getFormattedDate(e.target.value);
                this.setState({
                    memberDobChanged: true,
                    memberName: member,
                    memberKey: key,
                    memberNewDob: memberDetails[member][key],
                })
            } else {
                memberDetails[member][key] = e.target.value;
            }
            this.setState({
                members: memberDetails,
            });
        } else {
            if (key === 'title') {
                this.setState({
                    insureeTitleChanged: true
                })
            }
            if (key === 'dob') {
                this.setState({
                    insureeDobChanged: true
                })
            }
            if (key === 'pincode') {
                this.populateStateCity(e.target.value)
            }
            if (key === 'response_nominee_dob') {
                let userDetails = this.state.userDetails;
                userDetails[key] = this.getFormattedDate(e.target.value);
                this.setState({
                    userDetails: userDetails,
                });
            } else {
                let userDetails = this.state.userDetails;
                userDetails[key] = e.target.value;
                this.setState({
                    userDetails: userDetails,
                });
            }
        }
    }

    onValueChangeCycleDetails = (e, key) => {
        this.setState({
            cycleValueChanged: true
        })

        let cycleDetails = { ...this.state.cycleDetails };
        cycleDetails[key] = e.target.value;
        this.setState({
            cycleDetails: cycleDetails,
        }, () => {
            if (key === 'brand_id') {
                this.props.onSetCustomBrandVisibility(cycleDetails[key]);
            }
        });
    }

    onValueChangeDogDetails = (e, key) => {
        this.setState({
            dogValueChanged: true
        })

        let dogDetails = { ...this.state.dogDetails };
        dogDetails['dog'][key] = e.target.value;
        this.setState({
            dogDetails: dogDetails,
        });
    }

    updateDetails = (id, payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.putRequest('orders/' + id, payload)
            .then((res) => {
                if (this.state.memberDeleted) {
                    this.setState({
                        showLoader: false,
                        editDetails: false
                    });
                } else {
                    this.setState({
                        premiumChangeDialog: false,
                        showLoader: false,
                        editDetails: false
                    });
                    this.showMsg('Details updated successfully', "success")
                    this.getPolicyData();
                }
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }

    updateCycleDetails = (id) => {
        const { custom_brand, brand_id } = this.state.cycleDetails;
        const payload = {
            ...this.state.cycleDetails,
            create_certificate: true,
            custom_brand: brand_id == 23 ? custom_brand : ''
        };
        this.setState({
            showLoader: true
        });
        AxiosReactClient.putRequest('v2/orders/' + id, payload)
            .then((res) => {
                this.setState({
                    showLoader: false,
                    editCycleDetails: false
                }, () => {
                    this.props.onSetCustomBrandVisibility(this.props.policyHolderDetailsData?.response_brand_id == 23)
                });
                this.showMsg('Details updated successfully', "success")
                this.getPolicyData()
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }

    updateDogDetails = (id) => {
        if (!this.state.dogValueChanged) {
            this.setState({
                showLoader: false,
                editDogDetails: false
            });
            return;
        }
        const payload = {
            ...this.state.dogDetails
        };
        this.setState({
            showLoader: true
        });
        AxiosReactClient.putRequest('v2/orders/' + id, payload)
            .then((res) => {
                this.setState({
                    showLoader: false,
                    editDogDetails: false
                });
                this.showMsg('Details updated successfully', "success")
                this.getPolicyData()
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }

    onClickConfirm = (origin) => {
        if (origin === 'memberDeleted') {
            this.deleteMember()
            this.setState({
                premiumChangeDialog: false,
                memberDobChanged: false,
                insureeTitleChanged: false,
                insureeDobChanged: false,
                editDetails: false,
                memberDeleted: false
            })
        } else {
            let userDetails = this.state.userDetails;
            let response_members = this.state.members;
            userDetails = { ...userDetails, response_members }
            this.updateDetails(this.props.order_id, userDetails)
            this.setState({
                memberDobChanged: false,
                insureeTitleChanged: false,
                insureeDobChanged: false
            })
        }
    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };

    handleCheckboxChange = (e, member) => {
        this.setState({
            checked: e.target.checked
        }, () => {
            if (this.state.checked) {
                this.setState({
                    membersToDelete: member
                })

            } else {
                this.setState({
                    membersToDelete: ''
                })
                console.log('not checked')
            }

        })
    }

    deleteMember = () => {
        this.calculatePremium()
        let response_members = this.state.members;
        delete response_members[this.state.membersToDelete]

        let userDetails = this.state.userDetails;
        userDetails = { ...userDetails, response_members }
        this.setState({
            members: response_members,
            premiumChangeDialog: true,
            memberDeleted: true
        })
        this.updateDetails(this.props.order_id, userDetails)
    }
    populateStateCity = (pin) => {
        let cityState = new IndiaFirstLocation().get(pin);
        if (cityState) {
            let userDetails = this.state.userDetails;
            userDetails['state'] = cityState[1];
            userDetails['city'] = cityState[0];
            this.setState({
                userDetails: userDetails,
                stateName: cityState[1],
                cityName: cityState[0]
            })
        } else {
            this.setState({
                stateName: '',
                cityName: ''
            })
        }
    }
    getFormattedDate = (date) => {
        let fullDate = new Date(date);
        let newDate = moment(fullDate);
        return newDate.format('DD/MM/YYYY');
    }
    getDobFormattedDate = (date) => {
        // console.log(date)
        let curr_date = date?.substring(0, 2);
        let curr_month = date?.substring(3, 5); //Months are zero based
        let curr_year = date?.substring(6, 10);
        let formattedDate = curr_year + "-" + curr_month + "-" + curr_date;
        return formattedDate;
    }
    calculatePremium = () => {
        let response_members = this.state.members;
        delete response_members[this.state.membersToDelete]
        this.setState({
            members: response_members,
        });

        this.formData["gender"] = this.state.userDetails?.title.toLowerCase() === "mr" ? "male" : "female";
        this.formData["name"] = this.state.userDetails?.name;
        this.formData["mobile"] = this.state.userDetails?.mobile;
        this.formData["email"] = this.state.userDetails?.email;
        this.formData["dob"] = this.getFormattedDate(this.state.userDetails?.dob);
        this.formData["members[spouse][dob]"] = this.state.members['spouse'] ? this.state.members['spouse'].dob : '';
        this.formData["members[child1][dob]"] = this.state.members['child1'] ? this.state.members['child1'].dob : '';
        this.formData["members[child2][dob]"] = this.state.members['child2'] ? this.state.members['child2'].dob : '';
        this.formData["sum_assured_life"] = this.props.policyHolderDetailsData?.response_sum_assured_life;
        this.formData["sum_assured_health"] = this.props.policyHolderDetailsData?.response_sum_assured_health;
        this.formData["sum_assured_home"] = this.props.policyHolderDetailsData?.response_sum_assured_home;
        this.formData["sum_assured_health_topup"] = "0";
        this.formData["is_smoker"] = this.props.policyHolderDetailsData?.response_has_consumed_tobacco;
        this.setState({
            showLoader: true,
        });
        const data = new FormData();
        Object.keys(this.formData).forEach((key) =>
            data.append(key, this.formData[key])
        );
        AxiosReactClient.postRequest("premium/51", data)
            .then((response) => {
                this.setState({
                    showLoader: false,
                });
                this.setState({
                    monthlyPremium: response.data.data.premiums.monthly,
                    yearlyPremium: response.data.data.premiums.annual,
                    isBelowMinimum: response.data.data.premiums.is_below_minimum,
                });
            })
            .catch((err) => {
                this.setState({
                    showLoader: false,
                });
                console.log(err);
            });
    }

    defaultDate = (date) => {
        let curr_date = date.substring(0, 2);
        let curr_month = date.substring(3, 5); //Months are zero based
        let curr_year = date.substring(6, 10);
        let formattedDate = curr_year + "-" + curr_month + "-" + curr_date;
        let finalDate = new Date(formattedDate);
        finalDate.setDate(finalDate.getDate());
        return finalDate.toISOString().substr(0, 10);
    }
    towDecimalValues = (num) => {
        return num.toFixed(2);
    }
    isCycleProduct = () => {
        return this.props.policyHolderDetailsData && this.cycleProducts.indexOf(this.props.policyHolderDetailsData.product_id) > -1;
    }
    isLifeInsuranceProduct = () => {
        return this.props.policyHolderDetailsData && this.lifeInsuranceProducts.indexOf(this.props.policyHolderDetailsData.product_id) > -1;
    }
    isDogProduct = () => {
        return this.props.policyHolderDetailsData && this.dogProducts.indexOf(this.props.policyHolderDetailsData.product_id) > -1;
    }

    isMotorInsuranceProduct = () => {
        return this.props.policyHolderDetailsData && this.motorInsurancePoducts.indexOf(this.props.policyHolderDetailsData.product_id) > -1;
    }
    isTravelInsuranceProduct = () => {
        return this.props.policyHolderDetailsData && this.travelInsurancePoducts.indexOf(this.props.policyHolderDetailsData.product_id) > -1;
    }
    isAssuranceProduct = () => {
        return this.props.policyHolderDetailsData && this.assurancePoducts.indexOf(this.props.policyHolderDetailsData.product_id) > -1;
    }
    isLendingProduct = () => {
        return this.props.policyHolderDetailsData && this.lendingProducts.indexOf(this.props.policyHolderDetailsData.product_id) > -1;
    }
    render() {
        const { policyHolderDetailsData } = this.props;
        const child1Exists = this.props.policyHolderDetailsData && this.props.policyHolderDetailsData.response_members && 'child1' in this.props.policyHolderDetailsData.response_members && 'name' in this.props.policyHolderDetailsData.response_members['child1'];
        const child2Exists = this.props.policyHolderDetailsData && this.props.policyHolderDetailsData.response_members && 'child2' in this.props.policyHolderDetailsData.response_members && 'name' in this.props.policyHolderDetailsData.response_members['child2'];
        const spouseExists = this.props.policyHolderDetailsData && this.props.policyHolderDetailsData.response_members && 'spouse' in this.props.policyHolderDetailsData.response_members && 'name' in this.props.policyHolderDetailsData.response_members['spouse'];
        const is_monthly = this.props.policyHolderDetailsData?.is_monthly;
        const newPremium = is_monthly ? this.state.monthlyPremium : this.state.yearlyPremium
        return (
            <>
                <div className={"pt-4 d-flex justify-content-between flex-wrap mb-3"}>
                    <Col lg={"6"} md={"6"}>
                        <h4 className={"text-gray"}>
                            PERSONAL INFORMATION
                        </h4>
                    </Col>
                    <Col lg={"6"} md={"6"} className={"d-flex m-auto"}>
                        <Button color="primary"
                            outline
                            className="ml-auto px-4" type="button"
                            size="sm"
                            disabled={this.props.policyHolderDetailsData?.is_cancelled}
                            onClick={e => {
                                this.onClickEditDetails()
                            }}
                        >
                            {!this.state.editDetails ? "Edit" : "Save"}
                        </Button>
                        {this.state.editDetails &&
                            <Button color="primary"
                                outline
                                className="" type="button"
                                size="sm"
                                onClick={e => {
                                    this.onClickCancelEdit()
                                }}
                            >
                                X
                            </Button>
                        }
                    </Col>
                </div>
                <div className={"d-flex justify-content-start flex-wrap"}>
                    <Col lg={"4"} md={"4"}>
                        <h5 className={"font-weight-400 text-gray m-0 fnt-12"}>
                            Gender
                        </h5>
                        {!this.state.editDetails ?
                            <select
                                className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark ")}
                                disabled={!this.state.editDetails}
                                id="exampleFormControlSelect1"
                                size={"sm"}
                                value={policyHolderDetailsData?.title}
                                onChange={(e) => this.onValueChange(e, 'title')}
                            >
                                <option value="Mr">Mr</option>
                                <option value="Ms">Ms</option>
                                <option value="Mrs">Mrs</option>


                            </select>
                            :
                            <select
                                className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                disabled={!this.state.editDetails}
                                id="exampleFormControlSelect1"
                                size={"sm"}
                                defaultValue={policyHolderDetailsData?.title}
                                onChange={(e) => this.onValueChange(e, 'title')}
                            >
                                <option value="Mr">Mr</option>
                                <option value="Ms">Ms</option>


                            </select>
                        }
                    </Col>
                    <Col lg={"4"} md={"4"}>
                        <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                            Date of Birth
                        </h4>
                        <input type="date"
                            max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                            min={moment().subtract(56, "years").add(1, "days").format("YYYY-MM-DD")}
                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16 " : " bg-white border-1 text-dark my-2")}
                            placeholder="Dob"
                            defaultValue={policyHolderDetailsData?.dob && this.defaultDate(policyHolderDetailsData?.dob)}
                            onChange={(e) => this.onValueChange(e, 'dob')}
                            disabled={!this.state.editDetails} />

                    </Col>
                    <Col lg={"4"} md={"4"}>
                        <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                            Height & Weight
                        </h4>
                        <div className={"d-flex"}>
                            <div className={"d-flex"}>
                                <input type="text"
                                    className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16 w-10" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Feet"
                                    onChange={(e) => this.onValueChange(e, 'response_feet')}
                                    defaultValue={policyHolderDetailsData?.response_feet}
                                    disabled={!this.state.editDetails} />
                                <h5 className={!this.state.editDetails ? "mt-1 px-2 font-weight-400" : "m-auto px-2"}>
                                    {!this.state.editDetails ? " feet," : "ft."}
                                </h5>
                            </div>
                            <div className={"d-flex"}>
                                <input type="text"
                                    className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16 w-10" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Inches"
                                    onChange={(e) => this.onValueChange(e, 'response_inches')}
                                    defaultValue={policyHolderDetailsData?.response_inches}
                                    disabled={!this.state.editDetails} />
                                <h5 className={!this.state.editDetails ? "mt-1 px-2 font-weight-400" : "m-auto px-2"}>
                                    {!this.state.editDetails ? " inches," : "in"}
                                </h5>
                            </div>
                            <div className={"d-flex"}>
                                <input type="text"
                                    className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16 w-20" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Weight"
                                    onChange={(e) => this.onValueChange(e, 'response_weight')}
                                    defaultValue={policyHolderDetailsData?.response_weight}
                                    disabled={!this.state.editDetails} />
                                <h5 className={!this.state.editDetails ? "mt-1 px-2 font-weight-400" : "m-auto px-2"}>
                                    {!this.state.editDetails ? " kgs" : "kgs"}
                                </h5>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"4"} md={"4"}>
                        <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                            Marital Status
                        </h4>
                        {!this.state.editDetails ?
                            <select
                                className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark")}
                                disabled={!this.state.editDetails}
                                id="exampleFormControlSelect1"
                                size={"sm"}
                                value={policyHolderDetailsData?.response_marital_status}
                                onChange={(e) => this.onValueChange(e, 'response_marital_status')}
                            >
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                                <option value="divorced">Divorced</option>
                                <option value="widowed">Widowed</option>

                            </select>
                            :
                            <select
                                className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                disabled={!this.state.editDetails}
                                id="exampleFormControlSelect1"
                                size={"sm"}
                                defaultValue={policyHolderDetailsData?.response_marital_status}
                                onChange={(e) => this.onValueChange(e, 'response_marital_status')}
                            >
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                                <option value="divorced">Divorced</option>
                                <option value="widowed">Widowed</option>

                            </select>
                        }
                    </Col>
                    <Col lg={"4"} md={"4"}>
                        <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                            Address
                        </h4>
                        <input type="text"
                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark mt-2")}
                            placeholder="Pincode" defaultValue={policyHolderDetailsData?.pincode}
                            onChange={(e) => this.onValueChange(e, 'pincode')}
                            disabled={!this.state.editDetails} />
                        {this.state.editDetails ? <>
                            <input type="text"
                                className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " text-dark mt-2")}
                                placeholder="City" defaultValue={this.state.cityName}
                                onChange={(e) => this.onValueChange(e, 'city')}
                                disabled={true} />
                            <input type="text"
                                className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " text-dark mt-2")}
                                placeholder="State" defaultValue={this.state.stateName}
                                onChange={(e) => this.onValueChange(e, 'state')}
                                disabled={true} />
                        </>
                            : <>
                                <input type="text"
                                    className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " text-dark mt-2")}
                                    placeholder="City" defaultValue={this.props.policyHolderDetailsData?.city}
                                    onChange={(e) => this.onValueChange(e, 'city')}
                                    disabled={true} />
                                <input type="text"
                                    className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " text-dark mt-2")}
                                    placeholder="State" defaultValue={this.props.policyHolderDetailsData?.state}
                                    onChange={(e) => this.onValueChange(e, 'state')}
                                    disabled={true} />
                            </>
                        }
                        <input type="text"
                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark mt-2")}
                            placeholder="Address Line 1" defaultValue={policyHolderDetailsData?.address_line_1}
                            onChange={(e) => this.onValueChange(e, 'address_line_1')}
                            disabled={!this.state.editDetails} />
                        {policyHolderDetailsData?.address_line_2 ?
                            <input type="text"
                                className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark mt-2")}
                                placeholder="Address Line 2" defaultValue={policyHolderDetailsData?.address_line_2}
                                onChange={(e) => this.onValueChange(e, 'address_line_2')}
                                disabled={!this.state.editDetails} />
                            :
                            (this.state.editDetails &&
                                <input type="text"
                                    className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark mt-2")}
                                    placeholder="Address Line 2"
                                    defaultValue={policyHolderDetailsData?.address_line_2}
                                    onChange={(e) => this.onValueChange(e, 'address_line_2')}
                                    disabled={!this.state.editDetails} />)

                        }
                        <input type="text"
                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark mt-2")}
                            placeholder="Landmark" defaultValue={policyHolderDetailsData?.response_landmark}
                            onChange={(e) => this.onValueChange(e, 'response_landmark')}
                            disabled={!this.state.editDetails} />

                    </Col>
                </div>
                <hr className={"p-0 m-0 mt-4"} />
                {(spouseExists || child1Exists || child2Exists) && <>
                    <div className={"pt-4 " + (this.state.editDetails ? "d-flex justify-content-between" : "")}>
                        <Col lg={"9"} md={"9"}>
                            <div className={"d-flex mb-3"}>
                                <div className={"mr-3 ml-0 "}>
                                    <h4 className={"text-gray"}>
                                        FAMILY MEMBERS INSURED
                                    </h4>
                                </div>
                                {this.state.editDetails &&
                                    <div className={"ml-3 bg-light p-2 rounded-10"}>
                                        <h5 className={"m-auto"}>
                                            Do not change the complete name
                                        </h5>
                                    </div>
                                }
                            </div>
                        </Col>
                        {this.state.editDetails &&
                            <Col lg={"3"} md={"3"} className={"d-flex m-auto"}>
                                <Button color="primary"
                                    outline
                                    className="ml-auto px-4" type="button"
                                    size="sm"
                                    onClick={e => {
                                        this.setState({
                                            premiumChangeDialog: true
                                        })
                                        this.calculatePremium()
                                        // this.deleteMember()
                                    }}
                                >
                                    Remove
                                </Button>
                            </Col>
                        }
                    </div>
                    {/*<div className="checkbox">*/}
                    {/*    <input type="checkbox" id="checkbox" name="" eue=""/>*/}
                    {/*    <label htmlFor="checkbox"></label>*/}
                    {/*</div>*/}
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        {(this.props.policyHolderDetailsData && this.props.policyHolderDetailsData.response_members) && Object.values(this.props.policyHolderDetailsData.response_members).map((data, index) => {
                            let member = Object.keys((this.props.policyHolderDetailsData && this.props.policyHolderDetailsData.response_members) && this.props.policyHolderDetailsData.response_members)[index];
                            return ((spouseExists && member === 'spouse') || (child1Exists && member === 'child1') || (child2Exists && member === 'child2')) &&
                                <div key={index + 'adsgh'}>
                                    <div className={"d-flex py-2 w-100"}>
                                        {this.state.editDetails &&
                                            <div className="checkbox w-40" style={{ marginTop: "30px" }}>
                                                <input type="checkbox" id={"checkbox" + index + 1} name="" value=""
                                                    // checked={this.state.checked + index}
                                                    onClick={(e) => {
                                                        this.handleCheckboxChange(e, member)
                                                    }}

                                                />
                                                <label htmlFor={"checkbox" + index + 1}></label>
                                            </div>
                                        }
                                        <div className={"d-flex justify-content-start flex-wrap w-100"}>
                                            <Col lg={"4"} md={"4"}>
                                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                                    {this.capitalizeWords(member)}
                                                </h4>
                                                <div className={"d-flex"}>
                                                    <div className={"mr-1 w-100"}>
                                                        <input type="text"
                                                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                                            placeholder="Name"
                                                            onChange={(e) => this.onValueChange(e, 'name', true, member)}
                                                            defaultValue={data.name}
                                                            disabled={!this.state.editDetails} />
                                                    </div>
                                                    <div className={"ml-1 w-100"}>
                                                        <select
                                                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                                            disabled={!this.state.editDetails}
                                                            id="exampleFormControlSelect1"
                                                            // size={"lg"}
                                                            defaultValue={data.title}
                                                            onChange={(e) => this.onValueChange(e, 'title', true, member)}
                                                        >
                                                            <option value="mr">Male</option>
                                                            <option value="ms">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={"4"} md={"4"}>
                                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                                    Date of Birth
                                                </h4>
                                                <input type="date"
                                                    max={member === 'spouse' ? moment().subtract(18, "years").format("YYYY-MM-DD") : moment().subtract(0, "years").format("YYYY-MM-DD")}
                                                    min={member === 'spouse' ? moment().subtract(56, "years").add(1, "days").format("YYYY-MM-DD") : moment().subtract(26, "years").add(1, "days").format("YYYY-MM-DD")}
                                                    className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                                    placeholder="Dob"
                                                    defaultValue={data.dob && this.defaultDate(data.dob)}
                                                    onChange={(e) => this.onValueChange(e, 'dob', true, member)}
                                                    disabled={!this.state.editDetails} />
                                            </Col>
                                            <Col lg={"4"} md={"4"}>
                                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                                    Height & Weight
                                                </h4>
                                                <div className={"d-flex"}>
                                                    <div className={"d-flex"}>
                                                        <input type="text"
                                                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16 w-10" : " bg-white border-1 text-dark my-2")}
                                                            placeholder="Feet"
                                                            onChange={(e) => this.onValueChange(e, 'height_feet', true, member)}
                                                            defaultValue={data.height_feet}
                                                            disabled={!this.state.editDetails} />

                                                        <h5 className={!this.state.editDetails ? "mt-1 px-2 font-weight-400" : "m-auto px-2"}>
                                                            {!this.state.editDetails ? " feet," : "ft."}
                                                        </h5>

                                                    </div>
                                                    <div className={"d-flex"}>
                                                        <input type="text"
                                                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16 w-10" : " bg-white border-1 text-dark my-2")}
                                                            placeholder="Inches"
                                                            onChange={(e) => this.onValueChange(e, 'height_inch', true, member)}
                                                            defaultValue={data.height_inch}
                                                            disabled={!this.state.editDetails} />
                                                        <h5 className={!this.state.editDetails ? "mt-1 px-2 font-weight-400" : "m-auto px-2"}>
                                                            {!this.state.editDetails ? " inches," : "in"}
                                                        </h5>

                                                    </div>
                                                    <div className={"d-flex"}>
                                                        <input type="text"
                                                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16 w-20" : " bg-white border-1 text-dark my-2")}
                                                            placeholder="Weight"
                                                            onChange={(e) => this.onValueChange(e, 'weight', true, member)}
                                                            defaultValue={data.weight}
                                                            disabled={!this.state.editDetails} />

                                                        <h5 className={!this.state.editDetails ? "mt-1 px-2 font-weight-400" : "m-auto px-2"}>
                                                            {!this.state.editDetails ? " kgs" : "Kgs"}
                                                        </h5>

                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                </div>
                        })}
                    </div>
                    <hr className={"p-0 m-0 mt-5"} />
                </>
                }
                {policyHolderDetailsData?.nominee_name && <>
                    <div className={"pt-4 mb-3"}>
                        <h4 className={"text-gray"}>
                            NOMINEE INFORMATION
                        </h4>
                    </div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Nominee
                            </h4>
                            <div className={"d-flex"}>
                                <div className={"mr-1 w-100"}>
                                    <input type="text"
                                        className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                        placeholder="Nominee name"
                                        onChange={(e) => this.onValueChange(e, 'nominee_name')}
                                        value={policyHolderDetailsData?.nominee_name}
                                        disabled={!this.state.editDetails} />
                                </div>
                                <div className={"ml-1 w-100"}>
                                    {this.state.editDetails ?
                                        <select
                                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                            disabled={!this.state.editDetails}
                                            id="exampleFormControlSelect1"
                                            // size={"lg"}
                                            value={policyHolderDetailsData?.nominee_relation_id}
                                            onChange={(e) => this.onValueChange(e, 'nominee_relation_id')}
                                        >
                                            <option value="spouse">Spouse</option>
                                            <option value="father">Father</option>
                                            <option value="mother">Mother</option>
                                            <option value="brother">Brother</option>
                                            <option value="sister">Sister</option>
                                            <option value="son">Son</option>
                                            <option value="daughter">Daughter</option>
                                            <option value="sibling">Sibling</option>
                                            <option value="legal gaurdian">Legal Gaurdian</option>
                                        </select>
                                        :
                                        <select
                                            className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                            disabled={!this.state.editDetails}
                                            id="exampleFormControlSelect1"
                                            // size={"lg"}
                                            value={policyHolderDetailsData?.nominee_relation_id}
                                            onChange={(e) => this.onValueChange(e, 'nominee_relation_id')}
                                        >
                                            <option value="spouse">Spouse</option>
                                            <option value="father">Father</option>
                                            <option value="mother">Mother</option>
                                            <option value="brother">Brother</option>
                                            <option value="sister">Sister</option>
                                            <option value="son">Son</option>
                                            <option value="daughter">Daughter</option>
                                            <option value="sibling">Sibling</option>
                                            <option value="legal gaurdian">Legal Gaurdian</option>
                                            <option value="legal heir">Legal Heir</option>
                                        </select>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Date of Birth
                            </h4>
                            {(this.isMotorInsuranceProduct() || this.isAssuranceProduct()) ?
                                <input type="date"
                                    className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Dob"
                                    value={this.isMotorInsuranceProduct() ? policyHolderDetailsData?.manufacturer_detail?.nominee_dob : policyHolderDetailsData?.nominee_dob}
                                    onChange={(e) => this.onValueChange(e, 'nominee_dob')}
                                    disabled={true} /> :
                                <input type="date"
                                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                    min={moment().subtract(100, "years").add(1, "days").format("YYYY-MM-DD")}
                                    className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Dob"
                                    defaultValue={policyHolderDetailsData?.response_nominee_dob && this.defaultDate(policyHolderDetailsData?.response_nominee_dob)}
                                    onChange={(e) => this.onValueChange(e, 'response_nominee_dob')}
                                    disabled={!this.state.editDetails} />}
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Phone Number
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Mobile" defaultValue={policyHolderDetailsData?.nominee_mobile}
                                onChange={(e) => this.onValueChange(e, 'nominee_mobile')}
                                disabled={!this.state.editDetails} />
                        </Col>
                    </div>
                </>}
                {this.isLifeInsuranceProduct() && <>
                    <hr className={"p-0 m-0 mt-4"} />
                    <div className={"pt-4 mb-3"}>
                        <h4 className={"text-gray"}>
                            INCOME INFORMATION
                        </h4>
                    </div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Annual Income
                            </h4>
                            <h4 className={"font-weight-400 text-dark m-0 mt-1 fnt-16"}>
                                {policyHolderDetailsData?.response_annual_income}
                            </h4>
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                PAN
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="PAN" defaultValue={policyHolderDetailsData?.response_pan}
                                onChange={(e) => this.onValueChange(e, 'response_pan')}
                                disabled={!this.state.editDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Source of fund
                            </h4>
                            <h4 className={"font-weight-400 text-dark m-0 mt-1 fnt-16"}>
                                {policyHolderDetailsData?.response_source_of_fund ? this.capitalizeWords(policyHolderDetailsData?.response_source_of_fund + '') : ""}
                            </h4>
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Organization Name
                            </h4>
                            <h4 className={"font-weight-400 text-dark m-0 mt-1 fnt-16"}>
                                {policyHolderDetailsData?.response_organization_name}
                            </h4>
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                CIBIL Score
                            </h4>
                            <h4 className={"font-weight-400 text-dark m-0 mt-1"}>
                                {policyHolderDetailsData?.cibil ? policyHolderDetailsData?.cibil : "N/A"}
                            </h4>
                        </Col>
                    </div>
                </>
                }

                {this.isCycleProduct() && <>
                    <hr className={"p-0 m-0 mt-4"} />


                    <div className={"d-flex justify-content-between align-content-center pt-4 mb-3"}>
                        <div>
                            <h4 className={"text-gray"}>
                                CYCLE INFORMATION
                            </h4>
                        </div>
                        <div className={"d-flex justify-content-start"}>
                            <Button color="primary"
                                outline
                                className="ml-auto px-4" type="button"
                                size="sm"
                                disabled={this.props.policyHolderDetailsData?.is_cancelled}
                                onClick={e => {
                                    this.onClickEditCycleDetails()
                                }}
                            >
                                {!this.state.editCycleDetails ? "Edit" : "Save"}
                            </Button>
                            {this.state.editCycleDetails &&
                                <Button color="primary"
                                    outline
                                    className="" type="button"
                                    size="sm"
                                    onClick={e => {
                                        this.onClickCancelCycleEdit()
                                    }}
                                >
                                    X
                                </Button>
                            }
                        </div>
                    </div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Model
                            </h4>
                            <input type="text"
                                name={"model"}
                                className={"form-control " + (!this.state.editCycleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Model"
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'model')}
                                defaultValue={policyHolderDetailsData?.response_model}
                                disabled={!this.state.editCycleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Invoice Number
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editCycleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Invoice Number"
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'invoice_number')}
                                defaultValue={policyHolderDetailsData?.response_invoice_number}
                                disabled={!this.state.editCycleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Color
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editCycleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Color" defaultValue={policyHolderDetailsData?.response_color}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'color')}
                                disabled={!this.state.editCycleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Frame Number
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editCycleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Frame Number" defaultValue={policyHolderDetailsData?.response_frame_no}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'frame_no')}
                                disabled={!this.state.editCycleDetails} />
                        </Col>

                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Brand
                            </h4>
                            {this.state.editCycleDetails ?
                                <select
                                    className={"form-control " + (!this.state.editCycleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    disabled={!this.state.editCycleDetails}
                                    id="exampleFormControlSelect1"
                                    // size={"lg"}
                                    defaultValue={policyHolderDetailsData?.response_brand_id}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'brand_id')}
                                >
                                    {this.props.cycleBrandsListData.map(item => {
                                        return <option value={item.id} key={item.id}>{item.name}</option>
                                    })}
                                </select>
                                :
                                this.props.cycleBrandsListData && <select
                                    className={"form-control " + (!this.state.editCycleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    disabled={!this.state.editCycleDetails}
                                    id="exampleFormControlSelect1"
                                    // size={"lg"}
                                    value={policyHolderDetailsData?.response_brand_id}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'brand_id')}
                                >
                                    {this.props.cycleBrandsListData.map(item => {
                                        return <option value={item.id} key={item.id}>{item.name}</option>
                                    })}
                                </select>
                            }
                        </Col>

                        {this.props.showCustomBrands &&
                            <Col lg={"4"} md={"4"}>
                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                    Other Brand Name
                                </h4>
                                <input type="text"
                                    className={"form-control " + (!this.state.editCycleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Other Brand Name"
                                    defaultValue={policyHolderDetailsData?.response_custom_brand}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'custom_brand')}
                                    disabled={!this.state.editCycleDetails} />
                            </Col>}
                    </div>
                </>}


                {this.isDogProduct() && <>
                    <div className={"d-flex justify-content-between align-content-center pt-4 mb-3"}>
                        <div>
                            <h4 className={"text-gray"}>
                                DOG DETAILS
                            </h4>
                        </div>
                        <div className={"d-flex justify-content-start"}>
                            <Button color="primary"
                                outline
                                className="ml-auto px-4" type="button"
                                size="sm"
                                disabled={this.props.policyHolderDetailsData?.is_cancelled}
                                onClick={e => {
                                    this.onClickEditDogDetails()
                                }}
                            >
                                {!this.state.editDogDetails ? "Edit" : "Save"}
                            </Button>
                            {this.state.editDogDetails &&
                                <Button color="primary"
                                    outline
                                    className="" type="button"
                                    size="sm"
                                    onClick={e => {
                                        this.onClickCancelDogEdit()
                                    }}
                                >
                                    X
                                </Button>
                            }
                        </div>
                    </div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Is Dog Vaccinated?
                            </h4>
                            <span className={"text-dark p-0 mt--2 fnt-16"}>{policyHolderDetailsData?.response_dog.is_vaccinated ? 'Yes' : 'No'}</span>
                        </Col>

                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Gender
                            </h4>
                            <span
                                className={"text-dark p-0 mt--2 fnt-16"}>{policyHolderDetailsData?.response_dog.gender}</span>
                        </Col>

                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Age
                            </h4>
                            <span
                                className={"text-dark p-0 mt--2 fnt-16"}>{policyHolderDetailsData?.response_dog.age} Months</span>
                        </Col>

                        <Col lg={"4"} md={"4"} className={"mt-3"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Breed
                            </h4>
                            <span
                                className={"text-dark p-0 mt--2 fnt-16"}>{policyHolderDetailsData?.response_dog.breed}</span>
                        </Col>
                    </div>

                    <div className={"d-flex justify-content-start flex-wrap mt-3"}>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Name
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editDogDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Dog Name" defaultValue={policyHolderDetailsData?.response_dog.name}
                                onChange={(e) => this.onValueChangeDogDetails(e, 'name')}
                                disabled={!this.state.editDogDetails} />
                        </Col>

                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Defect and Ailments
                            </h4>
                            {this.state.editDogDetails ? <select
                                className={"form-control bg-white border-1 text-dark my-2"}
                                disabled={!this.state.editDogDetails}
                                id="dogDefectsAilments"
                                size={"sm"}
                                defaultValue={policyHolderDetailsData?.response_dog.defects_ailments}
                                onChange={(e) => this.onValueChangeDogDetails(e, 'defects_ailments')}
                            >
                                {options.defectAilments.map((item, index) => (
                                    <option value={item.value} key={index}>{item.title}</option>
                                ))}
                            </select> : <span
                                className={"text-dark p-0 mt--2 fnt-16"}>{policyHolderDetailsData?.response_dog.defects_ailments}</span>}
                        </Col>

                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Identification Mark
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editDogDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Frame Number"
                                defaultValue={policyHolderDetailsData?.response_dog.identification_mark}
                                onChange={(e) => this.onValueChangeDogDetails(e, 'identification_mark')}
                                disabled={!this.state.editDogDetails} />
                        </Col>

                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Spayed/Castrated
                            </h4>
                            {this.state.editDogDetails ? <select
                                className={"form-control bg-white border-1 text-dark my-2"}
                                disabled={!this.state.editDogDetails}
                                id="dogSpayedCastrated"
                                size={"sm"}
                                defaultValue={policyHolderDetailsData?.response_dog.spayed_castrated}
                                onChange={(e) => this.onValueChangeDogDetails(e, 'spayed_castrated')}
                            >
                                {options.spayedCastrated.map((item, index) => (
                                    <option value={item.value} key={index}>{item.title}</option>
                                ))}
                            </select> : <span
                                className={"text-dark p-0 mt--2 fnt-16"}>{policyHolderDetailsData?.response_dog.spayed_castrated}</span>}
                        </Col>
                    </div>
                    <hr className={"p-0 m-0 mt-5"} />
                </>}

                {this.isDogProduct() && policyHolderDetailsData?.response_dog?.selected_add_ons && <>
                    <div className={"d-flex justify-content-between align-content-center pt-4 mb-3"}>
                        <div>
                            <h4 className={"text-gray"}>
                                Add-ons
                            </h4>
                        </div>
                    </div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        <Col lg={"4"} md={"4"} className={"mt-3"}>
                            {policyHolderDetailsData?.response_dog?.selected_add_ons.map((addOnId) => { return <h4 className={"text-black"}>{petAddOns[addOnId]}</h4> })}
                        </Col>
                    </div>
                </>}

                {this.isMotorInsuranceProduct() && <>
                    <hr className={"p-0 m-0 mt-4"} />
                    <div className={"d-flex justify-content-between align-content-center pt-4 mb-3"}>
                        <div>
                            <h4 className={"text-gray"}>
                                VEHICLE INFORMATION
                            </h4>
                        </div>
                        {1 === 2 && <div className={"d-flex justify-content-start"}>
                            <Button color="primary"
                                outline
                                className="ml-auto px-4" type="button"
                                size="sm"
                                disabled={this.props.policyHolderDetailsData?.is_cancelled}
                                onClick={e => {
                                    this.onClickEditCycleDetails()
                                }}
                            >
                                {!this.state.editCycleDetails ? "Edit" : "Save"}
                            </Button>
                            {this.state.editCycleDetails &&
                                <Button color="primary"
                                    outline
                                    className="" type="button"
                                    size="sm"
                                    onClick={e => {
                                        this.onClickCancelCycleEdit()
                                    }}
                                >
                                    X
                                </Button>
                            }
                        </div>}
                    </div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Manufacturer
                            </h4>
                            <input type="text"
                                name={"model"}
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Model"
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_manufacturer_title')}
                                value={policyHolderDetailsData?.manufacturer_detail.vehicle_manufacturer_title}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Model
                            </h4>
                            <input type="text"
                                name={"model"}
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Model"
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_model_title')}
                                value={policyHolderDetailsData?.manufacturer_detail.vehicle_model_title}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Variant
                            </h4>
                            <input type="text"
                                name={"model"}
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Model"
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_variant_title')}
                                value={policyHolderDetailsData?.manufacturer_detail.vehicle_variant_title}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Registration Number
                            </h4>
                            <input type="text"
                                name={"model"}
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Model"
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_registration_number')}
                                value={policyHolderDetailsData?.manufacturer_detail.vehicle_registration_number}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Manufacturing Year
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Invoice Number"
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_manufacturing_year')}
                                value={policyHolderDetailsData?.manufacturer_detail.vehicle_manufacturing_year}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Insured Declared Value
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Color" value={`₹${policyHolderDetailsData?.manufacturer_detail.idv.toLocaleString('en')}`}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'idv')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Engine Number
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Frame Number" value={policyHolderDetailsData?.manufacturer_detail.vehicle_engine_number}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_engine_number')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Chassis Number
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Frame Number" value={policyHolderDetailsData?.manufacturer_detail.vehicle_chassis_number}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_chassis_number')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Is Personal Accident Coverage?
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Frame Number" value={renderPACoverYesNoText(policyHolderDetailsData)}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'pa_cover_required')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Is Vehicle on loan?
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Frame Number" value={policyHolderDetailsData?.manufacturer_detail.is_vehicle_on_loan ? 'Yes' : 'No'}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'is_vehicle_on_loan')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        {policyHolderDetailsData?.manufacturer_detail.is_vehicle_on_loan &&
                            <Col lg={"4"} md={"4"}>
                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                    Bank Financer Name
                                </h4>
                                <input type="text"
                                    className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Frame Number" value={policyHolderDetailsData?.manufacturer_detail.bank_financer_name ?? 'N/A'}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'bank_financer_name')}
                                    disabled={!this.state.editVehicleDetails} />
                            </Col>}
                        {!policyHolderDetailsData?.manufacturer_detail.is_new_insurance && <>
                            <Col lg={"4"} md={"4"}>
                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                    Previous Insurer Name
                                </h4>
                                <input type="text"
                                    className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Frame Number" value={policyHolderDetailsData?.manufacturer_detail.previous_insurer_name ?? 'N/A'}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'previous_insurer_name')}
                                    disabled={!this.state.editVehicleDetails} />
                            </Col>
                            <Col lg={"4"} md={"4"}>
                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                    Previous Policy Number
                                </h4>
                                <input type="text"
                                    className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Frame Number" value={policyHolderDetailsData?.manufacturer_detail.previous_policy_number ?? 'N/A'}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'previous_policy_number')}
                                    disabled={!this.state.editVehicleDetails} />
                            </Col>
                            {policyHolderDetailsData?.manufacturer_detail.vehicle_registration_date &&
                                <Col lg={"4"} md={"4"}>
                                    <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                        Vehicle Registration Date
                                    </h4>
                                    <input type="text"
                                        className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                        placeholder="Frame Number" value={moment(policyHolderDetailsData?.manufacturer_detail.vehicle_registration_date).format('DD-MM-YYYY')}
                                        onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_registration_date')}
                                        disabled={!this.state.editVehicleDetails} />
                                </Col>}
                            <Col lg={"4"} md={"4"}>
                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                    No Claim Bonus (%)
                                </h4>
                                <input type="text"
                                    className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Frame Number" value={policyHolderDetailsData?.manufacturer_detail.is_claimed === false && policyHolderDetailsData?.manufacturer_detail.ncb ? (policyHolderDetailsData?.manufacturer_detail.ncb > 0 ? policyHolderDetailsData?.manufacturer_detail.ncb : 'Not Applicable') : 'Already Claimed'}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'ncb')}
                                    disabled={!this.state.editVehicleDetails} />
                            </Col>

                            {policyHolderDetailsData?.manufacturer_detail.previous_own_damage_start_date &&
                                <Col lg={"4"} md={"4"}>
                                    <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                        Own Damage Previous Policy Start Date
                                    </h4>
                                    <input type="text"
                                        className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                        placeholder="Frame Number" value={moment(policyHolderDetailsData?.manufacturer_detail.previous_own_damage_start_date).format('DD-MM-YYYY')}
                                        onChange={(e) => this.onValueChangeCycleDetails(e, 'previous_own_damage_start_date')}
                                        disabled={!this.state.editVehicleDetails} />
                                </Col>}
                            {policyHolderDetailsData?.manufacturer_detail.previous_own_damage_end_date &&
                                <Col lg={"4"} md={"4"}>
                                    <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                        Own Damage Previous Policy End Date
                                    </h4>
                                    <input type="text"
                                        className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                        placeholder="Frame Number" value={moment(policyHolderDetailsData?.manufacturer_detail.previous_own_damage_end_date).format('DD-MM-YYYY')}
                                        onChange={(e) => this.onValueChangeCycleDetails(e, 'previous_own_damage_end_date')}
                                        disabled={!this.state.editVehicleDetails} />
                                </Col>}
                            {policyHolderDetailsData?.manufacturer_detail.previous_third_party_start_date &&
                                <Col lg={"4"} md={"4"}>
                                    <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                        Third Party Previous Policy Start Date
                                    </h4>
                                    <input type="text"
                                        className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                        placeholder="Frame Number" value={moment(policyHolderDetailsData?.manufacturer_detail.previous_third_party_start_date).format('DD-MM-YYYY')}
                                        onChange={(e) => this.onValueChangeCycleDetails(e, 'previous_third_party_start_date')}
                                        disabled={!this.state.editVehicleDetails} />
                                </Col>}
                            {policyHolderDetailsData?.manufacturer_detail.previous_third_party_end_date &&
                                <Col lg={"4"} md={"4"}>
                                    <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                        Third Party Previous Policy End Date
                                    </h4>
                                    <input type="text"
                                        className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                        placeholder="Frame Number" value={moment(policyHolderDetailsData?.manufacturer_detail.previous_third_party_end_date).format('DD-MM-YYYY')}
                                        onChange={(e) => this.onValueChangeCycleDetails(e, 'previous_third_party_end_date')}
                                        disabled={!this.state.editVehicleDetails} />
                                </Col>}
                        </>}
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                RTO Code
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Frame Number" value={policyHolderDetailsData?.manufacturer_detail.rta_code}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'rta_code')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                RTO City
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Frame Number" value={policyHolderDetailsData?.manufacturer_detail.rta_city}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'rta_city')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        {(policyHolderDetailsData?.manufacturer_detail?.selected_addons && policyHolderDetailsData?.manufacturer_detail?.selected_addons.length > 0) &&
                            <Col lg={"4"} md={"4"}>
                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                    ADD-ON
                                </h4>
                                <input type="text"
                                    className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Frame Number" value={mapping.addOns[policyHolderDetailsData?.manufacturer_detail.selected_addons] ?? 'N/A'}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'selected_addons')}
                                    disabled={!this.state.editVehicleDetails} />
                            </Col>}
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Payment By
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Frame Number" value={renderPaymentByText(policyHolderDetailsData)}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'payment_by')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        {policyHolderDetailsData?.policy_creation_ts &&
                            <Col lg={"4"} md={"4"}>
                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                    Policy Issued On Date
                                </h4>
                                <input type="text"
                                    className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Frame Number" value={moment(policyHolderDetailsData?.policy_creation_ts).format('DD-MM-YYYY')}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'policy_creation_ts')}
                                    disabled={!this.state.editVehicleDetails} />
                            </Col>}
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Vehicle Owner Type
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Vehicle Owner Type" value={policyHolderDetailsData?.manufacturer_detail.vehicle_owner_type.toUpperCase()}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_owner_type')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                GST Number
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="GST Number" value={policyHolderDetailsData?.manufacturer_detail?.gst_number ? policyHolderDetailsData?.manufacturer_detail?.gst_number : NOT_AVAILABLE}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'gst_number')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Nominee Date of Birth
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="GST Number" value={policyHolderDetailsData?.manufacturer_detail?.nominee_dob ? moment(policyHolderDetailsData?.manufacturer_detail?.nominee_dob).format('DD-MM-YYYY') : NOT_AVAILABLE}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'nominee_dob')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        {(policyHolderDetailsData?.manufacturer_detail.is_new_insurance && policyHolderDetailsData?.manufacturer_detail.vehicle_registration_date) &&
                            <Col lg={"4"} md={"4"}>
                                <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                    Vehicle Manufacture/Registration Date
                                </h4>
                                <input type="text"
                                    className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                    placeholder="Frame Number" value={moment(policyHolderDetailsData?.manufacturer_detail.vehicle_registration_date).format('DD-MM-YYYY')}
                                    onChange={(e) => this.onValueChangeCycleDetails(e, 'vehicle_registration_date')}
                                    disabled={!this.state.editVehicleDetails} />
                            </Col>}
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Own Damage Add-Ons
                            </h4>
                            <span className={"fnt-16"}>{renderOwnDamageAddOns(policyHolderDetailsData)}</span>
                            {/* <input type="text"
                                   className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                   placeholder="GST Number" value={renderOwnDamageAddOns(policyHolderDetailsData)}
                                   onChange={(e) => this.onValueChangeCycleDetails(e, 'od_addons')}
                                   disabled={!this.state.editVehicleDetails} /> */}
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Own Damage Discount
                            </h4>
                            {/* <span className={"fnt-16"}>{renderOwnDamageAddOns(policyHolderDetailsData)}</span> */}
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="GST Number" value={renderOwnDamageDiscount(policyHolderDetailsData)}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'od_discounting')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Third Party Liability Coverage
                            </h4>
                            <span className={"fnt-16"}>{renderThirdPartyAddOnsCoverage(policyHolderDetailsData)}</span>
                            {/* <input type="text"
                                   className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                   placeholder="GST Number" value={renderOwnDamageAddOns(policyHolderDetailsData)}
                                   onChange={(e) => this.onValueChangeCycleDetails(e, 'od_addons')}
                                   disabled={!this.state.editVehicleDetails} /> */}
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Marital Status
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="Marital Status" value={policyHolderDetailsData?.manufacturer_detail?.marital_status ? mapping.maritalStatus[policyHolderDetailsData?.manufacturer_detail?.marital_status] : NOT_AVAILABLE}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'marital_status')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                        <Col lg={"4"} md={"4"}>
                            <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                Vehicle Type
                            </h4>
                            <input type="text"
                                className={"form-control " + (!this.state.editVehicleDetails ? "bg-transparent border-0 shadow-none text-dark p-0 mt--2 fnt-16" : " bg-white border-1 text-dark my-2")}
                                placeholder="GST Number" value={policyHolderDetailsData?.manufacturer_detail?.motor_insurance_type}
                                onChange={(e) => this.onValueChangeCycleDetails(e, 'motor_insurance_type')}
                                disabled={!this.state.editVehicleDetails} />
                        </Col>
                    </div>
                </>}

                {this.isTravelInsuranceProduct() && <>
                    <hr className={"p-0 m-0 mt-4"} />
                    <div className={"d-flex justify-content-between align-content-center pt-4 mb-3"}>
                        <div>
                            <h4 className={"text-gray"}>
                                TRIP &amp; TRAVELLER INFORMATION
                            </h4>
                        </div>
                    </div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        {travelOptions.fields.map((item, index) => {
                            return policyHolderDetailsData?.[item.reference_key] && (
                                <Col key={index} lg={"4"} md={"4"} className={"mb-3"}>
                                    <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                        {item.title}
                                    </h4>
                                    <span className={"fnt-16"}>
                                        {policyHolderDetailsData?.[item.reference_key] ? policyHolderDetailsData?.[item.reference_key] : 'N/A'}
                                    </span>
                                </Col>
                            );
                        })}
                    </div>
                </>}

                {this.isAssuranceProduct() && <>
                    <hr className={"p-0 m-0 mt-4"} />
                    <div className={"d-flex justify-content-between align-content-center pt-4 mb-3"}>
                        <div>
                            <h4 className={"text-gray"}>
                                VEHICLE INFORMATION
                            </h4>
                        </div>
                    </div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        {assuranceOptions.fields.map((item, index) => {
                            return policyHolderDetailsData?.[item.reference_key] && (
                                <Col key={index} lg={"4"} md={"4"} className={"mb-3"}>
                                    <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                        {item.title}
                                    </h4>
                                    <span className={"fnt-16"}>
                                        {policyHolderDetailsData?.[item.reference_key] ? policyHolderDetailsData?.[item.reference_key] : 'N/A'}
                                    </span>
                                </Col>
                            );
                        })}
                    </div>
                </>}

                {this.isLendingProduct() && <>
                    <hr className={"p-0 m-0 mt-4"} />
                    <div className={"d-flex justify-content-between align-content-center pt-4 mb-3"}>
                        <div>
                            <h4 className={"text-gray"}>
                                LENDING INFORMATION
                            </h4>
                        </div>
                    </div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        {lendingOptions.fields.map((item, index) => {
                            return (policyHolderDetailsData?.[item.reference_key] || 1 === 1) && (
                                <Col key={index} lg={"4"} md={"4"} className={"mb-3"}>
                                    <h4 className={"font-weight-400 text-gray m-0 fnt-12"}>
                                        {item.title}
                                    </h4>
                                    <span className={"fnt-16"}>
                                        {policyHolderDetailsData?.[item.reference_key] ? policyHolderDetailsData?.[item.reference_key] + (item?.suffix ? item?.suffix : '') : 'N/A'}
                                    </span>
                                </Col>
                            );
                        })}
                    </div>
                </>}


                {/*------------Modals Start------------*/}

                <Modal
                    className="modal-dialog-centered"
                    size="lg"
                    isOpen={this.state.premiumChangeDialog}
                    toggle={() => {
                        this.setState({
                            premiumChangeDialog: !this.state.premiumChangeDialog
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>Change in Premium</h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                premiumChangeDialog: false
                            })
                        }} />
                    </div>
                    <hr className={"text-dark m-0 p-0"} />

                    <div className="modal-body text-center">
                        {this.state.showLoader ?
                            <div className={"mh-200"}>
                                <Loader msg={"Please Wait..."} />
                            </div>
                            :
                            <Card className={"m-0 bg-transparent shadow-none"}>
                                <CardBody className={"p-4"}>

                                    <div className={"text-center"}>
                                        <h5>
                                            {this.state.insureeDobChanged && <>
                                                DOB updated
                                                from {policyHolderDetailsData?.dob} to {this.getFormattedDate(this.state.userDetails?.dob)}, {policyHolderDetailsData?.name}
                                                <br /><br />
                                            </>
                                            }
                                            {this.state.insureeTitleChanged && <>
                                                Title updated
                                                from {policyHolderDetailsData?.title} to {this.capitalizeWords(this.state.userDetails?.title)}, {policyHolderDetailsData?.name}
                                                <br /><br />
                                            </>
                                            }
                                            {this.state.memberDobChanged && <>
                                                {this.capitalizeWords(this.state.memberName)} DOB updated
                                                from {this.state.memberOldDob} to {this.state.memberNewDob} {policyHolderDetailsData?.name}.<br /><br /> </>
                                            }
                                            {this.state.membersToDelete &&
                                                <>
                                                    Changed in no. of Family Members causes update in Premium
                                                    Amount.<br /><br />
                                                    Family Member Removed: <br />
                                                    {this.state.membersToDelete === 'child1' ? 'Child 1' : this.state.membersToDelete === 'child2' ? 'Child 2' : 'Spouse'}<br /><br />
                                                    {policyHolderDetailsData?.name}<br /><br />
                                                </>
                                            }
                                            {(this.state.insureeDobChanged || this.state.insureeTitleChanged || this.state.memberDobChanged || this.state.membersToDelete) && <>
                                                New premium amount is Rs.{newPremium} <br />
                                                Old premium amount is Rs.{policyHolderDetailsData?.premium} <br />
                                                Net difference is
                                                Rs.{policyHolderDetailsData?.premium > newPremium ? <> - {this.towDecimalValues(policyHolderDetailsData?.premium - newPremium)} </> : <> + {this.towDecimalValues(newPremium - policyHolderDetailsData?.premium)}</>}
                                                <br /><br />
                                            </>
                                            }
                                        </h5>
                                    </div>
                                </CardBody>
                            </Card>
                        }
                    </div>
                    <div className="modal-footer date-modal-footer ">
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            size="sm"
                            onClick={() => {
                                this.setState({
                                    premiumChangeDialog: false,
                                    editDetails: true
                                })
                            }}
                        >
                            Close</Button>
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            size="sm"
                            onClick={() => {
                                this.onClickConfirm(this.state.memberDeleted ? 'memberDeleted' : 'other')
                            }}
                        >
                            Done</Button>
                    </div>
                </Modal>

                {this.state.showLoader &&
                    <Loader msg={"Please Wait..."} />
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        policyHolderDetailsLoading: state.policies.policyHolderDetailsLoading,
        policyHolderDetailsData: state.policies.policyHolderDetailsData,
        cycleBrandsListLoading: state.policies.cycleBrandsListLoading,
        cycleBrandsListData: state.policies.cycleBrandsListData,
        showCustomBrands: state.policies.showCustomBrands
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),

        onGetPolicyHolderDetails: (id) => dispatch(actions.getPolicyHolderDetails(id)),

        onGetCycleBrandList: (id) => dispatch(actions.getCyclePolicyBrandsData(id)),
        onSetCustomBrandVisibility: (data) => dispatch(actions.onSetCustomCycleBrandVisibility(data)),


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailsTab);