import React, { Component, Fragment } from "react";
import classnames from "classnames";
import ScrollableDataCards from "../../../components/CustomDataCards/ScrollableDataCards";
import {
  Breadcrumb,
  BreadcrumbItem,
  NavItem,
  NavLink,
  Nav,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
class BusinessDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportsDataFilter: 2,
    };
  }
  render() {
    return (
      <Fragment>
        <Container fluid>
          <CardHeader className="border-0 bg-transparent px-0">
            <Row>
              <Col lg="8" xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-0"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary font-weight-400"
                      onClick={(e) => e.preventDefault()}
                    >
                      Dashboard
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary font-weight-400"
                      onClick={this.clicked}
                    >
                      Business Dashboard
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="text-right" lg="4" xs="6">
                <div className="d-inline">
                  <Nav className="justify-content-end" pills>
                    <NavItem className="mr-2 mr-md-0">
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: this.state.reportsDataFilter === 1,
                        })}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <span className="d-none d-md-block">Month</span>
                        <span className="d-md-none">M</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: this.state.reportsDataFilter === 2,
                        })}
                        data-toggle="tab"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <span className="d-none d-md-block">Week</span>
                        <span className="d-md-none">W</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div>
            <ScrollableDataCards />
          </div>
        </Container>
      </Fragment>
    );
  }
}
export default BusinessDashboard;
