import React from "react";

const NoDataFound = ({ msg = "No Data found" }) => {
  return (
    <tr className="cursor-pointer">
      <td colSpan="12" className="text-center">
        <span className="text-muted">
          <b>{msg}</b>
        </span>
      </td>
    </tr>
  );
};

export default NoDataFound;
