import React from "react";
import {
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import PaginationShowingData from "./PaginationShowingData";

const PaginationComp = ({ paginationState, setPaginationState }) => {
  const switchPage = (page) => {
    console.log(page, "...");
    if (paginationState?.current !== page) {
      setPaginationState({
        ...paginationState,
        current: page,
      });
    }
  };
  const isHidden = (page) => {
    var start = paginationState.current + 1;
    if (page > start) {
      if (page - start > 2 && start === 1) {
        return true;
      } else if (start !== 1 && page - start > 1) {
        return true;
      }
    } else {
      if (start - page > 2 && start === paginationState.end) {
        return true;
      } else if (start !== paginationState.end && start - page > 1) {
        return true;
      }
    }
    return false;
  };
  return (
    <CardFooter className="py-4 d-flex justify-content-between">
      <PaginationShowingData
        totalCount={paginationState.total}
        startCount={paginationState.current - 1}
      />
      <nav aria-label="...">
        <Pagination
          // className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem
            className={
              paginationState.start === paginationState.current
                ? "disabled"
                : ""
            }
          >
            <PaginationLink
              href="#pablo"
              onClick={(e) => {
                switchPage(paginationState.current - 1);
              }}
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {paginationState?.arr?.map((page) => {
            return (
              <PaginationItem
                key={"pager_" + page}
                hidden={isHidden(page)}
                className={paginationState.current === page ? "active" : ""}
              >
                <PaginationLink
                  href="#pablo"
                  onClick={(e) => {
                    switchPage(page);
                  }}
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          <PaginationItem
            className={
              paginationState.end > paginationState.current ? "" : "disabled"
            }
          >
            <PaginationLink
              href="#pablo"
              onClick={(e) => {
                switchPage(paginationState.current + 1);
              }}
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    </CardFooter>
  );
};

export default PaginationComp;
