import { Images } from "../constants/images";

export const initialLeadFormState = {
    id: '',
    gender: '',
    name: '',
    mobile: '',
    email: '',
    payment_status: 'free',
    is_vaccinated: false,
    source_id: 'Others',
    add_ons: 'None',
    start_date: '',
    policy_number: '',
    premium: '',
    remarks: '',
    hasError: false
};
export const errorMessageLead = {
    gender: '',
    name: '',
    mobile: '',
    email: '',
    policy_number: '',
    premium: ''
};
export const touchFieldsLead = {
    gender: false,
    name: false,
    mobile: false,
    email: false,
    policy_number: false,
    premium: false
};

export const initialCustomerDetailsFormState = {
    gender: '',
    name: '',
    dob: '',
    mobile: '',
    email: '',
    pincode: '',
    city: '',
    state: '',
    addressLine1: '',
    addressLine2: '',
    hasError: false
};
export const errorMessageCustomerDetails = {
    gender: '',
    name: '',
    dob: '',
    mobile: '',
    email: '',
    pincode: '',
    addressLine1: ''
};
export const touchFieldsCustomerDetails = {
    gender: false,
    name: false,
    dob: false,
    mobile: false,
    email: false,
    pincode: false,
    addressLine1: false
};

export const initialDogDetailsFormState = {
    dog_name: '',
    dog_gender: '',
    dog_breed: '',
    other_breed: '',
    dog_age_year: '',
    dog_age_month: '',
    dog_mark: '',
    spayed_castrated: 'Not Applicable',
    defects_ailments: '',
    source_id: 'Others',
    add_ons: 'None',
    payment_status: 'free',
    hasError: false
};
export const errorMessageDogDetails = {
    dog_name: '',
    dog_gender: '',
    dog_breed: '',
    other_breed: '',
    dog_age_year: '',
    dog_age_month: '',
    spayed_castrated: '',
    defects_ailments: ''
};
export const touchFieldsDogDetails = {
    dog_name: false,
    dog_gender: false,
    dog_breed: false,
    other_breed: false,
    dog_age_year: false,
    dog_age_month: false,
    spayed_castrated: false,
    defects_ailments: false
};

export const options = {
    addons: [
        { title: "None", value: "None" },
        { title: "Third Party Liability", value: "Third Party Liability" },
        { title: "Theft/Loss of your Dog", value: "Theft/Loss of your Dog" },
        { title: "OPD Expenses", value: "OPD Expenses" }
    ],
    breed: [
        {title: "Beagle", value: "beagle"},
        {title: "Boxer", value: "boxer"},
        {title: "Bulldog", value: "bulldog"},
        {title: "Chihuahua", value: "chihuahua"},
        {title: "Cocker Spaniel", value: "cocker spaniel"},
        {title: "Dachshand", value: "dachshand"},
        {title: "Dalmatian", value: "dalmatian"},
        {title: "Doberman", value: "doberman"},
        {title: "German Shepherd", value: "german shepherd"},
        {title: "Golden Retriever", value: "golden retriever"},
        {title: "Indian Bloodhound All Breeds", value: "indian bloodhound all breeds"},
        {title: "Indian Mongrel", value: "indian mongrel"},
        {title: "Labrador", value: "labrador"},
        {title: "Lhasa Apso", value: "lhasa apso"},
        {title: "Mastiff", value: "mastiff"},
        {title: "Pekingese", value: "pekingese"},
        {title: "Pitbull", value: "pitbull"},
        {title: "Pomeranian", value: "pomeranian"},
        {title: "Pug", value: "pug"},
        {title: "Rottweiler", value: "rottweiler"},
        {title: "Shih Tzu", value: "shih tzu"},
        {title: "Siberian Husky", value: "siberian husky"},
        {title: "Spitz", value: "spitz"},
        {title: "St Bernard", value: "st bernard"},
        {title: "Others", value: "others"}
    ],
    defectAilments: [
        { title: 'None', value: "None" },
        { title: 'All eye related problems', value: "All eye related problems" },
        { title: 'All types of cancers and turmors', value: "All types of cancers and turmors" },
        { title: 'UTI', value: "UTI" },
        { title: 'Venereal Granuloma', value: "Venereal Granuloma" },
        { title: 'Vestibular Disorder', value: "Vestibular Disorder" },
        { title: 'Distemper', value: "Distemper" },
        { title: 'Epilepsy', value: "Epilepsy" },
        { title: 'Hematoma in ear', value: "Hematoma in ear" },
        { title: 'Hip Dysplasia', value: "Hip Dysplasia" },
        { title: 'Peritonitis', value: "Peritonitis" },
        { title: 'Pneumonia', value: "Pneumonia" },
        { title: 'Pyometra', value: "Pyometra" },
        { title: 'Thyroid Dysfunction', value: "Thyroid Dysfunction" },
        { title: 'Cardiac Dysfuncation', value: "Cardiac Dysfuncation" },
        { title: 'Coagulation Disorders', value: "Coagulation Disorders" },
        { title: "Cushing's Syndrome", value: "Cushing's Syndrome" },
        { title: 'Diabetes', value: "Diabetes" },
        { title: 'Ascites', value: "Ascites" },
        { title: 'Canine Leptospirosis', value: "Canine Leptospirosis" },
        { title: 'Inflammation of prostate', value: "Inflammation of prostate" },
        { title: 'Kidney disfunction', value: "Kidney disfunction" },
        { title: 'Liver disfunction', value: "Liver disfunction" },
        { title: 'Insulinoma', value: "Insulinoma" },
        { title: 'Meningitis', value: "Meningitis" },
        { title: 'Osteo Arthritis', value: "Osteo Arthritis" },
        { title: 'Otitis', value: "Otitis" },
        { title: 'Pancreatic Disfunction', value: "Pancreatic Disfunction" },
        { title: 'Parvo virus infection', value: "Parvo virus infection" },
        { title: 'Upper respiratory track infection', value: "Upper respiratory track infection" },
        { title: 'Others', value: 'Others' }
    ],
    photos: [
        {
            id: "front",
            slug: 'photo1',
            sequence: 1,
            image: Images.frontDog,
            title: "1. Front-side picture",
            is_uploaded: false
        },
        {
            id: "back",
            slug: 'photo2',
            sequence: 2,
            image: Images.backDog,
            title: "2. Back-side picture",
            is_uploaded: false
        },
        {
            id: "right",
            slug: 'photo3',
            sequence: 3,
            image: Images.rightDog,
            title: "3. Right-side picture",
            is_uploaded: false
        },
        {
            id: "left",
            slug: 'photo4',
            sequence: 4,
            image: Images.leftDog,
            title: "4. Left-side picture",
            is_uploaded: false
        },
        {
            id: "top",
            slug: 'photo5',
            sequence: 5,
            image: Images.leftDog,
            title: "5. Top-view picture",
            is_uploaded: false
        }
    ],
    policyStatus: [
        // { title: "Policy Pending", value: "policy_pending" },
        // { title: "Documents Pending", value: "documents_pending" },
        // { title: "Documents Uploaded", value: "documents_uploaded" },
        // { title: "Documents Verified", value: "documents_verified" },
        // { title: "Policy Issued", value: "policy_issued" },
        // { title: "Policy Expired", value: "policy_expired" }
        { title: "Lead Created", value: "Lead Created" },
        { title: "Quotation Created", value: "Quotation Created" },
        { title: "Quotation Sent", value: "Quotation Sent" },
        { title: "Payment Captured", value: "Payment Captured" },
        { title: "Closed - Not Interested", value: "Closed - Not Interested" },
        // { title: "Policy Issued", value: "Policy Issued" }
    ],
    genderCustomer: [
        { title: "Mr", value: "mr" },
        { title: "Mrs", value: "mrs" },
        { title: "Ms", value: "ms" }
    ],
    gender: [
        { title: "Male", value: "male" },
        { title: "Female", value: "female" }
    ],
    month: [
        { title: "0 Month", value: 0 },
        { title: "1 Month", value: 1 },
        { title: "2 Months", value: 2 },
        { title: "3 Months", value: 3 },
        { title: "4 Months", value: 4 },
        { title: "5 Months", value: 5 },
        { title: "6 Months", value: 6 },
        { title: "7 Months", value: 7 },
        { title: "8 Months", value: 8 },
        { title: "9 Months", value: 9 },
        { title: "10 Months", value: 10 },
        { title: "11 Months", value: 11 }
    ],
    paymentStatus: [
        { title: 'Embedded', value: 'free' },
        { title: 'Non-Embedded', value: 'paid' }
    ],
    source: [
        { title: "Others", value: "Others" },
        { title: "Santosh Clinic", value: "Santosh Clinic" },
        { title: "Chikitsa Clinic", value: "Chikitsa Clinic" },
        { title: "SKRD Clinic", value: "SKRD Clinic" },
        { title: 'Dr Ruhil Vet Clinic', value: 'Dr Ruhil Vet Clinic' },
        { title: 'Internal', value: 'Internal' },
        { title: 'Doggie the App', value: 'Doggie the App' },
        { title: 'Pawrulz', value: 'Pawrulz' },
        { title: 'Website', value: 'Website' },
        { title: 'Dr Singh Noida', value: 'Dr Singh Noida' },
        { title: 'CP Vets - Sector 56', value: 'CP Vets - Sector 56' },
        { title: 'Niti Bagh Pet Clinic', value: 'Niti Bagh Pet Clinic' },
        { title: 'Noida 18 Pet Clinic', value: 'Noida 18 Pet Clinic' },
        { title: 'CP Vets - Sushant Lok', value: 'CP Vets - Sushant Lok' },
        { title: 'B2C Leads', value: 'B2C Leads' }
    ],
    spayedCastrated: [
        { title: 'Not Applicable', value: 'Not Applicable' },
        { title: 'Spayed', value: 'Spayed' },
        { title: 'Castrated', value: 'Castrated' }
    ],
    year: [
        { title: "0 Year", value: 0 },
        { title: "1 Year", value: 1 },
        { title: "2 Years", value: 2 },
        { title: "3 Years", value: 3 },
        { title: "4 Years", value: 4 },
        { title: "5 Years", value: 5 },
        { title: "6 Years", value: 6 },
        { title: "7 Years", value: 7 }
        // {title: "8 Years", value: 8},
        // {title: "9 Years", value: 9},
        // {title: "10 Years", value: 10}
    ],
    yesNo: [
        { title: "Yes", value: true },
        { title: "No", value: false }
    ]
};

export const dispositions = [
  {
  text: "Lead Created - Awaiting Action",
  value: "Lead Created - Awaiting Action",
  status:"Open"
  },
  {
  text: "Quotation Created - Awaiting Action",
  value: "Quotation Created - Awaiting Action",
  status:"Open"
  },
  {
  text: "Invalid Number",
  value: "Invalid Number",
  status:"Closed"
  },
  {
  text: "Wrong Number",
  value: "Wrong Number",
  status:"Closed"
  },
  {
  text: "Connected - Not Interested",
  value: "Connected - Not Interested",
  status:"Closed"
  },
  {
  text: "Connected - Not Eligible - Pre-Existing Diseases",
  value: "Connected - Not Eligible - Pre-Existing Diseases",
  status:"Closed"
  },
  {
  text: "Connected - Not Eligible - Age",
  value: "Connected - Not Eligible - Age",
  status:"Closed"
}, {
  text: "Connected - Not Eligible - Vaccination Card Not Available",
  value: "Connected - Not Eligible - Vaccination Card Not Available",
  status:"Closed"
}, {
  text: "Connected - Duplicate Query",
  value: "Connected - Duplicate Query",
  status:"Closed"
}, {
  text: "Connected - Test Lead",
  value: "Connected - Test Lead",
  status:"Closed"
}, {
  text: "Connected - Business Tie - Up",
  value: "Connected - Business Tie - Up",
  status:"Closed"
}, {
  text: "Connected - Careers",
  value: "Connected - Careers",
  status:"Closed"
}, {
  text: "Connected - Marketing / Spam",
  value: "Connected - Marketing / Spam",
  status:"Closed"
}, {
  text: "Connected - Language Barrier",
  value: "Connected - Language Barrier", 
  status:"Closed"
}, {
  text: "Not Connected - Not Responding / Switched Off - Tried Multiple Times",
  value: "Not Connected - Not Responding / Switched Off - Tried Multiple Times",
  status:"Closed"
}, {
  text: "Connected - Already Have Insurance from BAJAJ",
  value: "Connected - Already Have Insurance from BAJAJ",
  status:"Closed"
}, {
  text: "Connected - Product Information",
  value: "Connected - Product Information",
  status:"Closed"
}, {
  text: "Connected - Renewal",
  value: "Connected - Renewal",
  status:"Closed"
}, {
  text: "Connected - Product Details Shared",
  value: "Connected - Product Details Shared",
   status:"Pending"
}, {
  text: "Connected - Needs a Callback",
  value: "Connected - Needs a Callback",
   status:"Pending"
}, {
  text: "Connected - Awaiting Confirmations",
  value: "Connected - Awaiting Confirmations",
   status:"Pending"
}, {
  text: "Not Connected - Not Responding / Number Busy / Switched Off/Invalid No",
  value: "Not Connected - Not Responding / Number Busy / Switched Off/Invalid No",
   status:"Pending"
}, {
  text: "Connected - Quotation Link Shared",
  value: "Connected - Quotation Link Shared",
   status:"Pending"
}, {
  text: "Connected - Payment Unsuccessful",
  value: "Connected - Payment Unsuccessful",
   status:"Pending"
}, {
  text: "Connected - Drop-Off Before Payment",
  value: "Connected - Drop-Off Before Payment",
   status:"Pending"
}, {
  text: "Connected - Drop-Off After Payment",
  value: "Connected - Drop-Off After Payment",
   status:"Pending"
}, {
  text: "Connected - Customer Disconnected",
  value: "Connected - Customer Disconnected",
    status:"Pending"
}, {
  text: "Connected - Payment Received - Sale",
  value: "Connected - Payment Received - Sale",
  status:"Confirmed"
}, {
  text: "Connected - Payment Received - Policy Issued",
  value: "Connected - Payment Received - Policy Issued",
  status:"Confirmed"
}, {
  text: "Connected - Documents Pending",
  value: "Connected - Documents Pending",
  status:"Confirmed"
}]
export const petAddOns = {
    1: 'OPD expenses Cover (₹30,000)',
    2: 'Terminal Illness (₹30,000)',
    3: 'Theft/Loss/Straying Cover (₹20,000)',
    4: 'Third Party Liability Cover (₹5 Lakhs)',
    5: 'Long Term Care (₹25,000)',
    6: 'Mortality (₹10,000)'
};

export const mapping = {
    gender: {
        'male': 'Male',
        'female': 'Female'
    },
    isSmoker: {
        'true': 'Yes',
        'false': 'No'
    },
    paymentStatus: {
        'free': 'Embedded',
        'paid': 'Non-Embedded'
    },
    smoker: {
        'true': 'Smoker',
        'false': 'Non Smoker'
    },
    status: {
        'policy_pending': 'Policy Pending',
        'documents_pending': 'Documents Pending',
        'documents_uploaded': 'Documents Uploaded',
        'documents_verified': 'Documents Verified',
        'policy_issued': 'Policy Issued',
        'policy_expired': 'Policy Expired'
    }
};