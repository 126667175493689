import { Box } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import StateDropdown from "../../../components/StateDropdown/StateDropdown";
import ExportButton from "../../../components/reusableComp/ExportButton";
import NoDataFound from "../../../components/reusableComp/NoDataFound";
import PaginationComp from "../../../components/reusableComp/PaginationComp";
import { createPaginationArr, priceStandardForm, defaultStatusId } from "../../../helpers/utils";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import { useLocation } from "react-router";

const FranchiseRetailersListing = () => {
  const { pathname } = useLocation();
  let retailerId = pathname && pathname.split("=")[1];
  const [activeDateFilter, setActiveDateFilter] = useState("today");
  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState([]);
  const [filter, setFilter] = useState({
    state: { id: defaultStatusId(), value: "ALL", label: "All States" },
  });
  const [paginationState, setPaginationState] = useState({
    start: 1,
    current: 1,
    end: 0,
    limit: 25,
    count: 0,
    arr: [],
  });
  let tableHeader = [
    "Retailer Name",
    "Email",
    "Mobile number",
    "Child Code",
    "Store Name",
    "Created at",
  ];
  const handleChange = (e) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  };
  const handleSearchKeyDown = (e) => {
    e.key === "Enter" && handleClick();
  };
  const handleClick = () => {
    // write a logic to hit the api for search field
  };
  const handleOnChange = (selected) => {
    setFilter({
      ...filter,
      state: selected,
    });
  };

  const fetchListingData = async () => {
    const query = {
      page: paginationState.current - 1,
      limit: paginationState.limit,
      //   status: "inactive",
      state_id: filter.state.id,
      franchise_id: retailerId,
    };
    try {
      const response = await AxiosReactClient.getRequest(
        `franchise/retailers`,
        query
      );
      setListingData(response?.data?.data?.data);
      setPaginationState({
        ...paginationState,
        end: Math.ceil(
          response?.data?.data?.totalCount / paginationState.limit
        ),
        total: response?.data?.data?.total_count,
        arr: createPaginationArr(
          Math.ceil(response?.data?.data?.total_count / paginationState.limit)
        ),
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchListingData();
  }, [paginationState.current, filter.state]);
  let exportPayload = {
    state_id: filter.state.id,
    franchise_id: retailerId,
  };
  return (
    <>
      <Fragment>
        <Container fluid>
          <CardHeader className="border-0 px-0 bg-transparent ">
            <Row className="align-items-center py-4">
              <Col xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary h5 font-weight-400"
                      onClick={(e) => e.preventDefault()}
                    >
                      Franchise Retailers
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
            <Col
              className="pl-0 d-flex "
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <div style={{visibility:'hidden'}}>
                <StateDropdown 
                  onChange={(selectedOption) => handleOnChange(selectedOption)}
                />
              </div>
              <ExportButton url="franchise/retailers" {...{ exportPayload }} />
            </Col>

            <Box></Box>
            <Card className="mb-6">
              <CardBody className="px-0 py-0">
                <div className=" table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        {tableHeader.map((cur, id) => (
                          <th key={id}>{cur}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="all_tabl_heigth">
                      {listingData?.length === 0 && <NoDataFound />}
                      {listingData?.length > 0 &&
                        listingData.map((cur, id) => (
                          <tr key={id}>
                            <td className="table-user">
                              {cur?.dealership_name}
                            </td>
                            <td className="table-user">{cur?.email}</td>
                            <td className="table-user">{cur?.mobile}</td>
                            <td className="table-user">{cur?.child_code}</td>
                            <td className="table-user">{cur?.store_name}</td>
                            <td className="table-user">{cur?.created_at}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              {listingData?.length > 0 && (
                <PaginationComp
                  {...{ paginationState, setPaginationState, fetchListingData }}
                />
              )}
            </Card>
          </CardHeader>
        </Container>
      </Fragment>
    </>
  );
};

export default FranchiseRetailersListing;
