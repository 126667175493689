import React from "react";
// import classnames from "classnames";
import Chart from "chart.js";
// import { Line, Bar } from "react-chartjs-2";

import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // NavItem,
  // NavLink,
  // Nav,
  // Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import AlternativeHeader from "../../../components/Headers/AlternativeHeader.jsx";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../Store/actions";
import * as permissions from "../../../config/Casl/permissions";
import Can from "../../../config/Casl/Can";
// import {
//   POLICIES_LINE_GRAPH_OPTIONS,
//   CLAIMS_BAR_CHART_OPTIONS,
//   parseClaimsBarChartData,
//   parseCombinedPoliciesLineGraphData,
// } from "../../../variables/dashboardChartsData";
import {
  chartOptions,
  parseOptions,
} from "../../../../src/variables/charts.jsx";
import Shimmer from "../../../components/CustomUi/Shimmer/Shimmer";
import {
  convertToCommaSeperated,
  getSelectedDurationFilter,
  defaultStatusId,
} from "../../../helpers/utils";
import { DealerTypes } from "../../../data/products";
import AxiosReactClient from "../../../utilities/AxiosRestClient.js";
class Alternative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePoliciesFilter: 1,
      activeClaimsFilter: 1,
      salesLinegraphFilter: "monthWise",
      claimsBarchartFilter: "monthWise",
      dealerType: "",
      amountData: null,
      allState: {
        state_id: defaultStatusId(),
      },
      currentState: "",
    };
  }

  // state = {
  //   activePoliciesFilter: 1,
  //   activeClaimsFilter: 1,
  //   salesLinegraphFilter: "monthWise",
  //   claimsBarchartFilter: "monthWise",
  //   dealerType: "",
  //   amountData: null,
  //   allState: {
  //     state_id: this.defaultStatusId(),
  //   },
  // };

  productList = [];
  dealerList = [];
  franchise = localStorage.getItem("franchise");

  dealerTypes = [
    { title: "ALL", value: "" },
    { title: "COCO", value: "CREDIT" },
    { title: "FOCO", value: "PREPAID_WALLET" },
    { title: "GT", value: "PAYG" },
  ];
  dealerTypesForFranchise = [{ title: "ALL", value: "" }];

  switchLinegraphView = (e, index) => {
    e.preventDefault();
    this.setState({
      activePoliciesFilter: index,
      salesLinegraphFilter: index === 1 ? "monthWise" : "weekWise",
    });
  };

  switchBarChartView = (e, index) => {
    e.preventDefault();
    this.setState({
      activeClaimsFilter: index,
      claimsBarchartFilter: index === 1 ? "monthWise" : "weekWise",
    });
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  checkForPieChartData = (data) => {
    if (Object.values(data).length > 0) {
      let valArray = Object.values(data["claimStatus"]);
      const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);
      return arrSum(valArray) < 1 ? true : false;
    } else {
      return true;
    }
  };

  async fetchData() {
    AxiosReactClient.getRequest("dashboard/franchise/wallet")
      .then((response) => {
        if (response?.status === 200) {
          this.setState({ amountData: response?.data?.data?.wallet_balance });
        } else {
        }
      })
      .catch((err) => {});
  }

  componentDidMount() {
    this.fetchData();
    this.props.onGetPoliciesCount("today", this.state.allState);
    this.props.onGetLeadsCount("today", this.state.allState);
    this.props.onGetClaimsCount("today", this.state.allState);
    this.props.onGetRevinueCount("today", this.state.allState);
    this.props.onGetDealersCount("today", this.state.allState);
    this.props.onGetProductListData("today", this.state.allState);
    this.props.onGetDealerListData("today", this.state.allState);
    this.props.onGetClaimPiechartData("today", this.state.allState);
    this.props.onGetProductListRevenueData("today", this.state.allState);
    this.props.onGetPoliciesLinegraphData("monthWise");
    this.props.onGetClaimsBarchartData("monthWise");
    this.props.onGetCombinedPoliciesLinegraphData("monthWise");
  }

  refreshAllComponents(filter, params) {
    console.log(params, "...");
    this.setState({
      currentState: params?.state_id,
    });
    this.props.onGetPoliciesCount(filter, params);
    this.props.onGetLeadsCount(filter, params);
    this.props.onGetClaimsCount(filter, params);
    this.props.onGetRevinueCount(filter, params);
    this.props.onGetDealersCount(filter, params);
    this.props.onGetProductListData(filter, params);
    this.props.onGetDealerListData(filter, params);
    this.props.onGetClaimPiechartData(filter, params);
    this.props.onGetProductListRevenueData(filter, params);
  }

  getUpperCaseFilter = (filter) => {
    switch (filter.toUpperCase()) {
      case "THISWEEK":
        return "THIS WEEK";
      case "THISMONTH":
        return "THIS MONTH";
      case "LASTWEEK":
        return "LAST WEEK";
      case "LASTMONTH":
        return "LAST MONTH";
      default:
        return filter.toUpperCase();
    }
  };

  getExtraWhiteRows = (x) => {
    var rows = [];
    for (let index = 0; index < x; index++) {
      rows.push(
        <tr key={Math.random(100)}>
          <td className="table-user" style={{ borderTop: "0" }}></td>
          <td style={{ textAlign: "right", borderTop: "0" }}></td>
        </tr>
      );
    }
    return rows;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.productList = nextProps.dashProductListData.productList
      ? nextProps.dashProductListData.productList
      : [];
    this.dealerList = nextProps.dashDealerListData.dealerList
      ? nextProps.dashDealerListData.dealerList
      : [];
  }

  getTwoFixedPoint = (num) => {
    var x = num * 1;
    return x.toFixed(2);
  };

  onClickHandler = (value) => {
    this.setState({
      dealerType: value,
    });

    this.props.onGetDealerListData(this.props.dashPoliciesFilter, {
      invoicing_modal: value,
    });
  };

  render() {
    console.log(this.state.currentState, "....");
    let franchise = localStorage.getItem("franchise");
    let category = localStorage.getItem("category");
    let key = localStorage.getItem("key");
    let customHide = this.props.dashLeadsFilter === "custom" ? true : false;
    let vivoCategory = ["admin", "vivo_head"].includes(key) ? true : false;
    let stateCategory = key === "state_head" ? true : false;
    let forGarantieCardOnly = ["admin"].includes(key) ? true : false;
    let showFranchiseData =
      franchise === "true" ? this.dealerTypesForFranchise : this.dealerTypes;
    return (
      <>
        <AlternativeHeader
          filter={this.props.dashPoliciesFilter}
          click={(filter, params) => this.refreshAllComponents(filter, params)}
          title={"Dashboard"}
          icon={"fa-home"}
          class={"pb-6"}
          section={"dashboard"}
          allDisabled={"false"}
        />
        <Container className="mt--6" fluid>
          {/* <StateDropdown className="mt--6 mb-6"/> */}
          <Row>
            <Col>
              <Card className="bg-gradient-default border-0 cardHeight">
                {franchise === "false" ? (
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          <Row>
                            <Col className="pr-0">Active Retailers</Col>
                            {/* <Col xs="5" style={{ textAlign: "right" }}>
                            <small className="text-white">
                              {" "}
                              {this.getUpperCaseFilter(
                                this.props.dashDealersFilter
                              )}
                            </small>
                          </Col> */}
                          </Row>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          {this.props.dashDealersLoading ? (
                            <Shimmer />
                          ) : (
                            `${this.props.dashDealersCount}`
                          )}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {!customHide && (
                        <a
                          className="text-nowrap font-weight-600"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{
                            pointerEvents: "none",
                            color: "#cccccc !important",
                          }}
                        >
                          See details
                        </a>
                      )}
                    </p>
                  </CardBody>
                ) : (
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          <Row>
                            <Col className="pr-0">Wallet</Col>
                          </Row>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          {`₹ ${convertToCommaSeperated(
                            this.state.amountData
                          )}`}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {!customHide && (
                        <a
                          className="text-nowrap font-weight-600"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{
                            pointerEvents: "none",
                            color: "#cccccc !important",
                          }}
                        >
                          See details
                        </a>
                      )}
                    </p>
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col>
              <Card className="bg-gradient-primary border-0 cardHeight">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        <Row>
                          <Col>Total Plans Sold</Col>
                          {/* <Col xs="6" style={{ textAlign: "right" }}>
                            <small className="text-white">
                              {" "}
                              {this.getUpperCaseFilter(
                                this.props.dashPoliciesFilter
                              )}
                            </small>
                          </Col> */}
                        </Row>
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        {this.props.dashPoliciesLoading ? (
                          <Shimmer />
                        ) : (
                          this.props.dashPoliciesCount
                        )}
                      </span>
                    </div>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    {!customHide && (
                      <Link
                        className="text-nowrap text-white font-weight-600"
                        to={`/admin/policies/all?date=${getSelectedDurationFilter(
                          this.props.dashPoliciesFilter
                        )}&state=${this.state.currentState}`}
                      >
                        See details
                      </Link>
                    )}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Can
              I={permissions.GARANTIE.total_premium}
              a={permissions.GARANTIE.subject}
            >
              <Col>
                <Card className="bg-gradient-info border-0 cardHeight">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          <Row>
                            <Col>Total Premium</Col>
                            {/* <Col xs="6" style={{ textAlign: "right" }}>
                            <small className="text-white">
                              {" "}
                              {this.getUpperCaseFilter(
                                this.props.dashLeadsFilter
                              )}
                            </small>
                          </Col> */}
                          </Row>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          {this.props.dashLeadsLoading ? (
                            <Shimmer />
                          ) : (
                            "₹" +
                            convertToCommaSeperated(
                              this.getTwoFixedPoint(
                                this.props.dashPlanPremium?.totalPremium
                              )
                            )
                          )}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {!customHide && (
                        <Link
                          className="text-nowrap text-white font-weight-600"
                          to={`/admin/policies/all?date=${getSelectedDurationFilter(
                            this.props.dashPoliciesFilter
                          )}`}
                        >
                          See details
                        </Link>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Can>
            {franchise === "true" && (
              <Col>
                <Card className="bg-gradient-info border-0 cardHeight">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          <Row>
                            <Col>Total Retailer Commission</Col>
                          </Row>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          {this.props.dashLeadsLoading ? (
                            <Shimmer />
                          ) : (
                            "₹" +
                            convertToCommaSeperated(
                              this.getTwoFixedPoint(
                                this.props.dashPlanPremium
                                  ?.totalDealerCommission ?? ""
                              )
                            )
                          )}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {!customHide && (
                        <Link
                          className="text-nowrap text-white font-weight-600"
                          to={`/admin/partners/retailers?date=${getSelectedDurationFilter(
                            this.props.dashPoliciesFilter
                          )}`}
                        >
                          See details
                        </Link>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            )}
            {vivoCategory && (
              <Can
                I={permissions.GARANTIE.vivo_india}
                a={permissions.GARANTIE.subject}
              >
                <Col>
                  <Card className="bg-gradient-danger border-0 cardHeight">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 text-white"
                          >
                            <Row>
                              <Col>
                                Total
                                <span
                                  style={{ fontSize: "smaller" }}
                                  className="text-combined"
                                >
                                  {" "}
                                  vivo{" "}
                                </span>
                                margin
                              </Col>
                              {/* <Col xs="6" style={{ textAlign: "right" }}>
                                  <small className="text-white">
                                    {" "}
                                    {this.getUpperCaseFilter(
                                      this.props.dashRevinueFilter
                                    )}
                                  </small>
                                </Col> */}
                            </Row>
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 text-white">
                            {this.props.dashRevinueLoading ? (
                              <Shimmer />
                            ) : (
                              "₹" +
                              convertToCommaSeperated(
                                this.getTwoFixedPoint(this.props.dashOemRevenue)
                              )
                            )}
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        {!customHide && (
                          <Link
                            className="text-nowrap text-white font-weight-600"
                            to={`/admin/policies/all?date=${getSelectedDurationFilter(
                              this.props.dashPoliciesFilter
                            )}&state=${this.state.currentState}`}
                          >
                            See details
                          </Link>
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Can>
            )}
            {stateCategory && (
              <Can
                I={permissions.GARANTIE.state_margin}
                a={permissions.GARANTIE.subject}
              >
                <Col>
                  <Card className="bg-gradient-danger border-0 cardHeight">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 text-white"
                          >
                            <Row>
                              <Col>Total State Margin</Col>
                              {/* <Col xs="6" style={{ textAlign: "right" }}>
                            <small className="text-white">
                              {" "}
                              {this.getUpperCaseFilter(
                                this.props.dashRevinueFilter
                              )}
                            </small>
                          </Col> */}
                            </Row>
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 text-white">
                            {this.props.dashRevinueLoading ? (
                              <Shimmer />
                            ) : (
                              "₹" +
                              convertToCommaSeperated(
                                this.getTwoFixedPoint(
                                  this.props.dashPlanPremium?.totalOemState
                                )
                              )
                            )}
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        {!customHide && (
                          <Link
                            className="text-nowrap text-white font-weight-600"
                            to={`/admin/policies/all?date=${getSelectedDurationFilter(
                              this.props.dashPoliciesFilter
                            )}`}
                          >
                            See details
                          </Link>
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Can>
            )}
            <Can
              I={permissions.GARANTIE.garantie_margin}
              a={permissions.GARANTIE.subject}
            >
              {franchise === "false" && (
                <Col
                  style={{
                    visibility: vivoCategory ? "" : "hidden",
                    display: vivoCategory ? "" : "none",
                  }}
                >
                  <Card className="bg-gradient-default border-0 cardHeight">
                    {vivoCategory && (
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 text-white"
                            >
                              <Row>
                                <Col>Total Garantie Margin</Col>
                              </Row>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {this.props.dashRevinueLoading ? (
                                <Shimmer />
                              ) : (
                                "₹" +
                                convertToCommaSeperated(
                                  this.getTwoFixedPoint(
                                    this.props.dashPlanPremium
                                      ?.totalGarantieCommission
                                  )
                                )
                              )}
                            </span>
                          </div>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          {!customHide && (
                            <Link
                              className="text-nowrap text-white font-weight-600"
                              to={`/admin/policies/all?date=${getSelectedDurationFilter(
                                this.props.dashPoliciesFilter
                              )}`}
                            >
                              See details
                            </Link>
                          )}
                        </p>
                      </CardBody>
                    )}
                  </Card>
                </Col>
              )}
            </Can>
            {franchise === "false" && (
              <Col>
                <Card className="bg-gradient-default border-0 cardHeight">
                  {franchise === "false" && (
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 text-white"
                          >
                            <Row>
                              <Col className="pr-0">Approved Claims</Col>
                              {/* <Col xs="5" style={{ textAlign: "right" }}>
                            <small className="text-white">
                              {" "}
                              {this.getUpperCaseFilter(
                                this.props.dashDealersFilter
                              )}
                            </small>
                          </Col> */}
                            </Row>
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 text-white">
                            {this.props.dashDealersLoading ? (
                              <Shimmer />
                            ) : (
                              `${this.props.dashApprovedClaimsCount}/${this.props.dashClaimsCount}`
                            )}
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        {!customHide && (
                          <a
                            className="text-nowrap font-weight-600"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            style={{
                              pointerEvents: "none",
                              color: "#cccccc !important",
                            }}
                          >
                            See details
                          </a>
                        )}
                      </p>
                    </CardBody>
                  )}
                </Card>
              </Col>
            )}
          </Row>
          <Row>
            <Col md="6" xl="4">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Plans</h3>
                    </Col>
                    <Col xs="6" style={{ textAlign: "right" }}>
                      {/* <small>
                        {" "}
                        {this.getUpperCaseFilter(
                          this.props.dashProductListFilter
                        )}
                      </small> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pt-3 m-auto" xs="12">
                      <Badge color="primary" pill className="mr-2">
                        ALL
                      </Badge>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Product</th>
                      <th style={{ textAlign: "right" }}>Sold</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.productList.map((product, index) => {
                      let tempNameArr = [];
                      let extraStr = null;
                      if (product.name.length > 27) {
                        tempNameArr = product.name.split(" ");
                        extraStr = tempNameArr.splice(
                          tempNameArr.length - 1,
                          1
                        );
                      }
                      return (
                        <tr key={Math.random(100)}>
                          <td className="table-user">
                            <img
                              alt="..."
                              style={{ backgroundColor: "#f6f9fc" }}
                              className="avatar rounded-circle mr-3"
                              src={product.image}
                            />
                            <b style={{ position: "absolute" }}>
                              {" "}
                              {product.name.length <= 27
                                ? product.name
                                : product.name.slice(0, 26) + "..."}{" "}
                              <br />
                              {1 === 2 && <small>{product.provider}</small>}
                            </b>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <span className="text-muted">
                              {this.props.dashProductListLoading ? (
                                <Shimmer />
                              ) : (
                                product.ordersCount
                                // +
                                // " (" +
                                // Math.floor(
                                //   (product.ordersCount * 100) /
                                //     (this.props.dashPoliciesCount !== 0
                                //       ? this.props.dashPoliciesCount
                                //       : 1)
                                // ) +
                                // "%)"
                              )}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    {this.getExtraWhiteRows(5 - this.productList.length)}
                  </tbody>
                </Table>
                {this.productList.length === 0 ? (
                  <div
                    style={{
                      top: "50%",
                      left: "50%",
                      position: "absolute",
                      transform: "translate(-50%,-50%)",
                    }}
                  >
                    NO DATA
                  </div>
                ) : (
                  ""
                )}
              </Card>
            </Col>
            <Col md="6" xl="4">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Retailers</h3>
                    </Col>
                    {/* <Col xs="6" style={{ textAlign: "right" }}>
                      <small>
                        {" "}
                        {this.getUpperCaseFilter(
                          this.props.dashDealerListFilter
                        )}
                      </small>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col className="pt-3 m-auto" xs="12">
                      {showFranchiseData.map((item) => {
                        return (
                          <Badge
                            color={
                              this.state.dealerType === item.value
                                ? "primary"
                                : "secondary"
                            }
                            pill
                            className="mr-2"
                            onClick={() => this.onClickHandler(item.value)}
                            key={item.value}
                          >
                            {item.title}
                          </Badge>
                        );
                      })}
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>RETAILER</th>
                      <th style={{ textAlign: "right" }}>Sold</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.dealerList.map((dealer, index) => {
                      return (
                        <tr key={Math.random(100)}>
                          <td className="table-user">
                            <b>{dealer.dealershipName}</b>
                            <br />
                            <small>{DealerTypes[dealer.invoicingModal]}</small>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <span className="text-muted">
                              {this.props.dashDealerListLoading ? (
                                <Shimmer />
                              ) : (
                                dealer.totalSales
                              )}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    {this.getExtraWhiteRows(5 - this.dealerList.length)}
                  </tbody>
                </Table>
                {this.dealerList.length === 0 ? (
                  <div
                    style={{
                      top: "50%",
                      left: "50%",
                      position: "absolute",
                      transform: "translate(-50%,-50%)",
                    }}
                  >
                    NO DATA
                  </div>
                ) : (
                  ""
                )}
              </Card>
            </Col>
            <Col md="6" xl="4">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Premium</h3>
                    </Col>
                    {/* <Col xs="6" style={{ textAlign: "right" }}>
                      <small>
                        {" "}
                        {this.getUpperCaseFilter(
                          this.props.dashProductListFilter
                        )}
                      </small>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col className="pt-3 m-auto" xs="12">
                      <Badge color="primary" pill className="mr-2">
                        ALL
                      </Badge>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Product</th>
                      <th style={{ textAlign: "right" }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.dashProductListRevenueData &&
                      this.props.dashProductListRevenueData.map(
                        (product, index) => {
                          let tempNameArr = [];
                          let extraStr = null;
                          if (product.name.length > 27) {
                            tempNameArr = product.name.split(" ");
                            extraStr = tempNameArr.splice(
                              tempNameArr.length - 1,
                              1
                            );
                          }
                          return (
                            <tr key={Math.random(100)}>
                              <td className="table-user">
                                <img
                                  alt="..."
                                  style={{ backgroundColor: "#f6f9fc" }}
                                  className="avatar rounded-circle mr-3"
                                  src={product.image}
                                />
                                <b style={{ position: "absolute" }}>
                                  {" "}
                                  {product.name.length <= 27
                                    ? product.name
                                    : product.name.slice(0, 26) + "..."}{" "}
                                  <br />
                                  {1 === 2 && <small>{product.provider}</small>}
                                </b>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <span className="text-muted">
                                  {this.props.dashProductListRevenueLoading ? (
                                    <Shimmer />
                                  ) : (
                                    "₹" +
                                    convertToCommaSeperated(
                                      this.getTwoFixedPoint(product.revenue)
                                    )
                                  )}
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    {this.getExtraWhiteRows(5 - this.productList.length)}
                  </tbody>
                </Table>
                {this.productList.length === 0 ? (
                  <div
                    style={{
                      top: "50%",
                      left: "50%",
                      position: "absolute",
                      transform: "translate(-50%,-50%)",
                    }}
                  >
                    NO DATA
                  </div>
                ) : (
                  ""
                )}
              </Card>
            </Col>
          </Row>
          <hr className="my-4" />
          <Row>
            {/* <Col xl="8">
              <Card className="bg-default">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-light text-uppercase ls-1 mb-1">
                        Overview
                      </h6>
                      <h5 className="h3 text-white mb-0">Sales</h5>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem className="mr-2 mr-md-0">
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activePoliciesFilter === 1
                            })}
                            href="#pablo"
                            onClick={e => this.switchLinegraphView(e, 1)}
                          >
                            <span className="d-none d-md-block">Month</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activePoliciesFilter === 2
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.switchLinegraphView(e, 2)}
                          >
                            <span className="d-none d-md-block">Week</span>
                            <span className="d-md-none">W</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={parseCombinedPoliciesLineGraphData(this.props.dashCombinedPolicyLinegraphData.cycleSales, this.props.dashCombinedPolicyLinegraphData.mobileSales, this.props.dashCombinedPolicyLinegraphData.bikerSales, this.state.salesLinegraphFilter)}
                      options={POLICIES_LINE_GRAPH_OPTIONS.options}
                      id="chart-sales-dark"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col xl="12">
              <Card>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h5 className="h3 mb-0">Claims</h5>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem className="mr-2 mr-md-0">
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeClaimsFilter === 1,
                            })}
                            href="#pablo"
                            onClick={(e) => this.switchBarChartView(e, 1)}
                          >
                            <span className="d-none d-md-block">M</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeClaimsFilter === 2,
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={(e) => this.switchBarChartView(e, 2)}
                          >
                            <span className="d-none d-md-block">W</span>
                            <span className="d-md-none">W</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={parseClaimsBarChartData(
                        this.props.dashClaimBarchartData,
                        this.state.claimsBarchartFilter
                      )}
                      options={CLAIMS_BAR_CHART_OPTIONS.options}
                      className="chart-canvas"
                      id="chart-bars"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashPoliciesLoading: state.dashboard.dashPoliciesLoading,
    dashLeadsLoading: state.dashboard.dashLeadsLoading,
    dashClaimsLoading: state.dashboard.dashClaimsLoading,
    dashRevinueLoading: state.dashboard.dashRevinueLoading,
    dashDealersLoading: state.dashboard.dashDealersLoading,
    dashPoliciesLinegraphLoading: state.dashboard.dashPolicyLinegraphLoading,
    dashClaimsBarchartLoading: state.dashboard.dashClaimBarchartLoading,
    dashProductListLoading: state.dashboard.dashProductListLoading,
    dashDealerListLoading: state.dashboard.dashDealerListLoading,
    dashCombinedPoliciesLinegraphLoading:
      state.dashboard.dashCombinedPolicyLinegraphLoading,
    dashProductListRevenueLoading:
      state.dashboard.dashProductListRevenueLoading,
    dashPoliciesCount: state.dashboard.dashPoliciesCount,
    dashPoliciesTotalCount: state.dashboard.dashPoliciesTotalCount,
    dashLeadsCount: state.dashboard.dashLeadsCount,
    dashPlanPremium: state.dashboard.dashPlanPremium,
    dealerCommission: state.dashboard.dealerCommission,
    dashOemRevenue: state.dashboard.dashOemRevenue,
    dashLeadsTotalCount: state.dashboard.dashLeadsTotalCount,
    dashClaimsCount: state.dashboard.dashClaimsCount,
    dashApprovedClaimsCount: state.dashboard.dashApprovedClaimsCount,
    dashClaimsTotalCount: state.dashboard.dashClaimsTotalCount,
    dashRevinueCount: state.dashboard.dashRevinueCount,
    dashRevinueTotalCount: state.dashboard.dashRevinueTotalCount,
    dashDealersCount: state.dashboard.dashDealersCount,
    dashDealersTotalCount: state.dashboard.dashDealersTotalCount,
    dashPolicyLinegraphData: state.dashboard.dashPolicyLinegraphData,
    dashClaimBarchartData: state.dashboard.dashClaimBarchartData,
    dashProductListData: state.dashboard.dashProductListData,
    dashDealerListData: state.dashboard.dashDealerListData,
    dashClaimPiechartData: state.dashboard.dashClaimPiechartData,
    dashCombinedPolicyLinegraphData:
      state.dashboard.dashCombinedPolicyLinegraphData,
    dashProductListRevenueData: state.dashboard.dashProductListRevenueData,
    dashPoliciesFilter: state.dashboard.dashPoliciesFilter,
    dashLeadsFilter: state.dashboard.dashLeadsFilter,
    dashClaimsFilter: state.dashboard.dashClaimsFilter,
    dashRevinueFilter: state.dashboard.dashRevinueFilter,
    dashDealersFilter: state.dashboard.dashDealersFilter,
    dashPoliciesLinegraphFilter: state.dashboard.dashPoliciesLinegraphFilter,
    dashClaimsBarchartFilter: state.dashboard.dashClaimsBarchartFilter,
    dashProductListFilter: state.dashboard.dashProductListFilter,
    dashDealerListFilter: state.dashboard.dashDealerListFilter,
    dashClaimsPiechartFilter: state.dashboard.dashClaimsPiechartFilter,
    dashCombinedPoliciesLinegraphFilter:
      state.dashboard.dashCombinedPoliciesLinegraphFilter,
    dashProductListRevenueFilter: state.dashboard.dashProductListRevenueFilter,
    error: state.dashboard.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetPoliciesCount: (filter, params) =>
      dispatch(actions.getDashboardPoliciesCount(filter, params)),
    onGetLeadsCount: (filter, params) =>
      dispatch(actions.getDashboardLeadsCount(filter, params)),
    onGetClaimsCount: (filter, params) =>
      dispatch(actions.getDashboardClaimsCount(filter, params)),
    onGetRevinueCount: (filter, params) =>
      dispatch(actions.getDashboardRevinueCount(filter, params)),
    onGetDealersCount: (filter, params) =>
      dispatch(actions.getDashboardDealersCount(filter, params)),
    onGetPoliciesLinegraphData: (filter) =>
      dispatch(actions.getDashboardPoliciesLinegraphData(filter)),
    onGetClaimsBarchartData: (filter) =>
      dispatch(actions.getDashboardClaimsBarChartData(filter)),
    onGetProductListData: (filter, params) =>
      dispatch(actions.getDashboardProductListData(filter, params)),
    onGetDealerListData: (filter, params) =>
      dispatch(actions.getDashboardDealerListData(filter, params)),
    onGetClaimPiechartData: (filter, params) =>
      dispatch(actions.getDashboardClaimsPieChartData(filter, params)),
    onGetCombinedPoliciesLinegraphData: (filter) =>
      dispatch(actions.getDashboardCombinedPoliciesLinegraphData(filter)),
    onGetProductListRevenueData: (filter, params) =>
      dispatch(actions.getDashboardProductListRevenueData(filter, params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Alternative);
