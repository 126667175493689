import React, {Component} from "react";
import {Badge, Button, Col, Modal, Row, Table} from "reactstrap";
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import DropdownCustom from "../../../components/dropDown";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import Loader from "../../../components/CustomUi/Loader/Loader";
import moment from "moment";

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enachDetails: [
                {key: "Token ID ", value: "1234567"},
                {key: "Register Date", value: "14/06/2000"},
                {key: "Expiry Date", value: "14/06/2000"},
                {key: "Enach Max Amount", value: "999 INR"}
            ],
            subscribeEnachDialog: false,
            recievedPaymentLink: this.props.transactionListData?.subscription_url ? this.props.transactionListData?.subscription_url : '',
            transactionId: null,
            retryPaymentDialog: false,
            initiateRefundDialog: false,
            transactionAmount: '',
            isMonthly: false,

            selectedPaymentType: 'select',
            mannualPaymentDetails: {
                method: '',
                amount: this.props.transactionListData?.premium,
                payment_id: ''
            },
            addPaymentDialog: false,
            bankDetails: ''
        }

        this.getTransactionData()
        this.props.onGetPolicyHolderDetails(this.props.order_id)
    }

    mannualPaymentType = [
        {id: 'select', name: 'Select Payment Type'},
        {id: 'cheque', name: 'Cheque'},
        {id: 'neft', name: 'NEFT'},
    ]

    getTransactionData = () => {
        this.props.onGetTransactionList(this.props.order_id)
    }
    handlePaymentTypeChange = (e, key) => {
        let paymentDetails = this.state.mannualPaymentDetails;
        paymentDetails[key] = e.target.value;
        this.setState({
            selectedPaymentType: e.target.value,
            mannualPaymentDetails: paymentDetails
        })
    }

    optionsSubscribed = [
        {value: 'viewMore', text: 'View More', icon: ''},
        // {value: 'pauseEnach', text: 'Pause Enach', icon: ''},
        {value: 'resubscribeEnach', text: 'Resubscribe Enach', icon: ''},
    ]
    optionsNotSubscribed = [
        {value: 'subscribe', text: 'Subscribe Enach', icon: ''},
    ]
    optionsPausedSubscribed = [
        {value: 'viewMore', text: 'View More', icon: ''},
        {value: 'continueEnach', text: 'Continue Enach', icon: ''},
        {value: 'resubscribeEnach', text: 'Resubscribe Enach', icon: ''},
    ]
    annualSuccessfulPaymentOptions = [
        {value: 'initiateRefund', text: 'Initiate Refund', icon: ''},
    ]
    monthlySuccessfulPaymentOptions = [
        {value: 'initiateRefund', text: 'Initiate Refund', icon: ''},
        {value: 'viewBankDetails', text: 'View Bank Details', icon: ''},
    ]
    failedPaymentOptions = [
        {value: 'retryFailedPayment', text: 'Retry Failed Payment', icon: ''},
        // {value: 'sendPaymentLink', text: 'Send Payment Link', icon: ''},
    ]
    copyOptions = [
        {value: 'copyToClipboard', text: 'Copy Link', icon: ''},
    ]

    paymentMethod = [
        {key: 'card', value: 'Credit Card'},
        {key: 'emandate', value: 'Debit Card'},
        {key: 'emandate', value: 'Netbanking'},
    ]

    onDropdownOptionsClick = (e, {value}, {id, amount, is_monthly}, link) => {
        this.setState({
            dropDownValue: value
        });
        if (value === 'subscribe' || value === 'resubscribeEnach') {
            this.setState({
                subscribeEnachDialog: true,
                dropDownValue: ''
            });
        }
        if (value === 'retryFailedPayment') {
            this.setState({
                retryPaymentDialog: true,
                transactionId: id,
                dropDownValue: ''
            });
        }
        if (value === 'initiateRefund') {
            this.setState({
                initiateRefundDialog: true,
                transactionId: id,
                transactionAmount: amount,
                isMonthly: is_monthly,
                dropDownValue: ''
            });
        }
        if (value === 'copyToClipboard') {
            this.copyToClipboard(link)
            this.setState({
                dropDownValue: ''
            })
        }
        if (value === 'viewBankDetails' || value === 'viewMore') {
            this.setState({
                viewBankDetailsDialog: true,
                dropDownValue: '',
                bankDetails: value === 'viewBankDetails' ? 'againstTransaction' : 'againstSubscription'
            });
        }

    }
    onMethodChanged = (e) => {
        console.log(e.target.value)
        let value = e.target.value
        this.setState({
            paymentType: this.paymentMethod.find((method) => {
                return value === method.value && method.key
            })
        })
    }

    onClickSubscribeEnach = () => {
        this.payment('', 'paymentLink', {
            is_recurring: true,
            is_monthly: true,
            entity: "order",
            entity_id: this.props.order_id,
            payment_method: this.state.paymentType?.key
        }).then((res) => {
            this.setState({
                recievedPaymentLink: this.state.response.data.data.url,
                showLoader: false,
                subscribeEnachDialog: false
            })
            this.showMsg('Enach subscription initiated !', "success")
            this.getTransactionData()
        })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    onClickCreateOneTimeLink = () => {
        this.payment('', 'paymentLink', {
            is_recurring: false,
            is_monthly: true,
            entity: "order",
            entity_id: this.props.order_id,
            // payment_method: this.state.paymentType?.key
        }).then((res) => {
            this.setState({
                showLoader: false,
                oneTimePaymentLinkDialog: false
            });
            this.showMsg('Payment link created successfully !', "success")
            this.getTransactionData()
        })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }


    onClickOneTimePayment = () => {
        this.setState({
            oneTimePaymentLinkDialog: true
        })
    }
    onClickRetryPayment = () => {
        this.payment(this.state.transactionId, 'retryPayment').then((res) => {
            this.showMsg('Retry Payment successful !', "success")
            this.getTransactionData()
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    onClickInitiateRefund = (e) => {
        this.payment('', 'initiateRefund', {
            is_monthly: this.state.isMonthly,
            amount: this.state.transactionAmount,
            payment_id: this.state.transactionId
        }).then((res) => {
            this.setState({
                showLoader: false,
                initiateRefundDialog: false
            });
            this.showMsg(this.state.response.data.data.message, "success")
            this.getTransactionData()
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }

    async payment(id, type, payload) {
        this.setState({
            showLoader: true
        });
        if (type === 'retryPayment') {
            const response = await AxiosReactClient.getRequest('transactions/' + id + '/retry');
            // const json = await response.json();
            this.setState({response: response});
        }
        if (type === 'paymentLink') {
            const response = await AxiosReactClient.postRequest('payments/link', payload)
            // const json = await response.json();
            this.setState({response: response});
        }
        if (type === 'initiateRefund') {
            const response = await AxiosReactClient.postRequest('transactions/refund', payload)
            // const json = await response.json();
            this.setState({response: response});
        }
        if (type === 'addPayment') {

            const response = await AxiosReactClient.postRequest('orders/' + id + '/manual_payment', payload)
            // const json = await response.json();
            this.setState({response: response});
        }
    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };

    copyToClipboard = (text) => {
        let input = document.createElement('textarea');
        input.innerHTML = text;
        document.body.appendChild(input);
        input.select();
        let result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }
    isFutureDate = (dueDate) => {
        let dateObj = new Date();
        let month = dateObj.getMonth() + 1;
        let day = String(dateObj.getDate()).padStart(2, '0');
        let year = dateObj.getFullYear();
        let currentDate = year + '-' + month + '-' + day;
        return moment(currentDate).isBefore(dueDate)
    }

    oneTimePaymentButton = () => {
        let isDueDateInFuture = this.isFutureDate(this.props.transactionListData?.payments[0]?.due_date);
        if (!isDueDateInFuture && this.props.transactionListData?.is_subscribed && !this.props.transactionListData?.payments[0]?.is_successful) {
                return <div className={"m-auto"}>
                    <Button color="primary"
                            className="ml-auto" type="button"
                            outline
                            size="sm"
                            onClick={e => {
                                this.onClickOneTimePayment()
                            }}
                    >
                        Send Payment Link</Button>
                </div>

        }
        if((this.props.transactionListData?.payments[0]?.is_successful === null || !this.props.transactionListData?.payments[0]?.is_successful) &&
        this.props.transactionListData?.payments[0]?.payment_url !== null ){
            return <div className={"text-center m-auto d-flex"}>
                <h5 className={"m-auto"}><a href={this.props.transactionListData?.subscription_url}
                       target={'_blank'}>{this.props.transactionListData?.payments[0]?.payment_url}</a>
                </h5>
                <i className="fa fa-clone fa-outline mr-2 cursor-pointer pl-2 m-auto" aria-hidden="true"
                   onClick={(e) => {
                       this.copyToClipboard(this.props.transactionListData?.payments[0]?.payment_url)
                   }}
                />
            </div>
        }
    }

    onClickAddPayment = () => {
        this.payment(this.props.order_id, 'addPayment', this.state.mannualPaymentDetails).then((res) => {
            this.setState({
                showLoader: false,
                addPaymentDialog: false
            });
            this.showMsg('Payment added successfully', "success")
            this.getTransactionData()
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    onValueChange = (e, key) => {
        let paymentDetails = this.state.mannualPaymentDetails;
        paymentDetails[key] = e.target.value;
        paymentDetails = {...paymentDetails, amount: this.props.transactionListData?.premium}
        this.setState({
            mannualPaymentDetails: paymentDetails,
        });
    }

    capitalizeWords = (str) => {
        return str.split('_').join(' ').replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
    towDecimalValues = (num) => {
        return num ? num.toFixed(2) : 0;
    }

    render() {
        let newPremium = this.props.policyHolderDetailsData?.updated_details?.premium?.new_value;
        let oldPremium = this.props.policyHolderDetailsData?.updated_details?.premium?.old_value;
        return (
            <>
                {this.props.transactionListData?.is_monthly ? <>
                        <div className={"d-flex"}>
                            <div className={"transactions-monthly-box"}>
                                <h5 className={"text-gray"}>
                                    PREMIUM AMOUNT
                                </h5>
                                <h5 className={"text-dark"}>{this.props.transactionListData?.premium} /- Monthly</h5>
                                <h6 className={"text-gray fnt-12"}>
                                    Premium {
                                    (newPremium >
                                        oldPremium) ? (newPremium === oldPremium) ?
                                        (newPremium - oldPremium) :
                                        ' increased by ' + (this.towDecimalValues(newPremium - oldPremium)) + ' INR' :
                                        ' decreased by ' + (this.towDecimalValues(oldPremium - newPremium)) + ' INR'
                                }
                                </h6>
                            </div>
                            <div className={"transactions-monthly-box ml-2"}>
                                <div className={"d-flex"}>
                                    <h5 className={"text-gray"}>
                                        E MANDATE
                                    </h5>
                                    <div className={"mt--1"}>
                                        <Badge className="btn-icon btn-2 p-1 ml-2 "
                                               color={this.props.transactionListData?.is_subscribed && this.props.transactionListData?.payments[0]?.is_successful ?
                                                   "success" :
                                                   (this.props.transactionListData?.is_subscribed && !this.props.transactionListData?.payments[0]?.is_successful ?
                                                       "danger" :
                                                       "danger")
                                               }
                                        >
                                            {this.props.transactionListData?.is_subscribed && this.props.transactionListData?.payments[0]?.is_successful ?
                                                "SUBSCRIBED"
                                                :
                                                (this.props.transactionListData?.is_subscribed && !this.props.transactionListData?.payments[0]?.is_successful ?
                                                    "RESUBSCRIPTION REQUIRED" :
                                                    "NOT SUBSCRIBED")
                                            }


                                        </Badge>
                                    </div>
                                    <div className={"mt--1 ml-auto"}>
                                        {!this.props.policyHolderDetailsData?.is_cancelled &&
                                        <DropdownCustom

                                            trigger={
                                                <i className="fa fa-ellipsis-v cursor-pointer" aria-hidden="true"
                                                    // onClick={(e)=>{
                                                    //     this.onEnachOptionsClick()
                                                    // }}
                                                />
                                            }
                                            options={this.props.transactionListData?.is_subscribed && this.props.transactionListData?.payments[0]?.is_successful ?
                                                this.optionsSubscribed :
                                                ((!this.props.transactionListData?.is_subscribed && this.props.transactionListData?.subscription_url !== null) ?
                                                    this.copyOptions :
                                                    this.optionsNotSubscribed)
                                            }
                                            position={'top right'}
                                            onClickOption={(e, {value}) => {
                                                // console.log(e);
                                                this.onDropdownOptionsClick(e, {value}, {}, this.props.transactionListData?.subscription_url)
                                            }}
                                            value={this.state.dropDownValue}
                                        />
                                        }
                                    </div>
                                </div>
                                {this.props.transactionListData?.subscription_url === '' ?
                                    <h5 className={"text-dark mt-2"}>{this.props.transactionListData?.next_debit_date}</h5>
                                    :
                                    <h5 className={"text-primary font-weight-400 mt-2"}>
                                        <a href={this.props.transactionListData?.subscription_url}
                                           target={'_blank'}>{this.props.transactionListData?.subscription_url}</a>
                                    </h5>
                                }

                            </div>

                            <div className={"transactions-monthly-box ml-2"}>
                                <h5 className={"text-gray"}>
                                    RENEWAL DATE
                                </h5>
                                <h5 className={"text-dark"}>{this.props.transactionListData?.next_debit_date}</h5>
                            </div>


                        </div>

                    </>
                    :
                    <>
                        <div className={"d-flex"}>
                            <div className={"transactions-annual-box"}>
                                <h5 className={"text-gray"}>
                                    PREMIUM AMOUNT
                                </h5>
                                <h5 className={"text-dark"}>{this.props.transactionListData?.premium} /- Annual</h5>
                                <h6 className={"text-gray fnt-12"}>
                                    Premium {
                                    (newPremium >
                                        oldPremium) ? (newPremium === oldPremium) ?
                                        (newPremium - oldPremium) :
                                        ' increased by ' + (this.towDecimalValues(newPremium - oldPremium)) + ' INR' :
                                        ' decreased by ' + (this.towDecimalValues(oldPremium - newPremium)) + ' INR'
                                }
                                </h6>
                            </div>
                            <div className={"transactions-annual-box ml-2"}>
                                <h5 className={"text-gray"}>
                                    RENEWAL DATE
                                </h5>
                                <h5 className={"text-dark"}>{this.props.transactionListData?.next_debit_date}</h5>
                            </div>
                        </div>
                    </>
                }
                <hr className={"p-0 m-0 mt-4"}/>

                <div className={"d-flex justify-content-between my-4"}>
                    <h2 className={"text-light"}>
                        TRANSACTION HISTORY
                    </h2>
                    <div className={"d-flex ml-auto justify-content-end"}>

                        {this.oneTimePaymentButton()}
                        {!this.props.policyHolderDetailsData?.is_cancelled &&
                        <div className={"m-auto pl-4"}>
                            {this.props.transactionListData?.is_monthly &&
                            <Button color="primary"
                                    outline
                                    className="ml-auto" type="button"
                                    size="sm"
                                    onClick={e => {
                                        this.setState({
                                            addPaymentDialog: true
                                        })
                                    }}
                            >
                                Add Payment</Button>
                            }
                        </div>
                        }
                    </div>
                </div>

                <div className="table-responsive mh-400">
                    <Table className="align-items-center table-flush" hover>
                        <thead className="thead-light">
                        <tr>
                            <th>TRANSACTION STATUS</th>
                            <th>AMOUNT</th>
                            <th>GATEWAY ID</th>
                            <th>GATEWAY DATE</th>
                            <th>PAYMENT ID</th>
                            <th>PAYMENT MODE</th>
                            <th className="text-right"/>
                        </tr>
                        </thead>
                        <tbody>

                        {!this.props.transactionListLoading && this.props.transactionListData && this.props.transactionListData.payments.map((transaction, index) => {
                            return <tr key={index + 'dfjhjcf'}>
                                <td className="table-user">
                                    {transaction.refund_status !== null ? transaction.refund_status : (transaction.is_successful ?
                                        <span
                                            className={"text-success"}> Debited</span> : (!transaction.is_successful ?
                                            <span className={"text-danger"}>Failed</span> :
                                            <span className={"text-primary"}>In Process</span>))}
                                </td>
                                <td>
                                    {transaction.amount}
                                </td>
                                <td>
                                    {transaction.payment_id}
                                </td>
                                <td>
                                    {transaction.date}
                                </td>
                                <td>
                                    {transaction.id}
                                </td>
                                <td className="text-right">
                                    {transaction.method}
                                </td>
                                <td>
                                    {!this.props.policyHolderDetailsData?.is_cancelled &&
                                    <DropdownCustom
                                        trigger={
                                            <i className="fa fa-ellipsis-v cursor-pointer" aria-hidden="true"/>
                                        }
                                        options={(!this.props.transactionListData?.is_monthly) && transaction.is_successful ?
                                            this.annualSuccessfulPaymentOptions :
                                            ((this.props.transactionListData?.is_subscribed || this.props.transactionListData?.is_monthly) && transaction.is_successful ?
                                                this.monthlySuccessfulPaymentOptions :
                                                (!transaction.is_successful ?
                                                        this.failedPaymentOptions : ""
                                                ))

                                        }
                                        position={'top right'}
                                        onClickOption={(e, {value}) => {
                                            this.onDropdownOptionsClick(e, {value}, {
                                                id: transaction.id,
                                                amount: transaction.amount,
                                                is_monthly: this.props.transactionListData?.is_monthly
                                            })
                                            this.setState({
                                                paymentIndex: index
                                            })
                                        }}
                                        value={this.state.dropDownValue}
                                    />
                                    }
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </div>

                {/*----------Modals Start--------------*/}

                <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={this.state.subscribeEnachDialog}
                    toggle={() => {
                        this.setState({
                            subscribeEnachDialog: !this.state.subscribeEnachDialog
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>Select Payment Method</h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                subscribeEnachDialog: false
                            })
                        }}/>
                    </div>
                    <hr className={"text-dark m-0 p-0"}/>

                    <div className="modal-body text-left">
                        {this.paymentMethod.map((method, index) => {
                            return <div className="custom-control custom-radio mb-3" key={index + 'dfjcf'}>
                                <input type="radio" id={"customRadio" + index} name="customRadio"
                                       className="custom-control-input"
                                       value={method.value}
                                    // checked={this.state.paymentType === method.value}
                                       onChange={this.onMethodChanged}
                                />
                                <label className="custom-control-label" htmlFor={"customRadio" + index}>
                                    {method.value}
                                </label>
                            </div>
                        })}
                    </div>
                    <div className="modal-footer date-modal-footer ">

                        <Button color="primary"
                                className="ml-auto" type="button"
                                size="sm"
                                onClick={e => {
                                    this.onClickSubscribeEnach()
                                }}
                        >
                            Subscribe</Button>
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={this.state.oneTimePaymentLinkDialog}
                    toggle={() => {
                        this.setState({
                            oneTimePaymentLinkDialog: !this.state.oneTimePaymentLinkDialog
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>
                            One Time Payment Link
                        </h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                oneTimePaymentLinkDialog: false
                            })
                        }}/>
                    </div>
                    <hr className={"text-dark m-0 p-0"}/>

                    <div className="modal-body text-left">
                        {(this.props.transactionListData?.payments[0]?.is_successful === null || !this.props.transactionListData?.payments[0]?.is_successful) &&
                        this.props.transactionListData?.payments[0]?.payment_url !== null ?
                            <div className={"text-center d-flex justify-content-around"}>
                                <h5><a href={this.props.transactionListData?.subscription_url}
                                       target={'_blank'}>{this.props.transactionListData?.payments[0]?.payment_url}</a>
                                </h5>
                                <i className="fa fa-clone fa-outline mr-2 cursor-pointer" aria-hidden="true"
                                   onClick={(e) => {
                                       this.copyToClipboard(this.props.transactionListData?.payments[0]?.payment_url)
                                   }}
                                />
                            </div>
                            :
                            <div className={"text-center d-flex justify-content-around"}>
                                <h5> Do you want to create Payment Link</h5>
                            </div>
                        }
                    </div>
                    <div className="modal-footer date-modal-footer ">
                        {(this.props.transactionListData?.payments[0]?.is_successful === null ||!this.props.transactionListData?.payments[0]?.is_successful) &&
                        this.props.transactionListData?.payments[0]?.payment_url === null &&
                        <Button color="primary"
                                className="ml-auto" type="button"
                                size="sm"
                                onClick={e => {
                                    this.onClickCreateOneTimeLink()
                                }}
                        >
                            Create Payment link</Button>
                        }
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={this.state.retryPaymentDialog}
                    toggle={() => {
                        this.setState({
                            retryPaymentDialog: !this.state.retryPaymentDialog
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>Select Payment Method</h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                retryPaymentDialog: false
                            })
                        }}/>
                    </div>
                    <hr className={"text-dark m-0 p-0"}/>

                    <div className="modal-body text-left">
                        {this.paymentMethod.map((method, index) => {
                            return <div className="custom-control custom-radio mb-3" key={index + 'dfjcdff'}>
                                <input type="radio" id={"customRadio" + index} name="customRadio"
                                       className="custom-control-input"
                                       value={method.value}
                                    // checked={this.state.paymentType === method.value}
                                       onChange={this.onMethodChanged}
                                />
                                <label className="custom-control-label" htmlFor={"customRadio" + index}>
                                    {method.value}
                                </label>
                            </div>
                        })}
                    </div>
                    <div className="modal-footer date-modal-footer ">

                        <Button color="primary"
                                className="ml-auto" type="button"
                                size="sm"
                                onClick={e => {
                                    this.onClickRetryPayment()
                                }}
                        >
                            Retry</Button>
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={this.state.initiateRefundDialog}
                    toggle={() => {
                        this.setState({
                            initiateRefundDialog: !this.state.initiateRefundDialog
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>Refund</h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                initiateRefundDialog: false
                            })
                        }}/>
                    </div>
                    <hr className={"text-dark m-0 p-0"}/>

                    <div className="modal-body text-left">

                    </div>
                    <div className="modal-footer date-modal-footer ">

                        <Button color="primary"
                                className="ml-auto" type="button"
                                size="sm"
                                onClick={e => {
                                    this.onClickInitiateRefund(e)
                                }}
                        >
                            Initiate Refund</Button>
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    size="lg"
                    isOpen={this.state.addPaymentDialog}
                    toggle={() => {
                        this.setState({
                            addPaymentDialog: !this.state.addPaymentDialog
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>Add Payment Details</h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                addPaymentDialog: false
                            })
                        }}/>
                    </div>
                    <hr className={"text-dark m-0 p-0"}/>

                    <div className="modal-body text-left">

                        <Row>
                            <Col lg="4" md="4" xl="4">
                                <select className="form-control "
                                        id="exampleFormControlSelect1"
                                        size={"sm"}
                                        value={this.state.selectedPaymentType}
                                        onChange={(e) => {
                                            this.handlePaymentTypeChange(e, 'method')
                                        }}
                                >
                                    {this.mannualPaymentType.map((option, index) => {
                                        return <option value={option.id}
                                                       key={index + 'ghshd'}>{option.name}</option>
                                    })}

                                </select>
                            </Col>
                            {this.state.selectedPaymentType !== 'select' && <>
                                <Col lg="4" md="4" xl="4">
                                    <input type="text"
                                           className={"form-control bg-transparent border-1 text-dark"}
                                           placeholder={this.state.selectedPaymentType === 'cheque' ? 'Cheque Number' : 'UTR Number'}
                                           onChange={(e) => this.onValueChange(e, 'payment_id')}
                                    />
                                </Col>
                                <Col lg="4" md="4" xl="4">
                                    <input type="text"
                                           className={"form-control bg-transparent border-1 text-dark"}
                                           placeholder={this.state.selectedPaymentType === 'cheque' ? 'Cheque Amount' : 'Fund Transfer Amount'}
                                        // onChange={(e) => this.onValueChange(e, 'amount')}
                                           disabled={true}
                                           value={this.props.transactionListData?.premium}
                                    />
                                </Col>
                            </>
                            }
                        </Row>
                        {/*{this.state.selectedPaymentType !== 'select' && <>*/}
                        {/*    <Row className={"mt-4"}>*/}
                        {/*        <Col lg="4" md="4" xl="4">*/}
                        {/*            <h5 className={"m-0 text-grey font-weight-400"}>*/}
                        {/*                Policy Amount*/}
                        {/*            </h5>*/}
                        {/*            <h5 className={"m-0"}>*/}
                        {/*                {this.props.transactionListData?.premium}*/}
                        {/*            </h5>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</>*/}
                        {/*}*/}

                    </div>
                    <div className="modal-footer date-modal-footer ">
                        <Button color="primary"
                                className="ml-auto" type="button"
                                size="sm"
                                onClick={e => {
                                    this.onClickAddPayment(e)
                                }}
                        >
                            Done</Button>
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    size="lg"
                    isOpen={this.state.viewBankDetailsDialog}
                    toggle={() => {
                        this.setState({
                            viewBankDetailsDialog: !this.state.viewBankDetailsDialog
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>Details</h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                viewBankDetailsDialog: false
                            })
                        }}/>
                    </div>
                    <hr className={"text-dark m-0 p-0"}/>

                    <div className="modal-body text-left">
                        <div className={"d-flex justify-content-start flex-wrap"}>
                            {this.state.bankDetails === 'againstTransaction'
                                ?
                                this.props.transactionListData?.payments[this.state.paymentIndex]?.payment_token_details ?
                                    this.props.transactionListData?.payments[this.state.paymentIndex]?.payment_token_details && Object.values(this.props.transactionListData?.payments[this.state.paymentIndex]?.payment_token_details).map((values, index) => {
                                        let keys = Object.keys(this.props.transactionListData?.payments[this.state.paymentIndex]?.payment_token_details)[index];
                                        return keys !== 'flows' &&
                                            <Col lg={"4"} md={"4"} sm={"4"} key={index + 'adfghd'} className={"mx-4"}>
                                                <h5 className={"font-weight-bold m-0"}>
                                                    {this.capitalizeWords(keys)}
                                                </h5>
                                                <h5 className={"font-weight-400 m-0 mb-2"}>
                                                    {values}
                                                </h5>
                                            </Col>
                                    })
                                    :
                                    <h5 className={"text-center"}>
                                        No Data Available
                                    </h5>
                                :
                                this.props.policyHolderDetailsData?.payment_token_details ?
                                    this.props.policyHolderDetailsData?.payment_token_details && Object.values(this.props.policyHolderDetailsData?.payment_token_details).map((values, index) => {
                                        let keys = Object.keys(this.props.policyHolderDetailsData.payment_token_details)[index];
                                        return keys !== 'flows' &&
                                            <Col lg={"4"} md={"4"} sm={"4"} key={index + 'adfghd'} className={"mx-4"}>
                                                <h5 className={"font-weight-bold m-0"}>
                                                    {this.capitalizeWords(keys)}
                                                </h5>
                                                <h5 className={"font-weight-400 m-0 mb-2"}>
                                                    {values}
                                                </h5>
                                            </Col>
                                    })
                                    :
                                    <h5 className={"text-center"}>
                                        No Data Available
                                    </h5>
                            }
                        </div>
                    </div>
                    <div className="modal-footer date-modal-footer ">

                        <Button color="primary"
                                className="ml-auto" type="button"
                                size="sm"
                                onClick={e => {
                                    this.setState({
                                        viewBankDetailsDialog: false
                                    })
                                }}
                        >
                            Close</Button>
                    </div>
                </Modal>

                {this.state.showLoader &&
                <Loader msg={"Please Wait..."}/>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        transactionListLoading: state.policies.transactionListLoading,
        transactionListData: state.policies.transactionListData,
        policyHolderDetailsLoading: state.policies.policyHolderDetailsLoading,
        policyHolderDetailsData: state.policies.policyHolderDetailsData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetTransactionList: (id) => dispatch(actions.getTransactionList(id)),
        onGetPolicyHolderDetails: (id) => dispatch(actions.getPolicyHolderDetails(id)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Transactions);