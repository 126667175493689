const claims_filter = {
  data: [
    {
      title: "Claim Initiated",
      value: 8,
    },
    {
      title: "Claim Submitted",
      value: 9,
    },
    {
      title: "Documents Provided",
      value: 17,
    },
    {
      title: "Documents Verified",
      value: 18,
    },
    {
      title: "Approved",
      value: 11,
    },
    {
      title: "Rejected",
      value: 12,
    },
    {
      title: "Settlement Initiated",
      value: 14,
    },
    {
      title: "Settlement Completed",
      value: 15,
    },
    {
      title: "Closed",
      value: 35,
    },
    {
      title: "Partially Approved",
      value: 43,
    },
    
  ],
};
export default claims_filter;
