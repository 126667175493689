import { Box, Grid, Modal, Typography } from "@material-ui/core";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import NoDataFound from "../../../components/reusableComp/NoDataFound";
import TopCard from "../../../components/reusableComp/TopCard";
import {
  convertToCommaSeperated,
  createPaginationArr,
  downloadExcel,
  exportTransactionFun,
  loadScript,
  priceStandardForm,
} from "../../../helpers/utils";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import PaginationComp from "../../../components/reusableComp/PaginationComp";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExportButton from "../../../components/reusableComp/ExportButton";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

const TransactionListing = () => {
  const { pathname } = useLocation();
  const value = pathname.split("/")[pathname.split("/").length - 1];

  const [handleToast, setHandleToast] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [transactionListing, setTransactionListing] = useState([]);
  const [getAmountDetails, setGetAmountDetails] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [showAmountError, setShowAmountError] = useState(false);
  const [paginationState, setPaginationState] = useState({
    start: 1,
    current: 1,
    end: 0,
    limit: 25,
    count: 0,
    arr: [],
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let tableHeader = [
    "Created At",
    "Previous Balance",
    "Amount (credit/debit)",
    "Current Amount (in ₹)",
    "Transaction Info",
    "Description",
  ];
  const arr = [
    {
      name: "Wallet Balance",
      count: `₹ ${convertToCommaSeperated(getAmountDetails?.wallet_balance)} `,
    },
  ];

  const openModalBtn = () => {
    handleOpen();
  };

  const handleCloseModalWhenSuccess = (type) => {
    if (type === "paymentVerify") {
      setHandleToast(false);
    } else {
      setPaymentStatus(false);
    }
    handleClose();
  };
  const exportBtn = () => {
    downloadExcel(
      exportTransactionFun(transactionListing),
      "Transaction Report.xlsx"
    );
  };

  const handleAmountChanges = (e) => {
    let { value } = e.target;
    if (
      value.includes("-") ||
      value.includes(".") ||
      value > 100000 ||
      value > 0
    ) {
      setShowAmountError(true);
    } else {
      setShowAmountError(false);
    }
    if (e.target.value) setAmount(e.target.value);
  };

  const fetchListingData = async () => {
    const query = {
      page: paginationState.current - 1,
    };
    try {
      const response = await AxiosReactClient.getRequest(
        `franchise/transactions`,
        query
      );
      setTransactionListing(response?.data?.data?.data);
      setPaginationState({
        ...paginationState,
        end: Math.ceil(
          response?.data?.data?.total_count / paginationState.limit
        ),
        total: response?.data?.data?.total_count,
        arr: createPaginationArr(
          Math.ceil(response?.data?.data?.total_count / paginationState.limit)
        ),
      });
    } catch (err) {}
  };

  const cbUpdateRazorPayPaymentResponse = async ({ success }, entity_id) => {
    if (success) {
      setPaymentStatus(true);
      fetchAmount();
      fetchListingData();
    } else {
      setIsFormSubmitted(false);
    }
  };
  let franchiseId = localStorage.getItem("franchiseId");
  const updateRazorPayPaymentResponse = async (
    { razorpay_payment_id, razorpay_signature, razorpay_order_id },
    entity_id = null
  ) => {
    try {
      const payload = {
        entity: "franchise",
        entity_id: franchiseId,
        razorpay_payment_id,
        razorpay_signature,
        razorpay_order_id,
        razorpay_subscription_id: "",
      };
      const response = await AxiosReactClient.postRequest(
        "razorpay/response",
        payload
      );
      cbUpdateRazorPayPaymentResponse(response.data, entity_id);
    } catch (error) {
      // cbError(
      //   error && error.response && error.response.data
      //     ? error.response.data
      //     : buildRazorpayResponseErrorMessage()
      // );
    }
  };
  const displayRazorpay = async (arg) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: arg.key,
      amount: arg.amount.toString(),
      currency: "INR",
      description: "Franchise Amount",
      order_id: arg.rp_order_id,
      handler: async function (response) {
        await updateRazorPayPaymentResponse(response);
      },
      prefill: {
        name: getAmountDetails?.name,
        email: getAmountDetails?.email,
        contact: getAmountDetails?.mobile_number,
      },
      notes: {
        name: getAmountDetails?.name,
        contact: getAmountDetails?.mobile_number,
      },
      modal: {
        ondismiss: function () {
          setIsFormSubmitted(false);
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  const handlePayBtn = async () => {
    if (1 === 2) {
      try {
        const response = await AxiosReactClient.postRequest(
          `initiateRazorpay`,
          {
            amount: amount,
            entity: "franchise",
            entity_id: getAmountDetails?.id,
          }
        );
        if (response) {
          displayRazorpay(response?.data?.data);
        } else {
        }
      } catch (err) {}
    } else {
      displayPayU();
    }
  };

  const displayPayU = async () => {
    try {
      const payload = {
        amount: parseInt(amount),
        entity: "franchise",
        entity_id: getAmountDetails?.id,
      };
      const response = await AxiosReactClient.postRequest(
        `initiatePayu`,
        payload
      );
      if (response) {
        const paymentData = {
          key: response.data?.data.key,
          hash: response.data?.data.hash,
          txnid: response.data?.data.txnid,
          amount: response.data?.data.amount,
          firstname: response.data?.data.firstname,
          email: response.data?.data.email,
          phone: response.data?.data.phone,
          productinfo: response.data?.data.productinfo,
          surl: response.data?.data.surl,
          furl: response.data?.data.furl,
          custom_note: response.data?.data.customNote,
        };

        const form = document.createElement("form");
        form.method = "POST";
        form.action = response.data?.data.action;

        Object.keys(paymentData).forEach((key) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = paymentData[key];
          form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAmount = async () => {
    try {
      const response = await AxiosReactClient.getRequest(`franchise/balance`);
      setGetAmountDetails(response?.data?.data?.data);
    } catch (err) {}
  };
  const removeToast = () => {
    setTimeout(() => {
      setHandleToast({
        ...handleToast,
        isLoading: false,
      });
    }, 2000);
  };
  useEffect(() => {
    const verifyPayment = async () => {
      const query = {
        txnid: value,
      };
      try {
        const response = await AxiosReactClient.postRequest(
          `payu/verify-payment`,
          query
        );
        if (response?.status === 200) {
          // setPaymentStatus(true);
          setAmount(response?.data?.data?.amount);
          setMessage(response?.data?.data?.message);
          fetchAmount();
          fetchListingData();
          setHandleToast(true);
        } else {
          // setPaymentStatus(false);
          setHandleToast(false);
        }
      } catch (err) {}
    };
    if (value !== "wallet") {
      verifyPayment();
    }
  }, [value]);

  useEffect(() => {
    fetchAmount();
    fetchListingData();
  }, [paginationState.current]);

  return (
    <>
      <Fragment>
        <Modal open={handleToast}>
          <Box sx={style}>
            <Typography variant="h5">{message}</Typography>
            <Button
              type="button"
              onClick={() => handleCloseModalWhenSuccess("paymentVerify")}
              color="primary"
              style={{ marginTop: "2rem" }}
            >
              Ok
            </Button>
          </Box>
        </Modal>
        <Container fluid>
          <CardHeader className="border-0 px-0 bg-transparent">
            <Row className="align-items-center py-4">
              <Col xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary h5 font-weight-400"
                      onClick={(e) => e.preventDefault()}
                    >
                      Wallet
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="text-right" xs="6"></Col>
            </Row>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={4}>
                {arr.map((cur, id) => (
                  <TopCard
                    title={cur.name}
                    count={cur.count}
                    data={cur.date}
                    key={id}
                  />
                ))}
                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  style={{ height: "2rem", position: "relative", top: "90px" }}
                  onClick={openModalBtn}
                >
                  Add Funds
                </Button>
              </Grid>
            </Box>
            <Box
              style={{
                display: "flex",
                marginTop: "1rem",
                marginBottom: "1rem",
                justifyContent: "flex-end",
              }}
            >
              <ExportButton url="franchise/transactions" />
            </Box>
            {/* <Box className="mb-3 mt-6 mx-2" style={{ float: "right" }}>
            <input style={{ width: "50%", visibility: "hidden" }} />
            <i style={{ visibility: "hidden" }}></i>
            <Button
              color="primary"
              size="sm"
              type="button"
              onClick={exportBtn}
              style={{ float: "right" }}
              disabled={!transactionListing?.length}
            >
              Export
            </Button>
          </Box> */}

            <Card className="mb-6">
              <CardBody className="px-0 py-0">
                <div className=" table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        {tableHeader.map((cur, id) => (
                          <th key={id}>{cur}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="all_tabl_heigth">
                      {/* {transactionListing?.length === 0 && (
                  <tr>
                    <td colSpan="8">
                      <Spinner></Spinner>
                    </td>
                  </tr>
                )} */}
                      {transactionListing?.length === 0 && <NoDataFound />}
                      {transactionListing?.length > 0 &&
                        transactionListing.map((cur, id) => (
                          <tr key={id}>
                            <td className="table-user">{cur?.created_at}</td>
                            <td className="table-user">
                              {priceStandardForm(cur?.previous_balance)}
                            </td>
                            <td className="table-user">
                              {cur?.type === "credit" ? (
                                <>
                                  <ArrowDropUpIcon />
                                  {priceStandardForm(cur?.amount)}
                                </>
                              ) : cur?.type === "failure" ? (
                                <>
                                  <FiberManualRecordIcon
                                    sx={{ color: "white" }}
                                  />
                                  {priceStandardForm(cur?.amount)}
                                </>
                              ) : (
                                <>
                                  <ArrowDropDownIcon />
                                  {priceStandardForm(cur?.amount)}
                                </>
                              )}
                            </td>
                            <td className="table-user">
                              {priceStandardForm(cur?.current_balance)}
                            </td>
                            <td>
                              <ul style={{ marginLeft: "-2rem" }}>
                                {cur?.transactionInfo
                                  ? Object.entries(cur.transactionInfo)?.map(
                                      (item, i) => {
                                        return (
                                          <li
                                            key={i}
                                          >{`${item[0]} : ${item[1]}`}</li>
                                        );
                                      }
                                    )
                                  : "NA"}
                              </ul>
                            </td>
                            <td className="table-user">{cur?.description}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              {/* pagination logic  */}
              {transactionListing?.length > 0 && <></>}
              {transactionListing?.length > 0 && (
                <PaginationComp {...{ paginationState, setPaginationState }} />
              )}
            </Card>
          </CardHeader>
        </Container>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {paymentStatus ? (
            <Box sx={style}>
              <Typography variant="h5">
                ₹{amount} has been added to your wallet successfully.
              </Typography>
              <Button
                type="button"
                onClick={handleCloseModalWhenSuccess}
                color="primary"
                style={{ marginTop: "2rem" }}
              >
                Ok
              </Button>
            </Box>
          ) : (
            <Box sx={style}>
              <Box className="d-flex justify-content-between">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Add Funds
                </Typography>
                <CloseSharpIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <FormGroup style={{ marginTop: "2rem" }}>
                <label
                  className="form-control-label"
                  htmlFor="example3cols1Input"
                >
                  Amount
                </label>
                <Input
                  id="example3cols1Input"
                  placeholder="Enter the amount"
                  type="number"
                  min={0}
                  max={100000}
                  onChange={(e) => handleAmountChanges(e)}
                />
                {showAmountError && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Enter the correct amount (1 to 100000)
                  </p>
                )}

                <Button
                  type="button"
                  onClick={handlePayBtn}
                  color="primary"
                  disabled={showAmountError ? false : true}
                  style={{ marginTop: "2rem" }}
                >
                  Submit
                </Button>
              </FormGroup>
            </Box>
          )}
        </Modal>
      </Fragment>
    </>
  );
};

export default TransactionListing;
