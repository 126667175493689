import React, { useContext, useReducer, useEffect, useState } from "react";
import { Button, Modal, Row, Col } from "reactstrap";
import DealerContext from "../../../context/dealerContext";
import DealerReducer from "../../../reducers/dealerReducer";
import useApi from "../../../hooks/useApi";
import { ReducerUtils } from "../../../constants/reducers";
import {
  buildDocumentTypeList,
  checkDocumentExistense,
  getUniqueListBy,
  redirectWithBlank,
  syncDocumentTypeList,
  toBase64,
} from "../../../helpers/utils";
import { Documents } from "../../../data/documents";
import DocumentUpload from "./DocumentUpload";
import AxiosRestClient from "../../../utilities/AxiosRestClient";

const DocumentModal = ({ dealerId, isActiveForSell, isModalOpen, toggleModal }) => {
  const initialReducerState = useContext(DealerContext);
  const [state, dispatch] = useReducer(DealerReducer, initialReducerState);
  const [agentDetails, setAgentDetails] = useState(null);
  const [photo, setPhoto] = useState({
    pancard: "",
    gst: "",
    bank: ""
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [documentTypes, setDocumentTypes] = useState(buildDocumentTypeList());
  const details = useApi(`dealers/${dealerId}`);

  useEffect(() => {
    if (details) {
      const { dealershipDetails } = details;

      dealershipDetails.documents = getUniqueListBy(
        dealershipDetails.documents,
        "document_type_id"
      );

      dispatchEvent(ReducerUtils.agent.details, {
        ...details,
        dealershipDetails,
      });

      setDocumentTypes([
        ...syncDocumentTypeList(
          [...buildDocumentTypeList()],
          checkDocumentExistense(dealershipDetails.documents)
        ),
      ]);

      setAgentDetails({ ...details, dealershipDetails });
    }
  }, [details]);

  const dispatchEvent = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const photoChangeHandler = (keyname, file) => {
    setPhoto({
      ...photo,
      [keyname]: file,
    });
  };

  const buildSubmitRequestPayload = () => {
    return {
      entity_id: dealerId,
      entity_name: "dealer",
    };
  };

  const submitDocument = async (payload, keyname) => {
    await AxiosRestClient.postRequest(`v2/documents`, {
      ...buildSubmitRequestPayload(),
      ...payload,
      pages: [
        {
          data: await toBase64(photo[keyname]),
        },
      ],
    });
  };

  const submitBankDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_BANK_DETAILS_PROOF",
      },
      "bank"
    );
  };

  const submitPancardDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_PAN",
      },
      "pancard"
    );
  };

  const submitGstCertificateDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_GST_CERTIFICATE",
      },
      "gst"
    );
  };
  
  const submitDocumentHandler = async () => {
    setIsFormSubmitted(true);

    if (photo.bank) {
      await submitBankDocument();
    }
    if (photo.pancard) {
      await submitPancardDocument();
    }
    if (photo.gst) {
      await submitGstCertificateDocument();
    }

    setIsFormSubmitted(false);
    toggleModal("isDocumentModalOpen", false);
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isModalOpen}
      toggle={() => toggleModal("isDocumentModalOpen", false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h4>KYC Documents</h4>
      </div>
      <div className="modal-body ml-2 mr-4">
        {agentDetails &&
          agentDetails?.dealershipDetails?.documents?.length > 0 &&
          agentDetails?.dealershipDetails?.documents?.map((item, index) => {
            return item.pages.map((page, childIndex) => {
              return (
                childIndex === 0 && (
                  <Row key={`document_${index}`} className="mt-3">
                    <Col xs="7">
                      <label
                        className=" form-control-label mt-3"
                        htmlFor="dealer"
                      >
                        {Documents[item.document_type_id]?.name}
                      </label>
                    </Col>
                    <Col xs="5">
                      <Button
                        // color="primary"
                        // size="sm"
                        type="button"
                        onClick={() => redirectWithBlank(page.url)}
                        variant="outlined"
                        color="primary"
                        size="large"
                        style={{ borderRadius: "2vh" }}
                      >
                        <span className="btn-inner--icon mr-2">
                          <i className="fas fa-download" />
                        </span>
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                )
              );
            });
          })}
          
         {agentDetails && agentDetails?.dealershipDetails.is_active === true &&  agentDetails?.dealershipDetails.is_active_for_sell === true? (
          <>
             {(agentDetails && agentDetails?.dealershipDetails?.documents?.length === 0 || agentDetails && agentDetails?.dealershipDetails?.documents?.length >0) &&
          documentTypes.slice(1,3).map((item, index) => {
            return (
              <Row key={`document_${index}`} className="mt-3">
                <Col xs="7">
                  <label className=" form-control-label mt-3" htmlFor="dealer">
                    {Documents[item.document_type_id]?.name}
                  </label>
                </Col>
                <Col xs="5">
                  <DocumentUpload
                    title="Add Document"
                    photoChangeHandler={photoChangeHandler}
                    filename={item.filename}
                  />
                </Col>
              </Row>
            );
          })}
          </>
         ):null}
     
      </div>
      <div className="modal-footer date-modal-footer">
      {agentDetails && agentDetails?.dealershipDetails.is_active === true &&  agentDetails?.dealershipDetails.is_active_for_sell === true ? (
          <>
        {(agentDetails && agentDetails?.dealershipDetails?.documents?.length === 0 || agentDetails && agentDetails?.dealershipDetails?.documents?.length >0)  && (
          <Button
            color="primary"
            type="button"
            size="md"
            onClick={submitDocumentHandler}
            {...(isFormSubmitted && { disabled: true })}
          >
            Submit
          </Button>
        )}
              </>
         ):null}
        <Button
          color="secondary"
          type="button"
          size="md"
          onClick={() => toggleModal("isDocumentModalOpen", false)}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default DocumentModal;
