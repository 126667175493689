import React, { useState, useEffect } from "react";
import Select from "react-select";
import States from "./State";
import { Form, FormGroup } from "reactstrap";
import AxiosReactClient from "../../utilities/AxiosRestClient";

const StateDropdown = (props) => {
  const defaultState = { id: "all", value: "ALL", label: "All States" };
  const [selectedState, setSelectedState] = useState(defaultState);
  const [states, setStates] = useState([]);
  const [showState, setShowState] = useState(false);

  const handleStateSelect = (selectedOption) => {
    setSelectedState(selectedOption);
    props.onChange(selectedOption);
    // if (selectedOption.value !== "ALL") {
    //   window.location.reload();
    // }else if(selectedOption.value === "ALL"){
    //   window.location.reload();
    // }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 300,
    }),
    menu: (provided) => ({
      ...provided,
      width: 300,
    }),
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await AxiosReactClient.getRequest("v2/onboarded/states");
        const fetchedStates = response?.data?.data?.states || [];
        if (fetchedStates.length > 1){
          setShowState(true);
        }
        setStates(fetchedStates);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();

  }, []);
  return (
    <Form>
      {showState ? (
        <FormGroup>
          <Select
            id="stateDropdown"
            value={selectedState}
            onChange={handleStateSelect}
            options={[
              defaultState,
              ...states.map((state) => ({
                id: state.id,
                value: state.name,
                label: state.state_name,
              })),
            ]}
            styles={customStyles}
          />
        </FormGroup>
      ):null}
    </Form>
  );
};

export default StateDropdown;
