import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import Spinner from '../../../components/Spinner/Spinner';
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import { connect } from "react-redux";
import * as actions from '../../../Store/actions/index';
import { notify,notifyError } from "../../../helpers/utils";
import Notification from '../../../components/CustomUi/Notify/Notification';

class PasswordReset extends React.Component {
  
  state = {
    customStyles: {
      email:"",
      emailStete:null,
      password: "",
      passwordState: null,
      passwordConfirm: "",
      passwordConfirmState: null,

    },
   
  };
  
  submitHandler = () => {
    let newState = this.state.customStyles;
   
    if (newState.email === "") {
        newState.emailState = "invalid";
      notifyError('Enter email');
        // this.props.dispatchError({
        //   code: 0, message: 'Enter email'
        // })
        return
      } else {
        newState.emailState = "valid";
      }
    if (newState.password === "") {
      newState.passwordState = "invalid";
      notifyError('Enter password');
      // this.props.dispatchError({
      //   code: 0, message: 'Enter password'
      // })
      return
    } else {
      newState.passwordState = "valid";
    }


    if (newState.passwordConfirm === "") {
        newState.passwordConfirmState = "invalid";
        notifyError('Enter confirm password');
        // this.props.dispatchError({
        //   code: 0, message: 'Enter confirm password'
        // })
        return
      } else {
        newState.passwordConfirmState = "valid";
      }
    if (newState.passwordConfirm !== newState.password) {
        newState.passwordConfirmState = "invalid";
        notifyError('Confirm password mismatch');
        // this.props.dispatchError({
        //   code: 0, message: 'Confirm password mismatch'
        // })
        return
    } else {
        newState.passwordConfirmState = "valid";
    }
    this.setState({
      customStyles: newState
    });
    
    this.props.onResetPassword(this.state.customStyles.email, this.state.customStyles.password,this.state.customStyles.passwordConfirm,this.props.match.params.token);
  };
  keyPress = (e) => {
    if (e.keyCode === 13) {
      // put the login here
      this.submitHandler()
    }
  }

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;
    if (e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }
    this.setState({
      customStyles: newState
    });

  };


  render() {
    let form = <Form role="form" >
      <FormGroup
        className={classnames({
          focused: this.state.focusedEmail
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Please enter a Email"
            type="email"
            onFocus={() =>
              this.setState({ focusedEmail: true })
            }
            onBlur={() =>
              this.setState({ focusedEmail: false })
            }
            valid={
              this.state.customStyles.emailState === "valid"
            }
            invalid={
              this.state.customStyles.emailState ===
              "invalid"
            }
            onChange={e =>
              this.customStylesForm(e, "email")
            }
            onKeyDown={this.keyPress}
          />
        </InputGroup>
        <div className="invalid-feedback">
          Please enter a email.
      </div>
      </FormGroup>
      <FormGroup
        className={classnames({
          focused: this.state.focusedPassword
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Please enter a password"
            type="password"
            onFocus={() =>
              this.setState({ focusedPassword: true })
            }
            onBlur={() =>
              this.setState({ focusedPassword: false })
            }
            valid={
              this.state.customStyles.passwordState === "valid"
            }
            invalid={
              this.state.customStyles.passwordState ===
              "invalid"
            }
            onChange={e =>
              this.customStylesForm(e, "password")
            }
            onKeyDown={this.keyPress}
          />
        </InputGroup>
        <div className="invalid-feedback">
          Please enter a password.
      </div>
      </FormGroup>
      <FormGroup
        className={classnames({
          focused: this.state.focusedPasswordConfirm
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Please enter a confirm password."
            type="password"
            onFocus={() =>
              this.setState({ focusedConfirmPassword: true })
            }
            onBlur={() =>
              this.setState({ focusedConfirmPassword: false })
            }
            valid={
              this.state.customStyles.passwordConfirmState === "valid"
            }
            invalid={
              this.state.customStyles.passwordConfirmState ===
              "invalid"
            }
            onChange={e =>
              this.customStylesForm(e, "passwordConfirm")
            }
            onKeyDown={this.keyPress}
          />
        </InputGroup>
        <div className="invalid-feedback">
          Please enter a confirm password.
      </div>
      </FormGroup>
      
      <div className="text-center">
        <Button
          className="my-4"
          color="info"
          onClick={this.submitHandler}
          type="button">
          Submit
      </Button>
      </div>
    </Form>


    if (this.props.loading) {
      form = <Spinner />
    }
   
    return (
      <>
        <AuthHeader
          title="Garantie"
          lead="With great power comes great responsibility! Use it wisely"
        />
        <Container className="mt--8 pb-3">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <big>Reset your password {process.env.REACT_APP_TEST}</big>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  {form}
                </CardBody>
              </Card>
              <Notification />
             
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
    return {
      loading: state.auth.loading,
      error: state.auth.error
    };
  };
  const mapDispatchToProps = dispatch => {
    return {

    onResetPassword: (email, password,confirm_password,token) => dispatch(actions.resetPassword(email, password,confirm_password,token)),
    dispatchError: (messageObject) => dispatch(actions.authFail(messageObject)),
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

