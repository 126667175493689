import * as permissions from "../../../config/Casl/permissions.js";
import BillingReportListing from "./BillingReport/BillingReportListing.jsx";
import AddInvoices from "./Invoices/AddInvoices.jsx";
import InvoicesDetails from "./Invoices/InvoicesDetails.jsx";
import InvoicesListing from "./Invoices/InvoicesListing.jsx";
import WaterFallListing from "./WaterFallReport/WaterFallListing.jsx";
import FranchiseListing from "./franchise/FranchiseListing.jsx";

const reportRoute = {
  collapse: true,
  name: "Finance",
  icon: "ni ni-badge text-primary",
  state: "reportCollapse",
  permission: permissions.FINANCE.menu,
  subject: permissions.FINANCE.subject,
  views: [
    // {
    //   path: "/invoices",
    //   name: "Invoices",
    //   component: InvoicesListing,
    //   layout: "/admin",
    //   permission: permissions.USERS.store,
    //   subject: permissions.USERS.subject,
    // },
    {
      path: "/create-invoice",
      name: "Add Invoices",
      component: AddInvoices,
      layout: "/admin",
      invisible: true,
      permission: permissions.USERS.store,
      subject: permissions.USERS.subject,
    },
    // {
    //   path: "/invoice-details/:id",
    //   name: "Invoices View",
    //   component: InvoicesDetails,
    //   layout: "/admin",
    //   invisible: true,
    //   permission: permissions.USERS.store,
    //   subject: permissions.USERS.subject,
    // },
    // {
    //   path: "/invoices",
    //   name: "Invoices",
    //   component: InvoicesDetails,
    //   layout: "/admin",
    // },
    // {
    //   path: "/invoices/:id",
    //   name: "Invoices View",
    //   component: InvoicesDetails,
    //   layout: "/admin",
    // },
    {
      path: "/billing",
      name: "Billing Report",
      component: BillingReportListing,
      layout: "/admin",
      permission: permissions.FINANCE.billing_report,
      subject: permissions.FINANCE.subject,
    },
    {
      path: "/waterfall",
      name: "Waterfall Report",
      component: WaterFallListing,
      layout: "/admin",
      permission: permissions.FINANCE.waterfall_report,
      subject: permissions.FINANCE.subject,
    },
    {
      path: "/franchise",
      name: "Franchise",
      component: FranchiseListing,
      layout: "/admin",
      permission: permissions.PARTNERS.franchise,
      subject: permissions.PARTNERS.subject,
    },
  ],
};

export default reportRoute;
