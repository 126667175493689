import { Typography } from "@material-ui/core";
import React from "react";

const PaginationShowingData = ({ totalCount, startCount = 0 }) => {
  let currentPageForShowing = startCount * 25 + 1;
  let currentPlusForShowing =
    totalCount > (startCount + 1) * 25 ? (startCount + 1) * 25 : totalCount;
  return (
    <Typography className="mt-2">
      Showing &nbsp;
      {`${currentPageForShowing}-${
        totalCount > 25 ? currentPlusForShowing : totalCount
      } of ${totalCount}`}
    </Typography>
  );
};

export default PaginationShowingData;
