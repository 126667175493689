import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import { Badge, Button, Modal } from "reactstrap";

const AllDateFilter = ({ setActiveDateFilter, activeDateFilter }) => {
  const [modalState, setModalState] = useState(false);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [dateDialog, setDateDialog] = useState({
    startDate: "",
    endDate: "",
  });
  const dateArr = {
    Today: "today",
    Yesterday: "yesterday",
    "This Week": "thisWeek",
    "This Month": "thisMonth",
    "Last Week": "lastWeek",
    "Last Month": "lastMonth",
    // Custom: "custom",
  };
  const handleChips = (item) => {
    setActiveDateFilter(item);
    if (item === "custom") {
      openModal();
    }
  };
  const openModal = () => {
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };
  const handleSave = () => {
    setModalState(false);
  };
  const futureDisabled = (current) => {
    const today = moment();
    return current.isBefore(today);
  };
  const handleReactDatetimeChange = (who, date) => {
    if (
      date.startDate &&
      who === "endDate" &&
      new Date(date.startDate._d + "") > new Date(date._d + "")
    ) {
      setDate({
        ...date,
        startDate: date,
        endDate: date,
      });
    } else if (
      date.endDate &&
      who === "startDate" &&
      new Date(date.endDate._d + "") < new Date(date._d + "")
    ) {
      setDate({
        ...date,
        startDate: date,
        endDate: date,
      });
    } else {
      setDate({
        ...date,
        [who]: date,
      });
    }

    if (who === "startDate") {
      setDate({
        ...date,
        endDate: moment(date).add(6, "days"),
      });
      //   closeModal();
    }
    if (who === "endDate") {
      //   closeModal();
    }
  };
  const getClassNameReactDatetimeDays = (date) => {
    if (date.startDate && date.endDate) {
    }
    if (
      date.startDate &&
      date.endDate &&
      date.startDate._d + "" !== date.endDate._d + ""
    ) {
      if (
        new Date(date.endDate._d + "") > new Date(date._d + "") &&
        new Date(date.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (date.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (date.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };
  const isValidEndDate = (current) => {
    try {
      const startDate = moment(date.startDate).startOf("day");
      const endDate = moment(date.startDate).add(1, "year").subtract(1, "days");
      const currentEndDate = endDate.startOf("day");
      return (
        current.isSameOrAfter(startDate) &&
        current.isSameOrBefore(currentEndDate)
      );
    } catch (error) {
      console.log("error", error);
    }
  };
  const onReactDateFocused = (who) => {
    if (who === "startDate") {
      setDateDialog({
        startDate: false,
        endDate: true,
      });
    } else {
      setDateDialog({
        startDate: true,
        endDate: false,
      });
    }
  };
  return (
    <Grid style={{ display: "flex", justifyContent: "end" }}>
      {Object.keys(dateArr).map((cur, id) => (
        <Badge
          key={id}
          color={activeDateFilter === dateArr[cur] ? "primary" : "secondary"}
          pill
          onClick={() => handleChips(dateArr[cur])}
          style={{ marginLeft: "1rem" }}
        >
          {cur}
        </Badge>
      ))}
      {/* {modalState && (
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={openModal}
          toggle={closeModal}
        >
          <Grid style={{ padding: "2rem" }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <label className=" form-control-label">Start date</label>
                <ReactDatetime
                  open={dateDialog.startDate}
                  isValidDate={futureDisabled}
                  onFocus={(e) => onReactDateFocused("startDate")}
                  inputProps={{
                    placeholder: "Start Date",
                    readOnly: true,
                  }}
                  value={date.startDate}
                  timeFormat={false}
                  onChange={(e) => handleReactDatetimeChange("startDate", e)}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(currentDate);
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  dateFormat="DD/MM/YYYY"
                />
              </Box>
              <Box>
                <label className=" form-control-label">End date</label>
                <ReactDatetime
                  open={dateDialog.startDate}
                  isValidDate={isValidEndDate}
                  onFocus={(e) => onReactDateFocused("endDate")}
                  inputProps={{
                    placeholder: "End Date",
                    readOnly: true,
                  }}
                  value={date.endDate}
                  timeFormat={false}
                  onChange={(e) => handleReactDatetimeChange("endDate", e)}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(currentDate);
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  dateFormat="DD/MM/YYYY"
                />
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1.5rem",
              }}
            >
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={closeModal}
              >
                Close
              </Button>
              <Button
                color="primary"
                className="ml-auto"
                type="button"
                onClick={handleSave}
              >
                Apply
              </Button>
            </Box>
          </Grid>
        </Modal>
      )} */}
    </Grid>
  );
};

export default AllDateFilter;
