import { Box, Modal, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useHistory } from "react-router";
import PaginationComp from "../../../components/reusableComp/PaginationComp";
import {
  convertDateToString,
  convertToCommaSeperated,
  createPaginationArr,
  defaultStatusId,
  priceStandardForm,
} from "../../../helpers/utils";
import ExportButton from "../../../components/reusableComp/ExportButton";
import NoDataFound from "../../../components/reusableComp/NoDataFound";
import StateDropdown from "../../../components/StateDropdown/StateDropdown";

const ServiceCentres = () => {
  const history = useHistory();
  const [activeDateFilter, setActiveDateFilter] = useState("today");
  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [showError, setShowError] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [amount, setAmount] = React.useState(0);
  const [id, setId] = React.useState("");
  const [filter, setFilter] = useState({
    state: { id: "all", value: "ALL", label: "All States" },
  });
  const [field, setField] = useState({
    password: "",
    confirmPassword: "",
    userId: "",
    userType: "serviceCentre",
  });
  const [paginationState, setPaginationState] = useState({
    start: 1,
    current: 1,
    end: 0,
    limit: 25,
    count: 0,
    arr: [],
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
  };
  let tableHeader = [
    "Ref ID",
    "Name",
    "Mobile",
    "Email",
    "State",
    "City",
    "Total Claims",
    "Claimed Amount",
    "Approved Amount",
    "Address",
    "Onboarded Date",
    "Action",
  ];

  const handleOnChange = (selected) => {
    setFilter({
      ...filter,
      state: selected,
    });
  };
  useEffect(() => {
    if (field.password !== field.confirmPassword) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [field.confirmPassword]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setId("");
    setField({
      ...field,
      userId: "",
      password: "",
      confirmPassword: "",
    });
    setOpen(false);
  };
  const openModalBtn = (val) => {
    setId(val);
    handleOpen();
  };
  const handleChanges = (e, type) => {
    let { value } = e.target;
    setField({
      ...field,
      [type]: e.target.value,
      userId: id,
    });
  };

  const handleSubmitBtn = async () => {
    if (!showError) {
      try {
        const response = await AxiosReactClient.postRequest(
          `admin/reset-password`,
          field
        );
        if (response.data.success) {
          handleClose();
        } else {
        }
      } catch (err) {}
    }
  };
  const fetchListingData = async () => {
    const query = {
      page: paginationState.current - 1,
      limit: paginationState.limit,
      state_id:
        defaultStatusId() === "all" ? filter.state.id : defaultStatusId(),
    };
    try {
      const response = await AxiosReactClient.getRequest(
        `admin/service_centres`,
        query
      );
      setListingData(response?.data?.data?.service_centre);
      setPaginationState({
        ...paginationState,
        end: Math.ceil(
          response?.data?.data?.totalCount / paginationState.limit
        ),
        total: response?.data?.data?.totalCount,
        arr: createPaginationArr(
          Math.ceil(response?.data?.data?.totalCount / paginationState.limit)
        ),
      });
    } catch (err) {}
  };

  useEffect(() => {
    fetchListingData();
  }, [paginationState.current, filter.state, !paymentStatus]);
  let exportPayload = {
    state_id: defaultStatusId() === "all" ? filter.state.id : defaultStatusId(),
  };
  return (
    <>
      <Fragment>
        <Container fluid>
          <CardHeader className="border-0 px-0 bg-transparent ">
            <Row className="align-items-center py-4">
              <Col xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary h5 font-weight-400"
                      onClick={(e) => e.preventDefault()}
                    >
                      Service Center
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
            <Col
              className="pl-0 d-flex "
              style={{
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <StateDropdown
                onChange={(selectedOption) => handleOnChange(selectedOption)}
              />
              <ExportButton
                url="admin/service_centres"
                {...{ exportPayload }}
              />
            </Col>

            <Box></Box>
            <Card className="mb-6">
              <CardBody className="px-0 py-0">
                <div className=" table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        {tableHeader.map((cur, id) => (
                          <th key={id}>{cur}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="all_tabl_heigth">
                      {listingData?.length === 0 && <NoDataFound />}
                      {listingData?.length > 0 &&
                        listingData.map((dealer, id) => (
                          <tr key={id}>
                            <td>{dealer.id}</td>
                            <td>{dealer.name}</td>
                            <td>{dealer.mobile}</td>
                            <td>{dealer.email}</td>
                            <td>{dealer.state}</td>
                            <td>{dealer.city}</td>
                            <td>{dealer.total_claims}</td>
                            <td>{`₹${convertToCommaSeperated(
                              dealer.total_claimed_amount
                            )}`}</td>
                            <td>{`₹${convertToCommaSeperated(
                              dealer.total_approved_amount
                            )}`}</td>
                            <td>
                              <span
                                style={{
                                  width: "200px",
                                  display: "inline-block",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {dealer.address.address_line_1}
                              </span>
                            </td>
                            <td>
                              {convertDateToString(
                                dealer.created_at,
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td style={{ width: "10%" }}>
                              <Button
                                color={`btn-round btn-icon btn btn-primary btn-md btn-block1`}
                                size="sm"
                                type="button"
                                onClick={() => openModalBtn(dealer.id)}
                              >
                                Reset Password
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              {listingData?.length > 0 && (
                <PaginationComp
                  {...{ paginationState, setPaginationState, fetchListingData }}
                />
              )}
            </Card>
          </CardHeader>
        </Container>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box className="d-flex justify-content-between">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Reset Password
              </Typography>
              <CloseSharpIcon
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </Box>
            <FormGroup style={{ marginTop: "2rem" }}>
              <label className="form-control-label" htmlFor="password">
                Password
              </label>
              <Input
                id="password"
                placeholder="Enter the Password"
                onChange={(e) => handleChanges(e, "password")}
              />

              <label className="form-control-label" htmlFor="confirm_password">
                Confirm Password
              </label>
              <Input
                id="confirmPassword"
                placeholder="Enter the Confirm Password"
                onChange={(e) => handleChanges(e, "confirmPassword")}
              />
              {showError && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  Your password or confirm password is not matching
                </p>
              )}

              <Button
                type="button"
                onClick={handleSubmitBtn}
                color="primary"
                style={{ marginTop: "2rem" }}
              >
                Submit
              </Button>
            </FormGroup>
          </Box>
        </Modal>
      </Fragment>
    </>
  );
};

export default ServiceCentres;
