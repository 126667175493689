import React from "react";

class CustomDropdown extends React.Component {
  state = {
    hideDropdown: true,
    selectedFilter: this.props.defaultValue ?? "",
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (e) => {
    let classArray = [];
    if (e.path) {
      e.path.forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    } else {
      e.composedPath().forEach((element) => {
        if (typeof(element.className) === 'string') {
          classArray = classArray.concat(
            element.className && element.className?.split(" ")
          ); 
        } else {
          // console.log("Amit_code");
          // console.log(element.className);
          // classArray = classArray.concat(
          //   element.className && element.className.split(" ")
          // ); 
        }
      });
    }
    if (!classArray.includes("time-picker")) {
      this.setState({
        hideDropdown: true,
      });
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.selectedId === "" &&
      this.setState({
        selectedFilter: nextProps.selectedId,
      });
  }

  truncateText = (text, length) => {
    return text
      ? text.length > length
        ? text.substring(0, length) + "..."
        : text
      : "";
  };

  render() {
    return (
      <div className="d-inline position-relative">
        <div
          className="d-inline cursor-pointer hoverBg px-2 py-1"
          onClick={(e) => {
            this.setState({
              hideDropdown: false,
            });
          }}
        >
          <small>
            {" "}
            {this.props.name}:{" "}
            <span title={this.state.selectedFilter}>
              {this.truncateText(this.state.selectedFilter, 10)}
            </span>{" "}
            <i className="fa fa-angle-down ml-1" aria-hidden="true" />
          </small>
        </div>

        <div className="time-picker" hidden={this.state.hideDropdown}>
          {
            <>
              {" "}
              {this.props.options &&
                this.props.options.map((option, i) => (
                  <div
                    className={"text-left p-2"}
                    key={i}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        hideDropdown: true,
                        selectedFilter: option.name,
                      });
                      this.props.handleSelection(option.id);
                    }}
                  >
                    {" "}
                    {option.name}
                  </div>
                ))}{" "}
            </>
          }
        </div>
      </div>
    );
  }
}

export default CustomDropdown;
