import * as actionTypes from './actionTypes/partnerTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'

// START
export const getPartnerClaimListStart = () => {
    return {
        type: actionTypes.GET_PARTNER_CLAIM_LIST_START,
    };
};
export const getPartnerClaimCardsStart = () => {
    return {
        type: actionTypes.GET_PARTNER_CLAIM_CARDS_START,
    };
};
export const getPartnerClaimFilterListStart = () => {
    return {
        type: actionTypes.GET_PARTNER_CLAIM_FILTER_LIST_START,
    };
};


//SUCCESS
export const getPartnerClaimListSuccess = (data, count) => {
    return {
        type: actionTypes.GET_PARTNER_CLAIM_LIST_SUCCESS,
        data: data,
        count: count
    };
};
export const getPartnerClaimCardsSuccess = (data) => {
    return {
        type: actionTypes.GET_PARTNER_CLAIM_CARDS_SUCCESS,
        data: data
    };
};
export const getPartnerClaimFilterListSuccess = (data) => {
    return {
        type: actionTypes.GET_PARTNER_CLAIM_FILTER_LIST_SUCCESS,
        data: data
    };
};


//FAIL
export const getPartnerClaimListFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_CLAIM_LIST_FAIL,
        error: error
    };
};
export const getPartnerClaimCardsFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_CLAIM_CARDS_FAIL,
        error: error
    };
};
export const getPartnerClaimFilterListFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_CLAIM_FILTER_LIST_FAIL,
        error: error
    };
};

// ACTION
export const getPartnerClaimListData = (filter, params) => {
    return dispatch => {
        dispatch(getPartnerClaimListStart());
        AxiosReactClient.getRequest('partners/claim', params).then(response => {
            dispatch(getPartnerClaimListSuccess(response.data.data.claims, response.data.data.totalCount));
            console.log("esponse.data.data",response.data.data)
        })
            .catch(err => {
                if (err.response)
                    dispatch(getPartnerClaimListFail(err.response.data.error));
                else
                    dispatch(getPartnerClaimListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPartnerClaimCardsData = (params) => {
    return dispatch => {
        dispatch(getPartnerClaimCardsStart());
        AxiosReactClient.getRequest('partners/claim/cards', params).then(response => {
            dispatch(getPartnerClaimCardsSuccess(response.data.data.cards));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getPartnerClaimCardsFail(err.response.data.error));
                else
                    dispatch(getPartnerClaimCardsFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPartnerClaimFilterListData = () => {
    return dispatch => {
        dispatch(getPartnerClaimFilterListStart());
        AxiosReactClient.getRequest('filter/partner/list').then(response => {
            dispatch(getPartnerClaimFilterListSuccess(response.data.data.partner));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getPartnerClaimFilterListFail(err.response.data.error));
                else
                    dispatch(getPartnerClaimFilterListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};