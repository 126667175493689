import { Grid, Paper, Typography, styled } from "@material-ui/core";
import React from "react";
import { convertToCommaSeperated } from "../../helpers/utils";

const TopCard = ({ title, count, data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: "#fff",
    backgroundColor: "#3A63FC",
  }));
  return (
    <Grid item xs={3}>
      <Item>
        <Typography variant="h6" style={{ color: "#fff" }}>
          {title}
        </Typography>
        <Typography variant="h5" style={{ color: "#fff" }}>
          {count}
        </Typography>
        <Typography style={{ marginTop: "1rem" }}>{data}</Typography>
      </Item>
    </Grid>
  );
};

export default TopCard;
