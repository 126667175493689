import * as actionTypes from './actionTypes/partnerTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'

// START
export const getPartnerDealerListStart = () => {
    return {
        type: actionTypes.GET_PARTNER_DEALER_LIST_START,
    };
};
export const getPartnerDealerFilterListStart = () => {
    return {
        type: actionTypes.GET_PARTNER_DEALER_FILTER_LIST_START,
    };
};
export const getPartnerDealerCardsStart = () => {
    return {
        type: actionTypes.GET_PARTNER_DEALER_CARDS_START,
    };
};
export const postCreatePartnerStart = () => {
    return {
        type: actionTypes.POST_PARTNER_DEALER_CREATE_START,
    };
};

export const postCreatePartnerDashboardStart = () => {
    return {
        type: actionTypes.POST_PARTNER_DASHBOARD_CREATE_START,
    };
};

// SUCCESS
export const getPartnerDealerListSuccess = (data, count) => {
    return {
        type: actionTypes.GET_PARTNER_DEALER_LIST_SUCCESS,
        data: data,
        count: count,
    };
};
export const getPartnerDealerFilterListSuccess = (data) => {
    return {
        type: actionTypes.GET_PARTNER_DEALER_FILTER_LIST_SUCCESS,
        data: data
    };
};
export const getPartnerDealerCardsSuccess = (data) => {
    return {
        type: actionTypes.GET_PARTNER_DEALER_CARDS_SUCCESS,
        data: data,
    };
};
export const postCreatePartnerSuccess = (data) => {
    return {
        type: actionTypes.POST_PARTNER_DEALER_CREATE_SUCCESS,
        data: data,
    };
};

export const postCreatePartnerDashboardSuccess = (data) => {
    return {
        type: actionTypes.POST_PARTNER_DASHBOARD_CREATE_SUCCESS,
        data: data,
    };
};

// FAIL
export const getPartnerDealerListFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_DEALER_LIST_FAIL,
        error: error
    };
};
export const getPartnerDealerFilterListFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_DEALER_FILTER_LIST_FAIL,
        error: error
    };
};
export const getPartnerDealerCardsFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_DEALER_CARDS_FAIL,
        error: error
    };
};
export const postCreatePartnerFail = (error) => {
    return {
        type: actionTypes.POST_PARTNER_DEALER_CREATE_FAIL,
        error: error
    };
};

export const postCreatePartnerDashboardFail = (error) => {
    return {
        type: actionTypes.POST_PARTNER_DASHBOARD_CREATE_FAIL,
        error: error
    };
};

// ACTION
export const getPartnerDealerListData = (filter, params) => {
    return dispatch => {
        dispatch(getPartnerDealerListStart(filter));
        AxiosReactClient.getRequest('partners/dealer', params).then(response => {
                // console.log(response.data.data.dealers);
                dispatch(getPartnerDealerListSuccess(response.data.data.dealers, response.data.data.totalCount));
            })
            .catch(err => {
                // console.log(err);
                if (err.response)
                    dispatch(getPartnerDealerListFail(err.response.data.error));
                else
                    dispatch(getPartnerDealerListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPartnerDealerFilterListData = () => {
    return dispatch => {
        dispatch(getPartnerDealerFilterListStart());
        AxiosReactClient.getRequest('filter/partner/list').then(response => {
                dispatch(getPartnerDealerFilterListSuccess(response.data.data.partner));
            })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getPartnerDealerFilterListFail(err.response.data.error));
                else
                    dispatch(getPartnerDealerFilterListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPartnerDealerCardsData = (params) => {
    return dispatch => {
        dispatch(getPartnerDealerCardsStart());
        AxiosReactClient.getRequest('partners/dealer/cards', params).then(response => {
            // console.log(response.data.data.cards);
                dispatch(getPartnerDealerCardsSuccess(response.data.data.cards));
            })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getPartnerDealerCardsFail(err.response.data.error));
                else
                    dispatch(getPartnerDealerCardsFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};

export const postCreatePartner = (params) => {
    return (dispatch) => {
            dispatch(postCreatePartnerStart());
            AxiosReactClient.postRequest('partners/createPartner', params).then(response => {
                dispatch(postCreatePartnerSuccess(response.data));
                
            })
            
            .catch((err) => {
                console.log(err);
                if (err.response) dispatch(postCreatePartnerFail(err.response.data.error));
                else
                  dispatch(
                    postCreatePartnerFail({
                      code: 502,
                      message: err.message,
                    })
                  );
            });
    };
};

export const postCreatePartnerDashboard = (params) => {
    return (dispatch) => {
            dispatch(postCreatePartnerDashboardStart());
            AxiosReactClient.postRequest('partners/createDashboard', params).then(response => {
                dispatch(postCreatePartnerDashboardSuccess(response.data));
                
            })
            
            .catch((err) => {
                console.log(err);
                if (err.response) dispatch(postCreatePartnerDashboardFail(err.response.data.error));
                else
                  dispatch(
                    postCreatePartnerDashboardFail({
                      code: 502,
                      message: err.message,
                    })
                  );
            });
    };
};