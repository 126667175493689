import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../../Store/actions";
import CustomDropdown from "../../../components/Filters/CustomDropdown";
import Shimmer from "../../../components/CustomUi/Shimmer/Shimmer";
import Spinner from "../../../components/Spinner/Spinner";
import ReactDatetime from "react-datetime";
import StateDropdown from "../../../components/StateDropdown/StateDropdown";
import {
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import Can from "../../../config/Casl/Can";
import * as permissions from "../../../config/Casl/permissions";
import {
  buildPartnerDealersList,
  convertDateToString,
  convertToCommaSeperated,
  downloadExcel,
  defaultStatusId,
} from "../../../helpers/utils";
import { DealerTypes } from "../../../data/products";
import AxiosRestClient from "../../../utilities/AxiosRestClient";
import DocumentModal from "./DocumentModal";
import moment from "moment";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import PaginationShowingData from "../../../components/reusableComp/PaginationShowingData";

const orderIdSearch = /^[ a-zA-Z&0-9_-]*$/;

class DealersListing extends Component {
  previousMonthDate =
    new Date().getMonth() === 0
      ? new Date(new Date().getFullYear() - 1, 11)
      : new Date(new Date().getFullYear(), new Date().getMonth() - 1);
  constructor(props) {
    super(props);
    this.state = {
      hideFilters: false,
      searchText: "",
      view: "dealer-table",
      partnerId: "",
      hideProductDropdown: true,
      openDateDialog: false,
      openMonthDialog: false,
      cardFilter: "custom",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      selectedDate:
        new Date().getMonth() === 0
          ? new Date(new Date().getFullYear() - 1, 11)
          : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
      selectedCardsFilterDate: new Date(),
      dealerType: "ALL",
      isConfirmModalOpen: false,
      isDocumentModalOpen: false,
      isResetPasswordConfirmModalOpen: false,
      dealerId: null,
      isActive: false,
      isActiveForSell: false,
      buttonActionName: "active_inactive_request",
      state_id: defaultStatusId(),
    };
    props.onGetPartnerDealerCardsData(this.buildDefaultParams());
    this.getDealers();
  }
  pager = {
    pagerStart: 0,
    pagerEnd: 0,
    pageArray: [],
    limit: 25,
  };
  dealerTypes = [
    { id: "ALL", name: "ALL" },
    { id: "CREDIT", name: "COCO" },
    { id: "PREPAID_WALLET", name: "FOCO" },
    { id: "PAYG", name: "GT" },
  ];
  updatePagination = (total) => {
    this.pager.pageArray = [];
    this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
    for (let index = 0; index < this.pager.pagerEnd; index++) {
      this.pager.pageArray.push(index + 1);
    }
  };
  switchPage = (page) => {
    if (this.pager.pagerStart + 1 !== page) {
      this.pager.pagerStart = page - 1;
      this.getDealers();
    }
  };
  getDealers = (stateId) => {
    this.props.onGetPartnerDealerListData(
      {},
      {
        start: this.pager.pagerStart,
        partner_id: this.state.partnerId,
        month: this.state.selectedCardsFilterDate.getMonth() + 1,
        year: this.state.selectedCardsFilterDate.getFullYear(),
        invoicing_modal:
          this.state.dealerType != "ALL" ? this.state.dealerType : "",
        state_id: stateId ?? this.state.state_id,
      }
    );
  };
  isHidden = (page) => {
    var start = this.pager.pagerStart + 1;
    if (page > start) {
      if (page - start > 2 && start === 1) {
        return true;
      } else if (start !== 1 && page - start > 1) {
        return true;
      }
    } else {
      if (start - page > 2 && start === this.pager.pagerEnd) {
        return true;
      } else if (start !== this.pager.pagerEnd && start - page > 1) {
        return true;
      }
    }
    return false;
  };
  partnerChanged = (partnerId) => {
    this.pager.pagerStart = 0;
    this.setState(
      {
        partnerId: partnerId,
      },
      () => {
        this.getDealers();
      }
    );
  };
  dealerTypeChanged = (id) => {
    this.pager.pagerStart = 0;
    this.setState(
      {
        dealerType: id,
      },
      () => {
        this.getDealers();
      }
    );
  };
  componentDidMount() {
    this.props.onGetPartnerDealerFilterListData();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  refreshFilters = () => {
    this.pager.pagerStart = 0;
    this.setState(
      {
        partnerId: "",
        view: "dealer-table",
        searchText: "",
        dealerType: "ALL",
      },
      () => {
        this.getDealers();
      }
    );
  };
  onSearch = (value) => {
    if (!this.state.searchText.match(orderIdSearch)) {
      this.showMsg("Please, don't enter any special characters", "danger");
      return;
    }
    this.props.onGetPartnerDealerListData("", { dealer_search: value });
    this.setState({
      view: "table",
    });
  };
  onRefreshAllCards = (filter) => {
    this.pager.pagerStart = 0;
    this.setState({
      cardFilter: filter,
    });
    this.props.onGetPartnerDealerCardsData(this.buildDefaultParams());
  };
  handleClickOutside = (e) => {
    let classArray = [];
    if (e.path) {
      e.path.forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    } else {
      e.composedPath().forEach((element) => {
        if (typeof element.className === "string") {
          classArray = classArray.concat(
            element.className && element.className?.split(" ")
          );
        } else {
          // console.log("Amit_code");
          // console.log(element.className);
          // classArray = classArray.concat(
          //   element.className && element.className.split(" ")
          // );
        }
      });
    }
    if (!classArray.includes("reactDateTimeCustomClass")) {
      this.setState({
        openMonthDialog: false,
      });
    }
  };
  handleReactMonthFilterChange(e) {
    this.pager.pagerStart = 0;
    this.setState(
      {
        cardFilter: "custom",
        selectedCardsFilterDate: e._d,
      },
      () => {
        this.getDealers();
      }
    );
    this.props.onGetPartnerDealerCardsData({
      month: e._d.getMonth() + 1,
      year: e._d.getFullYear(),
    });
  }
  getMonthYear = (date) => {
    return (
      this.state.months[new Date(date).getMonth()] +
      " " +
      new Date(date).getFullYear()
    );
  };
  validDateMonthFilter = (current) => {
    const prev_oneyear = new Date();
    prev_oneyear.setFullYear(prev_oneyear.getFullYear() - 5);
    return new Date(current) >= prev_oneyear && new Date(current) <= new Date();
  };
  getTwoFixedPoint = (num) => {
    if (!num) {
      return 0;
    }
    return Math.ceil(num);
    var x = num * 1;
    return x.toFixed(2);
  };
  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type,
    });
  };
  buildDefaultParams = (stateId) => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const state_id = stateId ?? this.state.state_id;

    return { month, year, state_id };
  };
  exportList = () =>
    downloadExcel(
      buildPartnerDealersList(this.dealerList),
      "RetailerList.xlsx"
    );
  fetchAllExportData = (d1, d2) => {
    let payload = {
      export: 1,
      month: this.state.selectedCardsFilterDate.getMonth() + 1,
      year: this.state.selectedCardsFilterDate.getFullYear(),
      state_id: this.state.state_id,
    };
    let url = `partners/dealer`;
    AxiosReactClient.getRequest(url, payload)
      .then((res) => {
        this.setState({
          showLoader: false,
        });
        this.showMsg(`${res?.data?.data?.message}`, "success");
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };
  onClickHandler = () => this.props.history.push("/admin/dealers/add");
  handleButtonClick = ({ id, is_active, is_active_for_sell }) => {
    this.setState({
      dealerId: id,
      buttonActionName: is_active_for_sell
        ? "active_inactive_request"
        : "approve_request",
      isActive: is_active,
      isActiveForSell: is_active_for_sell,
      isConfirmModalOpen: true,
    });
  };
  handleUploadDocumentClick = ({ id }) => {
    this.setState({
      isDocumentModalOpen: true,
      dealerId: id,
    });
  };
  toggleModal = (key, value) => {
    this.setState({
      [key]: value,
    });
  };
  cbSuccess = (modalKey = "isConfirmModalOpen") => {
    this.getDealers();
    this.toggleModal(modalKey, false);
  };
  updateStatus = async (payload) => {
    await AxiosRestClient.putRequest(
      `dealers/${this.state.dealerId}/status`,
      payload
    );
    this.cbSuccess();
  };
  handleConfirmClick = (action = "") => {
    action === "yes"
      ? this.state.buttonActionName === "approve_request"
        ? this.updateStatus({ is_active_for_sell: true })
        : this.updateStatus({ is_active: !this.state.isActive })
      : this.toggleModal("isConfirmModalOpen", false);
  };
  handleResetPassword = async () => {
    await AxiosRestClient.putRequest(
      `dealers/${this.state.dealerId}/reset`,
      {}
    );

    this.cbSuccess("isResetPasswordConfirmModalOpen");
    this.showMsg(
      "New password has been sent to the dealer's email id.",
      "success"
    );
  };
  handleResetPasswordButtonClick = (dealer) => {
    this.setState({
      dealerId: dealer.id,
    });
    this.toggleModal("isResetPasswordConfirmModalOpen", true);
  };
  handleConfirmResetClick = (action = "") => {
    action === "yes"
      ? this.handleResetPassword()
      : this.toggleModal("isResetPasswordConfirmModalOpen", false);
  };
  handleOnChange = (selectedOption) => {
    this.props.onGetPartnerDealerCardsData(
      this.buildDefaultParams(selectedOption.id)
    );
    this.getDealers(selectedOption.id);
    this.setState({
      state_id: selectedOption.id,
    });
  };

  render() {
    let franchise = localStorage.getItem("franchise");
    this.dealerList = this.props.partnerDealerListData
      ? this.props.partnerDealerListData
      : [];
    this.dealerCards = this.props.partnerDealerCardsData
      ? this.props.partnerDealerCardsData
      : "";
    this.props.dealerTotalCount !== null &&
      this.updatePagination(this.props.dealerTotalCount);
    return (
      <>
        <Fragment>
          <Container fluid>
            <CardHeader className="border-0 px-0 bg-transparent ">
              <Row className="align-items-center py-4">
                <Col xs="6">
                  <Breadcrumb
                    className="d-none d-md-inline-block"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    {/* <BreadcrumbItem>
                      <a
                        href="#pablo"
                        className="text-primary"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem> */}
                    {/* <BreadcrumbItem>
                      <a
                        href="#pablo"
                        className="text-primary h5 font-weight-400"
                        onClick={(e) => e.preventDefault()}
                      >
                        Partners
                      </a>
                    </BreadcrumbItem> */}
                    <BreadcrumbItem>
                      <a
                        href="#pablo"
                        className="text-primary h5 font-weight-400"
                        onClick={(e) => e.preventDefault()}
                      >
                        Active Retailers
                      </a>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col className="text-right" xs="6"></Col>
              </Row>
              <Row>
                <Col xs="6">
                  <StateDropdown
                    onChange={(selectedOption) =>
                      this.handleOnChange(selectedOption)
                    }
                  />
                </Col>
                <Col className="text-right align-items-center" xs="6">
                  <div className="d-inline">
                    {/* <Badge
                    color={this.state.cardFilter === 'all' ? 'primary' : 'secondary'}
                    href="#pablo"
                    pill
                    onClick={e => {
                      e.preventDefault();
                      this.onRefreshAllCards('all')
                    }}
                  >
                    All</Badge> */}

                    <Badge
                      color={
                        this.state.cardFilter === "custom"
                          ? "primary"
                          : "secondary"
                      }
                      href="#pablo"
                      pill
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          openMonthDialog: true,
                        });
                      }}
                    >
                      {this.state.cardFilter === "custom"
                        ? this.getMonthYear(this.state.selectedCardsFilterDate)
                        : "Select Month"}
                    </Badge>
                    <ReactDatetime
                      className="d-inline cursor-pointer reactDateTimeCustomClass position-absolute rdtPicker-right-0"
                      open={this.state.openMonthDialog}
                      onFocus={(e) => {
                        this.setState({
                          openMonthDialog: true,
                        });
                      }}
                      onBlur={(e) => {
                        this.setState({
                          openMonthDialog: false,
                        });
                      }}
                      disableCloseOnClickOutside={false}
                      inputProps={{
                        placeholder: "Month",
                      }}
                      value={this.state.selectedCardsFilterDate}
                      dateFormat="MMM-YYYY"
                      timeFormat={false}
                      onChange={(e) => {
                        this.handleReactMonthFilterChange(e);
                        this.setState({
                          openMonthDialog: false,
                        });
                      }}
                      isValidDate={this.validDateMonthFilter}
                    />
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <Can
              I={permissions.PARTNERS.dealer_cards}
              a={permissions.PARTNERS.subject}
            >
              <Row>
                {/* <Col>
                  <Card className="bg-gradient-primary card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted text-white mb-0"
                          >
                            Total Retailers
                          </CardTitle>
                          <span className="h2 font-weight-bold text-white mb-0">
                            {!this.props.partnerdealerCardsLoading &&
                            this.dealerCards ? (
                              convertToCommaSeperated(
                                this.dealerCards.totalDealers
                              )
                            ) : (
                              <Shimmer />
                            )}
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                          {this.state.cardFilter === "all"
                            ? "Till " +
                              this.getMonthYear(this.previousMonthDate)
                            : this.getMonthYear(
                                this.state.selectedCardsFilterDate
                              )}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col> */}
                <Col>
                  <Card className=" bg-gradient-primary card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted text-white mb-0"
                          >
                            Active Retailers &nbsp; / &nbsp;Total Retailers
                          </CardTitle>
                          <span className="h2 font-weight-bold text-white mb-0">
                            {!this.props.partnerdealerCardsLoading &&
                            this.dealerCards ? (
                              this.dealerCards.totalActiveDealers
                            ) : (
                              <Shimmer />
                            )}{" "}
                            &nbsp; / &nbsp;
                            {!this.props.partnerdealerCardsLoading &&
                            this.dealerCards ? (
                              convertToCommaSeperated(
                                this.dealerCards.totalDealers
                              )
                            ) : (
                              <Shimmer />
                            )}
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                          {this.state.cardFilter === "all"
                            ? "Till " +
                              this.getMonthYear(this.previousMonthDate)
                            : this.getMonthYear(
                                this.state.selectedCardsFilterDate
                              )}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="bg-gradient-primary card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted text-white mb-0"
                          >
                            Total Plans Sold
                          </CardTitle>
                          <span className="h2 font-weight-bold text-white mb-0">
                            {!this.props.partnerdealerCardsLoading &&
                            this.dealerCards ? (
                              this.dealerCards.policiesSold
                            ) : (
                              <Shimmer />
                            )}
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                          {this.state.cardFilter === "all"
                            ? "Till " +
                              this.getMonthYear(this.previousMonthDate)
                            : this.getMonthYear(
                                this.state.selectedCardsFilterDate
                              )}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className=" bg-gradient-primary card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted text-white mb-0"
                          >
                            Total Premium
                          </CardTitle>
                          <span className="h2 font-weight-bold text-white mb-0">
                            {!this.props.partnerdealerCardsLoading &&
                            this.dealerCards ? (
                              "₹ " +
                              convertToCommaSeperated(
                                this.getTwoFixedPoint(this.dealerCards.revenue)
                              )
                            ) : (
                              <Shimmer />
                            )}
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                          {this.state.cardFilter === "all"
                            ? "Till " +
                              this.getMonthYear(this.previousMonthDate)
                            : this.getMonthYear(
                                this.state.selectedCardsFilterDate
                              )}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className=" bg-gradient-primary card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted text-white mb-0"
                          >
                            Total Commission
                          </CardTitle>
                          <span className="h2 font-weight-bold text-white mb-0">
                            {!this.props.partnerdealerCardsLoading &&
                            this.dealerCards ? (
                              "₹ " +
                              convertToCommaSeperated(
                                this.getTwoFixedPoint(
                                  this.dealerCards.commission
                                )
                              )
                            ) : (
                              <Shimmer />
                            )}
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                          {this.state.cardFilter === "all"
                            ? "Till " +
                              this.getMonthYear(this.previousMonthDate)
                            : this.getMonthYear(
                                this.state.selectedCardsFilterDate
                              )}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Can>
            {/* <Row className="px-3">
              <Col xs={12} sm={6} className="pl-0">
                <h5 className="text-primary h5 font-weight-400">All Dealers</h5>
              </Col>
              <Col className="pr-0 pb-1">
                <div className="float-right">
                  <Button
                    className="btn-icon btn-2"
                    size="sm"
                    outline
                    color="secondary"
                    type="button"
                    onClick={(e) => {
                      this.setState({
                        hideFilters: !this.state.hideFilters,
                      });
                    }}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-filter"></i>
                    </span>
                  </Button>
                </div>
              </Col>
            </Row> */}
            {/* export and filter */}
            <div>
              <Row
                className="bg-white m-0 py-2"
                hidden={this.state.hideFilters}
              >
                <Col className="p-1 px-3 text-muted">
                  <small>
                    <form
                      xs="6"
                      className="form-inline md-form mr-3 mb-4 d-inline"
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <input
                        className="ml-2 bg-white border-0 text-muted pr-2"
                        value={this.state.searchText}
                        onKeyDown={(e) => {
                          e.key === "Enter" && this.onSearch(e.target.value);
                        }}
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            this.setState({
                              view: "retailer-table",
                            });
                          }
                          this.setState({
                            searchText: e.target.value,
                          });
                        }}
                        type="text"
                        placeholder="Search by retailer code, name, or retailership"
                        aria-label="Search"
                        style={{ width: "50%" }}
                      />
                      <i
                        className="fas fa-search cursor-pointer mt-2"
                        aria-hidden="true"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onSearch(this.state.searchText);
                        }}
                      ></i>
                    </form>
                  </small>
                  {this.state.searchText !== "" &&
                    this.state.view === "table" && (
                      <Badge color="primary" pill className="ml-2">
                        {this.state.searchText}
                        <span
                          className="p-1 ml-2 cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            this.refreshFilters();
                            this.setState({
                              view: "dealer-table",
                              searchText: "",
                            });
                          }}
                        >
                          <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Badge>
                    )}
                </Col>
                <Row>
                  <Col className="p-1 text-right px-3">
                    {/* <CustomDropdown
                    name="Partner"
                    selectedId={this.state.partnerId}
                    handleSelection={(id) => {
                      this.partnerChanged(id);
                    }}
                    options={this.props.partnerDealerFilterListData}
                  /> */}
                    <span className="mr-2">
                      <CustomDropdown
                        name="Retailer Type"
                        selectedId={this.state.dealerType}
                        handleSelection={(id) => {
                          this.dealerTypeChanged(id);
                        }}
                        options={this.dealerTypes}
                        defaultValue="ALL"
                      />
                    </span>
                    {/* <Button
                    color="secondary "
                    outline
                    size="sm"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.refreshFilters();
                    }}
                  >
                    Clear
                  </Button> */}

                    {1 === 2 && (
                      <Button
                        color="primary"
                        size="sm"
                        onClick={this.onClickHandler}
                      >
                        Add Dealer
                      </Button>
                    )}

                    <Button
                      color="primary"
                      size="sm"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.fetchAllExportData();
                      }}
                      disabled={!this.dealerList.length}
                    >
                      Export
                    </Button>
                  </Col>
                </Row>
              </Row>
            </div>
            <Card className="m-0 mt-1">
              <CardBody className="px-0 py-0">
                <div className=" table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        {/* <th>Ref ID</th> */}
                        <th>Child Code</th>
                        <th>Retailer Name</th>
                        {/* <th>Retailer Name</th> */}
                        {/* <th>Dealership Name</th> */}
                        {/* <th>Retailer Type</th> */}
                        {/* <th>Payment Method</th> */}
                        <th>Plans Sold</th>
                        <th>Premium</th>
                        <th>Commission</th>
                        {/* <th>Status</th> */}
                        <th>Created Date</th>
                        <th style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody className="all_tabl_heigth">
                      {this.props.partnerDealerListLoading && (
                        <tr>
                          <td colSpan="8">
                            <Spinner></Spinner>
                          </td>
                        </tr>
                      )}
                      {!this.props.partnerDealerListLoading &&
                        this.props.partnerDealerListData &&
                        this.props.partnerDealerListData.length < 1 && (
                          <tr
                            key={"keyxasx1_duwe823"}
                            className="cursor-pointer"
                          >
                            <td colSpan="12" className="text-center">
                              <span className="text-muted">
                                <b>No Dealers Data found</b>
                              </span>
                            </td>
                          </tr>
                        )}
                      {!this.props.partnerDealerListLoading &&
                        this.dealerList.map((dealer, index) => (
                          <tr key={dealer.id}>
                            {/* <td className="table-user">
                              <span
                                className="text-primary font-weight-bold cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                    "/admin/dealers/" + dealer.id
                                  );
                                }}
                              >
                                {dealer.id}
                              </span>
                            </td> */}
                            {/* <td className="table-user">
                              <span
                                className="text-primary font-weight-bold cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                    "/admin/dealers/" + dealer.id
                                  );
                                }}
                              >
                                {dealer.code ?? "-"}
                              </span>
                            </td> */}
                            <td className="table-user">
                              <span
                                className="text-primary font-weight-bold cursor-pointer"
                                onClick={(e) => {
                                  if (franchise === "true") return;
                                  e.preventDefault();
                                  this.props.history.push(
                                    "/admin/dealers/" + dealer.id
                                  );
                                }}
                              >
                                {dealer.store_code ?? "-"}
                              </span>
                            </td>
                            {/* <td className="table-user">{dealer.code ?? "-"}</td> */}
                            <td>
                              {/* {dealer.name}
                              <br /> */}
                              {dealer.dealership_name}
                            </td>
                            {/* <td>{dealer.dealership_name}</td> */}
                            {/* <td>
                              {dealer.invoicing_modal
                                ? DealerTypes[dealer.invoicing_modal]
                                : "N/A"}
                            </td> */}
                            {/* <td>
                              {dealer.invoicing_modal
                                ? dealer.invoicing_modal
                                : "N/A"}
                            </td> */}
                            <td>{dealer.total_sales ?? 0}</td>
                            <td>
                              {dealer.total_premium > 0
                                ? "₹" +
                                  convertToCommaSeperated(
                                    this.getTwoFixedPoint(dealer.total_premium)
                                  )
                                : "-"}
                            </td>
                            <td>
                              {dealer.commission
                                ? "₹" +
                                  convertToCommaSeperated(
                                    this.getTwoFixedPoint(dealer.commission)
                                  )
                                : "-"}
                            </td>
                            {/* <td>{dealer.is_active ? "Active" : "InActive"}</td> */}
                            <td>
                              {convertDateToString(
                                dealer.created_at,
                                "DD-MM-YYYY"
                              )}
                            </td>
                            {franchise === "false" && (
                              <td
                                className="text-right"
                                style={{ width: "10%" }}
                              >
                                <Button
                                  color={`btn-round btn-icon btn btn-primary btn-md btn-block1`}
                                  size="sm"
                                  type="button"
                                  onClick={() =>
                                    this.handleUploadDocumentClick(dealer)
                                  }
                                >
                                  {"Documents"}
                                </Button>

                                <Button
                                  color={`btn-round btn-icon btn btn-primary btn-md btn-block1`}
                                  size="sm"
                                  type="button"
                                  onClick={() => this.handleButtonClick(dealer)}
                                  // {...(!dealer.documents.length && {disabled: true})}
                                >
                                  <span className="btn-inner--text">
                                    {dealer.is_active_for_sell !== true
                                      ? "Approve"
                                      : dealer.is_active
                                      ? "Make Inactive"
                                      : "Make Active"}
                                  </span>
                                </Button>

                                <Button
                                  color={`btn-round btn-icon btn btn-warning btn-md btn-block1`}
                                  size="sm"
                                  type="button"
                                  onClick={() =>
                                    this.handleResetPasswordButtonClick(dealer)
                                  }
                                >
                                  <span className="btn-inner--text">
                                    {"Reset Password"}
                                  </span>
                                </Button>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              {!this.props.partnerDealerListLoading &&
                this.props.partnerDealerListData &&
                this.props.partnerDealerListData.length > 0 && (
                  <CardFooter className="py-4 d-flex justify-content-between">
                    <PaginationShowingData
                      totalCount={this.props.dealerTotalCount}
                      startCount={this.pager.pagerStart}
                    />
                    <nav aria-label="...">
                      <Pagination
                        // className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem
                          className={
                            this.pager.pagerStart > 0 ? "" : "disabled"
                          }
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              this.switchPage(this.pager.pagerStart);
                            }}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {this.pager.pageArray.map((page) => {
                          return (
                            <PaginationItem
                              key={"pager_" + page}
                              hidden={this.isHidden(page)}
                              className={
                                this.pager.pagerStart + 1 === page
                                  ? "active"
                                  : ""
                              }
                            >
                              <PaginationLink
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.switchPage(page);
                                }}
                              >
                                {page}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        })}
                        <PaginationItem
                          className={
                            this.pager.pagerEnd > this.pager.pagerStart + 1
                              ? ""
                              : "disabled"
                          }
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              this.switchPage(this.pager.pagerStart + 2);
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                )}
            </Card>
          </Container>
        </Fragment>

        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={this.state.isConfirmModalOpen}
          toggle={() => {
            this.setState({
              isConfirmModalOpen: false,
            });
          }}
          backdrop="static"
        >
          <div className="modal-header">Confirm Dialog</div>
          <div className="modal-body">
            <Row>
              <Col xs={12}>Do you really want to perform this action?</Col>
            </Row>
          </div>
          <div className="modal-footer date-modal-footer ">
            <Button
              color="primary"
              // className="ml-auto"
              type="button"
              size="sm"
              onClick={() => this.handleConfirmClick("yes")}
            >
              Yes
            </Button>
            <Button
              color="primary"
              // className="ml-auto"
              type="button"
              size="sm"
              onClick={() => this.handleConfirmClick("no")}
            >
              No
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={this.state.isResetPasswordConfirmModalOpen}
          toggle={() => {
            this.setState({
              isResetPasswordConfirmModalOpen: false,
            });
          }}
          backdrop="static"
        >
          <div className="modal-header">Confirm Dialog</div>
          <div className="modal-body">
            <Row>
              <Col xs={12}>
                Do you really want to reset password for this dealer?
              </Col>
            </Row>
          </div>
          <div className="modal-footer date-modal-footer ">
            <Button
              color="primary"
              // className="ml-auto"
              type="button"
              size="sm"
              onClick={() => this.handleConfirmResetClick("yes")}
            >
              Yes
            </Button>
            <Button
              color="primary"
              // className="ml-auto"
              type="button"
              size="sm"
              onClick={() => this.handleConfirmResetClick("no")}
            >
              No
            </Button>
          </div>
        </Modal>

        {/* Start: Dealer Documents Section */}
        {this.state.isDocumentModalOpen && (
          <DocumentModal
            isModalOpen={this.state.isDocumentModalOpen}
            dealerId={this.state.dealerId}
            toggleModal={this.toggleModal}
          />
        )}
        {/* End: Dealer Documents Section */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state, ">>>");
  return {
    partnerDealerListLoading: state.partnerDealer.partnerDealerListLoading,
    partnerDealerCardsLoading: state.partnerDealer.partnerDealerCardsLoading,

    partnerDealerListData: state.partnerDealer.partnerDealerListData,
    dealerTotalCount: state.partnerDealer.totalDealerListCount,
    partnerDealerCardsData: state.partnerDealer.partnerDealerCardsData,

    partnerDealerFilterListData:
      state.partnerDealer.partnerDealerFilterListData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPartnerDealerListData: (filter, params) =>
      dispatch(actions.getPartnerDealerListData(filter, params)),
    onGetPartnerDealerFilterListData: () =>
      dispatch(actions.getPartnerDealerFilterListData()),
    onGetPartnerDealerCardsData: (data) =>
      dispatch(actions.getPartnerDealerCardsData(data)),
    onShowNotification: (notification) =>
      dispatch(actions.setNotificationData(notification)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DealersListing);
