import { Box } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import ExportButton from "../../../../components/reusableComp/ExportButton";
import NoDataFound from "../../../../components/reusableComp/NoDataFound";
import PaginationComp from "../../../../components/reusableComp/PaginationComp";
import { createPaginationArr } from "../../../../helpers/utils";
import AxiosReactClient from "../../../../utilities/AxiosRestClient";
import AllDateFilter from "../BillingReport/AllDateFilter";

const WaterFallListing = () => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [waterFallListing, setWaterFallListing] = useState([]);
  const [activeDateFilter, setActiveDateFilter] = useState("today");
  const [paginationState, setPaginationState] = useState({
    start: 1,
    current: 1,
    end: 0,
    limit: 25,
    total: 0,
    arr: [],
  });
  let tableHeader = [
    "Order Id",
    "Retailer Name",
    "Customer Name",
    "Order Status",
    "Purchase Date",
    "Phone Model Name",
    "Product Type",
    "Min Price",
    "Max Price",
    "Landing Cost Without GST",
    "Landing Cost With GST",
    "Vivo India Margin With GST",
    "Vivo India Margin Percentage",
    "State Landing Cost Without GST",
    "State Landing Cost With GST",
    "Vivo MOP With GST",
    "Vivo MOP Without GST",
    "Margin Available",
    "Margin Percentage",
    "Retailer Margin Pre GST",
    "Retailer Margin Percentage",
    "Retailer Margin With GST",
    "State Margin Pre GST",
    "State Margin Percentage",
    "State Margin With GST",
    "Additional Cost At Service",
  ];
  const arr = [
    { name: "TOTAL RETAILERS", count: "5", date: "April 2022" },
    { name: "ACTIVE RETAILERS", count: "34435", date: "April 2022" },
    { name: "TOTAL PLANS SOLD", count: "50000", date: "April 2022" },
    { name: "TOTAL PREMIUM", count: "109544", date: "April 2022" },
  ];
  let exportPayload = {
    duration: activeDateFilter,
  };
  const handleChange = (e) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  };
  const handleSearchKeyDown = (e) => {
    e.key === "Enter" && handleClick();
  };
  const handleClick = () => {
    // write a logic to hit the api for search field
  };

  const fetchListingData = async () => {
    const query = {
      page: paginationState.current - 1,
      // search,
      limit: paginationState.limit,
      duration: activeDateFilter,
    };
    try {
      const response = await AxiosReactClient.getRequest(
        `report/water-fall-report`,
        query
      );
      setWaterFallListing(response?.data?.data?.data);
      setPaginationState({
        ...paginationState,
        end: Math.ceil(
          response?.data?.data?.total_count / paginationState.limit
        ),
        total: response?.data?.data?.total_count,
        arr: createPaginationArr(
          Math.ceil(response?.data?.data?.total_count / paginationState.limit)
        ),
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchListingData();
  }, [paginationState.current, search, activeDateFilter]);
  return (
    <Fragment>
      <Container fluid>
        <CardHeader className="border-0 px-0 bg-transparent ">
          <Row className="align-items-center py-4">
            <Col xs="6">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listClassName="breadcrumb-links breadcrumb-dark"
              >
                <BreadcrumbItem>
                  <a
                    href="#pablo"
                    className="text-primary h5 font-weight-400"
                    onClick={(e) => e.preventDefault()}
                  >
                    Waterfall Report
                  </a>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col className="text-right" xs="6">
              <AllDateFilter {...{ setActiveDateFilter, activeDateFilter }} />
            </Col>
          </Row>
          {/* {1 === 2 && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={4}>
                {arr.map((cur, id) => (
                  <TopCard
                    title={cur.name}
                    count={cur.count}
                    data={cur.date}
                    key={id}
                  />
                ))}
              </Grid>
            </Box>
          )} */}
          <Box className="mb-3 mt-6 mx-2">
            <input
              className="ml-2 bg-white border-0 text-muted pr-2"
              onKeyDown={handleSearchKeyDown}
              type="text"
              placeholder="Search by name, retailer code or franchise"
              style={{ width: "50%", visibility: "hidden" }}
              onChange={(e) => handleChange}
            />
            <i
              className="fas fa-search cursor-pointer mt-2"
              aria-hidden="true"
              style={{ visibility: "hidden" }}
              onClick={handleClick}
            ></i>
            <div style={{ float: "right" }}>
              <ExportButton
                url="report/water-fall-report"
                {...{ exportPayload }}
              />
            </div>
          </Box>
          <Card className="mb-6">
            <CardBody className="px-0 py-0">
              <div className=" table-responsive">
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      {tableHeader.map((cur, id) => (
                        <th key={id}>{cur}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="all_tabl_heigth">
                    {/* {waterFallListing?.length === 0 && (
                      <tr>
                        <td colSpan="8">
                          <Spinner></Spinner>
                        </td>
                      </tr>
                    )} */}
                    {waterFallListing?.length === 0 && <NoDataFound />}
                    {waterFallListing.length > 0 &&
                      waterFallListing.map((cur, id) => (
                        <tr key={id}>
                          <td className="table-user">{cur?.order_id}</td>
                          <td className="table-user">{cur?.dealer_name}</td>
                          <td className="table-user">{cur?.customer_name}</td>
                          <td className="table-user">{cur?.order_status}</td>
                          <td className="table-user">{cur?.purchase_date}</td>
                          <td className="table-user">
                            {cur?.phone_model_name}
                          </td>
                          <td className="table-user">{cur?.product_type}</td>
                          <td className="table-user">{cur?.min_price}</td>
                          <td className="table-user">{cur?.max_price}</td>
                          <td className="table-user">
                            {cur?.landing_cost_without_gst}
                          </td>
                          <td className="table-user">
                            {cur?.landing_cost_with_gst}
                          </td>
                          <td className="table-user">
                            {cur?.vivo_india_margin_with_gst}
                          </td>
                          <td className="table-user">
                            {cur?.vivo_india_margin_percentage}
                          </td>
                          <td className="table-user">
                            {cur?.state_landing_cost_without_gst}
                          </td>
                          <td className="table-user">
                            {cur?.state_landing_cost_with_gst}
                          </td>
                          <td className="table-user">
                            {cur?.vivo_mop_with_gst}
                          </td>
                          <td className="table-user">
                            {cur?.vivo_mop_without_gst}
                          </td>
                          <td className="table-user">
                            {cur?.margin_available}
                          </td>
                          <td className="table-user">
                            {cur?.margin_percentage}
                          </td>
                          <td className="table-user">
                            {cur?.retailer_margin_pre_gst}
                          </td>
                          <td className="table-user">
                            {cur?.retailer_margin_percentage}
                          </td>
                          <td className="table-user">
                            {cur?.retailer_margin_with_gst}
                          </td>
                          <td className="table-user">
                            {cur?.state_margin_pre_gst}
                          </td>
                          <td className="table-user">
                            {cur?.state_margin_percentage}
                          </td>
                          <td className="table-user">
                            {cur?.state_margin_with_gst}
                          </td>
                          <td className="table-user">
                            {cur?.additional_cost_at_service}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            {/* pagination logic  */}
            {waterFallListing.length > 0 && (
              <PaginationComp {...{ paginationState, setPaginationState }} />
            )}
          </Card>
        </CardHeader>
      </Container>
    </Fragment>
  );
};

export default WaterFallListing;
