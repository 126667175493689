import { Alert } from "@mui/material";
import React from "react";

const NotificationShow = ({ status, msg }) => {
  return (
    <>
      <Alert severity={status}>{msg}</Alert>
    </>
  );
};

export default NotificationShow;
