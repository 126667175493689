import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import Spinner from "../../../components/Spinner/Spinner";
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import { connect } from "react-redux";
import * as actions from "../../../Store/actions/index";
import Popup from "../../../components/popup/index";
import { notifyError, validateEmail } from "../../../helpers/utils";
import Notification from "../../../components/CustomUi/Notify/Notification";

class Login extends React.Component {
  componentDidMount() {
    this.props.authCheckState();
    // if(this.props.isAuthenticated){
    //   let authRedirect = <Redirect to={this.props.authRedirectPath}/>
    // }
  }

  state = {
    customStyles: {
      email: "",
      emailState: null,
      password: "",
      passwordState: null,
      forgotEmail: "",
      forgotEmailState: null,
    },
    forgotPassword: false,
    loader: false,
  };

  submitHandler = () => {
    console.log("login");
    let newState = this.state.customStyles;
    if (newState.email === "") {
      newState.emailState = "invalid";

      this.props.dispatchError({
        code: 0,
        message: "Invalid email",
      });
      return;
    } else {
      newState.emailState = "valid";
    }
    if (newState.password === "") {
      newState.passwordState = "invalid";
      this.props.dispatchError({
        code: 0,
        message: "Enter password",
      });
      return;
    } else {
      newState.passwordState = "valid";
    }
    this.setState({
      customStyles: newState,
    });

    this.props.onLogin(
      this.state.customStyles.email,
      this.state.customStyles.password
    );
  };
  keyPress = (e) => {
    if (e.keyCode === 13) {
      // put the login here
      this.submitHandler();
    }
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;
    if (e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }
    this.setState({
      customStyles: newState,
    });
  };

  /*
    Below written code is related to forgot password functionality
  */

  onForgotClick = () => {
    this.setState({ loader: false });
    this.setState({ forgotPassword: true });
  };
  onForgotClose = () => {
    this.setState({ loader: false });
    this.setState({ forgotPassword: false });
  };
  onForgotSubmit = () => {
    let newState = this.state.customStyles;

    if (newState.forgotEmail === "") {
      newState.forgotEmailState = "invalid";
      notifyError("Enter email address.");

      return;
    } else {
      newState.forgotEmailState = "valid";
    }
    if (!validateEmail(newState.forgotEmail)) {
      newState.forgotEmailState = "invalid";
      notifyError("Invalid email address.");

      return;
    } else {
      newState.forgotEmailState = "valid";
    }

    this.setState({
      customStyles: newState,
    });

    this.setState({ loader: true });
    this.props.onForgotPassword(
      this.state.customStyles.forgotEmail,
      this.onForgotClose
    );
    newState.forgotEmail = "";
    newState.forgotEmailState = "valid";
    this.setState({
      customStyles: newState,
    });
    //this.onForgotClose();
  };
  /*
    Code end here for forgot password functionality
  */
  render() {
    let form = (
      <Form role="form">
        <FormGroup
          className={classnames("mb-3", {
            focused: this.state.focusedEmail,
          })}
        >
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Email"
              type="email"
              onFocus={() => this.setState({ focusedEmail: true })}
              onBlur={() => this.setState({ focusedEmail: false })}
              valid={this.state.customStyles.emailState === "valid"}
              invalid={this.state.customStyles.emailState === "invalid"}
              onChange={(e) => this.customStylesForm(e, "email")}
              onKeyDown={this.keyPress}
            />
          </InputGroup>
          <div className="invalid-feedback">Please enter an email.</div>
        </FormGroup>

        <FormGroup
          className={classnames({
            focused: this.state.focusedPassword,
          })}
        >
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Password"
              type="password"
              onFocus={() => this.setState({ focusedPassword: true })}
              onBlur={() => this.setState({ focusedPassword: false })}
              valid={this.state.customStyles.passwordState === "valid"}
              invalid={this.state.customStyles.passwordState === "invalid"}
              onChange={(e) => this.customStylesForm(e, "password")}
              onKeyDown={this.keyPress}
            />
          </InputGroup>
          <div className="invalid-feedback">Please enter a password.</div>
        </FormGroup>
        <div className="custom-control custom-control-alternative custom-checkbox">
          <input
            className="custom-control-input"
            id=" customCheckLogin"
            type="checkbox"
          />
          <label className="custom-control-label" htmlFor=" customCheckLogin">
            <span className="text-muted">Remember me</span>
          </label>
        </div>
        <div className="text-center">
          <Button
            className="my-4"
            color="info"
            onClick={this.submitHandler}
            type="button"
          >
            Sign in
          </Button>
        </div>
      </Form>
    );

    let forgotPassword = (
      <Form>
        <FormGroup
          className={classnames("mb-3", {
            focused: this.state.forgotFocusedEmail,
          })}
        >
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Email"
              type="email"
              onFocus={() => this.setState({ forgotFocusedEmail: true })}
              onBlur={() => this.setState({ forgotFocusedEmail: false })}
              valid={this.state.customStyles.forgotEmailState === "valid"}
              invalid={this.state.customStyles.forgotEmailState === "invalid"}
              onChange={(e) => this.customStylesForm(e, "forgotEmail")}
              onKeyDown={this.keyPress}
            />
          </InputGroup>
          <div className="invalid-feedback">Please enter an email.</div>
        </FormGroup>
        <div className="text-center">
          {!this.state.loader && (
            <Button
              className="my-4"
              color="info"
              onClick={this.onForgotSubmit}
              type="button"
            >
              Submit
            </Button>
          )}

          {this.state.loader && <Spinner />}
        </div>
      </Form>
    );

    if (this.props.loading) {
      form = <Spinner />;
    }

    // let authRedirect = null;
    // if (this.props.isAuthenticated) {
    //   authRedirect =  <Redirect to={this.props.authRedirectPath} />
    // }
    return (
      <>
        <AuthHeader
          title="Admin Section"
          lead="With great power comes great responsibility! Use it wisely"
        />
        <Container className="mt--8 pb-3">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <big>Sign in to Admin {process.env.REACT_APP_TEST}</big>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">{form}</CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="javascript: void(0);"
                    onClick={this.onForgotClick}
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
                {/* <Col className="text-right" xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <small>Create new account</small>
                  </a>
                </Col> */}
              </Row>
              {this.state.forgotPassword && (
                <Popup
                  forgotPassword={this.state.forgotPassword}
                  closeHandler={this.onForgotClose}
                  form={forgotPassword}
                  title={"Enter Email ID for Password Reset"}
                  footerClose={false}
                ></Popup>
              )}
            </Col>
          </Row>
          <Notification />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password) => dispatch(actions.login(email, password)),
    onForgotPassword: (email, closeHandler) =>
      dispatch(actions.forgotPassword(email, closeHandler)),
    onSetAuthRedirectPath: () =>
      dispatch(actions.setAuthRedirectPath("/admin")),
    authCheckState: () => dispatch(actions.authCheckState()),
    dispatchError: (messageObject) => dispatch(actions.authFail(messageObject)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
