import { Box, Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import ExportButton from "../../../../components/reusableComp/ExportButton";
import NoDataFound from "../../../../components/reusableComp/NoDataFound";
import PaginationComp from "../../../../components/reusableComp/PaginationComp";
import TopCard from "../../../../components/reusableComp/TopCard";
import { createPaginationArr } from "../../../../helpers/utils";
import AxiosReactClient from "../../../../utilities/AxiosRestClient";
import AllDateFilter from "./AllDateFilter";

const BillingReportListing = () => {
  const [activeDateFilter, setActiveDateFilter] = useState("today");
  console.log(activeDateFilter, "ooo");
  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState([]);
  const [paginationState, setPaginationState] = useState({
    start: 1,
    current: 1,
    end: 0,
    limit: 25,
    count: 0,
    arr: [],
  });
  let exportPayload = {
    duration: activeDateFilter,
  };
  let tableHeader = [
    "Child Code",
    "Trade Name",
    "Retailer Address",
    "GSTIN",
    "State",
    "Location",
    "Pin Code",
    "Plan purchase date",
    "Billing Month",
    "Plan",
    "model name",
    "Product Price Range",
    "Cost To Retailer",
  ];
  const arr = [
    { name: "TOTAL RETAILERS", count: "5", date: "April 2022" },
    { name: "ACTIVE RETAILERS", count: "34435", date: "April 2022" },
    { name: "TOTAL PLANS SOLD", count: "50000", date: "April 2022" },
    { name: "TOTAL PREMIUM", count: "109544", date: "April 2022" },
  ];
  const handleChange = (e) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  };
  const handleSearchKeyDown = (e) => {
    e.key === "Enter" && handleClick();
  };
  const handleClick = () => {
    // write a logic to hit the api for search field
  };

  const fetchListingData = async () => {
    const query = {
      page: paginationState.current - 1,
      // search,
      limit: paginationState.limit,
      duration: activeDateFilter,
    };
    try {
      const response = await AxiosReactClient.getRequest(
        `report/billing-report`,
        query
      );
      setListingData(response?.data?.data?.data);
      setPaginationState({
        ...paginationState,
        end: Math.ceil(
          response?.data?.data?.total_count / paginationState.limit
        ),
        total: response?.data?.data?.total_count,
        arr: createPaginationArr(
          Math.ceil(response?.data?.data?.total_count / paginationState.limit)
        ),
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchListingData();
  }, [paginationState.current, search, activeDateFilter]);

  return (
    <Fragment>
      <Container fluid>
        <CardHeader className="border-0 px-0 bg-transparent ">
          <Row className="align-items-center py-4">
            <Col xs="6">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listClassName="breadcrumb-links breadcrumb-dark"
              >
                <BreadcrumbItem>
                  <a
                    href="#pablo"
                    className="text-primary h5 font-weight-400"
                    onClick={(e) => e.preventDefault()}
                  >
                    Billing Report
                  </a>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs="6">
              <AllDateFilter {...{ setActiveDateFilter, activeDateFilter }} />
            </Col>
          </Row>
          {/* {1 === 2 && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={4}>
                {arr.map((cur, id) => (
                  <TopCard
                    title={cur.name}
                    count={cur.count}
                    data={cur.date}
                    key={id}
                  />
                ))}
              </Grid>
            </Box>
          )} */}
          <Box className="mb-3 mt-6 mx-2">
            <input
              className="ml-2 bg-white border-0 text-muted pr-2"
              onKeyDown={handleSearchKeyDown}
              type="text"
              placeholder="Search by name, child code or franchise"
              style={{ width: "50%", visibility: "hidden" }}
              onChange={(e) => handleChange}
            />
            <i
              className="fas fa-search cursor-pointer mt-2"
              aria-hidden="true"
              onClick={handleClick}
              style={{ visibility: "hidden" }}
            ></i>
            <div style={{ float: "right" }}>
              <ExportButton
                url="report/billing-report"
                {...{ exportPayload }}
              />
            </div>
          </Box>
          <Box></Box>
          <Card className="mb-6">
            <CardBody className="px-0 py-0">
              <div className=" table-responsive">
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      {tableHeader.map((cur, id) => (
                        <th key={id}>{cur}</th>
                      ))}
                    </tr>
                  </thead>
                  {/* {listingData?.length !== 0 &&
                    listingData?.map((cur, id) => (
                      <tbody>
                        <tr>
                          <td key={id}>Hello</td>
                        </tr>
                      </tbody>
                    ))} */}
                  <tbody className="all_tabl_heigth">
                    {/* {listingData?.length === 0 && (
                      <tr>
                        <td colSpan="8">
                          <Spinner></Spinner>
                        </td>
                      </tr>
                    )} */}
                    {listingData?.length === 0 && <NoDataFound />}
                    {listingData.length > 0 &&
                      listingData.map((cur, id) => (
                        <tr key={id}>
                          {/* <td className="table-user">{cur?.dealer_name}</td> */}
                          <td className="table-user">{cur?.child_code}</td>
                          <td className="table-user">{cur?.trade_name}</td>
                          <td className="table-user">{cur?.dealer_address}</td>
                          <td className="table-user">{cur?.GSTIN}</td>
                          <td className="table-user">{cur?.state}</td>
                          <td className="table-user">{cur?.location}</td>
                          <td className="table-user">{cur?.pincode}</td>
                          <td className="table-user">
                            {cur?.device_purchase_date}
                          </td>
                          <td className="table-user">{cur?.billing_period}</td>
                          <td className="table-user">{cur?.product_type}</td>
                          <td className="table-user">
                            {cur?.mobile_model_name}
                          </td>
                          <td className="table-user">{cur?.price_range}</td>
                          <td className="table-user">{cur?.cost_to_dealer}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            {listingData?.length > 0 && (
              <PaginationComp
                {...{ paginationState, setPaginationState, fetchListingData }}
              />
            )}
          </Card>
        </CardHeader>
      </Container>
    </Fragment>
  );
};

export default BillingReportListing;
