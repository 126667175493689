import * as actionTypes from "./actionTypes/authTypes";
import * as common from "./common";
import AxiosReactClient from "../../utilities/AxiosRestClient";
import { ADMIN_TYPE_OEM, HOD, ADMIN_TYPE_GRE } from "./actionTypes/policyTypes";
import { notify, notifyError } from "../../helpers/utils";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  localStorage.removeItem("selectedState");
  localStorage.removeItem("type");
  localStorage.removeItem("category");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    if (expirationTime) {
      dispatch(logout());
    }
  };
};
const isTokenExpired = () => {
  const token = localStorage.getItem("decoded_token");
  try {
    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date.valueOf() > new Date().valueOf();
  } catch (err) {
    return false;
  }
};

export const login = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password,
      grant_type: "password",
      user_type: "admin",
      // returnSecureToken: true
    };
    AxiosReactClient.postLoginRequest("user/login", authData)
      .then((response) => {
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("franchise", response.data.data.is_franchise ?? false);
        let jwtDecode = require("jwt-decode");
        let decoded = jwtDecode(localStorage.getItem("token"));
        localStorage.setItem("decoded_token", decoded);
        let userId = decoded.sub;
        dispatch(checkAuthTimeout(isTokenExpired()));
        localStorage.setItem("userId", userId);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem(
          "type",
          response.data.data?.state_config?.type ?? "NA"
        );
        localStorage.setItem(
          "category",
          response.data.data.state_config?.category ?? "NA"
        );
        localStorage.setItem(
          "key",
          response.data.data.state_config?.key ?? ""
        );
        dispatch(authSuccess(response.data.data.token, userId));
      })
      .catch((err) => {
        if (err.response) dispatch(authFail(err.response.data.error));
        else dispatch(authFail({ code: 502, message: err.message }));
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      if (isTokenExpired()) {
        dispatch(logout());
      } else {
        const userId = localStorage.getItem("userId");
        dispatch(authSuccess(token, userId));
        dispatch(checkAuthTimeout(isTokenExpired()));
      }
    }
  };
};

//// method for forgot password email send
export const forgotPassword = (email, closeHandler) => {
  return (dispatch) => {
    const data = {
      email: email,
    };
    AxiosReactClient.postLoginRequest("password/email", data)
      .then((response) => {
        notify(response.data.data);
        closeHandler();
      })
      .catch((err) => {
        if (err.response) {
          notifyError(err.response.data.data.email);
          closeHandler();
        } else dispatch(authFail({ code: 502, message: err.message }));
      });
  };
};

/// method for password to reset
export const resetPassword = (email, password, confirm_password, token) => {
  return (dispatch) => {
    const data = {
      email: email,
      password: password,
      password_confirmation: confirm_password,
      token: token,
    };
    AxiosReactClient.postLoginRequest("password/reset   ", data)
      .then((response) => {
        notify(response.data.data);
        setTimeout(() => {
          window.location = "/";
        }, 5000);
      })
      .catch((err) => {
        if (err.response) notifyError(err.response.data.data.msg);
        else dispatch(authFail({ code: 502, message: err.message }));
      });
  };
};
