import { Box, Card } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Breadcrumb,
  BreadcrumbItem,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { defaultStatusId, planSaleData } from "../../../../helpers/utils";
import AxiosReactClient from "../../../../utilities/AxiosRestClient";
import AllDateFilter from "../BillingReport/AllDateFilter";
import StateDropdown from "../../../../components/StateDropdown/StateDropdown";
import ExportButton from "../../../../components/reusableComp/ExportButton";

const AnalyticsMain = () => {
  const [activeDateFilter, setActiveDateFilter] = useState("thisMonth");
  const [analyticsData, setAnalyticsData] = useState({});
  const val = defaultStatusId();
  const [activeState, setActiveState] = useState(val);
  console.log(val, "analyticsData");
  const arr = [
    { name: "TOTAL RETAILERS", count: "5", date: "April 2022" },
    { name: "ACTIVE RETAILERS", count: "34435", date: "April 2022" },
    { name: "TOTAL PLANS SOLD", count: "50000", date: "April 2022" },
    { name: "TOTAL PREMIUM", count: "109544", date: "April 2022" },
  ];
  let {
    chartData,
    radialBarChartData,
    chartRowChartData,
    topRetailersData,
    topPriceRangWisePlanSold,
    premiumContribution,
    activeRetailerVsPlanSold,
  } = planSaleData(analyticsData);

  function onChange(opt) {
    setActiveState(opt);
  }
  const fetchAnalyticsData = async () => {
    const query = {
      duration: activeDateFilter,
      state_id: activeState,
    };
    try {
      const response = await AxiosReactClient.getRequest(
        `report/analytics`,
        query
      );
      setAnalyticsData(response?.data?.data);
    } catch (err) {}
  };
  useEffect(() => {
    fetchAnalyticsData();
  }, [activeDateFilter, activeState]);
  let exportPayload = {
    export: 1,
    exportType: "best_sold_model_report",
    duration: activeDateFilter,
    state_id: activeState,
  };
  let exportPayloadForRetailer = {
    export: 1,
    exportType: "sale_wise_best_retailers",
    duration: activeDateFilter,
    state_id: activeState,
  };

  return (
    <Fragment>
      <Container fluid>
        <CardHeader className="border-0 px-0 bg-transparent ">
          <Row className="align-items-center py-4">
            <Col xs="6">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listClassName="breadcrumb-links breadcrumb-dark"
              >
                <BreadcrumbItem>
                  <a
                    href="#pablo"
                    className="text-primary h5 font-weight-400"
                    onClick={(e) => e.preventDefault()}
                  >
                    Analytics
                  </a>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs="6">
              <AllDateFilter {...{ setActiveDateFilter, activeDateFilter }} />
            </Col>
          </Row>
          <Row style={{ marginLeft: "2px" }}>
            <StateDropdown
              onChange={(opt) => {
                onChange(opt.id);
              }}
            />
          </Row>
          <Box sx={{ flexGrow: 1 }}>
            {/* <Grid container spacing={4}>
              {arr.map((cur, id) => (
                <TopCard
                  title={cur.name}
                  count={cur.count}
                  data={cur.date}
                  key={id}
                />
              ))}
            </Grid> */}
          </Box>
        </CardHeader>
        <Row style={{ marginTop: "2rem" }}>
          <Col>
            <Card style={{ padding: "2rem" }}>
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={500}
              />
            </Card>
          </Col>
          <Col className="menuIcon">
            <Card style={{ padding: "2rem", position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  right: "25px",
                  cursor: "pointer",
                  zIndex: "999",
                }}
              >
                <ExportButton url="report/analytics" {...{ exportPayload }} />
              </div>
              <ReactApexChart
                options={chartRowChartData.options}
                series={chartRowChartData.series}
                type="bar"
                height={500}
              />
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "2rem" }}>
          <Col className="menuIcon">
            <Card style={{ padding: "2rem", position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  right: "25px",
                  cursor: "pointer",
                  zIndex: "999",
                }}
              >
                <ExportButton
                  url="report/analytics"
                  {...{ exportPayload: exportPayloadForRetailer }}
                />
              </div>
              <ReactApexChart
                options={topRetailersData.options}
                series={topRetailersData.series}
                type="bar"
                height={500}
              />
            </Card>
          </Col>
          <Col>
            <Card style={{ padding: "2rem" }}>
              <ReactApexChart
                options={radialBarChartData.options}
                series={radialBarChartData.series}
                type="radialBar"
                height={500}
              />
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "2rem" }}>
          <Col>
            <Card style={{ padding: "2rem" }}>
              <ReactApexChart
                options={topPriceRangWisePlanSold.options}
                series={topPriceRangWisePlanSold.series}
                type="bar"
                height={500}
              />
            </Card>
          </Col>
          <Col>
            <Card style={{ padding: "2rem" }}>
              <ReactApexChart
                options={premiumContribution.options}
                series={premiumContribution.series}
                type="line"
                height={500}
              />
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "2rem" }}>
          <Col>
            <Card style={{ padding: "2rem" }}>
              <ReactApexChart
                options={activeRetailerVsPlanSold.options}
                series={activeRetailerVsPlanSold.series}
                type="bar"
                height={500}
              />
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AnalyticsMain;
