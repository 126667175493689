import { Box, Modal, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import StateDropdown from "../../../../components/StateDropdown/StateDropdown";
import ExportButton from "../../../../components/reusableComp/ExportButton";
import NoDataFound from "../../../../components/reusableComp/NoDataFound";
import PaginationComp from "../../../../components/reusableComp/PaginationComp";
import {
  createPaginationArr,
  priceStandardForm,
} from "../../../../helpers/utils";
import AxiosReactClient from "../../../../utilities/AxiosRestClient";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useHistory } from "react-router";
import Can from '../../../../config/Casl/Can';
import * as permissions from '../../../../config/Casl/permissions';

const FranchiseListing = () => {
  let adminType = localStorage.getItem("type");
  const history = useHistory();
  const [activeDateFilter, setActiveDateFilter] = useState("today");
  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [showAmountError, setShowAmountError] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [amount, setAmount] = React.useState(0);
  const [id, setId] = React.useState("");
  const [filter, setFilter] = useState({
    state: { id: "all", value: "ALL", label: "All States" },
  });
  const [field, setField] = useState({
    transaction_id: "",
    payment_mode: "",
    amount: "",
    payment_source: "",
  });
  
  const [paginationState, setPaginationState] = useState({
    start: 1,
    current: 1,
    end: 0,
    limit: 25,
    count: 0,
    arr: [],
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
  };
  let tableHeader = [
    "Name",
    "Email",
    "Mobile number",
    "Wallet Balance",
    "Dealer Count",
    "Total Premium",
    "Total Commission",
    "Action",
  ];
  const handleChange = (e) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  };
  const handleSearchKeyDown = (e) => {
    e.key === "Enter" && handleClick();
  };
  const handleClick = () => {
    // write a logic to hit the api for search field
  };
  const handleOnChange = (selected) => {
    setFilter({
      ...filter,
      state: selected,
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setId("");
    setOpen(false);
  };
  const openModalBtn = (val) => {
    setId(val);
    handleOpen();
  };
  const handleAmountChanges = (e, type) => {
    let { value } = e.target;
    if (type === "amount") {
      if (
        value.includes("-") ||
        value.includes(".") ||
        value > 100000 ||
        value > 0
      ) {
        setShowAmountError(true);
      } else {
        setShowAmountError(false);
      }
    }
    setField({
      ...field,
      [type]: e.target.value,
    });

    // if (e.target.value) setAmount(e.target.value);
  };
  const handlePayBtn = async () => {
    try {
      const response = await AxiosReactClient.postRequest(
        `franchise/add-money/${id}`,
        field
      );
      if (response) {
        setPaymentStatus(true);
      } else {
      }
    } catch (err) {}
  };
  const handleCloseModalWhenSuccess = () => {
    setPaymentStatus(false);
    handleClose();
  };
  const fetchListingData = async () => {
    const query = {
      page: paginationState.current - 1,
      limit: paginationState.limit,
      status: "inactive",
      // state_id: filter.state.id,
    };
    try {
      const response = await AxiosReactClient.getRequest(
        `franchise/list`,
        query
      );
      setListingData(response?.data?.data?.data);
      setPaginationState({
        ...paginationState,
        end: Math.ceil(
          response?.data?.data?.totalCount / paginationState.limit
        ),
        total: response?.data?.data?.total_count,
        arr: createPaginationArr(
          Math.ceil(response?.data?.data?.total_count / paginationState.limit)
        ),
      });
    } catch (err) {}
  };
  useEffect(() => {
    fetchListingData();
  }, [paginationState.current, filter.state, !paymentStatus]);
  let exportPayload = {};
  return (
    <>
      <Fragment>
        <Container fluid>
          <CardHeader className="border-0 px-0 bg-transparent ">
            <Row className="align-items-center py-4">
              <Col xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      className="text-primary h5 font-weight-400"
                      onClick={(e) => e.preventDefault()}
                    >
                      Franchise
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
            <Col
              className="pl-0 d-flex "
              style={{
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <StateDropdown
                onChange={(selectedOption) => handleOnChange(selectedOption)}
              />
              <ExportButton url="franchise/list" {...{ exportPayload }} />
            </Col>

            <Box></Box>
            <Card className="mb-6">
              <CardBody className="px-0 py-0">
                <div className=" table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        {tableHeader.map((cur, id) => (
                          <th key={id}>{cur}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="all_tabl_heigth">
                      {listingData?.length === 0 && <NoDataFound />}
                      {listingData?.length > 0 &&
                        listingData.map((cur, id) => (
                          <tr key={id}>
                            <td className="table-user">{cur?.name}</td>
                            <td className="table-user">{cur?.email}</td>
                            <td className="table-user">{cur?.mobile_number}</td>
                            <td className="table-user">
                              {priceStandardForm(cur?.wallet_balance)}
                            </td>
                            <td className="table-user">{cur?.dealer_count}</td>
                            <td className="table-user">
                              {priceStandardForm(cur?.total_premium)}
                            </td>
                            <td className="table-user">
                              {priceStandardForm(cur?.total_commission)}
                            </td>
                            <td style={{ width: "10%" }}>
                              {adminType === "gsp" && (
                                <Can I={permissions.FINANCE.franchise_addmoney} a={permissions.FINANCE.subject}>
                                  <Button
                                    color={`btn-round btn-icon btn btn-primary btn-md btn-block1`}
                                    size="sm"
                                    type="button"
                                    onClick={() => openModalBtn(cur.id)}
                                  >
                                    Add Amount
                                  </Button>
                                </Can>
                              )}
                              <Button
                                color={`btn-round btn-icon btn btn-primary btn-md btn-block1`}
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  history.push(
                                    `/admin/partners/franchise-retailers/retailer_id=${cur.id}`
                                  );
                                }}
                              >
                                View Retailers
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              {listingData?.length > 0 && (
                <PaginationComp
                  {...{ paginationState, setPaginationState, fetchListingData }}
                />
              )}
            </Card>
          </CardHeader>
        </Container>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {paymentStatus ? (
            <Box sx={style}>
              <Typography variant="h5">
                ₹{field.amount} has been added to your wallet successfully.
              </Typography>
              <Button
                type="button"
                onClick={handleCloseModalWhenSuccess}
                color="primary"
                style={{ marginTop: "2rem" }}
              >
                Ok
              </Button>
            </Box>
          ) : (
            <Box sx={style}>
              <Box className="d-flex justify-content-between">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Add Money
                </Typography>
                <CloseSharpIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <FormGroup style={{ marginTop: "2rem" }}>
                <label className="form-control-label" htmlFor="amountInput">
                  Amount
                </label>
                <Input
                  id="amountInput"
                  placeholder="Enter the amount"
                  type="number"
                  min={0}
                  max={100000}
                  onChange={(e) => handleAmountChanges(e, "amount")}
                />
                {showAmountError && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Enter the correct amount (1 to 100000)
                  </p>
                )}
                <label
                  className="form-control-label"
                  htmlFor="paymentModeSelect"
                >
                  Select Payment Mode
                </label>
                <select
                  name="payment_mode"
                  className="form-control"
                  id="paymentModeSelect"
                  // value={paymentMode}
                  onChange={(e) => handleAmountChanges(e, "payment_mode")}
                >
                  <option value="">Select Product</option>
                  <option value="net_banking">NEFT / RTGS / IMPS</option>
                  <option value="cheque">Cheque</option>
                  <option value="upi">Upi</option>
                  <option value="other">Other</option>
                </select>
                <label
                  className="form-control-label"
                  htmlFor="transactionIdInput"
                >
                  Transaction Id
                </label>
                <Input
                  // value={transactionId}
                  id="transaction_id"
                  placeholder="Enter the Transaction Id"
                  onChange={(e) => handleAmountChanges(e, "transaction_id")}
                />
                <label
                  className="form-control-label"
                  htmlFor="transactionIdInput"
                >
                  Payment Source
                </label>
                <Input
                  // value={transactionId}
                  id="payment_source"
                  placeholder="Enter the Payment Source"
                  onChange={(e) => handleAmountChanges(e, "payment_source")}
                />

                <Button
                  type="button"
                  onClick={handlePayBtn}
                  color="primary"
                  // disabled={showAmountError}
                  style={{ marginTop: "2rem" }}
                >
                  Submit
                </Button>
              </FormGroup>
            </Box>
          )}
        </Modal>
      </Fragment>
    </>
  );
};

export default FranchiseListing;
