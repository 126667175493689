
// PARTNER
export const GET_PARTNER_POLICIES_LIST_START = 'GET_PARTNER_POLICIES_LIST_START';
export const GET_PARTNER_POLICIES_LIST_SUCCESS = 'GET_PARTNER_POLICIES_LIST_SUCCESS';
export const GET_PARTNER_POLICIES_LIST_FAIL = 'GET_PARTNER_POLICIES_LIST_FAIL';

export const GET_PARTNER_POLICIES_CARDS_START = 'GET_PARTNER_POLICIES_CARDS_START';
export const GET_PARTNER_POLICIES_CARDS_SUCCESS = 'GET_PARTNER_POLICIES_CARDS_SUCCESS';
export const GET_PARTNER_POLICIES_CARDS_FAIL = 'GET_PARTNER_POLICIES_CARDS_FAIL';

export const GET_PARTNER_DEALER_LIST_START = 'GET_PARTNER_DEALER_LIST_START';
export const GET_PARTNER_DEALER_FILTER_LIST_START = 'GET_PARTNER_DEALER_FILTER_LIST_START';
export const GET_PARTNER_DEALER_CARDS_START = 'GET_PARTNER_DEALER_CARDS_START';

export const GET_PARTNER_DEALER_LIST_SUCCESS = 'GET_PARTNER_DEALER_LIST_SUCCESS';
export const GET_PARTNER_DEALER_FILTER_LIST_SUCCESS = 'GET_PARTNER_DEALER_FILTER_LIST_SUCCESS';
export const GET_PARTNER_DEALER_CARDS_SUCCESS = 'GET_PARTNER_DEALER_CARDS_SUCCESS';

export const GET_PARTNER_DEALER_LIST_FAIL = 'GET_PARTNER_DEALER_LIST_FAIL';
export const GET_PARTNER_DEALER_FILTER_LIST_FAIL = 'GET_PARTNER_DEALER_FILTER_LIST_FAIL';
export const GET_PARTNER_DEALER_CARDS_FAIL = 'GET_PARTNER_DEALER_CARDS_FAIL';

export const GET_PARTNER_FINANCE_LIST_START = 'GET_PARTNER_FINANCE_LIST_START';
export const GET_PRODUCT_FINANCE_FILTER_LIST_START = 'GET_PRODUCT_FINANCE_FILTER_LIST_START';
export const GET_PARTNER_FINANCE_FILTER_LIST_START = 'GET_PARTNER_FINANCE_FILTER_LIST_START';
export const GET_PARTNER_FINANCE_CARDS_START = 'GET_PARTNER_FINANCE_CARDS_START';

export const GET_PARTNER_FINANCE_LIST_SUCCESS = 'GET_PARTNER_FINANCE_LIST_SUCCESS';
export const GET_PRODUCT_FINANCE_FILTER_LIST_SUCCESS = 'GET_PRODUCT_FINANCE_FILTER_LIST_SUCCESS';
export const GET_PARTNER_FINANCE_FILTER_LIST_SUCCESS = 'GET_PARTNER_FINANCE_FILTER_LIST_SUCCESS';
export const GET_PARTNER_FINANCE_CARDS_SUCCESS = 'GET_PARTNER_FINANCE_CARDS_SUCCESS';

export const GET_PARTNER_FINANCE_LIST_FAIL = 'GET_PARTNER_FINANCE_LIST_FAIL';
export const GET_PRODUCT_FINANCE_FILTER_LIST_FAIL = 'GET_PRODUCT_FINANCE_FILTER_LIST_FAIL';
export const GET_PARTNER_FINANCE_FILTER_LIST_FAIL = 'GET_PARTNER_FINANCE_FILTER_LIST_FAIL';
export const GET_PARTNER_FINANCE_CARDS_FAIL = 'GET_PARTNER_FINANCE_CARDS_FAIL';

export const GET_PARTNER_CLAIM_LIST_START = 'GET_PARTNER_CLAIM_LIST_START';
export const GET_PARTNER_CLAIM_VIEW_START = 'GET_PARTNER_CLAIM_VIEW_START';
export const GET_PARTNER_CLAIM_CARDS_START = 'GET_PARTNER_CLAIM_CARDS_START';
export const GET_PARTNER_CLAIM_FILTER_LIST_START = 'GET_PARTNER_CLAIM_FILTER_LIST_START';

export const GET_PARTNER_CLAIM_LIST_SUCCESS = 'GET_PARTNER_CLAIM_LIST_SUCCESS';
export const GET_PARTNER_CLAIM_VIEW_SUCCESS = 'GET_PARTNER_CLAIM_VIEW_SUCCESS';
export const GET_PARTNER_CLAIM_CARDS_SUCCESS = 'GET_PARTNER_CLAIM_CARDS_SUCCESS';
export const GET_PARTNER_CLAIM_FILTER_LIST_SUCCESS = 'GET_PARTNER_CLAIM_FILTER_LIST_SUCCESS';

export const GET_PARTNER_CLAIM_LIST_FAIL = 'GET_PARTNER_CLAIM_LIST_FAIL';
export const GET_PARTNER_CLAIM_VIEW_FAIL = 'GET_PARTNER_CLAIM_VIEW_FAIL';
export const GET_PARTNER_CLAIM_CARDS_FAIL = 'GET_PARTNER_CLAIM_CARDS_FAIL';
export const GET_PARTNER_CLAIM_FILTER_LIST_FAIL = 'GET_PARTNER_CLAIM_FILTER_LIST_FAIL';

export const POST_PARTNER_DEALER_CREATE_START = 'POST_PARTNER_DEALER_CREATE_START';
export const POST_PARTNER_DEALER_CREATE_SUCCESS = 'POST_PARTNER_DEALER_CREATE_SUCCESS';
export const POST_PARTNER_DEALER_CREATE_FAIL = 'POST_PARTNER_DEALER_CREATE_FAIL';

export const POST_PARTNER_DASHBOARD_CREATE_START = 'POST_PARTNER_DASHBOARD_CREATE_START';
export const POST_PARTNER_DASHBOARD_CREATE_SUCCESS = 'POST_PARTNER_DASHBOARD_CREATE_SUCCESS';
export const POST_PARTNER_DASHBOARD_CREATE_FAIL = 'POST_PARTNER_DASHBOARD_CREATE_FAIL';
export const PARTNER_CLAIMS_ID = '191'

