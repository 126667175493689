import * as actionTypes from '../actions/actionTypes/policyTypes';
import {updateObject} from '../utility';

const initialState = {
    error: null,

    policyInputsLoading: false,
    policyProductListLoading: false,
    policyProviderListLoading: false,
    policyStatusListLoading: false,
    policyDealerListLoading: false,
    policyCityListLoading: false,
    policyDealerCreditLoading: false,
    policyBrandsListLoading: false,
    policyListLoading: false,
    transactionListLoading: false,
    policyHolderDetailsLoading: false,
    loadingQuotation: false,
    policyCancellationLoading: false,
    policyPaymentDueCardLoading: false,
    policyResubscriptionCardLoading: false,
    quotationListLoading: false,
    cycleBrandsListLoading: false,

    policyInputsData: null,
    policyProductListData: null,
    policyProviderListData: null,
    policyStatusListData: null,
    policyDealerListData: null,
    policyCityListData: null,
    policyDealerCreditData: null,
    policyBrandsListData: null,
    policyListData: null,
    transactionListData: null,
    policyHolderDetailsData: null,
    quotation: null,
    policyTotalCount: null,
    policyPaymentDueCardData: null,
    policyResubscriptionCardData: null,
    quotationListData: null,
    cycleBrandsListData: null,

    showCustomBrands: false,
    quotationTotalCount: null,
    showCycleCustomBrands: false,


};

//  START 
const policyInputsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyInputsLoading: true
    });
};
const policyProductListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyProductListLoading: true
    });
};
const policyProviderListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyProviderListLoading: true
    });
};
const policyStatusListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyStatusListLoading: true
    });
};
const policyDealerListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyDealerListLoading: true
    });
};
const policyCityListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyCityListLoading: true
    });
};
const policyDealerCreditStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyDealerCreditLoading: true
    });
};
const policyBrandsListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyBrandsListLoading: true
    });
};

const policyListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyListLoading: true
    });
};
const transactionListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        transactionListLoading: true
    });
};
const policyHolderDetailsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyHolderDetailsLoading: true
    });
};
const retrieveQuotationStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loadingQuotation: true,
    });
};
const policyCancellationStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyCancellationLoading: true
    });
};
const policyPaymentDueCardStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyPaymentDueCardLoading: true
    });
};
const policyResubscriptionCardStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyResubscriptionCardLoading: true
    });
};

const oyoPolicyListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        quotationListLoading: true
    });
};
const cycleBrandsListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        cycleBrandsListLoading: true
    });
};

//  SUCCESS 
const policyInputsSuccess = (state, action) => {
    return updateObject(state, {
        policyInputsData: action.data,
        error: null,
        policyInputsLoading: false
    });
};
const policyProductListSuccess = (state, action) => {
    return updateObject(state, {
        policyProductListData: action.data,
        error: null,
        policyProductListLoading: false
    });
};
const policyStatusListSuccess = (state, action) => {
    return updateObject(state, {
        policyStatusListData: action.data,
        error: null,
        policyStatusListLoading: false
    });
};
const policyProviderListSuccess = (state, action) => {
    return updateObject(state, {
        policyProviderListData: action.data,
        error: null,
        policyProviderListLoading: false
    });
};
const policyDealerListSuccess = (state, action) => {
    return updateObject(state, {
        policyDealerListData: action.data,
        error: null,
        policyDealerListLoading: false
    });
};
const policyCityListSuccess = (state, action) => {
    return updateObject(state, {
        policyCityListData: action.data,
        error: null,
        policyCityListLoading: false
    });
};
const policyDealerCreditSuccess = (state, action) => {
    return updateObject(state, {
        policyDealerCreditData: action.data,
        error: null,
        policyDealerCreditLoading: false
    });
};
const policyBrandsListSuccess = (state, action) => {
    return updateObject(state, {
        policyBrandsListData: action.data,
        error: null,
        policyBrandsListLoading: false
    });
};

const policyListSuccess = (state, action) => {
    return updateObject(state, {
        policyListData: action.data,
        policyTotalCount: action.count,
        error: null,
        policyListLoading: false
    });
};
const transactionListSuccess = (state, action) => {
    return updateObject(state, {
        transactionListData: action.data,
        error: null,
        transactionListLoading: false
    });
};
const policyHolderDetailsSuccess = (state, action) => {
    return updateObject(state, {
        policyHolderDetailsData: action.policyHolderDetailsData,
        error: null,
        policyHolderDetailsLoading: false
    });
};
const retrieveQuotationSuccess = (state, action) => {
    return updateObject(state, {
        loadingQuotation: false,
        quotation: action.quotation,
    });
};
const policyCancellationSuccess = (state, action) => {
    return updateObject(state, {
        policyCancellationData: action.data,
        error: null,
        policyCancellationLoading: false
    });
};
const policyPaymentDueCardSuccess = (state, action) => {
    return updateObject(state, {
        policyPaymentDueCardData: action.data,
        error: null,
        policyPaymentDueCardLoading: false
    });
};
const policyResubscriptionCardSuccess = (state, action) => {
    return updateObject(state, {
        policyResubscriptionCardData: action.data,
        error: null,
        policyResubscriptionCardLoading: false
    });
};
const oyoPolicyListSuccess = (state, action) => {
    return updateObject(state, {
        quotationListData: action.data,
        quotationTotalCount: action.count,
        error: null,
        quotationListLoading: false
    });
};
const cycleBrandsListSuccess = (state, action) => {
    return updateObject(state, {
        cycleBrandsListData: action.data,
        error: null,
        cycleBrandsListLoading: false
    });
};

//  FAIL 
const policyInputsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyInputsLoading: false
    });
};
const policyProductListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyProductListLoading: false
    });
};
const policyStatusListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyStatusListLoading: false
    });
};
const policyProviderListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyProviderListLoading: false
    });
};
const policyDealerListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyDealerListLoading: false
    });
};
const policyCityListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyCityListLoading: false
    });
};
const policyDealerCreditFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyDealerCreditLoading: false
    });
};
const policyBrandsListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyBrandsListLoading: false
    });
};

const policyListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyListLoading: true
    });
};
const transactionListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        transactionListLoading: true
    });
};
const policyHolderDetailsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyHolderDetailsLoading: true
    });
};
const retrieveQuotationFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loadingQuotation: false,
    });
};
const policyCancellationFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyCancellationLoading: false
    });
};
const policyPaymentDueCardFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyPaymentDueCardLoading: false
    });
};
const policyResubscriptionCardFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyResubscriptionCardLoading: false
    });
};
const oyoPolicyListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        quotationListLoading: true
    });
};
const cycleBrandsListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        cycleBrandsListLoading: false
    });
};

// for custom brand fix
const policyCustomBrandShow = (state, action) => {
    return updateObject(state, {
        showCustomBrands: action.show
    });
};
const cycleCustomBrandShow = (state, action) => {
    return updateObject(state, {
        showCycleCustomBrands: action.show
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_POLICY_INPUTS_START:
            return policyInputsStart(state, action);

        case actionTypes.GET_POLICY_PRODUCT_LIST_START:
            return policyProductListStart(state, action);

        case actionTypes.GET_POLICY_STATUS_LIST_START:
            return policyStatusListStart(state, action);

        case actionTypes.GET_POLICY_PROVIDER_LIST_START:
            return policyProviderListStart(state, action);

        case actionTypes.GET_POLICY_DEALER_LIST_START:
            return policyDealerListStart(state, action);

        case actionTypes.GET_POLICY_CITY_LIST_START:
            return policyCityListStart(state, action);

        case actionTypes.GET_POLICY_DEALER_CREDIT_START:
            return policyDealerCreditStart(state, action);

        case actionTypes.GET_POLICY_BRANDS_START:
            return policyBrandsListStart(state, action);

        case actionTypes.GET_POLICY_LIST_START:
            return policyListStart(state, action);

        case actionTypes.GET_TRANSACTION_LIST_START:
            return transactionListStart(state, action);

        case actionTypes.GET_POLICY_HOLDER_DETAILS_START:
            return policyHolderDetailsStart(state, action);

        case actionTypes.POST_RETRIEVE_QUOTATION_DATA_START:
            return retrieveQuotationStart(state, action);

        case actionTypes.GET_POLICY_CANCELLATION_START:
            return policyCancellationStart(state, action);

        case actionTypes.GET_POLICY_PAYMENT_DUE_CARD_START:
            return policyPaymentDueCardStart(state, action);

        case actionTypes.GET_POLICY_RESUBSCRIPTION_DUE_CARD_START:
            return policyResubscriptionCardStart(state, action);

        case actionTypes.GET_QUOTATION_LIST_START:
            return oyoPolicyListStart(state, action);

        case actionTypes.GET_CYCLE_BRANDS_START:
            return cycleBrandsListStart(state, action);



        case actionTypes.GET_POLICY_INPUTS_SUCCESS:
            return policyInputsSuccess(state, action);

        case actionTypes.GET_POLICY_PRODUCT_LIST_SUCCESS:
            return policyProductListSuccess(state, action);

        case actionTypes.GET_POLICY_STATUS_LIST_SUCCESS:
            return policyStatusListSuccess(state, action);

        case actionTypes.GET_POLICY_PROVIDER_LIST_SUCCESS:
            return policyProviderListSuccess(state, action);

        case actionTypes.GET_POLICY_DEALER_LIST_SUCCESS:
            return policyDealerListSuccess(state, action);

        case actionTypes.GET_POLICY_CITY_LIST_SUCCESS:
            return policyCityListSuccess(state, action);

        case actionTypes.GET_POLICY_DEALER_CREDIT_SUCCESS:
            return policyDealerCreditSuccess(state, action);

        case actionTypes.GET_POLICY_BRANDS_SUCCESS:
            return policyBrandsListSuccess(state, action);

        case actionTypes.GET_POLICY_LIST_SUCCESS:
            return policyListSuccess(state, action);

        case actionTypes.GET_TRANSACTION_LIST_SUCCESS:
            return transactionListSuccess(state, action);

        case actionTypes.GET_POLICY_HOLDER_DETAILS_SUCCESS:
            return policyHolderDetailsSuccess(state, action);

        case actionTypes.POST_RETRIEVE_QUOTATION_DATA_SUCCESS:
            return retrieveQuotationSuccess(state, action);

        case actionTypes.GET_POLICY_CANCELLATION_SUCCESS:
            return policyCancellationSuccess(state, action);

        case actionTypes.GET_POLICY_PAYMENT_DUE_CARD_SUCCESS:
            return policyPaymentDueCardSuccess(state, action);

        case actionTypes.GET_POLICY_RESUBSCRIPTION_DUE_CARD_SUCCESS:
            return policyResubscriptionCardSuccess(state, action);

        case actionTypes.GET_QUOTATION_LIST_SUCCESS:
            return oyoPolicyListSuccess(state, action);

        case actionTypes.GET_CYCLE_BRANDS_SUCCESS:
            return cycleBrandsListSuccess(state, action);



        case actionTypes.GET_POLICY_INPUTS_FAIL:
            return policyInputsFail(state, action);

        case actionTypes.GET_POLICY_PRODUCT_LIST_FAIL:
            return policyProductListFail(state, action);

        case actionTypes.GET_POLICY_STATUS_LIST_FAIL:
            return policyStatusListFail(state, action);

        case actionTypes.GET_POLICY_PROVIDER_LIST_FAIL:
            return policyProviderListFail(state, action);

        case actionTypes.GET_POLICY_DEALER_LIST_FAIL:
            return policyDealerListFail(state, action);

        case actionTypes.GET_POLICY_CITY_LIST_FAIL:
            return policyCityListFail(state, action);

        case actionTypes.GET_POLICY_DEALER_CREDIT_FAIL:
            return policyDealerCreditFail(state, action);

        case actionTypes.GET_POLICY_BRANDS_FAIL:
            return policyBrandsListFail(state, action);

        case actionTypes.GET_POLICY_LIST_FAIL:
            return policyListFail(state, action);

        case actionTypes.GET_TRANSACTION_LIST_FAIL:
            return transactionListFail(state, action);

        case actionTypes.GET_POLICY_HOLDER_DETAILS_FAIL:
            return policyHolderDetailsFail(state, action);

        case actionTypes.POST_RETRIEVE_QUOTATION_DATA_FAIL:
            return retrieveQuotationFail(state, action);

        case actionTypes.GET_POLICY_CANCELLATION_FAIL:
            return policyCancellationFail(state, action);

        case actionTypes.GET_POLICY_PAYMENT_DUE_CARD_FAIL:
            return policyPaymentDueCardFail(state, action);

        case actionTypes.GET_POLICY_RESUBSCRIPTION_DUE_CARD_FAIL :
            return policyResubscriptionCardFail(state, action);

        case actionTypes.GET_QUOTATION_LIST_FAIL:
            return oyoPolicyListFail(state, action);

        case actionTypes.GET_CYCLE_BRANDS_FAIL:
            return cycleBrandsListFail(state, action);


        // for custom brand fix
        case actionTypes.CUSTOM_BRAND_SHOW:
            return policyCustomBrandShow(state, action);

            case actionTypes.CUSTOM_CYCLE_BRAND_SHOW:
            return cycleCustomBrandShow(state, action);


        default:
            return state;
    }
};

export default reducer;